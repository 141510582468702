@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

#avocado {

  .detailed-results {
    padding: 0;

    .ps-info {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 20px;
      > .ps-item {
        margin-right: 10px;
        .psi-label {
          font: normal normal 600 12px/24px Open Sans;
          letter-spacing: 0px;
          color: #272727;
        }
        input, .ant-select-selector {
          border-radius: 5px;
          box-shadow: 0px 2px 3px #0000001F;
          height: 40px;
        }
        .spsg-filter-select {
          min-width: 200px;
          .box-wrapper {
            .box {
              background: transparent;
              font-family: Proxima-Nova, Arial, Helvetica, sans-serif;
              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0);
              padding: 0 0 0 4px;
              margin: 0;
            }
          }
        }
      }
    }

    .ps-divide {
      width: 100%;
      height: 1px;
      content: '';
      background: #2D2D2D;
      margin: 20px 0;
      opacity: 0.2;
    }

    .ps-title {
      font: normal normal 300 21px/31px Oswald;
      letter-spacing: 0px;
      color: #000000;
      margin-bottom: 20px;
    }

    .ant-alert {
      border-radius: 4px;
      margin-bottom: 20px;
      &.ant-alert-success {
        background: #D6FFEB;
        border: 1px solid #36C285;
      }
      &.ant-alert-warning {
        background: #FBFFD6;
        border: 1px solid #FF9100;
      }

      .ant-alert-message > span {
        font: normal normal normal 15px/20px Open Sans;
        letter-spacing: 0px;
        color: #3B3B3B;
      }
    }
    

    .back {
      font-family: 'Oswald';
      font-weight: 300;
      font-size: 18px;
      color: #411F90;
      > span {
        box-sizing: border-box;
        position: relative;
        top: 3px;
        display: inline-block;
        transform: scale(var(--ggs,1));
        width: 22px;
        height: 22px;
        border: 2px solid transparent;
        border-radius: 100px;
        &:after {
          content: "";
          display: block;
          box-sizing: border-box;
          position: absolute;
          width: 10px;
          height: 10px;
          border-bottom: 2px solid #411F90;
          border-left: 2px solid #411F90;
          transform: rotate(45deg);
          left: 6px;
          top: 4px
        }
      }
    }

    .title-privacy-lens {
      font-size: 28px;
      color: #2D2D2D;
      font-weight: 400;
      font-family: 'Oswald', sans-serif;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .pl-download {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 3px #0000001F;
      border: 1px solid #D4D4D4;
      border-radius: 20px;
      overflow: hidden;
      padding: 24px;
      margin-bottom: 30px;
      > .download {
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 30px;
        margin-bottom: 30px;
        position: relative;
        .info {
          font: normal normal normal 14px Open Sans;
          color: #777D94;
          position: absolute;
          top: 0px;
          right: 0px;
          svg {
            color: #777D94;
            margin-right: 8px;
          }
        }
        h2 {
          flex: 1;
          text-align: left;
          font-family: 'Oswald';
          font-weight: 300;
          font-size: 24px;
          letter-spacing: 0.48px;
          color: #000000;
          margin: 0;
        }        
        > p {
          margin: 20px 0 30px 0;
        }
      }
    }

    .sldr {
      margin-bottom: 46px;
      p {
        font: normal normal normal 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #2D2D2D;
      }
      .ant-slider {
        .ant-slider-track {
          height: 14px;
          background-color: #00B3A6;
        }
        .ant-slider-rail {
          height: 14px;
        }
        .ant-slider-dot {
          display: none;
        }
        .ant-slider-handle {
          background: #F9F9F9;
          border-color: #F9F9F9;
          box-shadow: 0px 3px 6px #00000029;
          width: 24px;
          height: 24px;
        }
        .ant-slider-mark {
          top: 24px;
          > span {
            font: normal normal 300 14px Oswald;
            color: #707070;
            &:nth-child(2) {
              color: #00B3A6;
            }
          }
        }
      }
    }

    .scoreboard {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 -15px;
      .scr-title {
        flex: 1;
        .ttl {
          margin-bottom: 46px;
          text-align: left;
          font: normal normal normal 14px/19px Open Sans;
          letter-spacing: 0px;
          color: #2D2D2D;
          padding-left: 14px;
        }
      }
      .score {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 3px #0000001F;
        border: 1px solid #D4D4D4;
        border-radius: 20px;
        height: 160px;
        overflow: hidden;
        margin: 0 15px;
        .avg-score {
          color: #2D2D2D;
          font-size: 12px;
          font-family: Open Sans;
          b {
            font-weight: 600;
          }
        }
        .score-info {
          padding: 24px;
          flex: 1;
          position: relative;
          > h4 {
            font-family: 'Open Sans';
            font-weight: normal;
            font-size: 16px;
            color: #2D2D2D;
            margin: 0 0 12px 0;
          }
          .download {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #5833AF;
            border-radius: 6px;
            width: 24px;
            height: 24px;
            cursor: pointer;
            position: absolute;
            right: 12px;
            top: 26px;
            svg {
              color: #5833AF;
              font-size: 14px;
            }
          }
          .score-amnt {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 48px;
            > .score-count {
              font-family: 'Open Sans';
              font-weight: 600;
              font-size: 16px;
              padding-right: 20px;
              border-right: 1px solid #D4D4D4;
              > div {
                font-weight: 600;
                font-size: 12px;
                color: #7C828A;
              }
            }
            > .progress {
              background: #D7D9DC 0% 0% no-repeat padding-box;
              height: 14px;
              flex: 1;
              position: relative;
              margin-left: 20px;
              .amount {
                display: block;
                content: '';
                clear: both;
                background: #038FE9 0% 0% no-repeat padding-box;
                height: 14px;
                width: 76%;
                position: absolute;
                left: 0;
                top: 0;
              }
              &.green > .amount {
                background: #00B3A6;
              }
              &.blue > .amount {
                background: #038FE9;
              }
              &.red > .amount {
                background: #FC434B;
              }
            }
          }
        }
        .score-percent {
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #038FE9;
          font-family: 'Oswald';
          font-weight: medium;
          font-size: 32px;
          letter-spacing: 0px;
          color: #FFFFFF;
          height: 100%;
          &.blue {
            background: #038FE9;
          }
          &.green {
            background: #00B3A6;
          }
          &.red {
            background: #FC434B;
          }
          > i {
            font-size: 10px;
          }
        }
      }
    }

    .pl-all {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      > div {
        flex: 1;
      }
      .second {
        flex: 2;
        margin-left: 20px;
      }
    }

    .performance {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 3px #0000001F;
      border: 1px solid #D4D4D4;
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 30px;
      .ant-tag-volcano {
        margin-right: 0,
      }
      .performance-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 24px 0 24px;
        h2 {
          flex: 1;
          text-align: left;
          font-family: 'Oswald';
          font-weight: 200;
          font-size: 24px;
          letter-spacing: 0.48px;
          color: #000000;
          b {
            font-weight: 300;
          }
        }
        .download {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #5833AF;
          border-radius: 10px;
          width: 42px;
          height: 42px;
          cursor: pointer;
          svg {
            color: #5833AF;
            font-size: 16px;
          }
        }
      }
      .rule-filters {
        padding: 16px 24px 24px 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        button {
          margin-left: 12px;
          outline: 0;
          box-shadow: none;
          background: #F5F5F5;
          &:hover, &.active {
            border-color: #411F90;
            color: #411F90;
            background: #fff;
          }
        }
        .clear {
          color: #6565A9;
          cursor: pointer;
          margin-right: 4px;
          svg {
            margin-right: 4px;
          }
          &:hover {
            color: #411F90;
          }
        }
      }
    }

    .rule-antd {
      margin: 0 40px;
      .progress {
        background: #D7D9DC 0% 0% no-repeat padding-box;
        height: 14px;
        flex: 1;
        position: relative;
        margin-bottom: 5px;
        > div {
          display: block;
          content: '';
          clear: both;
          background: #00B3A6 0% 0% no-repeat padding-box;
          height: 14px;
          width: 76%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .ant-table-row td {
        h4 {
          font: normal normal 600 14px/14px Open Sans;
          color: #2d2d2d;
        }
        h2 {
          font: normal normal 600 22px/14px Open Sans;
          color: #2d2d2d;
        }
      }
      p {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 12px;
        color: rgba(45, 45, 45, 0.7);
        margin: 0;
      }
      .ant-table-thead th {
        background: #fff;
        font: normal normal 600 14px/14px Open Sans;
        letter-spacing: 0px;
        color: #2D2D2D;
      }
      .mustave {
        color: #038FE9;
        font-size: 24px;
        line-height: 24px;
        position: relative;
        top: 6px;
        margin-right: 10px;
      }
      .pscore {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        > div {
          flex: 1;
          font: normal normal normal 14px/24px Open Sans;
          letter-spacing: 0px;
          color: #2D2D2D;
          span {
            color: #038FE9;
            font-size: 24px;
            line-height: 24px;
            position: relative;
            top: 6px;
            margin-right: 10px;
          }
          &:last-child {
            text-align: right;
          }
        }
      }
    }

    .rule-tbl {
      display: flex;
      flex-direction: row;
      border-top: 1px solid rgba(45, 45, 45, 0.1);
      margin-bottom: 30px;
      .left-bar {
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          transform: rotate(-90deg);
          color: #2D2D2D;
          opacity: 0.6;
          font-family: 'Open Sans';
          font-size: 14px;
        }
      }

      table {
        flex: 1;
        tr > td {
          text-align: left;
          width: 50%;
          padding: 16px 24px;
          border-bottom: 1px solid rgba(45, 45, 45, 0.1);
          border-left: 1px solid rgba(45, 45, 45, 0.1);
          &:first-child {
            width: 30%;
            text-align: right;
            h4 {
              font-family: 'Open Sans';
              font-weight: 600;
              font-size: 14px;
              color: #2D2D2D;
              margin: 0;
              > span {
                color: #038FE9;
                font-size: 14px;
                margin-right: 4px;
              }
            }
          }
          &:last-child {
            width: 20%;
            font-family: 'Open Sans';
            font-weight: 600;
            font-size: 20px;
            color: #2D2D2D;
          }
          > .progress {
            background: #D7D9DC 0% 0% no-repeat padding-box;
            height: 14px;
            flex: 1;
            position: relative;
            margin-bottom: 5px;
            > div {
              display: block;
              content: '';
              clear: both;
              background: #00B3A6 0% 0% no-repeat padding-box;
              height: 14px;
              width: 76%;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
          p {
            font-family: 'Open Sans';
            font-weight: 400;
            font-size: 12px;
            color: rgba(45, 45, 45, 0.7);
            margin: 0;
          }
        }
        tr.legend > td {
          border: 0 !important;
          span {
            display: inline-block;
            font-family: 'Open Sans';
            font-size: 14px;
            color: rgba(45, 45, 45, 0.6);
          }
          > span:first-child {
            width: 50%;
            text-align: left;
          }
          > span:last-child {
            width: 50%;
            text-align: right;
          }
        }
      }
    }

    .properties-tbl {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 3px #0000001F;
      border: 1px solid #D4D4D4;
      border-radius: 20px;
      overflow: hidden;
      padding: 24px;
      position: relative;
      .spsg-input {
        box-shadow: 0px 2px 3px #0000001f;
        margin-bottom: 30px;
        width: 190px;
      }
      .tbl-filters {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        top: 30px;
        right: 30px;
        button {
          margin-left: 12px;
          outline: 0;
          box-shadow: none;
          background: #F5F5F5;
          &:hover, &.active {
            border-color: #411F90;
            color: #411F90;
            background: #fff;
          }
        }
        .download {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #5833AF;
          border-radius: 8px;
          width: 32px;
          height: 32px;
          cursor: pointer;
          margin-left: 16px;
          svg {
            color: #5833AF;
            font-size: 16px;
          }
        }
        .clear {
          color: #6565A9;
          cursor: pointer;
          margin-right: 4px;
          svg {
            margin-right: 4px;
          }
          &:hover {
            color: #411F90;
          }
        }
      }
      .ant-table-wrapper {
        thead > tr > th {
          &.must-have {
            background: #6565A9;
            color: #fff;
          }
          &.nice-to-have {
            background: #8D8DBB;
            color: #fff;
          }
          &.ant-table-cell {
            div.rule-weight {
              span.ant-tag.spsg-chip {
                background-color: #B3B3F3;
                border-radius: 8px;
                font-weight: 600;
                margin-right: 0px;
              }
            }
          }
        }
      }
      span.rules-icon {
        font-weight: 900;
        font-size: 12px;
        &.not-applicable {
          color: #3E3E3E;
          padding: 0px 4px;
        }
      }
    }

  }

}

.spsg-filter-dropdown.scoring-results-rules {
  min-width: 180px;
  span.rules-icon {
    font-weight: 900;
    font-size: 12px;
    &.not-applicable {
      color: #3E3E3E;
      padding: 0px 4px;
    }
  }
}
