  @font-main: proxima-nova, ProximaNova, Helvetica, Arial;
  .scenario-v2 {
    section {
      padding-left: 0;
    }
    .initial-alert-message {
      font-size: 13px;
      margin: 15px 30px 0px;
      border-radius: 4px;
      border: 1px solid #5ECDE5;
      background-color: #E9FAFC;
      .anticon-info-circle.ant-alert-icon {
        color: #5ECDE5;
      }
      .ant-alert-close-icon {
        color: #899AB4;
      }
      .anticon.anticon-close-circle, .anticon-info-circle.ant-alert-icon {
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    .template-edit-page {
      background: #f1f3f6;
      position: fixed;
      overflow: auto;
      height: 100%;
      z-index: 9999;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .template-header {
      background: white;
      height: 60px;
      display: flex;
      flex-direction: row;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 1;
      width: 100%;
      border: 1px solid #ddd;
    }
    .template-title {
      color: black;
      flex: 1;
      margin-left: 1%;
      display: flex;
      align-items: center;
      .ant-input {
        border: 0 !important;
      }
      .ant-input-affix-wrapper {
        border: 1px solid #fff;
      }
      .ant-input-affix-wrapper:focus {
        border: 1px solid #d9d9d9;
        box-shadow: none;
      }
      .header-button-group {
        display: inline-flex;
        height: 28px;
        margin-left: 1%;
        flex: 0.5;
        .header-submit-btn {
          line-height: initial;
          margin-right: 1%;
          background: #1890ff;
          color: #fff;
          font-size: 15px;
        }
        .header-close-btn {
          line-height: initial;
          background: #b3bfd0;
          color: #fff;
          font-size: 15px;
        }
      }
    }
    .template-button {
      margin-right: 15px;
      .btn-secondary{
        margin-right: 10px;
      }
    }
    .step-card {
      height: fit-content;
      width: fit-content;
      margin: 15px 10px 10px 10px;
      box-shadow: 0px 3px 6px #00000029;
      .anticon-close,
      .ant-tag-has-color,
      .ant-tag-has-color a,
      .ant-tag-has-color a:hover,
      .ant-tag-has-color .anticon-cross,
      .ant-tag-has-color .anticon-cross:hover {
        color: #1c6be3;
      }
    }
    .step-card.active {
      border: 2px solid #1890ff;
    }
    .ant-card-head {
      height: 20px;
      background: #f3f8fc;
    }
    .condition-title {
      color: #333333;
      font-style: bold;
      text-transform: capitalize;
    }
    .message-type-option {
      display: flex;
    }
    .condition-tag {
      background: #e0edfe;
    }
    .conditions-box {
      border: 1px solid #dfdfdf;
      margin-top: 10px;
      border-radius: 5px;
      .msg-delay-wrapper {
        display: flex;
        .select-container {
          margin-left: 5px;
          .info-circle {
            font-size: 12px;
            margin-left: 1%;
            svg {
              color: #899AB4;
            }
          }
        }
        .select-container:first-child {
          margin-left: 0px;
        }
      }
    }
    .sidepanel-box {
      background: white;
      flex: 0.6;
      height: 100%;
      margin: 10px;
    }

    .sidepanel-container {
      width: 20%;
      background: white;
      position: fixed;
      z-index: 2;
      right: 0px;
      top: 61px;
      height: 100%;
      padding-bottom: 100px;
      overflow: scroll;
      border-left: 1px solid #ddd;
      h4 {
        font-size: 14px;
        font-family: @font-main;
      }
      h5 {
        font-size: 12px;
        font-weight: 400;
        font-family: @font-main;
      }
      .ant-list-vertical .ant-list-item-content {
        margin-bottom: 0px;
      }
    }

    .sub-title {
      background: #f9fafb;
      color: 'black';
      padding: 10px;
    }
    .name-container {
      padding: 10px;
    }
    .step-header {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      width: 100%;
      i {
        cursor: pointer;
      }
      .edit-step-wrapper {
        .ant-input {
          width: 50%;
          border: 1px solid #1890ff;
        }
        button:first-of-type {
          background-color: #1370fb;
          border-color: #1370fb;
          color: #fff;
          font-weight: bold;
          margin-left: 10px;
        }
        button:last-of-type {
          background: #c9cbce;
          border-color: #c9cbce;
          color: #fff;
          font-weight: bold;
          border-radius: 0;
          margin-left: 10px;
        }
      }
    }
    .template-edit-page {
      .ant-input {
        border: none;
        border-bottom: 1px solid #dfdfdf;
        padding: 5px 10px;
        outline: none;
        background: transparent;
      }

      .ant-input-number {
        border: none;
        border-bottom: 1px solid #dfdfdf;
        background-color: #fff;
        border: 1px solid #d9d9d9;
        outline: none;
      }

      .ant-select-selection {
        border: 0px;
        background: transparent;
        border-bottom: 1px solid #dfdfdf;
        color: #000000;
      }

      .add-step-wrapper {
        padding: 10px;
        width: 100%;
        bottom: 30px;
        #add-step.ant-btn {
          width: 100%;
          text-align: left;
          height: 40px;
          font-size: 14px;
        }
      }
      .ant-collapse {
        width: 100%;
        padding: 0px 10px;
        border: 0;
        background: transparent;
        height: 80vh;
        overflow-y: scroll;
        .ant-collapse-item {
          border: 1px solid #d9d9d9;
          margin: 20px 0px;
          .ant-collapse-header {
            padding: 0;
            height: auto;
            .step-header {
              .custom-collapse-icon {
                background-color: transparent;
                border: 0;
                align-self: baseline;
                line-height: 28px;
                color: #411F90;
              }
            }
          }
        }
        .ant-collapse-item,
        .ant-collapse-item.active {
          background: #FFFFFF 0% 0% no-repeat padding-box;
          box-shadow: 0px 5px 10px #0000001F;
          border: 1px solid #D4D4D4;
          border-radius: 18px;
          .ant-collapse-content.ant-collapse-content-active {
            border-radius: 0 0 18px 18px;
          }
        }
      }
    }
    .ant-card-head {
      height: 15px;
      background: #f3f8fc;
    }

    .ant-card-body {
      padding: 10px;
    }
    .step-conditions-wrapper {
      display: flex;
      flex-direction: column;
      padding: 0px 12px;
      background: rgb(255, 255, 255);
      .add-condition-btn {
        margin: 5px;
        border-color: #1370FB;
        color: #1370FB;
        padding: 0 7px;
        height: auto;
        font-size: 12px;
        line-height: 20px;
      }
    }
    .condition-title {
      color: black;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
    }
    .condition-tag {
      background: #e0edfe;
    }
    .conditions-box {
      border: 1px solid #dfdfdf;
      margin-top: 10px;
      border-radius: 5px;
      padding: 10px;
    }
    .select-container-main {
      display: flex;
      flex-direction: row;
    }
    .select-container {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding: 2px;
      .ant-select {
        width: 100%;
      }
      .time-delay-alert {
        width: 740px;
        font-size: 12px;
        font-family: open Sans;
        font-weight: 500;
        border: 0;
        border-radius: 4px;
        margin-top: 10px;
        align-items: flex-start;  
        .anticon-warning.ant-alert-icon{
          color: #FF9100; 
        } 
      }
    }
    .select-message-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .spsg-select{
        .ant-select{
          width: 400px !important;
        }
      }
      span.anticon.anticon-eye {
        margin-top: 34px;
        margin-left: 10px;
        color: #411F90;
        z-index: 1;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    .alert-message {
      font-size: 13px;
      margin: 15px 0px;
      border-radius: 4px;
      align-items: flex-start;
      max-width: 240px;
      background-color: #E9FAFC;
      border: 0;
      border-radius: 4px;
      .anticon-info-circle.ant-alert-icon {
        color: #5ECDE5;
      }
    }

    .time-delay-alert .anticon,
    .alert-message .anticon{
      padding-right: 0px;
      padding-top: 2px;
      &.anticon-info-circle.ant-alert-icon,
      &.anticon-warning.ant-alert-icon {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
    .add-condition-block {
      display: flex;
    }
    .condition-dropdown-container {
      display: flex;
      flex-direction: column;
      padding: 2px;
      margin-right: 1%;
      .ant-select {
        width: 280px;
        .ant-select-selector{
          display: flex;
          align-items: center;
        }
      }
    }
    .repeat-msg {
      width: 15%;
    }
    .label-style {
      color: #4b4b4b;
      font-size: 11px;
    }

    .ant-layout-sider {
      background: #3ba0e9;
      color: #fff;

      flex: 0.3;
    }
    .ant-layout-content {
      background: rgba(16, 142, 233, 1);
      color: #fff;
      min-height: calc(100vh-50px);
      padding-bottom: 1%;

      .anticon-close,
      .ant-tag-has-color,
      .ant-tag-has-color a,
      .ant-tag-has-color a:hover,
      .ant-tag-has-color .anticon-cross,
      .ant-tag-has-color .anticon-cross:hover {
        color: #1c6be3;
      }
    }
    .delete-step-popup {
      .ant-popover-message-title {
        color: #f00;
      }
      .ant-popover-inner-content,
      .ant-popover-buttons {
        color: #f00;
        button:first-of-type {
          background: #ffffff;
          border: 1px solid #d9d9d9;
          color: #847c7c;
        }
        button:last-of-type {
          font-size: 14px;
          color: #fff;
          background-color: #1890ff;
        }
      }
    }
  }
  .scenario-view-message-wrapper {
    flex: 1;
    height: 100%;
    iframe {
      background-color: #595959;
      height: 100%;
      width: 100%;
    }
  }
  .scenario-modal-wrapper {
    .ant-modal {
      width: 100% !important;
      max-width: 100%;
      height: 100%;
      background: transparent;
      padding-bottom: 0;
      .ant-modal-close-x {
        width: auto;
      }
      .ant-modal-content {
        height: 100%;
        background: transparent;
        padding: 0;
        .ant-modal-header{
          margin: 0;
          padding: 16px 24px;
        }
        .ant-modal-close{
          &:hover{
            background: transparent;
          }
          top: 0;
          right: 0;
          width: auto
        }
        .ant-modal-body {
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100vh - 68px);
          padding: 24px;
          .message-preview {
            height: 100%;
            width: 100%;
            .iframe-full-screen-container {
              height: 100%;
              #preview-iframe {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
      .meesage-title-verticle-line{
        font-size: 32px;
        font-weight: 700;
      }
      h3 {
        margin: 0 0 0 0;
      }
      .message-id{
        font-size: 16px;
        color : #03a9f4;
      }
    }
  }
  .hide-scenario-multiple-dropdown {
    display: none;
  }

.sortableHelper {
  height: auto !important;
  z-index: 10000 !important;
  background: #fff;
  border: 1px solid #d9d9d9;
  margin: 20px 0px;
  .hide-condition-panel {
    padding: 16px;
    .add-condition-block {
      display: none;
    }
  }
  .ant-collapse-header {
    padding: 0;
    height: auto;
    .step-header {
      .step-header-arrow {
        margin-left: 0px !important;
      }
      .custom-collapse-icon {
        background-color: transparent;
        border: 0;
        align-self: baseline;
        line-height: 28px;
      }
      .step-conditions-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0px 12px;
        background: rgb(255, 255, 255);
        .add-condition-btn {
          margin: 5px;
          border-color: #1370FB;
          color: #1370FB;
          padding: 0 7px;
          height: auto;
          font-size: 12px;
          line-height: 20px;
        }
        .condition-title {
          color: #333333;
          font-weight: bold;
          text-transform: capitalize;
        }
      }
    }
  }
  .ant-collapse-content {
    .label-style {
      color: #4b4b4b;
      font-size: 11px;
    }
    .select-container {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding: 2px;
    }
    .select-container-main {
      display: flex;
      flex-direction: row;
    }
    .condition-title {
      color: #333333;
      font-weight: bold;
      text-transform: capitalize;
    }
    .conditions-box {
      border: 1px solid #dfdfdf;
      margin-top: 10px;
      border-radius: 5px;
      padding: 10px;
      .msg-delay-wrapper {
        display: flex;
        .select-container {
          margin-left: 5px;
        }
        .select-container:first-child {
          margin-left: 0px;
        }
      }
    }
  }
}

.info-container{
  .ant-popover-content .ant-popover-inner{
    width: 500px;
  }
}
