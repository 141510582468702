  button.advanced-settings {
    // margin-left: 10px;
    // position: absolute;
    // top: 149px;
    // right: 163px;
    color: black;
    font-size: 13px;
    position: relative;
    top: -200px;
  }

  .ant-modal.vl-advanced-settings {
    .ant-select-selection {
      width: 130px;
      margin-left: 10px;
    }

    label {
      font-size: 12px;
    }

    i {
      margin-left: 10px !important;
    }

    .cookies-expiration {
      display: flex;
      margin-top: 12px;

      input {
        width: 80px;
        margin-left: 10px;
        margin-right: 10px;
      }

      span {
        position: relative;
        top: 5px;
      }
    }

    .mms-domain {
      .invalid-url {
        color: red;
      }
    }

    .auto-scan {
      p {
        margin-bottom: 0;
      }
    }

    p.title {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 30px;
    }

    span {
      font-size: 12px;
    }

    .share-root-domain-checkbox {
      label {
        display: flex;
      }
      .spsg-alert.ant-alert {
        .anticon-warning {
          color: #FF9100;
          font-size: 20px;
        }
        .ant-alert-content .ant-alert-message{
          font-size: 10px;
        }
      }
    }

    .default-consent-type {
      label {
        margin-right: 10px;
      }
    }

    .anticon.anticon-info-circle {
      margin-left: 10px;
    }

    .special-treatment-p1 {
      display: flex;
      padding-top: 20px;

      p {
        font-size: 13px;
        font-weight: bold;
      }

      .country-select {
        margin-top: -5px;

        .ant-select-selector {
          width: 130px;
          margin-left: 10px;
        }
      }
    }

    .footer-container{
      display: flex;
      flex:1;
      flex-direction: row;
      align-items: center;
      .footer-btn-container{
        flex:1;
      }
      .settings-container{
        display: flex;
        align-items: center;
        cursor: pointer;
        color:#411F90;
        .setting-icon{
          margin-right:2px;
          color:#411F90;
        }
      }
    }
  }

  .flex-row {
    display: flex;
  }
