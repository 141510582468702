@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');

#avocado {
  .main {
    background-color: white !important;
  }
  .select-score {
    .ant-select-selection {
      width: 50px;
    }
  }
  .select-score-dropdown {
    width: 50px !important;
  }

  .ant-dropdown-menu.download-report {
    right: 20px;
    top: 200px;
    left: auto;
  }

  a.ant-dropdown-link {
    position: absolute;
    right: 20px;
    top: 175px;
    font-size: 13px;
  }


  .ant-dropdown-menu.scoring-report {
    button {
      border: 0;
      width: 80%;
      text-align: left;
    }

    a.export-csv {
      width: 30px;
      margin-top: 10px;
      margin-left: 20px;
      height: 30px;
      color: blue;
    }

    p.csv {
      position: absolute;
      left: 38px;
      font-size: 6px;
      font-weight: bold;
      color: blue;
      top: 200px;
    }

    i {
      position: absolute;
    }

    .anticon-file {
      right: 149px;
      left: 35px;
      font-size: 20px;
    }

    .export-pdf-icon {
      font-size: 20px;
      color: red;
      cursor: pointer;
      position: absolute;
      top: 198px;
      left: 74px;
    }

    i.anticon-check {
      right: 40px;
    }

    ul {
      height: 200px !important;
    }

    li {
      padding-bottom: 0;
      padding-top: 0;
    }

    p {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0;

      &.select-type {
        font-weight: bold;
      }

      &.undetermined {
        height: 40px;
        border-top: 1px solid lightgray;
        margin-top: 10px;
      }
    }

    .ant-radio-group {
      display: flex;
      position: absolute;
      right: 10px;
      top: 154px;

      label {
        font-size: 12px;
        font-weight: 500;
      }
    }

    li:last-child {
      height: 40px;
      border-top: 1px solid lightgray;
      margin-top: 10px;
    }
  }

  .privacy-lens {
    .title-privacy-lens {
      float: left;
      font-size: 34px;
      color: #2D2D2D;
      font-weight: 200;
      font-family: 'Oswald', sans-serif;
    }
    .text-message-wrapper {
      p {
        text-align: left;
        font: normal 14px Open Sans;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }
    .search-container {
      padding: 10px 0;
      justify-content: space-between;
      display: flex;
      width: 100%;
      
      .ant-input-search {
        width: 300px !important;
        border: 1px solid #D4D4D4;
        box-shadow: 0px 2px 3px #0000001F;
        border-radius: 5px;
        input, .ant-btn{
          border: none;
        }
        
        .ant-input-search-icon{
          padding: 5px 10px 0px 0px;
          line-height: initial;
          &:before {
            border: none;
          }
        }
        
      }
      .top-bar-left {
        display: flex;

        .rule-explainers {
          display: flex;
          color: #899AB4;
          cursor: pointer;

          span {
            margin-top: 8px;
          }

          div {
            margin-right: 20px;
            margin-left: 5px;
            margin-top: 7px;
          }
        }
      }

    }

    .ant-table-wrapper {
      background-color: white;
    }

    .ant-pagination.ant-table-pagination {
      margin: 10px;
    }

    .warning-message {
      background-color: #ffc0c0;
      padding: 10px;
      padding-bottom: 5px;
      display: flex;
      margin-bottom: 20px;
      max-height: 100px;
      overflow: scroll;

      p {
        margin-bottom: 0;
      }
    }
  }
  .privacy-lens.scoring {

    .domain-set-buttons {
      border-bottom: 1px solid lightgray;
      margin-bottom: 10px;

      button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        // background-color: #94ceff;

        &.selected {
          color: white;
          background-color: #7F92BD;
        }
      }
    }

    .rule-container {
      width: 650px;
      background-color: white;
      border: 1px solid lightgray;
      border-radius: 5px;
      position: absolute;
      z-index: 1;
      padding: 0 0 20px 20px;
      top: 655px;
      left: 360px;

      img {
        width: 300px;
        margin-right: 10px;
      }

      .rule-name {
        font-weight: bold;
        font-size: 17px;
        margin-bottom: -5px;
        margin-top: 20px;
      }
      .rule-description {
        margin-bottom: 10px;
      }
    }

    a.go-back {
      font-size: 12px;
      margin-top: -10px;
      position: absolute;
    }

    .properties-count {
      margin-bottom: 20px;
      span {
        font-weight: bold;
      }
      p {
        margin-right: 20px;
        font-size: 14px;
      }
    }

    .total-domains {
      font-weight: bold;
    }

    .top-table {
      font-family: 'Proxima-Nova';
      line-height: normal;
      display: flex;
      background-color: #F3F8FC;
      padding: 20px;
      border: 1px solid #DDDDDD;
      margin-bottom: 30px;

      .left {
        width: 16%;
        p {
          font-size: 13px;
        }
        p.number {
          font-weight: bold;
        }
        div {
          margin-top: 30px
        }
      }
      .list {
        p {
          text-align: left;
          letter-spacing: 0px;
          color: #212121;
          text-transform: capitalize;
          opacity: 1;

          font-size: 15px;
        }

        .percent {
          font-weight: 600;
          font-size: 13px;
        }
        .rule-name {
          font-weight: normal;
          font-size: 13px;
          font-weight: regular;
        }

        &>p {
          font-weight: 600;
        }
        border-left: 1px solid #DDDDDD;
        padding-left: 20px;
        padding-right: 20px;
        width: 28%;
      }
    }

    table {

      th.ant-table-cell.rule-column {
        width: 120px;
      }

      td {
        // padding: 0 40px 0 40px;
        max-width: 100px;
        padding: 10px 8px;
        font-size: 11px;
      }
      td:first-child, td:nth-child(2), td:last-child {
        padding: 10px 8px 10px 8px;
        max-width: 100px;
      }
      th {
        font-size: 8px;
        padding: 10px 8px;
      }

      th p {
        font-size: 8.5px;
        &.small {
          font-size: 8px;
          text-transform: none;
        }
      }

      td.rule-column, th.rule-column {
        text-align: center;
      }

      .average {
        color: #808080c7;
      }

      //XXX
      th.total-properties {
        height: 120px;
        padding-top: 30px;
      }

      .ant-table-cell.standards.more-than-four {
        width: 120px;
      }

      th.standards {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding: 0;

        .standards-table-title {
          border-bottom: 1px solid lightgray;
          height: 20px;
          padding-top: 5px;
          color: #000000A6;
        }

        .main-table-title {
          height: 80px;
          padding-left: 10px;
          padding-right: 10px;
          margin-top: 10px;

          .small {
            color: #000000A6;
          }
        }
      }

      .actions {
        i {
          font-size: 15px;
          margin-right: 10px;
        }
        span:first-child {
          margin-right: 15px;
        }
      }
    }
    
  }
}