  .main {

    #adblock-report-actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .sites-dropdown-container {
        position: inherit;
      }
    }

    .card.adblock {
      padding: 20px;
      margin-bottom: 30px;
    }

    .filters.external {
      display: flex;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;

      .ant-calendar-picker {
        .ant-calendar-picker-input.ant-input.ant-input-lg {
          &.ant-input-disabled {
            background: #F5F5F5;
          }
          color: #354052;
          height: 35px;
          width: 240px;
        }

        .ant-calendar-picker-input.ant-input {
          font-size: 14px;
          font-family: var(--font-main);
        }
      }

      .country-select {
        margin-left: 20px;
        font-size: 14px;
        color: #354052;
        font-family: var(--font-main);
        .ant-select-selection.ant-select-selection--single {
          height: 35px;

          .ant-select-selection__rendered {
            margin-left: 15px;
            line-height: 33px;
          }
        }
      }
    }

    .filters.internal {
      display: flex;
      justify-content: space-between;
      .ant-radio-button-wrapper {
        padding: 0 10px;
        color: #354052;
        height: 35px;
        line-height: 35px;
        font-size: 14px;
        font-family: var(--font-main);
      }
      .desktop-mobile {
        float: left;
      }

      .granularity {
        float: right;
      }
    }

    .data-table {
      border-radius: 4px;
      margin-bottom: 30px;
      padding: 0;

      .ant-pagination.ant-table-pagination {
        width: 50%;
      }

      th, td {
        padding-left: 30px;
      }
    }
    .export-csv-container {
      position: absolute;
      top: 85px;
      right: 120px;
      .export-csv {
        position: fixed;
        z-index: 1000;
        border: solid 1px #d3dff0;
        border-radius: 2px;
        color: #354052;
        font-size: 14px;
        font-weight: 600;
        padding: 5px 15px;

        &:hover {
          text-decoration: none;
          background: #e8f0fc;
        }
      }
    }
  }

  // css for range picker tilda '~' to dash '-'
  span.ant-calendar-range-picker-separator,
  .ant-calendar-range-middle {
    color:transparent;

    &::after {
      content: '-';
      font-size: 16px;
      font-weight: 600;
      color:#bbb;
      margin-left:-10px;
    }
  }

  .sites-dropdown-container {
    position: absolute;
  }

  .sites-dropdown {
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    color: #212F43;
    font-family: HelveticaNeue-Medium;
    outline: none;

    .ant-select-selection.ant-select-selection--single {
      box-shadow: none;
      background: inherit;

      .ant-select-arrow {
        font-size: 10px;
      }
      border: none;
    }
  }
