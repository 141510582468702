  .ant-modal.shared-modal {
    .ant-modal-body {
      padding-right: 30px;
      padding-left: 30px;
      padding-top: 35px;

      p {
        padding-bottom: 20px;
      }

      label {
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
      }

      .title {
        font-size: 16px;
        font-weight: bold;
        line-height: 16px;
        color: #000000;
      }

      .ant-input {
        font-size: 14px;
        line-height: 14px;
        padding-top: 17px;
        padding-bottom: 17px;
      }
    }
  }
