  @steel-blue: #5875a3;
  &.account_domains, &.account-invite, &.api_keys {
    .row {
      padding: 10px 20px 0;
      margin-bottom: 20px;
    }
  }

  .avocado-delete {
    color: @steel-blue;
    opacity: .7;
    font-size: 16px;

    &:hover {
      opacity: 1;
      text-decoration: none;
    }
  }
