body.adblock-index,
body.injection-index {
    .select2-search {
        display: none;
    }

    .adblock-graph {
        @axis-width: 40px;

        .loading {
            display: none;
            z-index: 10000;
        }

        .graph {
            position: relative;
            left: @axis-width;
        }

        .y-axis {
            text {
                fill: #949494;
                font-size: 14px;
                opacity: 1;
            }

            position: absolute;

            width: @axis-width;

            top: 0;
            bottom: 0;
        }

        .x-axis {
            .tick {
                stroke: none;
            }

            text {
                fill: #949494;
                font-size: 14px;
                opacity: 1;
            }

            position: relative;
            left: @axis-width;
            height: 20px;
        }

        .legend {
            .action {
                display: none;
            }

            font-family: 'Proxima-Nova Semibold';
            font-size: 13px;

            padding-left: @axis-width;

            background-color: inherit;
            color: inherit;

            ul {
                li {
                    display: inline;
                }
            }
        }
    }

    div.controls {
        height: 100%;
        padding-left: 35px;
        padding-right: 35px;

        .enabled-filters {
            display: none;
        }

        h4 {
            font-size: 16px;
            color: #454545;

            margin-top: 25px;
        }

        .global-filters {
            .btn-group {
                display: table-cell;
                .btn, a {
                    font-size: 13px;
                    color: #999;
                }
            }
        }

        .filter-btn-group {
            text-align: center;
            margin-bottom: 15px;
        }

        .filter-form {
            margin-bottom: 15px;

            .enabled-filters {
                margin-bottom: 10px;
            }

            .filter-column, .filter-value {
                width: 33%;
                margin-right: 10px;
            }

            .add-filter,
            .remove-filter {
                color: black;
            }

            .remove-filter {
                display: none;
            }

            .add-filter {
                display: inline;
            }
        }

        .enabled-filters {
            .remove-filter {
                display: inline;
            }

            .add-filter {
                display: none;
            }
        }

        .segment-select {
            width: 100%;
        }

        .export-csv-button {
            margin-top: 30px;
        }
    }

    // Most select2 components live at the top level of the body
    .select2-results, .select2-choice {
        font-family: 'Proxima-Nova Semibold';
        font-size: 13px;
        color: #999;
    }

    .data-table {
        margin-top: 25px;
    }
}
