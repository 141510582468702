.ant-dropdown .spsg-filter-dropdown {
    .list-search {
        height: 50px;
    }
    .filter-button-group {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-top: 1px solid #d4d4d4;
        .ant-btn-secondary {
            border: 1px solid transparent;
            padding-left: 8px;
            &:hover,
            &:focus {
                color: #411f90;
            }
        }
        .ant-btn-primary {
            &:hover,
            &:focus {
                background-color: #6565a9;
                border-color: #6565a9;
            }
        }
    }

    .ant-dropdown-menu, .ant-menu {
        border: 0px;
        box-shadow: none;
        max-height: 300px;
        &-title-content {
            display: inline-flex;
            gap: 8px;
            width: 100%;
            justify-content: space-between;
        }
        > .ant-dropdown-menu-item {
            .ant-checkbox-wrapper {
                margin-right: 0;
            }
        }
        .filter-divider {
            margin: 10px 0px;
            background-color: #d4d4d4;
        }
    }
}
