@charset "UTF-8";
@font-path: "../../../fonts/";
@font-name: "sp-dialogue";

@font-face {
  font-family: @font-name;
  src        : url("@{font-path}@{font-name}.eot");
  src        : url("@{font-path}@{font-name}.eot?#iefix") format("embedded-opentype"),
  url("@{font-path}@{font-name}.woff") format("woff"),
  url("@{font-path}@{font-name}.ttf") format("truetype"),
  url("@{font-path}@{font-name}.svg#sp-dialogue") format("svg");
  font-weight: normal;
  font-style : normal;
}

[data-icon]:before {
  font-family            : "sp-dialogue" !important;
  content                : attr(data-icon);
  font-style             : normal !important;
  font-weight            : normal !important;
  font-variant           : normal !important;
  text-transform         : none !important;
  speak                  : none;
  line-height            : 1;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family            : "sp-dialogue" !important;
  font-style             : normal !important;
  font-weight            : normal !important;
  font-variant           : normal !important;
  text-transform         : none !important;
  speak                  : none;
  line-height            : 1;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ad-block-icon:before {
  content: "\61";
}

.icon-value-icon:before {
  content: "\62";
}

.icon-always-icon:before {
  content: "\63";
}

.icon-continue-icon:before {
  content: "\64";
}

.icon-custom-icon:before {
  content: "\65";
}

.icon-delay-icon:before {
  content: "\66";
}

.icon-message-icon:before {
  content: "\67";
}

.icon-off-switch:before {
  content: "\68";
}

.icon-on-switch:before {
  content: "\69";
}

.icon-once-icon:before {
  content: "\6a";
}

.icon-range-icon:before {
  content: "\6b";
}

.icon-repeat-icon:before {
  content: "\6c";
}

.icon-simple-icon:before {
  content: "\6d";
}