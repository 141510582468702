  .ant-modal.shared-modal.message-variables {
    width: 680px !important;
    top: 100px !important;

    .ant-modal-content {

      .unsaved-changes {
        position: absolute;
        background-color: white;
        padding: 20px;
        padding-top: 140px;
        z-index: 9;
        width: 100%;
        height: 100%;
        top: 0;
        text-align: center;
        border: 5px solid white;
        border-radius: 5px;

        h2 {
          font-size: 22px;
        }

        .buttons {
          display: flex;
          justify-content: center;

          button {
            margin-right: 10px;
          }
        }
      }

      .ant-modal-body {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
        max-height: 500px;

        .add-variable-container {
          display: flex;
          border-radius: 3px 3px 0 0;
          background-color: #F7F9FC;
          border-top: 1px solid #e5e9ee;
          border-bottom: 1px solid #e5e9ee;
          padding: 20px 30px;
          padding-bottom: 15px;

          input {
            width: 238px;
            margin-bottom: 0;
          }

          i {
            padding-left: 5px;
          }

          button {
            height: 36px;
            margin-top: 17px;
          }

          .ant-alert {
            position: absolute;
            top: 65px;
          }
        }

        .main-container {
          padding-right: 30px;

          display: flex;

          .site-list {
            width: 250px;
            border-right: 1px solid #e5e9ee;
            overflow: auto;
            height: 300px;
            // overflow: -webkit-paged-y;

            ul {
            }

            li {
              border-bottom: 1px solid #e5e9ee;
              padding: 20px 30px;
              font-size: 14px;
              font-weight: 600;
              line-height: 15px;

              &.highlighted {
                box-shadow: 4px 0px 0px #35CD96 inset;
              }
            }
          }

          .variable-list-container {
            padding-top: 20px;
            padding-left: 30px;
            overflow: auto;
            height: 300px;
            width: 70%;

            p.title {
              padding-bottom: 0;
            }

            label, p {
              max-width: 350px
            }

            .name-container input {
              border: none;
              margin-bottom: -10px;
              padding-bottom: 0;
              padding-top: 0;
            }
          }
        }

        label {
          display: flex;
          flex-direction: column;
          padding-right: 15px;

          .name-container {
            display: flex;
            p {
              padding-bottom: 0;
              margin-bottom: 0;
              padding-top: 5px;
              padding-right: 5px;
            }
            button {
              width: 19px;
              height: 19px;
            }
            i {
              font-size: 16px;
            }
          }

          input {
            margin-top: 5px;
          }

          &.title {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
