  @font-main: proxima-nova, ProximaNova, Helvetica, Arial;
  .scenario-v2 {
    .condition-label {
      font-size: 12px;
      color: #4a4a4a;
      margin-right: 2%;
    }
    .condition-title {
      font-size: 14px;
      color: #000000;
    }
    .condition-label-two {
      color: #4a4a4a;
      font-size: 12px;
    }
    .search-dropdown {
      width: 180;
      margin-left: 10px;
    }
    #condition-dropdown {
      width: 300px;
      margin-left: 5%;
      line-height: 28px;
      .ant-select {
        width: 100%;
        border: 0;
        .ant-select-selection--multiple,
        .ant-select-selection--single {
          border: 0;
          border-bottom: 1px solid #a79d9d;
          border-radius: 0;
        }
        .ant-select-selection-overflow-item .ant-select-selection-item{
          line-height: 25px;
        }
      }
      label {
        font-size: 12px;
        color: #4a4a4a;
      }
    }

    .ant-radio-group-solid
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      background: #fff;
      border: 1px solid #fff;
      box-shadow: none;
      color: #000;
    }
    .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: #fff;
    }
    .ant-radio-button-wrapper {
      background: #8699b3;
      border: 1px solid #8699b3;
      color: #000;
      text-align: center;
      width: 70px;
      padding: 0px;
    }
    .ant-radio-group {
      // margin-left: 2%;
    }
    .scenario-button-wrapper {
      flex: 1;
      align-self: flex-end;
    }
  }

  .scenario-condition-popover-regex {
    font-family: "Courier New"; 
    font-size: 14px; 
    font-weight: bold;
  }
