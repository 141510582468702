.scan-setup-form {
  &--modal {
    height: 100%;

    .ant-modal-header {
      padding: 0;
      .ant-modal-title {
        margin: 0;
        word-wrap: break-word;
        height: 50px;
        padding: 10px;
        img {
          height: 33px;
          width: 203px;
          margin-left: 30px;
        }
      }
    }
    .ant-modal-body {
      .bulk-scan-property {
        span {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }
      }
      .bulk-scan-performance {
        .scan-details-progress {
          span {
            color: var(--Components-Progress-Global-colorText, #272727);
            font-family: "Open Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
          .bulk-scan-performance-details {
            span {
              color: var(
                --Components-Input-Global-colorTextDescription,
                #8f8f8f
              );
            }
          }
        }
      }
    }
    .ant-modal-footer {
      margin-top: 0;
    }
    > div {
      height: inherit;
      .ant-modal-content {
        padding: 0;
      }
    }
  }
  &--container {
    max-width: 1028px;
    margin: 0 auto;
    padding-bottom: var(--ant-padding-md);
    background: transparent;
    .ant-flex {
      margin-bottom: var(--ant-margin);
    }
  }
  &--form {
    padding: 16px 104px;
    border-radius: var(--ant-border-radius-lg);
    border: 1px solid var(--ant-color-border);
    background: var(--ant-color-bg-container);
    .ant-form-item {
      margin-bottom: var(--ant-margin-xxs);
    }
    .interval-input-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .interval-input {
      flex: 1;
    }
    .custom-scenario-container {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      gap: 12px;
      border-radius: var(--Border-Radius-borderRadiusLG, 8px);
      border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d3d9dd);
      background: var(--Colors-Neutral-Bg-colorBgContainer, #fff);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03),
        0px 1px 6px -1px rgba(0, 0, 0, 0.02),
        0px 2px 4px 0px rgba(0, 0, 0, 0.02);
      .action-row {
        padding: 16px 0px;
        margin: 8px 0px;
        border-bottom: 1px solid #d3d9dd;
        background: #fff;
        .ant-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
  &--custom {
    display: flex;
    padding: var(--Space-Margin-margin, 16px) 16px 16px 16px;
    flex-direction: column;
    align-self: stretch;
    border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d3d9dd);
    border-radius: var(--Border-Radius-borderRadiusLG, 8px);
  }
}

.scan-url-modal {
  .ant-modal-body {
    padding: 0;
  }
}

.bulk-scan-sitemap {
  font-family: "Open Sans";
  font-style: normal;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;

  .ant-col {
    span {
      color: var(--ant-color-text-base);
    }
  }

  .ant-divider {
    margin: 16px 0px;
  }

  .ant-form-item-explain-success {
    color: var(--ant-color-success);
  }

  .ant-upload {
    .ant-upload-drag-container {
      .ant-upload-text {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
}
