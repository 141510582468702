@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

#avocado {
  .privacy-lens {
    .title-privacy-lens {
      text-align: left;
      font: normal normal normal 28px Oswald;
      letter-spacing: 0px;
      color: #2D2D2D;
      text-transform: uppercase;
      opacity: 1;
      margin-bottom: 20px;
    }
    .subtitle-privacy-lens {
      cursor: pointer;
      border-top: 1px solid #e8e8e8;
      margin-left: 5px;
      font-size: 22px;
      padding: 24px 0 0 0;
      letter-spacing: 0px;
      color: #2D2D2D;
      font-family: 'Oswald';
      font-weight: 200;
      margin-top: 20px;
      margin-bottom: 30px;
    }
    .vendor-filter {
      box-shadow: 0px 2px 3px #0000001F;
      border-radius: 5px;
      input {
        border-color: #D4D4D4;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      .ant-input-group-addon {
        border-color: #D4D4D4;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .scoring {

    .summary-card {
      padding: 0;
      border-radius: 20px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 10px #0000001F;
      border: 1px solid #D4D4D4;
      opacity: 1;
      margin-bottom: 30px;
      cursor: pointer;

      &:hover {
        border-color: #411F90;
      }

      .summary-title {
        text-align: left;
        font: normal normal bold 16px Open Sans;
        letter-spacing: 0px;
        color: #2D2D2D;
        opacity: 1;
        margin: 0;
        padding: 14px 20px;
        border-bottom: 1px solid #d4d4d4;
        position: relative;
        .stag {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          height: 25px;
          line-height: 25px !important;
          border-radius: 5px;
          font: normal normal 600 12px/17px Open Sans;
          letter-spacing: 0px;
          color: #1E231D;
          padding: 0 10px;
          &.completed {
            background: #BAF5D6;
          }
          &.in-progress {
            background: #FAEBBC;
          }
        }
      }

      .summary-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 -30px;
        padding: 14px 20px;
        > .summary-score {
          flex: 1;
          margin: 0 30px;
          .progress {
            background: #D7D9DC 0% 0% no-repeat padding-box;
            height: 12px;
            width: 100%;
            position: relative;
            margin: 12px 0;
            border-radius: 6px;
            overflow: hidden;
            .amount {
              display: block;
              content: '';
              clear: both;
              background: #038FE9 0% 0% no-repeat padding-box;
              height: 100%;
              width: 76%;
              position: absolute;
              left: 0;
              top: 0;
            }
            &.green > .amount {
              background: #00B3A6;
            }
            &.blue > .amount {
              background: #038FE9;
            }
            &.red > .amount {
              background: #FE6E1B;
            }
          }
          .average-title {
            font: normal normal bold 36px Open Sans;
            color: #000000;
            line-height: 77px;
            text-align: center;
          }
          .avg-score {
            color: #2D2D2D;
            font-size: 12px;
            font-family: Open Sans;
            b {
              font-weight: 600;
            }
          }
          .score-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            > div {
              white-space: nowrap;
              letter-spacing: 0px;
              color: #2D2D2D;
              &:last-child {
                text-align: right;
                font: normal normal bold 14px Open Sans;
              }
              &:first-child, .full-title {
                flex: 1;
                font: normal normal 600 14px Open Sans;
                text-align: left;
                span {
                  font: normal normal 400 12px Open Sans;
                  color: #7C828A;
                  margin-left: 8px;
                }
              }
              
            }
            .download {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid #411F90;
              border-radius: 6px;
              width: 24px;
              height: 24px;
              cursor: pointer;
              svg {
                color: #411F90;
                font-size: 14px;
              }
            }
          }
        }
      }

    }


    .title-privacy-lens {
      display: block;
      float: none;
    }

    .subtitle-privacy-lens {
      font: normal normal 300 21px/31px Oswald;
      position: relative;
      > b {
        font-weight: 400;
      }
      .sfilter {
        position: absolute;
        right: 0;
        top: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .sfilter-title {
          font: normal normal 600 14px/19px Open Sans;
          margin-right: 10px;
        }
        .ant-radio-group {
          .ant-radio-button-wrapper {
            margin-right: 10px;
            border-radius: 5px;
            background: #F5F5F5;
            font-family: Open Sans;
            font-size: 14px;
            letter-spacing: 0px;
            color: #2D2D2D;
            height: 34px;
            line-height: 34px;
            border: 1px solid #d9d9d9 !important;
            &:before {
              display: none;
            }
            &.ant-radio-button-wrapper-checked {
              background: #fff;
              border-color: #411F90;
              border: 1px solid #411F90 !important;
              
            }
          }
        }
      }
    }

    .scoring-search {
      > .spsg-input {
        width: 300px;
        box-shadow: 0px 2px 3px #0000001f;
      }
    }

  }

}