div.add-edit-delete-component{
  display: flex;
  &.space-between {
    justify-content: space-between;
    align-items: baseline;
    .spsg-input {
      width: 90%;
    }
  }
  .saved-value-div {
    width: 90%;
    line-height: 32px;
  }
  div.btn-group{
    display: flex;
    &.hide{
      display: none;
    }
  }
}
