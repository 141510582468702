.checkbox-color-mixin{
  // &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  // .ant-checkbox:hover .ant-checkbox-inner,
  // .ant-checkbox-input:focus + .ant-checkbox-inner,
  // .ant-checkbox-checked::after {
  //   border-color: #411F90;
  // }
  // &.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
  // .ant-checkbox-checked .ant-checkbox-inner {
  //   background-color: #411F90 !important;
  //   border-color: #411F90 !important;
  // }
  // .ant-checkbox-indeterminate .ant-checkbox-inner::after{
  //   background-color: #411F90;
  //   border: 1px solid #411F90;
  //   border-radius: 2px;
  // }
}
.spsg-checkbox {
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }
  .checkbox-color-mixin();
}


.spsg-checkbox.purple {
  .checkbox-color-mixin();
  .ant-checkbox-checked.ant-checkbox-disabled {
    .ant-checkbox-inner{
      background-color: #F4F6F8 !important;
      border-color: #D3D9DD !important;
    }
  }
}

.ant-checkbox-group {
  .checkbox-color-mixin()
}
