.ant-select-dropdown {
    &.spsg-select-multiple-drodown {
        .list-search {
            height: 50px;
        }

        .filter-button-group {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-top: 1px solid #d4d4d4;

            // .spsg-btn {
            //     height: 32px;
            //     line-height: 0px;
            //     font-size: 14px;

            //     &.cancel {
            //         border: none;
            //         color: #6565a9;
            //         background: transparent;
            //         padding-left: 8px;
            //         &:hover {
            //             color: #411f90;
            //         }
            //     }
            // }
        }

        .ant-select-item.ant-select-item-group,
        .ant-select-item.ant-select-item-option {
            font: normal normal normal 12px/22px Open Sans;

            .ant-select-item-option-content {
                display: flex;
                justify-content: space-between;
                align-items: center;

                span.spsg-select-dropdown-label {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                span.anticon-check-square {
                    color: #FFFFFF;
                    font-size: 19px;
                    margin-right: 1px;

                    >svg {
                        border: 1px solid #411f90;
                        border-radius: 1px;
                    }
                }

                span.anticon-minus-square,
                span.anticon-plus-square {
                    padding-right: 5px;
                    min-width: 16px;
                    font-size: 16px;
                    color: #411F90;
                }
            }

            &.ant-select-item-option-selected {
                background: #e9dfff;
                align-items: center;

                span.anticon-check-square {
                    color: #411f90;
                    font-size: 21px;
                    margin-right: 0px;

                    >svg {
                        border: 0px solid #411f90;
                        border-radius: 3px;
                    }
                }
            }
        }

    .filter-button-group {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-top: 1px solid #d4d4d4;

      .spsg-btn {
        height: 32px;
        line-height: 0px;
        font-size: 14px;

        &.cancel {
          border: none;
          color: #6565a9;
          background: transparent;
          padding-left: 8px;
          &:hover {
            color: #411f90;
          }
        }
      }
    }

    .ant-select-item.ant-select-item-option {
      font: normal normal normal 12px/22px Open Sans;

      .ant-select-item-option-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span.spsg-select-dropdown-label {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        span.anticon-check-square {
          color: #ffffff;
          font-size: 19px;
          margin-right: 1px;

          > svg {
            border: 1px solid #411f90;
            border-radius: 1px;
          }
        }
      }

      &:not(.ant-select-item-option-selected) {
        span.anticon-check {
          > svg {
            display: none;
          }
        }
      }

      &.ant-select-item-option-selected {
        background: #e9dfff;
        align-items: center;

        span.anticon-check-square {
          color: #411f90;
          font-size: 21px;
          margin-right: 0px;

          > svg {
            border: 0px solid #411f90;
            border-radius: 3px;
          }
        }
        &.ant-select-item-option-disabled{
          span.anticon-check-square {
            color: #707070;
            > svg {
              border: 0px solid #707070;
            }
          }
        }
      }
    }
  }
  &.regions-select{
    width: 360px !important;
    padding: 20px;
    .spsg-tree {
      .ant-tree{
        height: inherit;
        max-height: 30vh;
        overflow-y: scroll;
        margin: var(--ant-margin-xs);
      }
    }
    .spsg-tree label.spsg-checkbox{
      margin-left: 2px
    }
    .regions-select-dropdown-footer{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      >button {
        height: 32px;
        line-height: 0px;
        font-size: 14px;
        &.cancel {
          border: none;
          color: #6565a9;
          background: transparent;
          padding-left: 8px;
          &:hover {
              color: #411F90;
          }
        }
      }
    }
  }
}

.select-drodown-mixin {
  .ant-select.spsg-select-multiple {
    .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
      .ant-select-selection-item{
        color: var(--ant-color-info);
        background: var(--ant-color-info-bg);
        border-color: var(--ant-color-info-border);
      }
    }
    // .ant-select-selection-overflow-item {
    //   .ant-select-selection-item {
    //     // background: #e9dfff;
    //     font-weight: 600;
    //     padding-inline-end: 8px;
    //   }
    // }

    div.ant-select-selection-search {
      display: none;
    }

    .ant-select-selection-overflow-item {
      .anticon.anticon-check,
      .anticon.anticon-check-square {
        display: none;
      }
    }

    .anticon.anticon-search.ant-select-suffix {
      display: none;
    }
  }
}

#avocado {
  .select-drodown-mixin();
}
.ant-modal {
  .select-drodown-mixin();
}
#avocado,.avocado-modal {
    div.ant-select.spsg-select-multiple {
      .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
        .ant-select-selection-item{
          color: var(--ant-color-info);
          background: var(--ant-color-info-bg);
          border-color: var(--ant-color-info-border);
        }
      }
        .ant-select-selection-overflow-item {
            .ant-select-selection-item {
                .anticon.anticon-close {
                    display: flex;
                    align-items: center;
                    margin-left: 14px;
                    color: #9B9B9B;
                }
            }
        }

        div.ant-select-selection-search {
            display: none;
        }

        .ant-select-selection-overflow-item {

            .anticon.anticon-check-square {
                display: none;
            }

            .anticon.anticon-close {
                display: flex;
                align-items: center;
                margin-left: 14px;
                color: #9B9B9B;
            }
        }

        .anticon.anticon-search.ant-select-suffix {
            display: none;
        }
    }
}
