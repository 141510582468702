  .ant-modal-wrap {
    .privacy-manager-override {
      width: 500px !important;
      margin: 0 auto !important;
      top: 100px !important;

      .ant-select-selection {
        width: 440px;
      }
    }
  }

  .choice-options-list{
    .manage-pm-button {
      background-color: #1370FB;
      border-color: #1370FB;
      padding-top: 9px;
      padding-bottom: 9px;
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 20px;
      width: 100%;
    }
  }
