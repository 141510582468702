.update-user-tab, .create-new-account-container {
  .ant-tabs-content-holder {
    background-color: white;
  }
  .update-user-container {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    background-color: white;
    padding: 25px;

    label {
      display: flex;
      flex-direction: column;
      font-size: inherit;
      padding-bottom: 10px;
    }
    input {
      font-size: inherit;
    }

    button.ant-btn.ant-btn-primary {
      padding: 7px;
      margin-bottom: 0;
    }
  }
}

.create-new-account-container  {
  height: inherit;
}
