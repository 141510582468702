.privacy-lens-features {
  display: flex;
  flex-direction: column;
  .description {
    color: rgb(187, 187, 187);
    font-size: 11px;
  }
}

.user-permissions-container {
  .ant-checkbox-wrapper {
    position: absolute;
    right: 0px;
  }
  .ant-menu-vertical {
    margin-bottom: 0;
    margin-top: 0;
  }
  ul {
    border: none;
  }
  .anticon {
    margin-left: -15px;
    padding-right: 5px;
  }
  .ant-radio {
    position: absolute;
    right: -22px;
  }
  .ant-collapse-header {
    background-color: white;
    width: 300px;
    padding: 3px !important;
    div {
      padding: 0 5px !important;
      font-size: 14px;
      p {
        font-size: 14px;
        margin-bottom: 0;
        padding: 2px 7px;
        background-color: rgb(236 236 236 / 85%);
      }
      svg {
        margin-top: 6px;
      }
    }
  }
  .ant-collapse-content {
    width: 300px;
    .ant-menu-item .ant-menu-title-content .ellipsis-list-item {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 5px;
    }
  }
  .ant-collapse-arrow {
    position: absolute;
    right: 10px;
  }

  .ant-radio-group {
    display: flex;
    flex-direction: column;
  }
  .ant-menu-item {
    height: 100%;
  }
  label.ant-radio-wrapper {
    padding: 5px 0;
  }
}

  .manage-users-container {
    .header-container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
    }

    p.all-access {
      font-size: 12px;
    }

    .edit-buttons button {
      margin-left: 15px;
    }

    button.cancel, button.save {
      width: 70px;
    }

    .anticon-delete:before {
      font-size: 15px;
      padding-left: 40px;
      cursor: pointer;
    }

    .edit-button-container {
      display: flex;
      justify-content: flex-end;

      button {
        margin-right: 30px;
      }
    }

    .ant-table-tbody {
      background-color: white;
    }

    table {
      margin-bottom: 60px;
    }

    .ant-select {
      min-width: 300px;
    }

    .search-users {
      width: 212px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .invite-button {
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
    }

    .sites-container {
      padding-top: 0;
      display: flex;
      div.site {
        border-radius: 3px;
        background-color: #E0E7F2;
        background-color: #f3f3f3;
        border-radius: 4px;
        margin-right: 10px;
        padding: 5px 9px 5px 9px;
      }

      .more {
        padding-top: 5px;
      }
    }

    .ant-table-tbody > tr > td:first-child {
      padding: 20px 30px;
    }

    .ant-table-thead > tr > th:first-child  {
      padding-left: 30px;
    }

    .user-name {
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
    }

    .user-email {
      font-size: 12px;
      line-height: 12px;
      margin-bottom: 0;
    }
  }

  .ant-select-dropdown.select-user-feature ul.ant-select-dropdown-menu{
    max-height: 150px;
  }

.ant-modal.invite-modal {
  .ant-collapse {
    background-color: white;
    .anticon-right {
      right: 35px;
    }
    .ant-radio {
      right: -23px;
    }
    .ant-radio-wrapper {
      font-size: 14px;
      font-weight: normal;
      padding: 8px 0;
    }
    .ant-menu-vertical {
      margin: 0 !important;
    }
    .ant-menu-item {
      margin: 0 !important;
    }
  }

  .ant-menu-vertical {
    margin: 0 !important;
  }

  top: 40px;

  .ant-modal-header {
    padding-left: 30px;
  }

  .ant-modal-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    color: #000000;
  }

  .ant-modal-body {
    padding-right: 30px;
    padding-left: 30px;

    .admin-access-wrapper {
      display: flex;
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      border: 1px solid #EAEEF1;
      border-radius: 5px;
      background-color: #F7F9FC;
      justify-content: space-between;
    }

    p.admin-access-message {
      font-size: 12px;
    }

    .sites-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    input:nth-child(2) {
      margin-bottom: 15px;
    }

    label {
      font-size: 12px;
      font-weight: 600;
      line-height: 14px;
    }

    .ant-input {
      font-size: 14px;
      line-height: 14px;
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
}
