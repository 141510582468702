@import "../brand.css.less";
@import "../bootstrap/index.less";
@import "../less/fontawesome.less";

@navbar-height                   : 80px;
@navbar-default-color            : white;
@navbar-default-bg               : black;
@navbar-default-border           : transparent;
@navbar-default-link-color       : #c7c6c6;
@navbar-default-link-hover-color : white;
@navbar-default-link-active-color: white;
@navbar-default-link-active-bg   : inherit;

@font-face {
    font-family: 'Glyphicons Halflings';
    src        : url('@{icon-font-path}@{icon-font-name}.eot');
    src        : url('@{icon-font-path}@{icon-font-name}.ttf') format('truetype'),
    url('@{icon-font-path}@{icon-font-name}.eot?#iefix') format('embedded-opentype'),
    url('@{icon-font-path}@{icon-font-name}.woff') format('woff'),
    url('@{icon-font-path}@{icon-font-name}.ttf') format('truetype');
}