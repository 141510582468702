#avocado {

  .pl-accounts-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #0000001F;
    border: 1px solid #D4D4D4;
    border-radius: 20px;
    overflow: hidden;
    padding: 24px;
    position: relative;
    font-family: Open Sans;
    font-size: 14px;
    .search-container {
      justify-content: space-between;
      margin-bottom: 20px;
      .spsg-input.pl-account-search {
        >span.ant-input-affix-wrapper {
          &:hover,&.ant-input-affix-wrapper-focused  {
            box-shadow:0px 2px 3px #0000001F;
          }
        }
      }
      .pl-accounts-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
    
        .ant-radio-group {
          .ant-radio-button-wrapper {
            margin-right: 10px;
            border-radius: 5px;
            background: #F5F5F5;
            font-family: Open Sans;
            font-size: 14px;
            letter-spacing: 0px;
            color: #2D2D2D;
            height: 34px;
            line-height: 34px;
            border: 1px solid #d9d9d9 !important;
    
            &:before {
              display: none;
            }
    
            &.ant-radio-button-wrapper-checked {
              background: #fff;
              border-color: #411F90;
              border: 1px solid #411F90 !important;
    
            }
          }
        }
      }
      .warning-icon {
        padding: 0 5px;
      }
    }
      tr.ant-table-row {
        &.row-class-error {
          >td {
            background-color: #fff1f0;
            .validation-error-msg{
              color: #ff2626;
              font-weight: 600;
              margin-top: 8px;
            }
          }
        }
      }
    td {
      &.ant-select,
      &.ant-picker,
      input {
        min-width: 80%;
      }
      &.ant-table-cell.rules {
        text-align: center;
      }
    }
    th {
      &.ant-table-cell{
        &.subtitle,&.rules{
          font-weight: 400;
        }
      }
    }
    .ant-dropdown-trigger {
      font-size: 12px;
    }
    .rules-title {
      left: 58%;
      position: relative;
      top: 30px;
      z-index: 2;
      font-weight: 600;
    }

    table {
      .ant-table-thead>tr>th {
        &.title {
          background-color: #846FB7;
          padding: 20px;
        }
        &.slidder-arrow-container {
          padding: 0 0;
          border-bottom: 0px;
          background-color: #C5BBDD;

        }

        .slidder-arrow-container {
          padding: 0 0;
          border-bottom: 0px;
          background-color: #C5BBDD;

          &:hover {
            background-color: #411faa;
          }

          &.slider-div {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}

.ant-dropdown {
  >.ant-table-filter-dropdown {
    .pl-account-filters-dropdown {
      font-family: Open Sans;

      .ant-radio-group {
        display: flex;
        flex-direction: column;

        .ant-radio-button-wrapper {
          border: 0px;
          font-weight: 400;

          &::before {
            background-color: #fff;
          }

          &:hover {
            color: #4f556d;
            font-weight: 600;
          }

          &.ant-radio-button-wrapper-checked {
            background: #e9dfff;
            color: #4f556d;
            font-weight: 600;
          }
        }
      }
    }
  }
}
.ant-select-dropdown {
    &.scan-allowance-dropdown {
        padding: 15px;
        box-shadow: 0px 5px 10px #0000001F;
        border: 1px solid #D4D4D4;
        border-radius: 5px;
        background: #FFFFFF;
        min-width: 230px !important;

        >div {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            >.scan-allowance-dropdown-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                >button {
                  height: 32px;
                  line-height: 0px;
                  font-size: 14px;
                  &.cancel {
                    border: none;
                    color: #6565a9;
                    background: transparent;
                    padding-left: 8px;
                    &:hover {
                        color: #411F90;
                    }
                  }
                }
                &.scan-allowance-error-message{
                  font-size: 12px;
                  color: #ff2626;
                }
            }
            >.ant-divider-horizontal{
              margin: 10px 0px;
            }
        }
        .spsg-input {
          width: 50%;
        }
    }
}
.ant-select{
    &.scan-allowance-select{
        border: 1px solid #D4D4D4;
        border-radius: 5px;
        &:hover {
            >.ant-select-selector{
                box-shadow: 0px 5px 10px #0000001F;
                border: 1px solid #D4D4D4;
            }
           
        }
    }
}