#avocado{
  .search-container {
    .search-bar {
      width: 300px;
      margin-bottom: 10px;
    }
    .clear-button {
      margin-left: 10px;
      padding: 6px;
    }
    .save {
      float: right;
    }
  }
  .masq-page {

    .data-table {
      background: white;

      th {
        padding-left: 30px;
      }

      td {
        padding-left: 30px;
      }
    }
  }
}
