  .fa-circle.green {
    color: green !important;
  }
  .fa-circle.gray {
    color: lightgray !important;
  }
  .flex-row {
    display: flex;
  }

  .consent-compensation-table-container {
    background-color: white;
    padding: 20px;

    td:first-child, th:first-child {
      padding-left: 50px;
    }

    .edit-rate {
      input {
        width: 60px;
        height: 27px;
      }
      button.cancel {
        height: 27px;
        margin-left: 10px;
        margin-right: 5px;
      }
      cursor: pointer
    }

    .ant-input-search.vendor-filter {
      height: 32px;
    }

    .category-selector {
      margin-left: 30px;
      .ant-select {
        width: 110px;
        .ant-select-selection {
          border: none;
          border-bottom: 1px solid lightgray;
          border-radius: 0;
        }
      }
      p {
        width: 60px;
        font-size: 12px;
        margin-top: 5px;
      }
    }

    .ant-table-wrapper table thead > tr > th {
      font-size: 10px;
      color: black;
      background-color: #d8edf866;
      border: none;
    }

    .ant-table-thead > tr:first-child > th:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .ant-table-thead > tr:first-child > th:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    
    i.fa-circle {
      padding-left: 15px;
    }

    .add-vendor-btn {
      // color: #0294FF;
      text-transform: uppercase;
      font-size: 12px;
      // border: 1px solid #0294FF;
      padding-bottom: 5px;
      padding-top: 5px;
      height: 33px;
    }

    .vendor-name {
      color: #0294FF;
      cursor: pointer;
      font-weight: bold;
    }

    .table-top {
      justify-content: space-between;
    }

    table {
      padding-top: 10px;
      td {
        height: 55px;
      }
    }
  }

  button.toggle-iab {
    position: absolute;
    z-index: 9;
    left: 320px;
    top: 65px;
  }

  button.toggle-add-iab {
    position: absolute;
    z-index: 9;
    top: 104px;
    right: 472px;
  }

  button.toggle-add-iab-new {
   float:right;
  }

  .loading-add-vendors > .sk-fading-circle  {
    left: 735px;
  }

  .ant-select-dropdown.add-vendors.not-visible {
    display: none !important;
  }
    
  .ant-modal.ant-modal.edit-vendor-compensation {
    width: 800px !important;

    button.ant-btn {
      font-size: 12px;
    }

    .ant-input-search.vendor-filter {
      position: absolute;
      z-index: 9;
      width: 268px;
      top: 99px;
      border: none;
      border-bottom: 1px solid lightgray;
      margin-bottom: 0;
      margin-left: 29px;

      .ant-input {
        width: 216px;
        margin-left: 0;
        border: none !important;
        box-shadow: none !important;
        text-shadow: none !important;
        margin-bottom: 0;
      }

      .ant-input-suffix {
        display: none;
        // position: absolute;
        // left: 10px;
        // top: 20px;
        // width: 20px;

        // i {
        //   font-size: 20px;
        //   margin-bottom: 15px;
        //   color: #bebebe;
        // }
      }
    }

    .ant-input-search.vendor-filter-new {
      .ant-input {
        width: 216px;
        margin-left: 25px;
        border: none !important;
        box-shadow: none !important;
        text-shadow: none !important;
        margin-bottom: 0;
      }
      .ant-input-suffix {
        position: absolute;
        left: 10px;
        top: 20px;
        width: 20px;
        i {
          font-size: 20px;
          margin-bottom: 15px;
          color: #bebebe;
        }
      }
    }

    .select-title {
      position: absolute;
      z-index: 9;
      top: 66px;
      font-size: 15px;
      font-weight: bold;
      left: 44px;
    }

    .ant-modal-body {
      margin-top: 20px;
      padding: 0;
      height: 430px;

      p.title {
        font-weight: bold;
        background-color: #212F43;
        color: white;
        padding: 15px;
        text-transform: capitalize;
        margin-right: 0;
      }

      .row-1 {
        justify-content: space-between;
      }

      .row-2 {
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 15px;
      }

      .start-date {
        margin-right: 20px;
        padding-right: 20px;
        span {
          margin-left: 10px;
        }
        border-right: 1px solid lightgray;
        height: 15px;
      }

      span.bold {
        font-weight: bold;
      }

      .deal-info {
        p {
          width: 75px;
          font-size: 14px;
        }
      }

      .ant-select {
        margin-top: -5px;
        margin-left: 10px;
      }

      .ant-select-selection--single {
        width: 100px;
      }

      .ant-radio-group {
        width: 130px;
        border-right: 1px solid lightgray;
        height: 15px;
        margin-right: 20px;
      }

      .ant-input {
        width: 60px;
        height: 32px;
        margin-top: -5px;
        margin-left: 15px;
      }

      .ant-switch-checked {
        border-color: #0AD326;
        background-color: #0AD326;
      }

      .title-2 {
        font-weight: bold;
        font-size: 14px;
      }

      .history {
        margin-top: 30px;
      }

      .ant-table-wrapper table thead > tr > th {
        font-size: 10px;
        color: black;
        background-color: #d8edf866;
      }

      table {
        border: 1px solid #d3d3d366;
        td {
          background: none;
        }
      }
    }
  }

  .ant-select-dropdown.add-vendors.not-visible {
    display: none !important;
  }

  .ant-modal.ant-modal.edit-vendor-compensation.add-vendors {
    width: 1000px !important;
    left: ~"calc((100% - 1000px)/2)";
    position: fixed;
    top: 10px;

    .ant-modal-footer {
      background-color: #e5e4e4;
    }

    p.title {
      margin-bottom: 0;
    }

    .ant-select {
      width: 500px;
      padding-top: 20px;
      margin-left: 30px;
      margin-bottom: 0;

      .ant-select-selector {
        // display: none;
        .ant-select-selection-search {
          display: none;
        }
        border: none;
        .ant-select-selection-item {
          display: none;
        }
      }

      .ant-select-selection {
        border: none;
        box-shadow: none;
        border-bottom: 1px solid lightgray;
        border-radius: 0;

        li {
          display: none;
        }

        li:last-child {
          display: inline;
        }
      }
    }

    p.title-3 {
      font-size: 14px;
      margin-left: 40px;
      margin-bottom: 0;
      margin-top: 15px;
      width: 370px;
      padding-bottom: 10px;
      border-bottom: 1px solid lightgray
    }

    .selected-vendors, .no-vendors-message {
      margin-left: 10px;
      padding-left: 30px;
      border-left: 1px solid lightgray;
      height: 332px;
      overflow: auto;
      padding-right: 52px;

      div.vendor-container {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid lightgray;
        width: 370px;
        justify-content: space-between;

        div {
          font-weight: bold;
        }

        i {
          color: #c3c2c2;
          font-size: 19px;
        }
      }
    }

    .no-vendors-message {
      font-size: 16px;
      font-weight: bold;
      border-left: none;
      padding-top: 40px;
    }
  }

  .ant-select-dropdown.add-vendors.not-visible {
    display: none !important;
  }

  .ant-select-dropdown.add-vendors{
    margin-top: -50px;
    top: 215px !important;
    box-shadow: none;
    position: fixed;

    .ant-select-item {
      padding: 0;
    }


    .ant-select-item-option-content {
      font-size: 14px;
      border-bottom: 1px solid lightgray;
      padding: 10px;
    }
    ul {
      max-height: 240px;
    }
  }

  .vendor-list-v2{
    .ant-tabs-nav-container {
      font-size: 14px;
      padding-left: 10px;
      padding-right:10px;
  }
  }

  .vendor-v2-listview{
    height:250px;
    padding: 10px;
    overflow-y: scroll;
  }

  .selected-vendors-new, .no-vendors-message {
      padding-right: 10px;
      padding-left: 10px;
      height: 250px;
      overflow: auto;
      div.vendor-container {
      padding: 10px;
      border-bottom: 0.5px solid lightgray;
      justify-content: space-between;
      align-items: center;
      div {
        color:#000000A6;
      }
      i {
        color: #c3c2c2;
        font-size: 14px;
      }
    }
  }

  .no-vendors-message {
    font-size: 12px;
    font-weight: bold;
    border-left: none;
    padding-top: 40px;
  }
  .main-container{
    display: flex;
    flex:1;
    .flex-row {
      display: flex;
      flex:1;
      .switch-vendors{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
      }
      .flex-row-sub{
        display: flex;
        flex:1;
        flex-direction: column;
        margin-right: 10px;
        .vendor-v2-listview {
          height:300px;
          margin-top: 20px;
          padding: 0px;
        }
        .vendor-tab-container{
          display: flex;
          flex:1;
          flex-direction: row;
          align-items: center;
          .vendor-item-container{
            flex:1
          }
        }
        .vendor-search{
          flex:1;
          width:230px;
          align-items: center;
        }
        .btn-container{
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          align-items: baseline;
        }
      }
    }
    .selected-vendors-container{
      display: flex;
      flex: 1;
      flex-direction: column;
      .selected-vendors{
        height: 350px;
        overflow: auto;
      }
      .vendor-container.flex-row{
        flex: 1;
        align-items: center;
        font-weight: 600;
        margin-top: 10px;
        .vendor-name{
          flex: 1;
        }
      }
    }
  }
