.summary-page-mixin {
  .summary-components{
    height: calc(100vh - 300px);
    overflow-y: scroll;
    background-color: white;
    padding: 10px 20px;
    .step {
      margin: 5px 5px;
      // scroll-margin-top: 80px;
      .field{
        padding: 5px 5px;
        .field-value{
          display: inline-flex;
          border: 1px solid #D3D9DD;
          border-radius: 6px;
          height: 45px;
          padding: 0px 20px;
          align-items: center;
          width: min-content;
          font-weight: 600;
          margin-right: 5px;
          width: max-content;
        }
        >.heading {
          display: flex;
          justify-content: space-between;
          font: normal normal 600 16px/24px Open Sans;
          margin: 5px 0px;
          .go-to-step{
            color: @primary-color;
            font-size: 16px;
            svg {
              vertical-align: bottom;
              margin-left: 5px;
              path {
                fill: @primary-color;
              }
            }
          }
        }
        >.context{
          font: normal normal normal 14px/19px Open Sans;
          margin: 5px 0px;
        }
        >.content{
          display: flex;
          flex-wrap: wrap;
          background: #FAFAFA 0% 0% no-repeat padding-box;
          border: 1px solid #DEDEDE;
          border-radius: 6px;
          padding: 10px 10px;
          margin: 10px 0px;
          .spsg-chip.usp-summary {
            line-height: 36px;
            >b {
              padding: 5px;
              border-radius: 10px;
              background: #8B829F;
              color: white;
              margin-left: 5px;
              height: 70%;
              line-height: 15px;
            }
          }
        }
      }
    }
  }

  .summary-header{
    position: sticky;
    top: 0;
    z-index: 999;
    height: 110px;
  }
}
