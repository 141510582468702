  &.reports {
    .sitesentry-subnav {
      .container {
        height: 60px;
      }
    }
  }

  .recharts-wrapper {
    padding: 15px 0;
  }

  .adblock-report.subnav {
    position: fixed;
    top: 97px;
    background: white;
    font-size: 0.857em;
    border-top: none;
    width: 100%;
    .list-inline {
      margin-bottom: 0;
      padding:0;

      strong {
          color: #979797;
      }

      li {
        padding: 0 16px 0 11px;
        border-right: none;
        font-size: 14px;
        font-weight: 500;
        padding:0;
        border-bottom: 3px solid transparent;
        margin:0 20px 0 10px;
        &.active {
          color: #354052;
          border-bottom: 3px solid #3D7DE1;
          font-weight:600;
        }

        &.noborder {
          border-right: none;
        }

        a {
          padding: 0;
          padding-bottom: 12px;
          display:block;
          color: #212F43;
          letter-spacing: 0;
          line-height: 15px;

          &.consent {
            padding-bottom: 7px;
          }

          &.active {
            color: #0294FF;
            border-bottom: 3px solid #0294FF;
            margin-bottom: -3px;
          }

          &:hover {
            color: #354052;
            text-decoration: none;
          }
        }
      }
    }
  }
