  @font-main: proxima-nova, ProximaNova, Helvetica, Arial;
  .scenario-v2{
  .dialogue-container {
    .ant-dropdown {
      .ant-dropdown-menu-item {
        padding: 0;

        .avo-dialogue-icons {
          padding: 7px 26px;
          margin: 0;
        }
      }
    }

    .ant-modal {
      top: 300px;
    }
  }

  .save-and-cancel-container {
    display: flex;
    justify-content: flex-end;
    .confirm {
      margin-left: 10px;
      background: #1370fb;
      color: white;
    }
  }

  .scenario.panel.panel-default.card.v1 {
    .anticon.anticon-ellipsis.ant-dropdown-trigger {
      margin: 0;
    }
  }

  .scenario-dashboard-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .scenario-geneic-title {
      flex: 1;
    }
    .search-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .search-bar{
        margin-left: 0;
      }
    }
  }

  .newScenarioTable {
    table {
      border-collapse: collapse;
    }
    table thead > tr > th {
      .ant-table-column-sorters {
        justify-content: initial;
        .ant-table-column-title{
            flex: initial;
        }
      }
    }
    table tbody > tr > td {
      text-align: left;
      letter-spacing: 0;
      line-height: 14px;
      color: #000000a6;
      opacity: 1;
      background: white;
      padding-top: 15px;
      padding-bottom: 15px;
      .anticon-exclamation-circle {
        color: #FF2626;
        margin-left: 5px;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
    .no-type-row {
      border: 2px solid #f5222d;
      border: 2px solid #ff9191;
    }
    .ant-select {
      width: 160px;
    }
    #set-campaign-type{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ant-select {
        .ant-select-selector{
          display: flex;
          align-items: center;
          height: 35px;
        }
      }
      .ant-btn{
        height: 35px;
        line-height: 0;
      }
    }
    #set-type{
      .ant-btn{
        height: 32px;
        line-height: 0;
        padding: 4px 7px 4px 7px;
        transition:none;
        span {
          font-size: 13px;
        }
      }
    }
  }
  .scenarios-outer-wrapper {
    padding-top: 55px !important;
  }
  .scenarios-container {
    
    .anticon-ellipsis:before {
      font-size: 30px;
    }
    .scenario-title {
      font-weight: bold;
    }

    .site-card-border-less-wrapper {
      margin: 10px 0;
    }
    .actionContainer {
      display: flex;
      align-items: center;
      i:hover {
        color: #000000d9;
        cursor: pointer;
      }
      .actionicon {
        margin-left: 12px;
        margin-right: 12px;
      }
    }

    .stepContainer {
      padding: 5px;
      .step-title {
        font-size: 12px;
        color: black;
      }
      .anticon.anticon-eye {
        svg {
          color: #411F90 !important;
          width: 18px;
          height: 18px;
        }
      }
      .error-message {
        font-size: 12px;
        font-weight: 600;
        font-family: Open Sans;
        color: #FF2626;
        margin-top: 5px;
        .anticon.anticon-edit {
          color: #899AB4;
        }
      }
    }

    table {
      border: 1px solid #ddd;
    }

    table thead > tr > th {
      font-size: 0.929em;
      background: #f3f8fc;
      opacity: 1;
      text-align: left;
      letter-spacing: 0.12px;
      color: #000000;
      opacity: 1;
      text-transform: none;
    }

    .ant-table-row-expand-icon-cell {
      text-align: center;
      .ant-table-row-expand-icon{
        color: #411F90;
        border: 1px solid #411F90;
      }
      .ant-table-row-expand-icon:hover{
        color: #6565A9;
        border: 1px solid #6565A9;
      }
    }

    .anticon.anticon-ellipsis.ant-dropdown-trigger {
      position: absolute;
      z-index: 9;
      right: 50px;
      margin-top: -58px;
    }

    .ant-collapse {
      margin-bottom: 30px;
      .ant-collapse-content.ant-collapse-content-active {
        overflow: visible;
        padding: 0;

        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }

    .v2-description {
      position: absolute;
      margin-top: 13px;
      margin-left: 30px;
      z-index: 9;
      width: 500px;
    }

    .v2-header {
      position: absolute;
      display: flex;
      margin-top: 40px;
      margin-left: 30px;

      .active-status {
        display: inline-flex;
        padding-right: 5px;
      }

      .date-created {
        display: inline-flex;
      }
    }

    .ant-collapse {
      background: white;
      .ant-collapse-header {
        font-size: 15px;
        font-family: @font-main;
        font-weight: bold;
        line-height: 15px;
        color: black;
        margin: 25px 0;
      }

      .ant-collapse-item-active {
        i.arrow {
          transform: rotate(-90deg);
        }
      }

      i.arrow {
        transform: rotate(90deg);
        position: absolute;
        // left: 90%;
      }
    }

    .scenario-v2 {
      background: white;
      .panel-body {
        padding: 0;
        .panel-table.table {
          margin-bottom: 0;
        }
      }

      .v2-buttons {
        padding: 20px 30px;
        button {
          size: 14px;
          line-height: 14px;
          font-family: @font-main;
          padding: 10px 15px;

          &:hover {
            color: black;
          }
        }

        .cancel-save-container {
          float: right;

          .save {
            margin-left: 15px;
            background: #0ad326;
            color: white;
          }
        }
      }

      .no-scenario {
        display: flex;
        border-top: 1px solid #dce2e9;
        height: 100px;
        background-color: #f4f6f9;
        z-index: 0;
        p {
          font-size: 16px;
          margin: 30px;
          align-self: center;
        }
      }

      .scenario-row {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #dce2e9;
        height: auto;
        padding: 0 30px;
        background-color: #f4f6f9;
        z-index: 0;

        .ant-select.ant-select-enabled,
        .ant-cascader-picker,
        .ant-input-number,
        .ant-input {
          width: 270px;
          margin-bottom: 10px;
        }
        .ant-input-number-handler-wrap {
          z-index: 9;
        }

        &.editing {
          &:hover {
            .delete-button.row {
              display: block;
            }
          }

          .scenario-block-container {
            &:hover {
              background: #f7f9fc;
              cursor: pointer;
            }
          }
        }

        .delete-button {
          position: absolute;
          color: red;
          margin-top: -55px;
          margin-left: 100px;
          display: none;

          &.row {
            margin: 0 20px 0 -40px;
          }
        }

        .row-index {
          margin-top: 30px;
          display: inline-flex;
          margin-right: 30px;
        }

        &:last-child {
          border-bottom: 1px solid #dce2e9;
        }

        .scenario-block-container {
          z-index: 0;
          overflow: visible;
          margin: 30px 30px 30px 0;
          background: white;
          max-width: 200px;
          height: 40px;
          display: inline-flex;
          border-top: none;
          box-shadow: 0 1px 2px 0 #c0cfdf;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          padding: 15px;

          .step-block-editing {
            display: inline-flex;
            flex-direction: column;

            &.message-freq-cap {
              :nth-child(1) {
                order: 1;
              }
              .limit {
                order: 4;
              }
              .freq {
                order: 5;
              }
              .seconds {
                order: 6;
              }
              .show-message {
                order: 2;
              }
              .ant-select {
                order: 3;
              }
            }
          }

          .country-type-select-container {
            width: 270px;
            justify-content: space-between;
            display: inline-flex;

            .ant-cascader-picker {
              width: 180px;
              margin-right: 10px;

              .ant-input.ant-cascader-input {
                width: inherit;
              }
            }
          }

          .ant-select {
            align-items: center;
            margin-bottom: 10px;
          }

          &.block-editing {
            z-index: 99;
            width: 300px;
            height: 40px;

            .block-internal {
              height: auto;
              background: white;
              padding: 15px;
              margin-top: 94px;
              border-radius: 5px;
              box-shadow: 0 1px 2px 0 #c0cfdf;
            }

            &:hover {
              .delete-button {
                display: block;

                &.editing {
                  margin-top: -20px;
                  margin-left: 150px;
                }
              }
            }
          }
        }

        .generic-step-block-container {
          border-top: none;
          margin: 20px;
          z-index: 1;

          .ant-cascader-picker-label {
            display: none;
          }

          &.editing {
            z-index: 2;
          }

          .add-event {
            height: 42px;
            background: transparent;
          }

          .event-container {
            background: white;
            border-radius: 5;
            box-shadow: 0 2px 10px 0 rgba(71, 97, 124, 0.1);
            padding: 15px;
          }
        }
      }
    }

    table.table thead {
      tr > th:nth-child(1) {
        width: 50px;
      }

      tr > th:nth-child(2) {
        width: 40%;
      }
    }

    select {
      position: relative;
      max-width: 250px;
    }

    input {
      position: relative;
    }
  }
  .redirect-to-group-wrapper{
      position: relative;
      margin: 0;
      top: 0;
      left: 0;
      z-index: unset;
      margin-bottom: 1%;
      .redirect-text {
        cursor: pointer;
      }
  }
 }

.sceanrio-domain-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .page-label,
  .vendor-list{
    text-align: left;
    font:600 14px Open Sans;
    color: #272727;
    opacity: 1;
    .vl-name{
      cursor: pointer;
      margin-left: 5px;
    }
    .ant-tag.spsg-chip.vendor-title {
      cursor: pointer;
    }
  }
  .page-title{
      text-align: left;
      font: 600 14px Open Sans;
      letter-spacing: 0px;
      color: #272727;
      opacity: 1;
    }
  .scenario-add-options {
    display: flex;
    .scenario-import {
      margin-right: 10px;
      border: 1px solid #c5cdd9;
      background: linear-gradient(white, #F2F4F7);
      color: #000;
    }
  }
  .select-button-container {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    button {
      border: none;
      border-radius: 3px;
      padding: 11px 15px;
      font-family: @font-main;
      font-size: 14px;
      line-height: 14px;
      min-width: 100px;

      &.select-button {
        max-width: 145px;

        &:hover {
          background: #1265e0;
        }
      }
    }
  }
}

.action-msg {
  .ant-popover-content {
    .ant-popover-inner {
      background-color: #FFF1F0;
    }
    .ant-popover-inner-content {
      color: #FF2626 !important;
      font-size: 11px !important;
      padding: 4px !important;
    }
  }
}

