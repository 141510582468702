table.list-manager {
    font-size: 19px;

    th {
        color: #515151;

        &.remove,
        &.admin {
            text-align: center;
        }
    }

    tr {
        &.entry {
            td {
                border-top: 0px;
            }
        }

        td {
            color: #898888;
            vertical-align: middle;
        }

        td:nth-child(3),
        td:nth-child(4) {
            text-align: center;
        }
    }

    .pad {
        width: 30%;
    }

    .btn-submit {
        width: 115px;
    }

    td.remove-cell, td.submit-cell {
        text-align: center;
    }

    td.submit-cell input {
    }

    a.remove {
        color: #000;
    }
}
