.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}

.ant-tabs-nav-container {
    font-size: 17px;
}

.ant-dropdown-trigger, .ant-dropdown-link {
    font-size: 16px;
    cursor: pointer;
}

.ant-dropdown-menu-item {
    font-size: 16px !important;
}

.ant-message {
    z-index: 99999;
}

.ant-select-dropdown {
    z-index: 99999;
}

.ant-popover {
    z-index: 99999;
}

.ant-select-dropdown-menu {
    max-height: 200px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  padding-bottom: 0;
  padding-top: 10px;
}

.ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab  {
  padding: 3px 30px;
}

.ant-dropdown {
  z-index: 9999999;
}


.ant-tooltip  {
  z-index: 999999;
}
