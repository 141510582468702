    .sites-container {
        padding-top: 60px;

        h6 {
            display:none;
        }

        .table.site-list {
            border: 1px solid #E6EAEE;

            thead {
                display: none;
            }

            tbody > tr > td {
                border: none;
                border-bottom: 1px solid #E6EAEE;
                font-family:"Myriad Pro", Arial;
                font-size: 19px;
                font-weight: 400;
                color: #354052;
                padding: 20px 20px 12px;
                cursor: pointer;

                &:last-child {
                  padding: 16px 20px;
                }
            }

            tbody > tr:nth-child(even) {
                background-color: #F7FAFC;
            }

            tbody > tr:hover td:nth-child(1) {
                box-shadow: 4px 0px 0px #35CD96 inset;
                color: #318c6b;
            }

            .btn {
                min-width: auto;
                width: 24px;
                height: 24px;
                color: transparent;
                outline: 0;
                background-color:transparent;
                border:none;
                background-size:16px 16px;
                background-position: 5px 5px;
                background-repeat: no-repeat;
                opacity:0.4;
            }

            .btn:hover {
                opacity: 0.7;
            }

            .btn:active {
                box-shadow:none;
                opacity: 0.7;
            }

            tbody > tr > td:nth-child(2) {
                text-align: right;
            }

            .custom-icon.btn.i-delete:hover {
                // background-image: url('../../../../../../../images/icon-delete.png');
                background-color: transparent;
            }
        }

        button {
          font-size: 14px;
          font-weight: bold;
        }

        p.title {
          font-size: 20px;
          font-weight: bold;
          line-height: 25px;
        }

        i {
          font-size: 32px;
        }

        td.edit {
          padding-top: 15px;
          padding-bottom: 10px;
        }
    }

    p.site-group-edit, p.site-group-delete {
      font-family: "Helvetica Neue";
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
      padding: 10px 13px;
      margin-bottom: 0;
    }

    p.site-group-delete {
      color: #FF5479;
    }
