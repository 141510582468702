#avocado {
  .ant-modal.rule-explainers {
    .ant-modal-content {
      width: 750px;
      margin: 0 auto;

      .ant-modal-close {
        color: white
      }

      .ant-modal-body {
        margin-top: 20px;
        padding: 0;

        .rule-explainer-modal-container {
          margin: 30px;
          .ant-input-affix-wrapper {
            width: 665px !important;
          }
          padding-bottom: 30px;
          .ant-input {
            margin-bottom: 0;
          }
          img {
            margin-right: 20px;
            width: 320px;
          }
          p {
            margin-bottom: 0;
          }
          .rule-container {
            .rule-name {
              font-weight: bold;
              font-size: 17px;
              margin-bottom: -5px;
              margin-top: 20px;
            }
            .rule-description {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
