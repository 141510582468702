
.pagination-container {
    width: 100%;
    display: inline-flex;
    .pagination{
        margin: 0 auto;
        .previous, .next{
            color: #D9D9D9;
        }
        .active{
            a {
                border-color:#411F90;
                color: #411F90;
                &:hover,&:focus{
                    background-color: #F5F0FF !important;
                    // color: #411F90;
                }
            }
        }
        a{
            background-color: white;
            margin: 5px;
            border-radius: 4px;
            width: 25px;
            height: 25px;
            color: #949494;
            line-height: 10px;
            padding: 7px;
            font-size: 15px;
            
            .break-me{
                border: none;
            }
            &:focus {
                outline: 0;
            }
        }
        >li:not(.disabled){
            >a {
                &:hover,
                &:focus {
                    background: #fff;
                    border-color: #411F90;
                }
            }
        }
    }
    
}