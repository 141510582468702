@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.spsg-input {
  > input, >span.ant-input-affix-wrapper {
    padding: 0 10px;
    height: 36px;
    outline: 0;
    box-shadow: none;
    border-radius: 3px;
    background: #fff;
    border: 1px solid #DAE4Eb;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #272727;
    overflow: hidden;
    &::placeholder {
      color: #d9d9d9;
    }
    &:focus,&.ant-input-affix-wrapper-focused {
      border: 1px solid #4f28b3;
      background: #fff;
    }
    &[disabled] {
      border: 1px solid #f0f2f4;
      background: #fafbfc;
      color: #bbbbbb;
      cursor: not-allowed !important;
      &::placeholder {
        color: #bbbbbb;
      }
    }
  }
  &.spsg-left .spsg-icn {
    position: absolute;
    left: 16px;
    top: 17px;
    svg {
      font-size: 16px;
    }
  }
  &.spsg-right .spsg-icn {
    position: absolute;
    right: 16px;
    top: 17px;
    svg {
      font-size: 16px;
    }
  }

  &.purple{
    flex:1;
    input{
      width: 250px;
      height: 34px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 3px #0000001F;
      border: 1px solid #D4D4D4;
      opacity: 1;
      flex:1
    }
  }

  &.spsg-left {
    input {
      padding-left: 42px;
    }
  }

  &.spsg-right {
    input {
      padding-right: 42px;
    }
  }

  &.spsg-error {
    >.ant-input-affix-wrapper, >.ant-input {
      border: 1px solid #c02822;
    }
  }

  // .spsg-details {
  //   font-family: Open Sans;
  //   font-weight: normal;
  //   font-size: 12px;
  //   color: #ff2626;
  // }

  &.input-medium {
    .input-box > input {
      padding: 0 12px;
      height: 40px;
      line-height: 40px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 21px;
    }
    .input-icon-left {
      top: 14px;
      svg {
        width: 14px;
        height: 14px;
        stroke-width: 1.75;
      }
    }
    .input-icon-right {
      top: 14px;
      svg {
        width: 14px;
        height: 14px;
        stroke-width: 1.75;
      }
    }
  }

  &.input-large {
    .input-box > input {
      padding: 0 16px;
      height: 56px;
      line-height: 56px;
      border-radius: 12px;
      font-size: 16px;
      line-height: 24px;
    }
    .input-icon-left {
      top: 20px;
      svg {
        width: 18px;
        height: 18px;
        stroke-width: 1.75;
      }
    }
    .input-icon-right {
      top: 20px;
      svg {
        width: 18px;
        height: 18px;
        stroke-width: 1.75;
      }
    }
  }
  >span.ant-input-affix-wrapper{
    span.ant-input-suffix >span.anticon-search >svg {
      opacity: 0.4;
    }
  }
}

//for ant default input search
span.ant-input-affix-wrapper{
  span.ant-input-suffix >span.anticon-search >svg {
    opacity: 0.4;
  }
}

.ant-input-search.bulk-filter{
  width: 300px;
}

