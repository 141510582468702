@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

#avocado {
  .admin-accuracy-container {
    .ant-tabs-nav {
      padding-top: 0;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #362360;
    }
    .ant-tabs-ink-bar {
      position: absolute;
      background: #000000;
      pointer-events: none;
      color: #362360;
    }
    .ant-tabs-tab {
      font: normal normal 300 20px/42px Oswald;
      color: #43425D;
    }
      
    .summary-score {
      width: 330px;
      flex: 1;
      margin-right: 20px;
      margin-top: 35px;
      &.small {
        width: 140px;
        margin-top: 10px;
      }
      .progress {
        background: #D7D9DC 0% 0% no-repeat padding-box;
        height: 10px;
        width: 100%;
        position: relative;
        margin: 12px 0;
        &.small {
          height: 6px;
        }
        .amount {
          display: block;
          content: '';
          clear: both;
          background: #2699FB 0% 0% no-repeat padding-box;
          height: 100%;
          width: 76%;
          position: absolute;
          left: 0;
          top: 0;
        }
        &.green > .amount {
          background: #198908;
        }
        &.blue > .amount {
          background: #2699FB;
        }
        &.red > .amount {
          background: #FC434B;
        }
        &.turquoise > .amount {
          background: #00B3A6;
        }
      }
      .score-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        > div {
          white-space: nowrap;
          letter-spacing: 0px;
          color: #2D2D2D;
          &:last-child {
            text-align: right;
            font: normal normal bold 14px Open Sans;
          }
          &:first-child, .full-title {
            flex: 1;
            font: normal normal 600 14px Open Sans;
            text-align: left;
          }
        }
        &.small > div {
          &:last-child {
            text-align: right;
            font: normal normal bold 12px Open Sans;
          }
          &:first-child, .full-title {
            flex: 1;
            font: normal normal 600 12px Open Sans;
            text-align: left;
          }
        }
      }
    }
    .title-privacy-lens {
      font-size: 28px;
      color: #2D2D2D;
      font-weight: 400;
      font-family: 'Oswald', sans-serif;
      margin-top: 24px;
      margin-bottom: 24px;
  
      span {
        font-weight: 200;
      }
    }
  
    .subtitle-privacy-lens {
      cursor: pointer;
      border-top: 1px solid #e8e8e8;
      margin-left: 5px;
      font-size: 22px;
      padding: 24px 0 0 0;
      letter-spacing: 0px;
      color: #2D2D2D;
      font-family: 'Oswald';
      font-weight: 200;
      margin-top: 20px;
      margin-bottom: 30px;
      &.composition {
        margin-bottom: 30px;
        border-bottom: 1px solid lightgray;
        padding-bottom: 15px;
        padding-top: 0;
        margin-top: 0;
        border-top: none;
        color: #411F90;
      }
      
      span {
        font-weight: 400;
      }
    }

    .summary-card {
      padding: 30px;
      border-radius: 20px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 10px #0000001f;
      border: 1px solid #D4D4D4;
      opacity: 1;
      margin-bottom: 30px;
      cursor: pointer;
    }

    .selects-container {
      .ant-select {
        width: 200px;
        border-radius: 5px;
        box-shadow: 0px 5px 10px #0000001f;
        border: 1px solid #D4D4D4;
        margin-right: 10px;
      }

      &:nth-child(2) {
        width: 300px;
      }

      button {
        max-width: 200px;
        background-color: #411F90 !important;
        letter-spacing: 0px;
        font-size: 16px;
        font-weight: 200;
        font-family: 'Oswald';
        border-radius: 5px;
        color: white;
        width: 100px;
      }

      p {
        margin-top: 20px;
        span {
          font-weight: bold;
        }
        margin-top: 20px;
        font-size: 13px;
      }
    }

    .estimates-container {
      .composition {
        width: 50%;
        border-right: 1px solid lightgray;
        padding-right: 40px;
      }

      .manual {
        width: 50%;
        padding-left: 40px;

        .pink-container {
          background: #E9DFFF 0% 0% no-repeat padding-box;
          border: 0.5px dashed #411F90;
          border-radius: 10px;
          opacity: 1;
          padding: 20px;
          border-radius: 20px;
          p {
            text-align: left;
            font: normal normal normal 13px/20px Open Sans;
            letter-spacing: 0px;
            color: #43425D;
            span {
              text-decoration: underline;
              color: #411F90
            }
          }
        }
      }
    }

    .compostion-container {

      .data-container {
        justify-content: space-between;
      }

      .title-pie {
        text-align: center;
      }

      .pie-container {
        border-left: 1px solid lightgray;
        padding-left: 35px;

        ul.labels {
          padding: 20px;
          li {
            font-size: 18px;
            span {
              font-size: 14px;
              color: #3B3B3B
            }
          }
        }
      }

      .left {
        width: 25%;
        padding-left: 40px;
        padding-top: 40px;

        .val-container {
          margin-right: 20px;
          margin-bottom: 20px;
          text-align: left;
          font: normal normal normal 12px/17px Open Sans;
          letter-spacing: 0px;
          color: #000000;
          opacity: 0.8;
          .value {
            margin-top: 10px;
            font: normal normal 600 18px/24px Open Sans;
            opacity: 0.87;
          }
        }
      }
      .right {
        width: 37%;
      }
      .middle {
        width: 38%;
      }
    }

    .ant-table-wrapper {
      .ant-table-container {
        border-color: rgba(65, 31, 144, 0.3);
      }

      table {
        border-color: rgba(65, 31, 144, 0.3);

        thead > tr > th {
          background: #E9DFFF;
          font-family: 'Open Sans';
          font-size: 12px;
          font-weight: 600;
          color: #2d2d2d;
          text-transform: none !important;
          border-color: rgba(65, 31, 144, 0.3);
          p {
            font-size: 12px;
          }
        }

        tbody > tr > td {
          border-color: rgba(65, 31, 144, 0.3);
        }
      }
    }
  }

  .manual-scoring {
    .top-lane {
      justify-content: space-between;
      margin-top: 0 !important;

      .title-privacy-lens {
        font-weight: 200;
        font-size: 24px;
      }
      button {
        max-width: 200px;
        background-color: #411F90 !important;
        letter-spacing: 0px;
        font-size: 16px;
        font-weight: 200;
        font-family: 'Oswald';
        border-radius: 5px;
        color: white;
        width: 100px;
        margin-top: 24px;
      }
    }
    .search-container {
      .ant-input-search {
        height: 35px;
      }
      .download {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #411F90;
        border-radius: 6px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        color: #411F90;
        margin-left: 10px;
      }

      .ant-upload-list.ant-upload-list-text {
        display: none;
      }

      .template {
        width: 300px;
        font-size: 12px;
        margin-top: 10px;
      }

      .ant-upload {
        margin-left: 25px;
      }
    }
    .go-back {
      margin-top: 20px;
      color: gray;
      cursor: pointer;
      p {
        font-size: 12px;
      }
      span {
        margin-top: 1px;
      }
    }

    .ant-collapse {
      border: none;

      .ant-collapse-header {
        background-color: white;
        text-align: left;
        font: normal normal 300 22px/14px Oswald;
        letter-spacing: 0px;
        color: #411F90;

        span.number {
          font-weight: 500;
          margin-left: 5px;
        }
      }

      .privacy-lens-grid-properties {
        border: none;
        padding: none;
        padding: 0;
        margin: 0;
      }

      .ant-collapse-content {
        border: none;
      }
    }
  }
}

.batch-edit {
  .title {
    font-size: 26px;
    padding: 24px 0 0 0;
    letter-spacing: 0px;
    color: #2D2D2D;
    font-family: 'Oswald';
    font-weight: 200;
    padding-left: 20px;
    border-bottom: 1px solid lightgray;
  }
  
  button.selected {
    border: 1px solid purple;
  }
}


.admin-accuracy-container {
  .filters {
    p.filters-title {
      margin-top: 30px;
    }
    .filter-select {
      width: 250px;
      margin-right: 10px;
  
      p {
        font: normal normal 600 13px/12px Open Sans;
        letter-spacing: 0px;
        color: #2D2D2D;
      }
  
      .ant-select {
        box-shadow: 0px 2px 3px #0000001F;
        border: 1px solid #D4D4D4;
        border-radius: 5px;
      }
    }
  }
  p.filters-title {
    font: normal normal 600 15px/14px Open Sans;
    letter-spacing: 0px;
    color: #2D2D2D;
    margin-right: 15px;
  }
  .quick-filters {
    .max-rule {
      font: normal normal 600 12px/12px Open Sans;
      letter-spacing: 0px;
      color: #2D2D2D;
      opacity: 0.5;
      width: 200px;
      margin-top: 5px;
      margin-left: 30px;
    }
    margin-top: 10px;
    p.filters-title {
      margin-top: 10px;
    }

    button {
      text-align: center;
      font: normal normal 600 13px/14px Open Sans;
      letter-spacing: 0px;
      color: #2D2D2D;
      width: 96px;
      height: 34px;
      background: #F5F5F5 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 3px #0000001F;
      border-radius: 5px;
      margin-right: 10px;

      &.selected {
        background: white;
        box-shadow: 0px 2px 3px #0000001F;
        border: 1px solid #411F90;
        border-radius: 5px;
      }
    }

    .perform {
      margin-left: 40px;
      button {
        width: 189px;
        height: 34px;
      }
    }
  }

  .accuracy-graph {
    margin-top: 40px;
    .recharts-legend-item-text {
      margin-top: 10px;
      font: normal normal normal 13px/16px;
      letter-spacing: 0px;
      color: #8E8E8E;
    }
  }

  .snapshot-evolution-container {
    .subtitle-privacy-lens {
      border-top: none !important;
    }
    .system-accuracy {
      font: normal normal bold 17px/24px Open Sans;
      letter-spacing: 0px;
      color: #000000;
      opacity: 0.87;
    }
  }

  .inacuracy-container {
    .subtitle-privacy-lens {
      border-top: none !important;
    }
    .language-table-container {
      margin-left: 50px;
    }
    .search-container {
      padding: 10px 0;
      justify-content: space-between;
      display: flex;
      width: 100%;
      .ant-input-search {
        width: 300px !important;
        border: 1px solid #D4D4D4;
        box-shadow: 0px 2px 3px #0000001f;
        border-radius: 5px;
      }
    }
  }
}

.search-container.p-l-admin .download {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #411F90;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #411F90;
  margin-left: 10px;
}

.downpload-container.p-l-admin {
  justify-content: flex-end;
  display: flex;
  margin-bottom: 10px;
}

