@import "./diagnose.css.less";

.vendor-evidence-modal{
  .ant-modal {
    .ant-spin-dot-item{
      background-color: @primary-color;
    }
    .ant-modal-content{
      border-radius: 10px;
      .ant-modal-close-x {
        margin: 0 auto;
        svg {
          color: @font-color !important;
        }
      }
      .ant-modal-header{
        border-radius: 10px 10px 0 0;
        .ant-modal-title{
          span {
            color: #9c9c9c;
            font-size: 14px;
          }
        }
      }
      .ant-modal-body{
        font-size: 14px;
        padding-top: 10px;
        .ant-btn-primary{
          background-color: @primary-color;
          border-color: @primary-color;
          font-size: 14px;
          &:hover{
            opacity: 0.8;
            transition: all 0.2s;
          }
        }
        .info-block{
          margin-bottom: 10px;
          .info-block-title{
            color: #9c9c9c;
          }
          .privacy-policy-link{
            color: @primary-color;
          }
          .download-csv-btn{
            border-color: @primary-color;
            color: @primary-color;
            border-radius: 10px;
            .anticon{
              padding: 0;
            }
          }
        }
        .info-block-flex{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .detailed-info-block{
          display: flex;
          border-top: 1px solid lightgrey;
          border-bottom: 1px solid lightgrey;
          .detailed-info-filters{
            padding: 10px 0;
            width: 30%;
            border-right: 1px solid lightgrey;
            .detailed-info-websites-filter .detailed-info-websites-filter-items{
              max-height: 312px;
              overflow: scroll;
              .ant-menu {
                .ant-menu-item-selected {
                  color: @primary-color;
                  background-color: #f6f2ff;
                }
                .ant-menu-item{
                  height: 24px;
                  line-height: 24px;
                  padding: 0 5px;
                  &:hover{
                    color: @primary-color;
                  }
                }
                .ant-menu-item-active{
                  color: @primary-color;
                }
              }
            }
            .detailed-info-regions-filter{
              margin-bottom: 10px;
            }
            .ant-select{
              width: 95%;
              .ant-select-selector{
                height: 40px;
                border-radius: 5px;
                box-shadow: 0px 2px 3px @boxshadow-color;
              }
              &.ant-select-focused, &:hover{
                .ant-select-selector{
                  border-color: @primary-color;
                }
              }
            }
          }

          .detailed-info-results{
            padding: 10px 0 10px 10px;
            width: 70%;
            .detailed-info-results-header{
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              width: 98%;
              .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused{
                border-color: @primary-color;
                box-shadow: 0px 2px 3px @boxshadow-color;
              }
              .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
                border-color: @primary-color;
              }
              .ant-input{
                margin: 0;
              }
            }
            .detailed-info-results-websites{
              padding: 0;
              max-height: 400px;
              overflow: scroll;
              .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
                border: 1px solid;
                padding: 0 3px;
                border-radius: 2px;
              }
            }
            .ant-collapse {
              background-color: #f6f2ff;
              border-color: @table-border-color;
              > .ant-collapse-item {
                border-color: @table-border-color;
                .ant-collapse-content > .ant-collapse-content-box{
                  padding: 4px 4px 4px 10px;
                  p {
                    margin: 0;
                  }
                }
                > .ant-collapse-header {
                  padding: 4px;
                  color: @primary-color;
                }
              }
              .ant-collapse-content{
                border-color: @table-border-color;
              }
              .detailed-info-host-item:not(:last-child){
                border-bottom: 1px solid @table-border-color
              }

            }

          }
        }
      }
    }
  }
}

.regions-filter-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background-color: #f6f2ff;
}
