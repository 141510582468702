@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

.ant-message-notice-wrapper{
.spsg-message {
  .anticon {
    margin: 0 !important;
    padding: 0 !important;
  }

  > .ant-message-notice-content {
    background: #777777;
    border-radius: 5px;
    padding: 8px 15px;
    border: 1px solid #D9D9D9;
    box-shadow: none !important;
    
    .ant-message-custom-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        margin-right: 12px;
        color: #fff;
        width: 18px;
        height: 18px;
      }
      span {
        font-family: Open Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
      }
    }
    
  }

  &.msg-success > .ant-message-notice-content {
    background: #E2f4e4;
    border-color: #CBE9CF;
    .ant-message-custom-content {
      svg {
        color: #43BA54;
      }
      span {
        color: #272727;
      }
    }
  }

  &.msg-error > .ant-message-notice-content {
    background: #FFF1F0;
    border-color: #FFD2CF;
    max-width: 60vw;
    .ant-message-custom-content {
      svg {
        color: #FF2626;
      }
      span {
        color: #272727;
      }
    }
  }
}
}