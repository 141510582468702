//universal figma styleguide override

// overriding ants default padding of Tag as it does not match the design even after providing the right tokens. 
.avocado.ant-tag {
  padding: 1px 8px;
  .ant-tag-close-icon {
    margin-left: 8px;
    color: inherit;
    font-size: 12px;
  }
  img {
    vertical-align: middle;
  }
}

//close wizard button
button.close-wizard-btn.ant-btn-icon-only{
  border: 0;
  &.ant-btn-default {
    color: var(--ant-color-icon);
    &:hover, &:focus{
      color: var(--ant-color-primary-hover)
    }
    &:active {
      color: var(--ant-color-primary-active);
    }
  }
}

//modal close button
.ant-modal-wrap {
  --ant-modal-footer-padding: 16px;
  button.ant-modal-close {
    .ant-modal-close-x{
      color: var(--ant-color-icon);
    }
    &:hover, &:focus{
      .ant-modal-close-x{
        color: var(--ant-color-primary-hover);
      }
      background-color: transparent;
    }
    &:active {
      .ant-modal-close-x{
        color: var(--ant-color-primary-active);
      }
    }
  }
}

//Tabs font weight and icon
.avocado.ant-tabs-css-var, .ant-tabs {
  --ant-tabs-horizontal-margin: 0;
  .ant-tabs-tab-active {
    font-weight: var(--ant-font-weight-strong);
  }
  .ant-tabs-nav-list {
    .ant-tabs-tab-btn {
      display: flex;
      >.ant-tabs-tab-icon {
        margin-inline-end: 0;
        margin-inline-start: var(--ant-margin-sm);
        order: 2;
      }
    }
  }
}

//selected menu item font weight
.ant-menu {
  .ant-menu-item-selected, .ant-menu-submenu-selected {
    font-weight: 600;
  }
}

//typography
.ant-typography {
  mark {
    background-color: var(--ant-color-primary-bg);
  }
  div& {
    margin-bottom: 0;
  }
}

//Input 
.ant-input-affix-wrapper.avocado {
  .ant-input-suffix {
    margin-inline-start: var(--ant-margin-xs);
  }
}

//Table 
.ant-table-wrapper {
  .ant-table-row-expand-icon{
    border-radius: 2px;
  }
}

//date-picker
.ant-picker-dropdown {
  .ant-picker-footer {
    .ant-picker-now-btn {
      color: var(--ant-color-info);
    }
  }
  .ant-picker-time-panel {
    .ant-picker-header {
      .ant-picker-header-view {
        display: inline-block; /* Ensure it's treated as a block/inline-block element */
        color: transparent; /* Hide the original text */
        position: relative;
        &::before {
          content: "Time (HH:MM:SS)";
          color: var(--ant-color-text-base); /* Show new content */
          position: absolute;
          top: 0;
          left: 0;
          line-height: 22px;
          padding: var(--ant-padding-xs) var(--ant-padding);
        }
      }
    }
  }
}

//alert 
.ant-alert-content {
  text-align: left;
}
