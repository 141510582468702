#avocado .reports-layout {
  .altpay {
    margin-top: 100px;

  .content-container {
    padding-top: 210px;
  }

  .sites-dropdown-container {
    margin-top: -50px;
  }

  .altpay-table.card {
    padding: none;

    thead > tr > th {
      color: black;
      font-size: 12px;
      text-transform: none;
      line-height: 17px;
      font-family: 'Proxima-Nova';
      font-weight: 500;
    }

    tbody > tr > td {
      padding-bottom: 16px;
      padding-top: 16px;
    }

    th.description, td.description {
      min-width: 150px;
    }

    tr.total {
      font-weight: 800;
      color: black;
    }
  }
}

}
