.spsg-toggle.ant-switch {
  background: #ECEFF1;
  height: 14px;
  max-height: 14px;
  max-width: 44px;
  &.ant-switch-checked {
    background: #D3EED6;
    .ant-switch-handle {
      left: calc(100% - 18px);
    }
    &:hover{
      background-color: #D3EED6;
    }
  }
  &[disabled] {
    opacity: 0.2 !important;
  }

  .ant-switch-handle {
    top: -2px;
    left: -2px;
  }

  .ant-switch-handle::before {
    background: #A7B3BF !important;
  }

  &.ant-switch-checked .ant-switch-handle::before {
    background: #43BA54 !important;
  }



  .ant-click-animating-node {
    display: none;
  }
}
