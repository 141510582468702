@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;600&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.spsg-headline {
  font-family: Open Sans;
  margin: 0 0 30px 0;
  color: #2B3B4B;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0px;

  &.headline-1 {
    font-size: 30px;
    font-weight: bold;
    &.headline-oswald {
      font-size: 18px;
    }
  }

  &.headline-2 {
    font-size: 20px;
    font-weight: 400;
    &.headline-oswald {
      font-size: 18px;
    }
  }

  &.headline-3 {
    font-size: 16px;
    font-weight: 500;
    &.headline-oswald {
      font-size: 16px;
      font-weight: 400;
    }
  }

  &.headline-4 {
    font-size: 16px;
    font-weight: 400;
    &.headline-oswald {
      font-size: 16px;
      font-weight: 200;
    }
  }

  &.headline-oswald {
    font-family: Oswald;
    font-weight: 400;
    font-size: 28px;
    line-height: 41px;
  }

  &.headline-paragraph {
    font-family: Open Sans;
    font-weight: 400;
    color: #272727;
    font-size: 14px;
    margin: 0 0 30px 0;
  }

  > span {
    font-weight: 500;
    margin-left: 12px;
  }

}