  .ant-modal.edit-purpose {
    width: 480px;

    .ant-select-selection {
      width      : 130px;
      margin-left: 10px;
    }

    .ant-select-selection-item {
      width: 130px;
    }

    .ant-tabs-nav {
      font-size   : 13px;
      margin-left : 15px;
      margin-right: 15px;
    }

    .ant-modal-body {
      padding: 0;
    }

    .edit-purpose-tab-container {
      padding    : 30px;
      padding-top: 0;
      height: 80vh;
      overflow-y: scroll;
      font-family: @primary-font;
      * {
        // padding: 0 3px;

        .ql-toolbar.ql-snow {
          padding: 8px;
        }

        .ql-snow.ql-toolbar button,
        .ql-snow .ql-toolbar button {
          padding: 3px 5px;
        }

        .ql-editor {
          padding: 12px 15px;
        }

        .ql-snow .ql-tooltip {
          padding: 5px 12px;
          left: -25.0391px;
        }

        .ql-header {
          display: none;
        }
      }



      .default-consent-type {
        display    : flex;
        align-items: baseline;

        label {
          width: 220px;
        }
      }

      .purpose-opt-in {
        margin-top: 20px;

        i {
          margin-left: 5px;
        }

        span {
          font-weight: bold;
          font-size  : 13px;
        }
      }

      .purpose-id {
        font-size    : 14px;
        margin-bottom: 0;
      }

      .consent-actions {
        .tag-manager {
          margin-top: 25px;
        }

        .tag-manager-key-val-container {
          display        : flex;
          flex-direction : row !important;
          justify-content: space-between;

          input,
          textarea {
            width     : 380px;
            margin-top: 5px;
          }
        }

        label.url {
          margin-top: 25px;
        }
      }

      .iab-map-title {
        display       : flex;
        flex-direction: row;
        margin-top    : 20px;

        i {
          display    : flex;
          align-items: center;
          margin-left: 5px;
          padding-top: 6px;
        }
      }

      .button-container {
        align-items: flex-end;
        margin-top : 5px;
      }

      .checkbox-container {
        display  : flex;
        flex-flow: row wrap;

        .iab-purpose-checkbox {
          font-weight: bold;
          margin-top : 10px;
          width      : 48%
        }
      }

      label.title {
        margin-bottom: 0;
        margin-top   : 15px;
        font-weight  : bold;
        font-size    : 14px;
      }

      .description {
        padding: 10px;
        border: 1px solid #f0f2f4;
        color: #bbbbbb;
        border-radius: 3px;
        background-color: #fff;
      }
      .purpose-illustrations{
        padding: 10px;
        background-color: #F5F5F5;
        border-radius: 3px;
        margin-bottom: 10px;
        .container{
          padding: 0px;
          >p {
            font-size: 12px;
            line-height: 16px;
          }
          >.ant-alert{
            margin: 15px 0px;
          }
          .ant-collapse-header{
            padding: 0px;
            margin: 12px 0px;
            .ant-collapse-expand-icon{
              order: 2;
              margin-left: 5px;
            }
            .ant-collapse-header-text{
              order: 1;
              flex: 0 0 max-content;
              font-weight: 600;
            }
            .ant-collapse-extra{
              order: 3;
              font-size: 12px;
              button.ant-switch.spsg-toggle {
                margin-left: 5px;
              }
              span.anticon-delete{
                color: red;
              }
            }
          }
          .ant-collapse-content-box{
            padding: 0px 0px;
            margin-bottom: 12px;
            .ql-snow{
              background-color: #fff;
              &.ql-disabled{
                color: #bbbbbb;;
              }
            }
          }
          >button{
            height: 32px;
            line-height: 0px;
            font-size: 12px;
            font-weight: 600;
            margin-top: 10px;
          }
        }
      }
      .label {
        font-weight: bold;
      }

      .translations {
        display: flex;

        p {
          font-size : 14px;
          margin-top: 5px;
        }
      }

      .purposes-container {

        p {
          font-size: 13.5px;

          &.iab {
            margin-bottom: 0;
          }
        }

        .purposes-in-stack {
          margin-bottom: 40px;
        }

        .purpose-container {
          display        : flex;
          justify-content: space-between;
          border-top     : 1px solid lightgray;

          i {
            margin-top: 4px;
            cursor    : pointer;
          }
        }

        input {
          margin-left : 5px;
          margin-right: 5px;
          width       : 185px;
        }

        .custom-purpose{
          .spsg-details {
            position: absolute;
          }
        }
      }
    }
  }
