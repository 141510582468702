
form#ldms {
  .ant-form-item {
    margin-bottom: 0;
    .ant-picker, .ant-space  {
      width: 100%;
    }
    .ant-form-item-label {
      padding-bottom: var(--ant-margin-xxs);
    }
  }
}

.ant-picker-dropdown.ldms-go-live-date {
  .ant-picker-time-panel {
    width: 176px;
    .ant-picker-header {
      .ant-picker-header-view {
        &::before {
          content: "Time in UTC (hh:mm:ss)";
          padding: var(--ant-padding-xs) 0;
        }
      }
    }
  }
}