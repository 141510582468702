.spsg-alert {
  &.ant-alert {
    align-items: flex-start;
    .ant-alert-content {
      .ant-alert-message {
        font: normal 12px Open Sans;
        letter-spacing: 0px;
        color: #202020;
        opacity: 1;
      }
    }
    &.ant-alert-warning {
      background: #fbffd6 0% 0% no-repeat padding-box;
      border: 0px;
      border-radius: 4px;
      opacity: 1;
    }
    .ant-alert-close-icon {
      color: #899ab4;
      .anticon.anticon-close-circle {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
  span {
    margin-top: 0.2em;
    &.anticon-warning {
      color: #ff9100;
    }
  }
  border: none;
}
