  .regions-dropdown {

    label.ant-radio-button-wrapper {
      height: 14px;
      width: 14px;
      padding: 0;
      border-radius: 30px !important;
      border-color: #411F90 !important;
    }

    .regions-dropdown {
      height: 50px;
      min-width: 170px !important;
      width: 170px;
    }

    .ant-radio-button-checked{
      border-color: #411F90 !important;
      background: radial-gradient(#411F90 45%, rgba(255, 0, 0, 0) 51%);
    }

  }

  .ant-dropdown-trigger.regions-dropdown-main {
    cursor: pointer;
    background-color: #efefef;
    border: 1px solid #cfcfcf;
    border-radius: 5px;
    width: 170px;
    font-size: 16px;
    font-weight: 600;
  }

  .region-block{
      color: #4F556D;
      font-size: 16px;
      &:not(:last-child){
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 5px;
        margin-bottom: 10px;
      }
    .radio-btn-name{
        font-size: 16px;
        font-weight: 600;
        padding-left: 5px;
        

    }
    .subregion-name{
      padding-left: 20px;
      padding-right: 5px;
      padding-top: 2px;
      padding-bottom: 2px;
  
      .check-mark-grey{
          cursor: pointer;
          float: right;
          margin-left: 30px;
          transform: rotate( 45deg); 
          height: 11px; width: 5px; 
          border-bottom: 1.5px solid #C9C9C9; 
          border-right: 1.5px solid #C9C9C9; 
      }
      .check-mark-color{
        cursor: pointer;
        float: right;
        margin-left: 30px;
        transform: rotate( 45deg); 
        height: 11px; width: 5px; 
        border-bottom: 1.5px solid #411F90; 
        border-right: 1.5px solid #411F90; 
      }
    }
  }
  

