.spsg-select {
  .ant-select {
    width: 200px;
    border-radius: 3px;
    .ant-select-selector {
      box-shadow: none !important;
      outline: 0 !important;
      border: 1px solid #DAE4EB;
      border-radius: 3px;
    }
    &:hover .ant-select-selector, &:focus .ant-select-selector {
      border: 1px solid #DAE4EB;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16) !important;
      outline: 0 !important;
    }
    &.ant-select-open.ant-select-show-search{
      border-color: @primary-color;
    }
    &.ant-select-status-error:hover .ant-select-selector {
      border-color: #ff2626;
    }
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #DAE4EB !important;
  }
  &.spsg-error {
    .ant-select{
      &:hover, &:focus, &{
        .ant-select-selector{
          border-color: #ff2626;
        }
      }
    }
    .spsg-details {
      font-family: Open Sans;
      font-weight: normal;
      font-size: 12px;
      color: #ff2626;
    }
  }
}

.ant-select.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer)  {
  >.ant-select-selector{
    box-shadow: none;
  }
}

//SelectWithSelectAll
.ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
  >.ant-select-selection-item{
    color: var(--ant-color-info);
    background: var(--ant-color-info-bg);
    border-color: var(--ant-color-info-border);
  }
}
