@fallback-fonts: "Proxima-Nova", Arial, Helvetica, sans-serif;
@dark-grey: #2f3237;
@light-grey: #f0f0f0;
@light-blue: #8699b3;
@very-light-blue: #f8fbfe;

.campaigns-container {
    padding-top: 70px;
    max-width: 1200px;

    table.tiny.table {
        font-family: "Proxima-Nova", @fallback-fonts;
        font-size: 13px;
        font-weight: 600;
        color: @dark-grey;

        td.partition-header {
            position: relative;

            & > div.summary-tabs {
              background-color: @very-light-blue;
              max-width: 1200px;

              p {
                font-family: "Proxima-Nova", @fallback-fonts;
                font-size: 15px;
                font-weight: 600;
                color: @dark-grey;
                float: left;

                &.total {
                    font-style: italic;
                    color: @light-blue;
                }

                &.description {
                  float: left;
                  margin-left: 4px;
                  margin-top: 3px;
                }

                &.no-data-comment {
                    text-align: center;
                    width: 100%;
                    margin-bottom: 35px;
                    margin-top: 5px;
                }
              }

              .ant-tabs-bar {
                  border-bottom: none;

                  div.ant-tabs-content > div.ant-tabs-tabpane > div > table.tiny.table {
                      & > thead {
                          & > tr {
                              border-top: none;
                              border-right: none;
                              border-left: none;
                          }
                      }
                      & > tbody {
                          tr {
                              border-top: none;
                              border-right: none;
                              border-left: none;
                          }

                          &:last-child tr {
                              border-bottom: none;
                          }
                      }
                  }
              }

              table {
                  border: none;
              }

              .ant-tabs-tab {
                  padding: 8px 30px;
              }

              .ant-tabs-tabpane {
                  padding-top: 20px
              }


              .partition-selector {
                  position: absolute;
                  top: 21px;
                  right: 25px;

                  p {
                      padding-right: 10px;
                      padding-top: 3px;
                  }

                  .ant-select {
                      width: 190px;
                      font-size: 15px;
                  }
              }

              .ant-dropdown-link {
                  color: #3d7de1;
              }

              tr.partition-row > td {
                  font-family: "Proxima-Nova", @fallback-fonts;
                  font-size: 13px;
                  font-weight: 600;
                  color: @dark-grey;
              }

              .campaign-summary-container {
                  background-color: @very-light-blue;
                  border: 1px solid #eee;
                  border: none;

                  .summary-dropdown-label {
                      color: #7c91ac;
                  }

                  .campaign-summary {
                      margin: 0 30px;
                      padding-bottom: 15px;

                      button {
                          position: absolute;
                          right: 60px;
                          top: 180px;
                          z-index: 1;
                          padding: 3px 5px;
                      }

                      .campaign-summary-description, h2, p.description {
                          font-family: "Proxima-Nova", @fallback-fonts;
                          font-size: 13px;
                          font-weight: 600;
                          text-align: center;
                          color: @light-blue;
                      }

                      .campaign-summary-number {
                          font-family: "Myriad Pro", @fallback-fonts;
                          font-size: 25px;
                          font-weight: 600;
                          line-height: 0.88;
                          color: @dark-grey;
                          text-align: center;
                      }

                      .toggleShowScenarioLink {
                          position: absolute;
                          bottom: 20px;
                          right: 45px;
                          text-decoration: underline;
                          color: @dark-grey;
                          cursor: pointer;
                      }

                      .header-tables-container {
                          margin-top: 20px;
                          padding-bottom: 20px;

                          &.no-adblock > div {
                            max-width: 600px;
                          }

                          &.adblock-included > div {
                            &:first-child {
                                float: left;
                                width: 51%;
                                min-width: 350px;

                            }

                            &:last-child {
                                float: right;
                                width: 47%;
                                min-width: 275px;
                            }
                          }
                      }

                      .tables {
                          .ant-table-thead > tr.ant-table-row-hover > td,
                          .ant-table-tbody > tr.ant-table-row-hover > td,
                          .ant-table-thead > tr:hover > td,
                          .ant-table-tbody > tr:hover > td {
                              background-color: #f3f9fe;
                          }

                          .ant-table-tbody > tr.highlighted.ant-table-row:hover > td {
                              background-color: rgba(107, 138, 179, 0.08);
                          }

                          h2 {
                              font-size: 18px;
                              font-weight: 600;
                              font-style: normal;
                              font-stretch: normal;
                              color: #354052;
                              color: var(--dark-grey-blue);
                          }

                          th {
                              background-color: transparent;
                              text-transform: none;
                              font-size: 12px;
                              padding-bottom: 8px;
                              font-family: "Proxima-Nova", @fallback-fonts;
                          }

                          td {
                              font-family: "Proxima-Nova", @fallback-fonts;
                              font-size: 15px;
                              font-weight: 570;
                              color: @dark-grey;
                              border-bottom: none;
                              text-align: left;
                              height: 60px;
                              padding-top: 15px;
                              padding-botton: 15px;

                              &.description, &.blue {
                                  color: @light-blue;
                              }
                          }

                          tr {
                              height: 60px;
                          }

                          .engagement-table-container > h2 {
                              margin-left: 600px;
                          }

                          .values-table-container {
                              .ant-table-row-expand-icon {
                                  height: auto;
                              }

                              position: relative;
                              float: left;
                              width: 600px;
                              z-index: 99;
                              background-color: @very-light-blue;

                              .incomplete-data-alert {
                                  position: absolute;
                                  top: 23px;
                                  left: 86px;
                                  padding-right: 6px;
                                  padding: 5px 20px 5px px;
                                  border: none;
                              }

                              th {
                                  &.count {
                                      position: absolute;
                                      top: 10px;
                                      left: 216px;
                                  }

                                  &.rpm {
                                      position: absolute;
                                      top: 10px;
                                      left: 400px;
                                  }

                                  &.value {
                                      text-align: right;
                                  }
                              }

                              tr {
                                  &:first-child {
                                      cursor: pointer;
                                  }

                                  &.highlighted {
                                      background-color: rgba(107, 138, 179, 0.07)
                                  }

                                  &.low-opacity {
                                      opacity: 0.4;
                                  }
                              }

                              td {
                                  &.type {
                                      width: 24%;
                                      padding-left: 15px;
                                  }

                                  &.count {
                                      text-align: right;
                                  }

                                  &.description {
                                      width: 24%;
                                      font-size: 13px;
                                  }

                                  &.rpm {
                                      width: 17%;
                                  }

                                  &.value {
                                      width: 20%;
                                      text-align: right;
                                      padding-right: 15px;
                                  }
                              }

                              input {
                                  width: 75px;
                                  font-size: inherit;
                                  border-radius: 5px;
                                  padding-left: 10px;
                              }

                              input[type=number]::-webkit-inner-spin-button,
                              input[type=number]::-webkit-outer-spin-button {
                                  -webkit-appearance: none;
                                  -moz-appearance: none;
                                  appearance: none;
                                  margin: 0;
                              }

                              input[type="number"] {
                                  background-color:#F4F7F9;
                                  border:1px solid #DDE3EC;
                                  border-radius:3px;
                                  text-align:right;
                                  padding:3px 10px;
                                  font-family:"Proxima-Nova Semibold";
                                  font-size:14px;
                                  color:#354052;
                              }

                              td.description + td > span {
                                  position:relative;
                              }

                              span.currencySign{
                                  position:absolute;
                                  top:-2px;
                                  left:10px;
                                  color:#6B8AB3;
                              }
                          }

                          .breakdown-table-container.old-version {
                              width: 605px;
                              float: left;

                              .choice-breakdown-table {
                                  th, td:nth-child(4) {
                                      top: 10px;
                                      width: 19%;
                                  }

                                  th, td {
                                    &:nth-child(2) {
                                      padding-right: 10px;
                                      width: 20%;
                                    }
                                  }

                                  td:nth-child(1) {
                                    width: 24%
                                  }
                              }
                          }

                          .breakdown-table-container {
                              position: relative;
                              float: right;
                              width: 370px;

                              .choice-breakdown-table {

                                  th, td {
                                      &:nth-child(4) {
                                          width: 21%;
                                          text-align: right;
                                      }

                                      &:nth-child(2) {
                                          width: 22%;
                                          text-align: right;
                                      }

                                      &:nth-child(3) {
                                          width: 21%;
                                          text-align: right;
                                      }

                                      &.choice-name.beta {
                                          width: 23%;
                                      }
                                  }

                                  th:nth-child(4){
                                    position: absolute;
                                    right: 44px;
                                  }

                                  th {
                                    padding-right: 0;
                                  }

                                  & > tbody {
                                      & > tr {
                                          border-top: 1px solid @light-grey;

                                          &:first-child {
                                              border-top: none;
                                          }

                                          & > td {
                                              font-family: "Proxima-Nova", @fallback-fonts;
                                              font-size: 15px;
                                              font-weight: 600;
                                              color: @dark-grey;
                                              border: none;
                                              text-align: left;

                                              &.description {
                                                  color: @light-blue;
                                              }

                                              &:nth-child(2), &:nth-child(3) {
                                                  width: 25%;
                                                  text-align: right;
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }

                      .devision-line {
                          height: 1px;
                          width: 100%;
                          background-color: @light-grey;
                      }

                      h2 {
                          font-size: 14px;
                          text-align: left;
                          padding-left: 30px;
                          margin-top: 30px;
                      }

                      .dropdown-menu > .active > a {
                          background-color: #7c91ac;
                      }



                      p {
                          margin-bottom: 0;
                      }

                      .no-choices-message {
                          padding: 25px 60px;
                      }
                  }
              }
          }
        }
    }

    .ant-select-dropdown.campaign-summary {
        font-size: 15px;
    }

}
