.spsg-datepicker {
    border-color: #d4d4d4;
    border-radius: 5px;

    &:hover {
        border-color: #d4d4d4;
        box-shadow: 0px 2px 3px #0000001F;

        >.ant-picker-input {
            >span.ant-picker-suffix {
                color: rgba(0, 0, 0, 0.75);
            }
        }
    }

    &.ant-picker-focused {
        border-color: #d4d4d4;
        box-shadow: 0px 2px 3px #0000001F;
    }

    >.ant-picker-input {
        >input[disabled] {
            cursor: not-allowed !important;
        }

        >input:focus,
        >input:hover {
            border-color: #d4d4d4;
        }
    }
}

.spsg-datepicker-popup {
    font-family: "Open Sans";

    >.ant-picker-panel-container {
        .ant-picker-header {
            >button {
                color: #212F43;

                &:hover {
                    color: #411faa;
                }
            }

            .ant-picker-header-view {
                button {
                    color: #212F43;

                    &:hover {
                        color: #411faa;
                    }
                }
            }
        }

        .ant-picker-body {
            >table.ant-picker-content {
                tbody {
                    tr {
                        td.ant-picker-cell:hover.ant-picker-cell-in-view {
                            div.ant-picker-cell-inner {
                                background-color: #e9dfff !important;
                                border-radius: 6px;
                            }
                        }

                        td.ant-picker-cell.ant-picker-cell-in-view {
                            &.ant-picker-cell-selected {
                                div.ant-picker-cell-inner {
                                    color: white;
                                    background-color: #411faa;
                                    border-radius: 6px;
                                }
                            }

                            &.ant-picker-cell-today {
                                .ant-picker-cell-inner::before {
                                    border: 1px solid #411faa;
                                    border-radius: 6px;
                                }
                            }
                        }

                        &.ant-picker-week-panel-row {
                            &:hover {
                                >td {
                                    background: #e9dfff;
                                }
                            }

                            &.ant-picker-week-panel-row-selected td,
                            .ant-picker-week-panel-row-selected:hover td {
                                background: #411faa;
                            }
                        }
                    }
                }
            }
        }
    }
}