.spsg-treeSelect {
    .ant-select {
        width: 200px;
        .ant-select-selector {
          height: 35px;
          box-shadow: none !important;
          outline: 0 !important;
          border: 1px solid #DAE4EB;
          border-radius: 3px;
        }
        &:hover .ant-select-selector, &:focus .ant-select-selector {
          border: 1px solid #DAE4EB !important;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16) !important;
          outline: 0 !important;
        }
        &.ant-select-open.ant-select-show-search{
          border: 1px solid #43BA54;
        }
      }
    &.spsg-error {
        .ant-select-selector {
          border: 1px solid #ff2626;
        }
        .spsg-details {
          font-family: Open Sans;
          font-weight: normal;
          font-size: 12px;
          color: #ff2626;
        }
      }
}
