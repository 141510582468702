  .ant-modal.create-site-group {
    top: 100px !important;

    &.shared-modal .ant-modal-body p {
      padding-bottom: 10px;
    }

    .ant-select, input {
      width: 100%;
    }

    .ant-select-selection.ant-select-selection--multiple {
      max-height: 100px;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    label {
      display: flex;
      flex-direction: column;
    }

    .ant-modal-footer {
      position: absolute;
      padding: 0 0 0 0;
      top: 10px;
      width: 100%;
      border: none;
    }

    button.ant-btn.ant-btn-primary {
      margin-right: 40px;
    }

    .admin-access-wrapper {
      display: flex;
      padding: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      border: 1px solid #EAEEF1;
      border-radius: 5px;
      background-color: #F7F9FC;
      justify-content: space-between;
    }

    .sites-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    input:nth-child(2) {
      margin-bottom: 15px;
    }
  }
