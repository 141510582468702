.native-message-builder {
  .ant-modal .ant-select-selection--single {
    width: 100% !important;
  }

  .ant-modal .ant-select {
    margin-bottom: 0 !important;
  }

  label {
    color         : black;
    margin        : 0;
    text-transform: uppercase;
    font-size     : 12px;
  }


  .content-container {
    position    : absolute;
    width       : 100%;
    height      : 100%;
    padding     : 20px;
    padding-left: 30px;
    margin      : 0;
    border      : 0;
    outline     : 0;

    .styles-container {
      background: #FFF;
      max-height: 100%;
      position  : relative;
      width     : 50%;
      margin    : 15px 0;


      .four-options>.option-inputs>div {
        display       : flex;
        flex-direction: column-reverse;

        h5 {
          color         : black;
          margin        : 0;
          text-transform: uppercase;
          font-size     : 12px !important;
          align-self    : start;
          height        : 30px;
          font-weight   : 400;
          display       : flex;
          align-items   : center;
        }
      }

      .options-color-picker,
      .options-copy-font-size-and-opacity,
      .options-z-index {
        display      : inline-flex;
        position     : relative;
        border-radius: 4px;
        height       : 30px;
        padding      : 0px;
        color        : rgba(0, 0, 0, 0.65);
        font-size    : 12px;
        width        : 100%;
      }

      .options-color-picker,
      .options-z-index {
        input[type="text"] {
          height          : 30px;
          padding         : 0 10px;
          width           : inherit;
          border-left     : 2px solid transparent;
          border-radius   : 4px;
          background-color: transparent;
          border          : 1px solid #d9d9d9;

          &:hover {
            border: 1px solid #49a9ee;
          }

          &:focus {
            border    : 1px solid #49a9ee;
            box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
          }
        }

        &.options-error {
          border: 0;

          input[type="text"] {
            border: 1px solid red !important;
          }
        }
      }

      .options-color-picker {
        input[type="color"] {
          position: absolute;
          top     : 1px;
          right   : 1px;
          cursor  : pointer;
          opacity : 0;
          display : block;
          height  : 28px;
          width   : 34px;
          z-index : 100;
        }

        span {
          position        : absolute;
          top             : 6px;
          right           : 8px;
          padding         : 0 0 2px 0;
          font-size       : 16px;
          color           : #999999;
          text-align      : center;
          background-color: transparent;
          width           : 18px;
          height          : 18px;
          border-radius   : 3px;
          border          : 1px solid #d9d9d9;
        }
      }

      .bg-color-container {
        width: 50%;

        h5 {
          font-size: 10px !important;
        }
      }
    }

    .custom-fields-container, .add-field-btn-container, .custom-field-row-container {
      margin: 15px 0;
    }

    .custom-field-row {
      width           : 70%;
      background-color: #bebebe1f;
      display         : flex;
      justify-content : space-between;
      padding         : 10px;

      .key-container, .value-container {
        width: 45%
      }

      .trash-container {
        width          : 5%;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        padding-top    : 15px;
        cursor         : pointer;
      }
    }

    .text-input-container, .privacy-manager-select-container {
      margin: 15px 0;
      width : 50%;
    }

    .choice-options-adder {
      width          : 50%;
      display        : flex;
      margin         : 15px 0;

      div:first-child {
        margin-right: 5px;
      }
    }

    .choice-options-container {
      margin: 15px 0;
    }

    .collapse-container {
      .ant-collapse {
        margin: 10px 0;
        position: relative;
        opacity: 0.65;
      }
    }

    .trash-icon-container {
      position: absolute;
      top     : 0;
      right   : 0;
      padding : 10px;
      cursor: pointer;
      transition: transform .2s, opacity .2s;
      opacity: .5;

      &:hover {
        transform: scale(1.2);
        opacity: 1;
      }
    }

    h3, h4, h5 {
      text-transform: uppercase;
      margin        : 0;
      padding       : 10px 0;
    }

    h5 {
      font-size: 14px !important;
    }
  }

  .name-container {
    height         : 60px;
    padding        : 10px;
    border-bottom  : .5px solid #c9c9c9;
    display        : flex;
    flex-direction : column;
    justify-content: center;

    >div {
      display    : flex;
      align-items: baseline;

      label {
        margin        : 0;
        margin-right  : 5px;
        text-transform: uppercase;
      }

      input {
        margin-bottom: 0 !important;
      }
    }
  }

  .section-menu-item {
    display       : flex;
    align-items   : center;
    padding       : 10px;
    border-bottom : .5px solid #c9c9c9;
    height        : 60px;
    text-transform: uppercase;
    font-size     : 12px;
    font-weight   : lighter;
    cursor        : pointer;

    * {
      margin-right: 10px;
    }

    &.active {
      background-color: #bebebe47;
    }

    &:hover {
      background-color: #eaeaea4a;
    }
  }
}