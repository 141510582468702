  .add-variable-dropdown-modal {
    color: white;

    .content {
      z-index: 999;
      width: 170px;
      border-radius: 5px;
      background-color: #181F2A;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
      position: absolute;
      right: 20px;
      background-color: black;
      padding: 12px;

      &.in-custom-css {
        top: 171px;
        background-color: inherit;
        width: 100%;
        right: 10px;

        .variables {
          max-height: 270px;
          overflow: auto;
        }

        button {
          width: 100%;
        }
      }

      .name-and-value {
        cursor: pointer;
      }

      .name {
        font-size: 12.5px;
        font-weight: 600;
        line-height: 12px;
      }

      .value {
        color: #F5A623;
        font-family: "Courier New";
        font-size: 12px;
        font-weight: bold;
        line-height: 12px;
        padding-bottom: 15px;
      }

      button {
        background-color: #37465C;
        color: white;
      }

      textarea {
        display: flex;
        height: 13px !important;
        border: none !important;
        overflow: auto;
        outline: none;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        resize: none;

        height: auto;
        padding: 0;
        background: transparent;
        cursor: pointer;
      }
    }
  }
