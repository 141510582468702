.view-more-btn,
.view-less-btn {
  margin-top: 8px;
  cursor: pointer;
  color: @primary-color;
  background: none;
  border: none;
  text-decoration: underline;
  &:hover{
    opacity: 0.8;
  }
}

