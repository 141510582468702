@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');

  .domain-sets-container {
    display: flex;
    flex-direction: column;

    &.loading-modal {
      opacity: 0.5;
    }

    .container-pl-usage-standards {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .standards {
        display: flex;
        .spsg-filter-select{
          width: 320px;
        }
      }
      .ant-select {
        width: 300px;
      }
      >.search-container > .spsg-input {
        width: 300px;
        box-shadow: 0px 2px 3px #0000001f;
      }
    }
    .pl-usage {
      &.green {
        border: 1px solid #43BA54;
        ant-progress-success-bg, .ant-progress-bg {
          background-color: #43BA54;
        }
      }
      &.yellow {
        border: 1px solid #FFAB3C;
        ant-progress-success-bg, .ant-progress-bg {
          background-color: #FFAB3C;
        }
      }
      &.red {
        border: 1px solid #FF8282;
        ant-progress-success-bg, .ant-progress-bg {
          background-color: #FF0909;
        }
      }
      .ant-progress {
        font-size: 0;
        top: -6px;
        .ant-progress-outer {
          .ant-progress-inner {
            width: 296px;
          }
        }
      }
      .pl-usage-top {
        padding-top: 10px;
        padding-left: 10px;
        display: flex;
        justify-content: space-between;
        .right {
          font-weight: bold;
          padding-right: 20px;
        }
      }
      width: 300px;
      border-radius: 6px;
      height: 45px;
      ant-progress-success-bg, .ant-progress-bg {
        position: relative;
      }
      .ant-progress-inner {
        width: 300px;
      }
      span.ant-progress-text {
        display: none;
      }
    }
  }

  .ant-select-item-option-active, .ant-select-item-option-selected{
    background-color: #E9DFFF;
  }

  .ant-select-item-option-selected{
    &:after{
      content: '';
      display: inline-block;
      transform: rotate(45deg);
      margin-top: 5px;
      height: 11px;
      width: 5px;
      border-bottom: 1.5px solid #411F90;
      border-right: 1.5px solid #411F90;
    }
  }

  .ant-tooltip.average-impressions {
    .ant-tooltip-inner {
      width: 500px;
    }
  }

  .ant-modal-body {
    .domain-set-title {
      font-family: 'Oswald';
      color: white;
      padding: 10px;
      background: #411F90;
      font-size: 18px;
    }

    .domain-name-modal{
      font-size: 16px;
      font-weight: 600;
      font-family: 'Oswald';
    }

    .ant-select-selector{
      box-shadow: 0px 2px 3px #0000001f;
    }

    .spsg-input.domain-name{
      >input.domain-name{
        box-shadow: 0px 2px 3px #0000001f;
        max-width: 300px;
      }
    }
  }
  .ant-modal.new-standard {
    font: normal normal normal 14px/19px Open Sans;
    .ant-alert { 
      .ant-alert-content > .ant-alert-message {
        font-size: 12px;
      }
    }

    .ant-modal-content .ant-modal-body .modal-container {
      .name-container {
        p {
          font-size: 12px;
          margin-bottom: 0;
        }
      }

      .ant-tabs-tab {
        font-size: 13px;
      }
      .add-property-set-container {
        border: 1px solid #E9DFFF;
        border-radius: 9px;
        margin: 10px 0;
        .incorrect-domains-link a {
          color: #6565A9;
          text-decoration: underline;
        }
        .header-property-set{
          cursor: pointer;
          color: #2D2D2D;
          font-size: 15px;
          background: #E9DFFF;
          border-top-right-radius: 9px;
          border-top-left-radius: 9px;
          font-weight: 500;
          padding: 5px 0;
          .arrow-domain-set{
            padding: 10px;
            transform: rotate(180deg) scale(0.9);
            cursor: pointer;
          }
        }
        
        button {
          border: 1px solid #B5B3F2;
          font: normal normal 600 14px/19px Open Sans;
          border-radius: 2px;
        }

        button.selected {
          background-color: #E9DFFF;
          border: 1px solid #411F90;
          color: #411F90;
        }

        .body-property-set{
          padding: 10px 30px;
          .action-title{
            font-size: 12px;
            padding: 5px 0;
          }
          .applied-regions{
            letter-spacing: 0px;
            color: #000000;
            opacity: 0.5;
            font-size: 14px;
            font-weight: 500;
          }
          .region-name{
            box-shadow: 0px 2px 3px #0000001F;
            border: 1px solid #d9d9d9;
            max-width: 300px;
            border-radius: 2px;
            padding: 4px 9px;
            color: #00000030;
            margin: 5px 0;
          }
          .manual-add-domains{
            margin-bottom: 30px;
            background: #F5F0FF;
            .domains-title{
              background: #E9DFFF;
              padding: 5px 10px;
              margin: 0;
              span{
                font-weight: 600;
              }
            }
            .add-domains-input{
              border: 1px solid #D4D4D4;
              padding: 10px 0 10px 20px;
              
              span{
                font-weight: 600;
              }
              button{
                background: #411F90;
                border: none;
                color: white;
                padding: 5px 25px;
                border-radius: 5px;
                font-family: 'Oswald';
                letter-spacing: 1px;
                font-weight: 300;
              }
              .spsg-input {
                display: inline;
                input{
                  max-width: 300px;
                  padding-right: 10px;
                  margin-right: 10px;
                  box-shadow: 0px 2px 3px #0000001f;
                  height: 32px;
                }
              }
            }
          }
          .rule-details {
            display: inline;
            .view-rules-details{
              color: #411F90;
              padding: 0 15px;
              font-weight: 500;
              cursor: pointer;
            }
            .wrap-rules{
              position: relative;
              margin-top: -2px;
              margin-bottom: 20px;
              max-width: 500px;
              .arrow-rules {
                width: 20px;
                height: 20px;
                border: 1px solid #CACED5;
                transform: rotate(45deg);
                position: absolute;
                top: 0%;
                margin-top: -9px;
                right: 130px;
                z-index: 1;
              }
              .arrow-cover-rules {
                top: 0%;
                margin-top: 0px;
                right: 128px;
                width: 24px;
                height: 1px;
                border: 1px solid transparent;
                position: absolute;
                background: white;
                z-index: 5;
                transform: rotate(0deg);
              }
              .box-rules{
                z-index: 3;
                position: relative;
                border: 1px solid #CACED5;
                border-radius: 10px;
                background: white;
              }
            }
            .rule-details-table{
              max-width: 500px;
              padding: 3px;
              .ant-table-thead > tr > th{
                background: #E9DFFF;
                color: #43425D80;
                padding: 5px 15px;
              }
              .ant-table-tbody > tr > td{
                padding: 5px 15px;
                color: #272727;
                font-size: 12px;
              }
              .checked-rules{
                display: block;
                transform: rotate(45deg);
                height: 11px;
                width: 5px;
                border-bottom: 1.5px solid #411F90;
                border-right: 1.5px solid #411F90;
                margin: 0 auto;
              }
            }
          }
          
          .ant-input-group{
            display: flex;
          }

          .ant-input-search-button{
            border-left: none;
            box-shadow: 1px 2px 3px #0000001f;
          }

          .add-properties{
            cursor: pointer;
            display: inline-block;
            padding: 5px 10px;
            margin-bottom: 10px; 
            color:#6565A9;
            font-weight: 500;
            .cvs-icons{
              transform: scale(0.8);
              padding-right: 10px;
            }
          }

          .wrap {
            position: relative;
            margin-top: -2px;
            margin-bottom: 20px;
          }
          .arrow {
            width: 10px;
            height: 10px;
            border: 1px dashed #6565A9;
            transform: rotate(45deg);
            position: absolute;
            top: 0%;
            margin-top: -5px;
            left: 50px;
            z-index: 10;
          }
          .arrow-cover {
            top: 0%;
            margin-top: -3px;
            left: 50px;
            width: 10px;
            height: 10px;
            border: 1px solid transparent;
            position: absolute;
            background: #F5F0FF;
            z-index: 30;
            transform: rotate(45deg);
          }
          .box {
            background: #F5F0FF !important;
            z-index: 20;
            position: relative;
            border: 1px dashed #6565A9;
            background: #F5F0FF;
            border-radius: 10px;
            .ant-upload.ant-upload-drag{
              background: #F5F0FF !important;
              border-radius: 10px;
              a{
                color: #6565A9;
                font-weight: 500;
              }
              .drop-files{
                padding: 5px;
              }
            }
          }
          
          .warning-text{
            float: right;
            font-size: 12px;
            width: 300px;
            img{
              padding-right: 5px;
              vertical-align: middle;
            }
            
          }
      
          
          div.standard-to-delete {
            padding-top: 7px;
            padding-bottom: 7px;
            border-bottom: 1px solid lightgray;
            justify-content: space-between;
            div {
              font-weight: bold;
            }
      
            i {
              color: #c3c2c2;
              font-size: 19px;
              cursor: pointer;
            }
          }
      
          div.standard-to-delete:hover {
            background-color: #daf2ff;
          }

         .domain-sets-table{
           .ant-table-content {
            border: 1px solid #411f9021;
            border-top: 0;
           }
           .remove-actions{
             cursor: pointer;
             float: right;
             .remove-selected-properties{
              color: #411F9099;
              padding-right: 15px;
             }
             .remove-not-applicable-properties{
              color: #F5222D;
              padding-left: 11px;

             }
           }

           tr[data-row-key="remove actions"] > .ant-table-selection-column > label.ant-checkbox-wrapper{
            display: none;
          }
          .ant-table-thead > tr > th {
            background: #6565A9;
            color: #FFFFFFcc;
            font-size: 14px;
            font-weight: 500;
            text-transform: capitalize;
            padding: 5px 5px;
            border-right: 0px;
          }

          .warning-url{
            padding-left: 10px;
            vertical-align: middle;
          }

          .delete-icon {
            float: right;
            height: 16px;
            transform: scale(0.9);
          }

          .ant-checkbox{
            border: 1px solid #411F90;
            border-radius: 4px;
          }
          tbody > tr > td {
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 5px;
            border-right: 0px;
          }

          td.ant-table-cell.ant-table-selection-column {
            padding-left: 16px !important;
          }

          .ant-select {
            width: 105px;
          }
          .ant-pagination.ant-table-pagination{
            .ant-pagination-options-size-changer{
              width: fit-content;
              margin-bottom: 0;
            }
          }
          .ant-table-footer {
            padding: 0px;
          }
         }
         .info-message {
          margin: 10px 0px;
          font-family: Open Sans;
          width: fit-content;
          }
          .search-container {
            height: 42px;
            >.spsg-input {
              width: 300px;
              .ant-input-affix-wrapper {
                box-shadow: 0px 2px 3px #0000001f;
              }
              input {
                height: 30px;
              }
            }
          }
          .spsg-select {
            display: inline-block;
            .ant-select-selector {
              box-shadow: 0px 2px 3px #0000001f !important;
            }
          }
          .domains-validation-error {
            color: #FF2626;
            font-size: 12px;
            margin: 5px 2px;
          }
        }
       
      }
    }
  }
  .privacy-lens {
    .create-button-container{
      text-align: right;
      .create-button {
        max-width: 200px;
        background-color: #411F90 !important;
        letter-spacing: 0px;
        font-size: 16px;
        font-weight: 200;
        font-family: 'Oswald';
        position: static;
        &:hover {
          background-color: #E9DFFF;
          color: #2D2D2D;
          color: white;
          font-weight: 300;
        }
      }
    }
    
    .privacy-lens-grid-properties {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 600px){
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 1000px) {
        grid-template-columns: repeat(3, 1fr);
      }
 
      gap: 20px;
      padding-bottom: 30px;
      border-top: 1px solid #2d2d2d30;
      padding-top: 30px;
      margin-top: 20px;

      .property-card{
        color: black;
        cursor: pointer;
        border: 1px solid #D4D4D4;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
        min-width: 380px;
        width: 25vw;
        .property-card-header {
          border: 0.5px solid #8F8F8F;
          border-radius: 12px 12px 0px 0px;
          background-color: white !important;
          width: 100%;
          color: #411F90 !important;
          display: flex;
          justify-content: space-between;
          padding: 10px 5px 10px 15px;
          .activate-button {
            color: white;
            cursor: pointer;
            background: #411F90 0% 0% no-repeat padding-box;
            border: 1px solid #411F90;
            border-radius: 6px;
            padding: 1px 7px;
          }
          .property-card-title {
            display: inline-block;
            font-weight: 600;
            font-size: 15px; 
            letter-spacing: 0px; 
            font: normal normal 600 16px/22px Open Sans;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            .ant-dropdown-menu-item{
            display: none;
          }
          }
        }
        .property-card-body{
          padding: 10px 5px 10px 15px;
          box-shadow: 0px 7px 19px #0000001F;
          border: 0.5px solid #8F8F8F;
          border-radius: 0px 0px 12px 12px;
          height: 230px;

          .tag{
            border-radius: 5px;
            padding: 3px 5px;
            margin-top: 20px;
            margin-bottom: 10px;
            width: max-content;
            font: normal normal 600 12px/17px Open Sans;
            letter-spacing: 0px;
            color: #1E231D;
            &.red {
              background: #FFB4AE 0% 0% no-repeat padding-box;
            }
            &.green {
              background: #BAF5D6 0% 0% no-repeat padding-box;
            }
            &.gray {
              background: #E1E1E1 0% 0% no-repeat padding-box;
            }
          }
        }
        .properties-number {
          width: 50%;
          padding: 10px 0;
          color: #000000CC;
          font-size: 12px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          span{
            font-weight: 500;
            font-size: 14px;
          }
          &:nth-child(2) {
            padding-left: 60px;
          }
        }

        .property-dates {
          padding: 10px 0;
          display: flex;
          background: #F1EBFF 0% 0% no-repeat padding-box;
          box-shadow: 0px 1px 3px #00000029;
          border: 1px solid #7070702E;
          border-radius: 4px;
          width: 270px;
          padding-left: 10px;
          div {
            font-size: 14px;
          }
          span {
            font-size: 12px;
          }
          .img-calendar {
            margin-right: 10px;
            padding-bottom: 3px;
          }
          .date-first-column{
            padding-right: 10px;
            border-right: 1px solid #DDDDDD;
            width: 120px;
            display: inline-block;
          }
          .date-second-column{
            max-width: 130px;
            padding-left: 10px;
            display: inline-block;
          }
          .date{
            letter-spacing: 0px;
            color: #000000CC;
            font-weight: 600;
            font-size: 14px;
          }
        }

        .percent-properties-scanned {
          p {
              margin: 15px 0 -5px 0;
              font-size: 12px;
          }
          &.inactive {
            p {
              margin-top: 60px;
            }
          }
          .ant-progress {
            .ant-progress-outer {
              width: 98%;
              .ant-progress-bg {
                background-color: green;
              }
            }
          }
        }

        .property-applied {
          padding: 10px 0;
          .property-applied-title{
            color: #000000CC;
            font-size: 12px;
          }
          .standards-properties{
            color: #411F90;
            font-weight: 500;
            padding-top: 5px;
          }
        }
      }
    }
    .ant-tabs-nav-list {
      .ant-tabs-tab {
        font-size: 16px;
        color: #232A58;
        >.ant-tabs-tab-btn {
          &:active,&:focus {
            color: #b5b3f2;
          }
        }
        &.ant-tabs-tab-active {
          >.ant-tabs-tab-btn{
            color: #411f90;
            text-shadow: 0 0 0.25px currentcolor;
            font-weight: 600;
          }
        }
      }
      .ant-tabs-ink-bar {
        height: 3px !important;
        background-color: #411F90;
      }
    }
  }

  //modals 

  .ant-modal-root > .ant-modal-wrap > .ant-modal.purple.new-design {
    width: 730px !important;
    border: 1px solid #411F90;
    border-radius: 10px 10px 0px 0px;
    padding-bottom: 0;
    .ant-modal-body {
      padding: 0;
      .title {
        font-family: 'Oswald';
        font-family: 'Oswald';
        color: white;
        padding: 10px;
        background: #411F90;
        font-size: 18px;
        &:hover {
          color: white !important;
        }
        text-align: left;
        font: normal normal 200 20px/24px Oswald;
        color: #FFFFFF;
      }
      .modal-container {
        padding: 0 20px;
        p {
          margin-top: 20px;
          text-align: left;
          font: normal normal normal 13px/20px Open Sans;
          letter-spacing: 0px;
          color: #000000;
        }
        p > span {
          font-weight: bold;
        }
       .standard-name {
          input {
            max-width: 300px;
            box-shadow: 0px 2px 3px #0000001f;
          }
        }
      }
    }
    .ant-modal-footer {
      .spsg-btn {
        height: 34px;
        font-size: 15px;
        line-height: 0px;

        &.cancel {
          border: none;
          color: #411F90;
          background: transparent;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .linked-sets {
      span {
        color: orange;
      }
      border: 1px solid #CCE6CF;
      border-radius: 6px;
      p {
        margin-top: 10px;
        margin-left: 10px;
      }
      .property-sets {
        display: flex;
        background-color: #e9fdec;;
        .domain-set {
          background: #B1D3B5 0% 0% no-repeat padding-box;
          border-radius: 6px;
          font: normal normal 600 13px/16px Open Sans;
          padding: 2px 5px;
          margin: 10px;
        }
      }
    }
    .ant-modal-content .ant-modal-close-x svg {
      color: white;
    }
  }

  .ant-modal.purple.deactvate-ps {
    width: 730px;

    .modal-container {
      .download-scoring-result-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .success-alert {
          margin-right: 20px;
          svg {
            color: white;
            border-radius: 100%;
            background: #43ba54 0% 0% no-repeat padding-box;
            width: 18px;
            height: 18px;
            margin-top: 0px;
            padding: 4px;
            transform: translateY(2px);
          }
          .success-text {
            font-family: Open Sans;
            color: #43ba54 ;
            padding-left: 6px;
          }
        }
        button {
          border: 2px solid #411F90;
          border-radius: 5px;
          text-align: left;
          font: normal normal normal 15px/14px Oswald;
          letter-spacing: 0px;
          color: #411F90;
        }
      }

      .question {
        padding-top: 20px;
        border-top: 1px solid lightgray;
      }
    }


  }

  .account-manager-link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }