  .vendor-header {
    position: relative;
  }
  .active-switch {
    position       : absolute;
    display        : inline-flex;
    top            : 0;
    right          : 0;
    max-width      : 1160px;
    width          : ~"calc(50%)";
    justify-content: flex-end;

    .ant-switch {
      margin-left: 10px;

      &.ant-switch-checked {
        border-color    : #0AD326;
        background-color: #0AD326;
      }
    }
  }


  .privacy-manager-container {

    .ql-snow .ql-tooltip {
      z-index : 999;
      left    : inherit !important;
      top     : inherit !important;
    }
  

    .main-title {
      color           : #000000;
      font-family     : "Proxima-Nova";
      font-size       : 19px;
      font-weight     : 800;
      line-height     : 25px;
      background-color: transparent;
      border          : none;
      margin          : 10px 0;
      padding-left    : 0;
    }

    .panel-buttons {
      position: absolute;
      top     : 185px;
      right   : 20px;

      button {
        margin-right: 10px;
      }

      .copy-url-modal {
        display: inline;
      }
    }

    .main-container {
      background-color: white;
      display: block;
      .options-color-picker,
      .options-copy-font-size-and-opacity {
        border          : none;
        background-color: transparent;

        >input[type="text"] {
          border          : none;
          background-color: white;
          color           : black;
          border          : 1px solid #d9d9d9;
          border-radius   : 4px;
        }

        span {
          border: 1px solid #d9d9d9;
        }
      }

      .container {
        padding       : 40px;
        display       : flex;
        flex-direction: row;

        table th {
          font-size     : 12px;
          font-weight   : 700;
          font-family   : 'Proxima-Nova';
          text-transform: none;
          color         : black;
          line-height   : 17px;
          letter-spacing: normal;
        }

        p {
          width      : 280px;
          color      : #000000;
          font-family: "Proxima-Nova";
          font-size  : 15px;
          font-weight: bold;
          line-height: 15px;
          display    : block;
        }

        .ql-editor p {
          width      : auto;
          font-size  : 12px;
          font-weight: 400;
          line-height: 1.42em;
          display    : block;
          color      : rgba(0, 0, 0, 0.65);
        }

        .quill {
          width: 480px;

          .ql-toolbar {
            border                 : 1px solid #d9d9d9;
            border-top-left-radius : 4px;
            border-top-right-radius: 4px;
            padding                : 4px;

            button {
              height: 20px;
            }
          }

          .ql-container {
            border                    : 1px solid #d9d9d9;
            border-top                : 0;
            border-bottom-left-radius : 4px;
            border-bottom-right-radius: 4px;
          }

          .ql-editor {
            padding: 8px;
            color  : rgba(0, 0, 0, 0.65);
          }
        }

        .container-left {
          width: 280px;

          span {
            display  : flex;
            max-width: 200px;
          }

          i {
            margin-left: 5px;
          }

          p {
            width: auto;
          }
        }

        div.title {
          width: 280px;
        }

        label {
          display    : flex;
          flex       : 1;
          color      : #000000;
          font-family: "Proxima-Nova";
          font-size  : 11px;
          font-weight: bold;
          line-height: 11px;
          margin     : 10px 0;
        }

        .checkboxes-container {
          margin-top: 20px;

          .ant-checkbox-checked::after {
            height: auto;
          }

          p.checkbox-title {
            font-size    : 12px;
            font-weight  : bold;
            margin-bottom: 5px;
          }

          p.checkbox-descr {
            color    : rgba(0, 0, 0, 0.65);
            font-size: 12px;
          }
        }

        .selectors.text>input,
        .ant-select {
          width: 480px;
        }

        .selectors.styles>.group {
          display       : flex;
          flex-direction: row;
          flex-wrap     : wrap;

          div {
            margin-right: 30px;
            width       : 100px;
          }
        }

        .selectors.styles>.fontFamily {
          width: 310px;
        }

        .ant-collapse {
          margin-top: 20px;

          .ace_editor {
            margin-top: 16px;
          }
        }
      }

      .container-neutral {
        padding       : 40px;
        display       : flex;
        flex-direction: row;

        table th {
          font-size  : 12px;
          font-weight: bold;
        }

        .radio-container {
          margin-top  : 20px;
          padding-left: 22px;

          >p {
            font-size    : 12px;
            font-weight  : bold;
            margin-bottom: 0;
          }

          .ant-radio-group {
            margin-top: 10px;
          }
        }

        label.ant-checkbox-wrapper {
          display    : flex;
          flex       : 1;
          color      : #000000;
          font-family: "Proxima-Nova";
          font-size  : 11px;
          font-weight: bold;
          line-height: 11px;
          margin     : 10px 0;
        }

        p {
          width      : 280px;
          color      : #000000;
          font-family: "Proxima-Nova";
          font-size  : 15px;
          font-weight: bold;
          line-height: 15px;
          display    : block;
        }

        .container-left {
          width: 280px;

          span {
            display  : flex;
            max-width: 200px;
          }

          i {
            margin-left: 5px;
          }

          p {
            width: auto;
          }
        }

        div.title {
          width: 280px;
        }

        .checkboxes-container {
          margin-top: 20px;

          p.checkbox-title {
            font-size    : 12px;
            font-weight  : bold;
            margin-bottom: 5px;
          }

          p.checkbox-descr {
            color    : rgba(0, 0, 0, 0.65);
            font-size: 12px;
          }

          .ant-checkbox-checked::after {
            height: auto;
          }
        }
      }
    }
  }

  .ant-modal {
    //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
    // .ant-select {
    //   margin-bottom: 10px;
    // }

    // .ant-select-selection--single {
    //   width: 480px;
    // }

    //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
    // .ant-input {
    //   margin-bottom: 10px;
    // }

    .copy-field {
      cursor: pointer;
    }
  }
