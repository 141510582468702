  .changelog-popover-content-portal {
    max-height: 200px;
    max-width : 240px;
    overflow  : scroll;
  }

  .changelog-tag-container-portal .ant-tag {
    margin: 2px; 
    cursor: default;
  }

  .changelog {
    table {
      margin-top      : 20px;
      background-color: white;
    }

    .error-msg {
      margin-top: 50px;
      font-size : 16px;
      color     : red;
    }

    .ant-tag {
      margin: 2px;
      cursor: default;
    }

    .changelog-container {
      margin-top: 50px;
    }

    .time-picker {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      div:first-child {
        margin-right: 10px;
      }
    }
  }
