  .scenario {
    .invalid-regex {
      border: 1px solid red;
    }
  }

  .site-group-campaign-summary {
    display: flex;
    flex-direction: column;

    p {
      color: #108ee9;
      font-size: 17px;
      padding-left: 20px;
      border-bottom: 1.6px solid;
      font-weight: 600;
      padding-bottom: 6px;
      margin-bottom: 0;

      &.errors {
        color: red;
      }
    }

    .site {
      color: black;
      font-family: "Helvetica Neue";
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      border-bottom: 1px solid #d3d3d36b;
      padding: 20px 15px;

      &.no-campaign {
        background-color: rgb(248, 251, 254);
      }

      &:last-child {
        border-bottom: none;
      }
      &:last-child.no-campaign {
        margin-bottom: 45px;
      }
    }

    button {
      color: black;
    }
  }

  .campaign-privacy{
    .campaign-title{
      font-size:13px;
      margin-bottom:5px;
    }
    .subheading-container{
      display:flex;
      flex-direction:row;
      flex:1;
      font-size:12px;
    }
    .campaign-subheading{
      display:flex;
      flex:1;
      font-weight:600;
    }
    .blank-div{
      display:flex;
      flex:0.5;
    }
    .content-container{
      display:flex;
      flex-direction:row;
      flex:1;
      font-size:12px;
      margin-top: 10px;    
    }
    .content-title{
      display:flex;
      flex:1;
    }
    .content-description{
      display:flex;
      flex:1;
      justify-content:center;
    }
  }
