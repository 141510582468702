#avocado{
  .main-container-layout{
   display: flex;
   flex: 1;
   flex-direction: column;
   background: transparent;
  .icon{
      color: @primary-color;
    }
   .section{
     padding-left: 0px;
   }

   .header{
        background: transparent;
        padding: 0 10px;
    }
   .header-container{
      background: #FBFBFB;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      color: #272727;
      padding-left: 10px;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      width:100%;
      
      .side-1{
        width: 30%;
      }
      .side-2{
        width:55%;
      }
   }
   .content{
      background: white;
      display: flex;
      flex-direction: row;
      border: 1px solid #E9E9E9;
      .integrations{
        width: 20%;
        background: white;
        padding-top: 15px;
        border-right: 1px solid #E9E9E9;
        .ant-list-item:hover{
            background-color:#E9E9E9
        }
        .integration-search{
          width: 250px;
        }

        .vendorIcon{
          width: 44px;
          height: 44px;
          border-radius: 4px;
          border: 0.5px solid #FFF;
        }
      }
      .connections{
        width: 60%;
        background: white;
        padding: 0px 10px;
        .vendor-connections-list{
          height: 400px;
          overflow-y: scroll;
        }
        .connection-search{
          width: 250px;
        }
        .api-input{
           width: 300px;
           padding: 10px;
        }
      }
    }
   
   .content{
     padding-left: 10px;
     background: white;
   }
  
}
}