  @font-headers: myriad-pro, MyriadPro, Helvetica, Arial;
  @font-main: proxima-nova, ProximaNova, Helvetica, Arial;


  .scenario-v2{

  .select-button {
      color: white;
      background: hsl(216, 97%, 53%);
  }

  .scenarios-container{
    .search-container .search-bar {
      width: 190px;
      margin-left: 20px;
      margin-bottom: 0 !important;
    }
    .ant-select-selection {
      width: 190px;
      height: 30px;
      position: relative;
      cursor: pointer;
      color: lightgray;
      font-weight: 100;
    }

    table thead > tr > th {
      background: white;
    }
  }
  
  .select-button-container {
    // float: none;
    // position: absolute;
    // top: 147px;
    // z-index: 1000;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    button {
      border: none;
      border-radius: 3px;
      padding: 11px 15px;
      font-family: @font-main;
      font-size: 14px;
      line-height: 14px;
      width: 100px;

      &.select-button {
        max-width: 145px;

        &:hover {
          background: #1265e0;
        }
      }
    }
  }
}

  .ant-modal.select-template-modal {
    width: 850px !important;
    .ant-modal-close-x {
      color: #fff;
    }
    .ant-modal-header {
      background: #212f43;
      color: white;
    }
    .ant-modal-close-x:before {
      color: white;
    }
    .ant-input {
      margin-bottom: 0px;
    }
    .search-container {
      display: flex;
      align-items: center;
      .campaign-type-label {
        font-weight: 500;
        margin-right: 1%;
      }
      .ant-select {
        margin-bottom: 0;
        margin-right: 13px;
      }
    }

    .search-bar {
      flex: 1;
      margin-right: 10px;
    }
    
    table {
      margin-top: 10px;
    }

    table tbody > tr > td {
      text-align: left;
      letter-spacing: 0;
      color: #1c6be3;
      font-size: 14px;
      font-weight: 200;
      cursor: pointer;
      padding: 10px 16px;
    }
    table tbody > tr {
      opacity: 0.7;
    }
    table tbody > tr:hover {
      opacity: 1;
    }
  }

  .site-card-border-less-wrapper {
    margin-top: 10px;
  }

  .site-collapse-custom-collapse .site-collapse-custom-panel {
    border-radius: 2px;
    margin-bottom: 24px;
    border: 0px;
    overflow: hidden;
  }