#avocado, .ant-modal-wrap, .ant-select-dropdown, .ant-popover {
  @import "./styles/Button.less";
  @import "./styles/Checkbox.less";
  @import "./styles/Filterbox.less";
  @import "./styles/Chip.less";
  @import "./styles/Input.less";
  @import "./styles/InlineTabs.less";
  @import "./styles/Radio.less";
  @import "./styles/Select.less";
  @import "./styles/Toggle.less";
  @import "./styles/Headline.less";
  @import "./styles/Table.less";
  @import "./styles/Tabs.less";
  @import "./styles/FilterSelect.less";
  @import "./styles/TreeSelect.less";
  @import "./styles/Add-Edit-Delete.less";
}

@import "./styles/Infotip.less";
@import "./styles/Tooltip.less";
@import "./styles/Message.less";
@import "./styles/Modal.less";
@import "./styles/Popconfirm.less";
@import "./styles/Dropdown.less";
@import "./styles//DatePicker.less";
@import "./styles/TableFilterDropdown.less";
@import "./styles/Slider.less";
@import "./styles/SelectDropdown.less";
@import "./styles/Alert.less";
@import "./styles/Tree.less";
@import "./styles/LinesEllipsis.less";
@import "./styles/entity_selection.less";
