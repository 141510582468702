@import "./vendor-status-modal.css.less";

.vendor-details-title {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.vendor-details-container {
  border-radius: var(--Border-Radius-borderRadiusLG, 8px);
  border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d3d9dd);
  background: var(--Colors-Neutral-Bg-colorBgContainer, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03),
    0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
  padding: 16px;
  margin-top: 20px;
  .ant-row {
    margin-top: 8px;
    .ant-col {
      .ant-typography {
        margin-left: 5px;
      }
    }
  }
  span {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    .ant-text {
      color: #272727;
      font-weight: 500;
      margin-left: 5px;
    }
  }
  .vendor-details-category {
    display: flex;
    align-items: center;

    .vendor-status-cell-content {
      margin-left: 5px;
    }
    .reference-vendors-cell {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;

      .reference-vendors-cell-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
        margin-right: 5px;
      }

      .ant-tag {
        margin: 0;
        border: 1px solid #b198d9;
        border-radius: 4px;
        background: #eae4f2;
        color: #6565a9;
        padding: 4px 7px;
        font-weight: 700;
      }
    }
  }

  .vendor-details-category > span {
    margin-right: 8px;
  }
}
.vendor-details-properties {
  margin-top: 20px;
  .ant-tag {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
.vendor-details-tech-container {
  margin-top: 20px;

  .ant-card-body .ant-tabs-content-holder .ant-tabs-content .ant-table-content {
    .vendor-details-table-header-title{
      display: flex;
      align-items: center;
      span {
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        svg {
          color: #6B6B6B;
          font-weight: 500;
          margin-left: 5px;
        }
      }
    }
    .diagnose-spinning.ant-spin.ant-spin-spinning {
      position: initial;
      color: var(--color-primary);

      .ant-spin-text {
        margin-top: 0;
        margin-left: 0;
      }
    }
    .diagnose_dasboard-title {
      display: flex;
      justify-content: flex-start;
      align-items: end;
      // text-align: center;
      .ant-btn {
        margin-left: 20px;
        margin-bottom: 4px;
      }
    }

    .ant-table-cell {
      .vendor-details-paramters-btn {
        text-align: center;
      }

      .vendor-details-tooltip {
        .vendor-details-scanurl {
          display: flex;
          align-items: center;
        }

        .ant-tag {
          margin: 0;
          border: 1px solid #b198d9;
          border-radius: 4px;
          background: #eae4f2;
          color: #6565a9;
          padding: 4px 7px;
          font-weight: 700;
        }

        .detailed-breakdown-cell-tooltip-content {
          color: #232a58 !important;
          padding: 15px !important;
        }
      }
    }
  }
}
