:root {
    --white           : #ffffff;
    --HR-grey         : #eaeef1;
    --pale-grey-two   : #e7ecf5;
    --pale-grey-three : #eaeef1;
    --pale-grey-four  : #f4f7f9;
    --pale-grey-five  : #dde3ec;
    --pale-grey-six   : #f5f8fd;
    --very-light-blue : #f4f9ff;
    --main-dark       : #354052;
    --dark-grey-blue  : #354052;
    --greenish-teal   : #3fcc97;
    --dark-sky-blue   : #3d7de1;
    --steel-blue      : #5875a3;
    --light-steel-blue: #708EB6;
    --canvas          : #F1F3F6;
    --squash          : #f5a623;
    --sidebar-dark    : #252e3d;
    --dark            : #252e3d;
    --duck-egg-blue   : #e9f0fa;
    --pale-grey       : #e7eaee;
    --bluey-grey      : #8699b3;

    --main-dropshadow: #d1d6de;

    --font-headers: myriad-pro, MyriadPro, Helvetica, Arial;
    --font-main   : proxima-nova, ProximaNova, Helvetica, Arial;
    --card-shadow : 0 2px 6px 0 #DDE3EC;

    --color-primary-text-color: #272727;
    --color-secondary-text-color: #6B6B6B;
    --color-primary: #411F90;
}

#avocado {
    @white           : #ffffff;
    @HR-grey         : #eaeef1;
    @pale-grey-two   : #e7ecf5;
    @pale-grey-three : #eaeef1;
    @pale-grey-four  : #f4f7f9;
    @pale-grey-five  : #dde3ec;
    @pale-grey-six   : #f5f8fd;
    @very-light-blue : #f4f9ff;
    @main-dark       : #354052;
    @dark-grey-blue  : #354052;
    @greenish-teal   : #3fcc97;
    @dark-sky-blue   : #3d7de1;
    @steel-blue      : #5875a3;
    @light-steel-blue: #708EB6;
    @canvas          : #F1F3F6;
    @squash          : #f5a623;
    @sidebar-dark    : #252e3d;
    @dark            : #252e3d;
    @duck-egg-blue   : #e9f0fa;
    @pale-grey       : #e7eaee;
    @bluey-grey      : #8699b3;

    @main-dropshadow: #d1d6de;

    @font-headers: myriad-pro,
    MyriadPro,
    Helvetica,
    Arial;
    @font-main: proxima-nova,
    ProximaNova,
    Helvetica,
    Arial;
    @card-shadow: 0 2px 6px 0 #DDE3EC;
    @pink-af    : red;

    .container .navbar-header {
        min-height: 60px;
    }

    label {
        font-weight: 400;
    }

    .dropdown-menu {
        border-radius: 4px;
    }

    .glyphicon.avo-sp-adblock.dashboard {
        top          : -1px;
        padding-right: 5px;
    }

    .card {
        position          : static;
        background-color  : white;
        box-shadow        : @card-shadow;
        border-radius     : 3px;
        -webkit-transition: box-shadow 1s;
        transition        : box-shadow 1s;

        &:hover {
            box-shadow: 1px 3px 7px 1px #dde3ec;
        }
    }

    .navbar-brand {
        all        : unset;
        font-family: @font-headers;
        font-size  : 20px;
        font-weight: 600;
        color      : @dark-grey-blue;
        padding    : 8px 14px;
        display    : inline-block;
    }

    nav.navbar {
        background-color: @dark;

        a {
            color: #566c87;
        }

        &:hover {
            li.active a {
                padding-left: 22px;
            }
        }

        li {
            &:hover a {
                color: @white;
            }
        }

        li.active a {
            color           : @white;
            border-left     : 4px solid @dark-sky-blue;
            padding-left    : 16px;
            background-color: #1c2430;
        }
    }

    .sitesentry-subnav {
        top   : 0;
        right : 0;
        height: 60px;

        .container {
            margin   : auto;
            max-width: 1200px;
            width    : 100%;
            padding  : 0 20px;
        }

        margin-top   : 70px;
        border-bottom: 1px solid @pale-grey-five;

        .navbar-text {
            color      : @light-steel-blue;
            font-family: @font-headers;
            font-weight: 300;
        }

        li.sitesentry-site-selector a.dropdown-toggle,
        li.sitesentry-range-selector a.dropdown-toggle,
        li.sitesentry-site-selector a.dropdown-toggle:focus,
        li.sitesentry-range-selector a.dropdown-toggle:focus {
            font-family   : @font-headers;
            color         : @main-dark;
            line-height   : initial;
            padding-top   : 18px;
            padding-bottom: 6px;
        }
    }

    &.registrations,
    &.admin {
        .col-xs-5 {
            background-color: white;
            box-shadow      : @card-shadow;
            border-radius   : 3px;
            padding         : 30px;
            font-family     : @font-main;
            color           : @main-dark
        }

        .account-id {
            font-weight: 400;
        }
    }

    .panel,
    .messages-container table {
        padding-top: 1px;
    }

    // removes dotted line on dropdown focus in firefox
    select:-moz-focusring {
        color      : transparent;
        text-shadow: 0 0 0 #000;
    }

    select:-moz-focusring * {
        color      : #000;
        text-shadow: none;
    }

    //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
    // button.ant-btn { 
    //     font-size: 12px;
    // }

    .dropdown-pages-header {
        border-bottom   : 1px solid lightgray;
        font-size       : 20px;
        font-family     : Proxima-Nova;
        line-height     : 21px;
        font-weight     : 600;
        font-family     : myriad-pro, MyriadPro, Helvetica, Arial;
        text-align      : center;
        position        : fixed;
        z-index         : 9999;
        width           : 100%;
        // background-color: white;
        left            : 0;
        top             : 70px;
        padding         : 18px;

        >p {
            margin: 0;
        }
        position: inherit;
    }

    .update-user-container,
    .domains-container,
    .manage-users-container,
    .api-keys-container,
    .admin-settings-page {
        margin-top: 60px;
    }

    .account-owner-container {
        display        : flex;
        direction      : column;
        justify-content: center;
        align-items    : center;

        .account-column-container {
            width: 60%;
        }

        .owner-cell-container {
            display        : flex;
            align-items    : center;
            justify-content: space-between;

            .ant-select-selection {
                width: 150px;
            }

            .avo-dialogue-icons {
                top: 0;
            }

            .save-cancel-icons-holder {
                display    : flex;
                margin-left: 10px;

                >div {
                    padding: 0 5px;
                    cursor : pointer;
                }
            }
        }

        >div {
            width: 50%;
        }

        tr>td {
            background-color: white;
        }

        .ant-table-row {
            height: 60px;
        }
    }

    .zendesk-organization-container {
        .owner-cell-container {
            display        : flex;
            align-items    : center;
            justify-content: space-between;

            .ant-select-selection {
                width: 150px;
            }

            .avo-dialogue-icons {
                top: 0;
            }

            .save-cancel-icons-holder {
                display    : flex;
                margin-left: 10px;

                >div {
                    padding: 0 5px;
                    cursor : pointer;
                }
            }
        }
    }

}