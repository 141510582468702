@fallback-fonts: Arial, Helvetica, sans-serif;

    .messages-container {
        table.table {
            border: 1px solid #eee;
            max-width: 1200px;

            tbody {
                tr > td {
                    border: none;
                    background-color: #F3F8FC;
                    padding: 10px;
                    vertical-align: middle;
                }

                tr:nth-child(even) > td {
                    background-color: #EDF4FA;
                }

                tr > td:first-child {
                    position: relative;

                    .custom-icon.i-message-preview {
                        position: absolute;
                        top: 8px;
                    }
                }

                tr > td:nth-child(2),
                tr > td:nth-child(3),
                tr > td:nth-child(4) {
                    width: 120px;
                }

                tr > td:nth-child(5) {
                    width: 150px;
                    padding:0;
                    text-align:center;
                }

                tr:hover {
                    color: #318c6b;

                    td:nth-child(1) {
                        box-shadow: 4px 0px 0px #35CD96 inset;
                    }
                }
            }

            tr th {
                text-align: left;
                padding: 10px 5px 8px;
                color: #8699B3;
                border-bottom: none;
                font-size: 10px;
                font-family: "Myriad Pro",  @fallback-fonts;
            }

            thead > tr > th:nth-child(1), tbody > tr > td:nth-child(1) {
                padding-left:20px;
            }
        }

        h3 {
            display: block;
            .notice-title{
              font-weight: 500;
              font-size: 18px;
            }
        }
    }
