@import 'bootstrap_with_overrides.css.less';
@import '../util/elements.less';

@fallback-fonts: Arial, Helvetica, sans-serif;
@fonts-path: "../../fonts/";

body {
    background-color: #fafafa;
    padding: 0;
    @font-face {
        font-family: 'Proxima-Nova Semibold';

        src: font-url('@{fonts-path}proximanova-semibold.eot');
        src: font-url('@{fonts-path}proximanova-semibold.eot?#iefix') format('embedded-opentype'),
        font-url('@{fonts-path}proximanova-semibold.ttf') format('true-type'),
        font-url('@{fonts-path}proximanova-semibold.woff') format('woff'),
        font-url('@{fonts-path}proximanova-semibold.svg') format('svg');
    }

    @font-face {
        font-family: 'Proxima-Nova';

        src: font-url('@{fonts-path}proximanova.eot');
        src: font-url('@{fonts-path}proximanova.eot?#iefix') format('embedded-opentype'),
        font-url('@{fonts-path}proximanova.ttf') format('true-type'),
        font-url('@{fonts-path}proximanova.woff') format('woff'),
        font-url('@{fonts-path}proximanova.svg') format('svg');
    }

    font-family: 'Open Sans';
    height: 100%;
    * {
        outline: none;
    }
}

.main {
  	background-color: #fafafa;
  	margin-bottom:0;
  	width: 100%;
}

.main div.container.content-container {
    padding-top: 100px;

    > .navbar-header {
       width: 100%;
       float: none;
   }
}

.main div.container.content-container {
    padding: 100px 20px 0;
    margin: auto;
    position: absolute;
    right: 0px;
    left: 0px;
    min-height: ~"calc(100% - 170px)";
    width: 100%;
    max-width: 1200px;
}

div.navbar {
    .navbar-brand {
        font-size: 26px;
        font-family: "Myriad Pro", @fallback-fonts;
    }

    &.navbar-primary {
        .navbar-brand,
        .navbar-brand:hover,
        .navbar-brand:focus {
            color: white;
        }

        .navbar-brand {
            margin-right: 35px;


            img {
                display: inline;

                position: relative;
                bottom: 1px;

                margin-right: 3px;
            }
        }

        ul.nav {
            &> li {
                margin-right: 20px;
            }
        }

        a {
            .icon {
                font-size: 18px;
            }

            span {
                font-size: 14px;
                vertical-align: middle;

                margin-right: 4px;
            }
        }

        .sp-icon-profile {
            font-size: 18px;
        }
    }

    font-size: 15px;

    input.faded {
        background-color: lighten(@navbar-default-bg, 20%);
        border-color: black;
    }

    input.faded:focus {
        background-color: white;
    }
}

.sitesentry-subnav {
    .rounded(0px);
    border-right: 1px solid #ddd;
    margin-left: 1px;

    @height: 79px;

    background-color: transparent;
    color: @sitesentry-black;

    margin-bottom: 0px;

    border-bottom:1px solid #ddd;

    height: auto;
    min-height: auto;

    .container {
        height: auto;
        margin: 0;
        padding: 0px;

        // Only adjust height of top level nav bar
        .navbar-collapse > ul {
            >li {
                margin-right: 10px;

                >a {
                    padding: 0;
                    line-height: @height;
                }
            }
        }
    }

    .navbar-brand {
        height: 78px;
        font-size: 32px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        line-height: @height;
        margin-right: 18px;
    }

    .navbar-brand,
    .navbar-brand:hover,
    .navbar-brand:focus {
        color: #515151;
    }

    .navbar-text {
        font-size: 18px;
        color: #b4b4b4;

        padding: 0;
        margin: 0;
    }

    .container > ul > li {
        & > a {
            padding-left: 2px;
            padding-right: 2px;
        }

        margin-left: 5px;
        margin-right: 5px;
    }

    li.sitesentry-site-selector,
    li.sitesentry-range-selector {
        a.dropdown-toggle,
        a.dropdown-toggle:focus {
            font-size: 18px;

            text-decoration: underline;
            color: #949494;
        }

        a,
        a:hover,
        a:focus {
            color: lighten(@sitesentry-black, 35%);
        }
    }
}

.main div.navbar.sitesentry-subnav
{
	position:fixed;
	background-color:#ffffff;
	z-index:100;
  width: 100%;
}

.btn:active, input:active, .btn:focus, input:focus {
  outline: 0 !important;
}

form {
    .form-control {
        border: 1px solid #d7d7d7;
        height: 33px;

        font-size: 15px;
        color: #2a2a2a
    }

    label {
        font-size: 19px;
        color: #515151;

        i {
            font-size: 12px;
        }
    }

    .btn-submit {
        height: 33px;
        background-color: #35cd96;
        border: 1px solid #3AB286;

        font-size: 15px;
        font-weight: bold;
        color: #FFF;
    }

    .btn-submit:focus,
    .btn-submit:hover {
        color: #FFF;
    }

    .bootstrap-switch {
        .bootstrap-switch-handle-on,
        .bootstrap-switch-handle-off {
            &.bootstrap-switch-primary {
                background-color: #35cd96;
            }
        }
    }
}


/* jay's un-less-ed additions */

.table {
    display:table;

    .table {
      background-color: transparent
    }

  .main{
    padding: 0;
    height: 100%;
  }
}

.main{
  padding: 0;
  height: 100%;
}

.header > .cell , .content > .cell, .footer > .cell
{
  padding:27px 40px;
}

.main .header .cell
{
  border-bottom:1px solid #ddd;
  height:79px;
}

.main .footer .cell
{
  border-top:1px solid #ddd;
  height:30px;
  padding: 13px 40px;
  background-color:#F5F5F5;
  text-align:center;
}

.main .content > .cell
{
  padding: 0 40px;
}


.header h1
{
  font-size:22px;
  font-weight:bold;
  margin:0;
}

.content .table
{
  width:100%;
  height:100%;
}

.content .table .cell
{
  padding-top:30px;
  padding-bottom:30px;
  vertical-align:top;
}

.preview-panel
{
  width:50%;
  padding-right:25px;
  border-right:1px solid #ddd;
}

.editor-panel
{
  width:50%;
  padding-left:25px;
  overflow:auto;
}

// .message-preview
// {
//   background-image:url(../assets/screen-wired.png);
//   background-size:cover;
//   width:100%;
//   height:100%;
//   position:relative;
// }

.label-preview
{
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  width:100%;
  background-color:#fff;
  text-align:center;
  padding:5px;
  color:#CFCFCF;
  z-index:1000;
}

.msg-veil
{
  background-color:rgba(0,0,0,.75);
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:999;

}

.msg-overlay
{
  width:80%;
  height:50%;
  max-width:550px;
  max-height:325px;
  background-color:#fff;
  z-index:1000;
  position:absolute;
  margin-left:auto;
  margin-right:auto;
  left:0;
  right:0;
  margin-top:20%;
}

.editor-panel .wrapper
{
    position:relative;
    height:100%;
}

.editor-panel .wrapper .cell
{
    padding:0;
}

.editor-panel h2 {
  position: relative;
  text-align: center;
  font-size:18px;
  font-weight:bold;
  text-transform:uppercase;
}

.editor-panel h2 b {
  color:#3D7DE1;
}

.editor-panel h2 span {
  background: #fff;
  padding: 0 45px;
  position: relative;
  z-index: 1;
}

.editor-panel h2:before {
  background: #ddd;
  content: "";
  display: block;
  height: 1px;
  position: absolute;
    top: 50%;
  width: 100%;
}

.editor-panel h2:before {
  left: 0;
}

.editor-panel input.msg-name
{
    width:100%;
    border:0;
    font-weight:100;
    text-align:center;
    font-size:30px;
    padding:10px 0;
}

.editor-panel input.msg-name::-webkit-input-placeholder{
    font-style:italic;
}
.editor-panel input.msg-name::-moz-placeholder{
    font-style:italic;
}
.editor-panel input.msg-name:-ms-input-placeholder{
    font-style:italic;
}

.editor-panel button.next-step
{
    background-color:#3D7DE1;
    border-radius:5px;
    border:0;
    color:#ffffff;
    padding:10px 20px 10px 20px;
    min-width:250px;
    display:block;
    margin:20px auto;
    font-size:15px;
}

.editor-panel button.next-step span
{
    margin-left:10px;
    font-size:14px;
}

ul.breadcrumbs
{
    overflow:auto;
    margin:0 auto;
    width:auto;
}

ul.breadcrumbs li
{
    display:inline;
    margin:0 10px;
    font-weight:bold;
    color:#BAC5D4;
}

ul.breadcrumbs li.active
{
    color:#667487;
}

ul.breadcrumbs li+li:before
{
    content:'>';
    margin-right:20px;
}


nav.sidebar
{
  display: none;
}

.navbar-default .navbar-nav>.account>a
{
    text-overflow: ellipsis;
}

.navbar-default .navbar-nav > .logo > a
{
    border-bottom: solid 2px #212A39;
    padding-top:25px;
    padding-bottom:25px;
}

.navbar-default .navbar-nav>.logo>a img
{
    width:161px;
}

#wrapper #header .container .pull-right
{
	float:none;
}

.navbar-nav > li > .dropdown-menu {
    overflow: auto;
    max-height: 670px;

    li {
        margin: 5px 0;
    }
}

nav:hover .forAnimate{
  opacity: 1;
}
section{
  padding-left: 15px;
}

.adblock .table .table {
	  background-color:transparent;
}

// Dashboard and Adblock graphs

body.index-index .graph-container, body .graph-container {
	  margin-left: 0;
}

.row.adblock-graph.dasboard {
    padding: 0px 40px 0 0;
}

.col-xs-5, .col-xs-12 {
    padding: 0;
}

.table .table {
	  background-color: transparent;

    th {
    	  text-transform: uppercase;
        border-bottom: 1px solid #ddd;
        font-weight: 700;
    }

    tbody td {
    	  border-top: 1px solid #eee;
    }


}

input:disabled {
    cursor: auto !important;
}

.account-id {
  font-size: 19px;
  margin-bottom: 33px;
  font-weight: bold;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

pre {
  display: block;
  padding: 9px;
  margin: 0 0 9.5px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.176;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

pre code, pre .scenario-json-textarea {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #bbb;
  border:1px solid transparent;
}
