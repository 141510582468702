.ant-select-dropdown.spsg-dropdown {
  padding: 0px;
  background: #FFFFFF;
  border: 0.5px solid #DAE4EB;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;

  .ant-select-dropdown-menu-item-group-title {
    font-family: Open Sans;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #272727;
  }
  > .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu-item-group-list > .ant-select-dropdown-menu-item,
  .ant-select-item {
    background: transparent;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #272727;
    padding: 10px 20px;
    border-radius: 0;
    transition: none !important;
    margin-bottom: 0;
    &.ant-select-item-group {
      color: rgba(0,0,0,.45);
      font-size: 12px;
      cursor: default;
      padding-left: 12px;
    }
    &:last-child { 
      margin-bottom: 0; 
    }
    &:hover, &.ant-select-dropdown-menu-item-active, &.ant-select-item-option-active {
      background: #f2f4f6;
    }
    &.ant-select-dropdown-menu-item-selected, &.ant-select-item-option-selected {
      background: #E9DFFF;
      color: #272727;
    }
  }

}

.ant-dropdown.spsg-filter-dropdown, .ant-dropdown .spsg-filter-dropdown, .ant-select-dropdown.spsg-select-multiple-drodown {
  padding: 0px;

  .ant-dropdown-menu, .ant-menu {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #0000001F;
    border: 1px solid #D4D4D4;
    border-radius: 5px;
    max-height: 360px;
    overflow: auto;
     > .ant-dropdown-menu-item,
    .ant-dropdown-menu-item-group-list > .ant-dropdown-menu-item,
    .ant-item,
    .ant-menu-item,
    .ant-menu-item-group-list > .ant-menu-item {
      background: transparent;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px !important;
      line-height: 18px;
      color: #4F556D;
      padding: 10px 20px;
      border-radius: 0;
      transition: none !important;
      margin-bottom: 0;
      &:last-child { 
        margin-bottom: 0; 
      }
      &:hover, &.ant-dropdown-menu-item-active,&.ant-menu-item-active, &.ant-item-option-active {
        background: #E9DFFF;
      }
      &.ant-dropdown-menu-item-selected, &.ant-menu-item-selected, &.ant-item-option-selected, &.selactive {
        background: #E9DFFF;
        color: #4F556D;
      }

      .ant-checkbox-wrapper {
        margin-right: 12px;
      }
      &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner,
      .ant-checkbox-checked::after {
        border-color: #411F90;
      }
      &.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #411F90 !important;
        border-color: #411F90 !important;
      }
      .ant-checkbox-indeterminate .ant-checkbox-inner::after{
        background-color: #411F90;
        }
    }
  }

  .list-search {
    padding: 10px 20px;
    position: relative;
    // svg {
    //   position: absolute;
    //   right: 30px;
    //   top: 20px;
    //   color: #ddd;
    // }
    // input {
    //   border: 1px solid #D4D4D4;
    //   border-radius: 5px;
    //   font: normal normal 600 12px/34px Open Sans;
    //   letter-spacing: 0px;
    //   color: #2D2D2D;
    //   height: 34px;
    //   line-height: 34px;
    //   &:hover, &:focus {
    //     outline: 0;
    //     border-color: #43BA54;
    //     box-shadow: none;
    //   }
    // }
  }
}
