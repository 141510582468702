  .domains-container, .api-keys-container {
    color: rgba(0, 0, 0, 0.65);

    .table-footer {
      display: flex;
      padding: 20px 30px;
      padding-bottom: 10px;
      input {
        margin-right: 30px;
      }
    }

    td, th {
      padding: 20px 30px;
      font-size: 13px;
      font-weight: bold;
    }

    td {
      background-color: white;
    }

    p {
      font-size: 16px;
      font-weight: bold;
      line-height: 14px;
      margin-left: 20px;
      margin-bottom: 30px;
    }

    i {
      margin-left: 12px;
      cursor: pointer;
    }
  }
