
@import "./bootstrap/bootstrap.less";
@import "./brand.css.less";
// @import './util/sp-portal-icons.css.less';
@import "./util/sp-dialogue.css.less";
@import "./util/bootstrap-switch.css";
@import "./util/bootstrap-slider.css";
@import "./util/select2-bootstrap.css";
@import "./util/spinkit.css";
@import "./util/antd.css";

@import "./dashboard/account.css.less";
@import "./dashboard/altpay.less";
@import "./dashboard/base.css.less";
@import "./dashboard/bootstrap_with_overrides.css.less";
@import "./dashboard/campaign_stats.css.less";
@import "./dashboard/campaign_summary.less";
@import "./dashboard/error.css.less";
@import "./dashboard/list_management_table.css.less";
@import "./dashboard/message-preview.less";
@import "./dashboard/metrics_graph.css.less";
@import "./dashboard/redesign.css.less";
@import "./dashboard/registrations.css.less";
@import "./dashboard/shared.less";
@import "./dashboard/spinner.css.less";
@import "./dashboard/warning_message.css.less";
@import "./dashboard/admin.css.less";

@import "./dashboard/avocado/index.less";

@import "./dashboard/avocado/ccpa/do_not_sell_modal.less";
@import "./dashboard/avocado/ccpa/data_request.less";
@import "./dashboard/avocado/ccpa/dsar_requests.less";
@import "./dashboard/avocado/ccpa/export_popup.less";

@import "./diagnose/diagnose.css.less";
@import "./diagnose/datepicker.css.less";
@import "./diagnose/vendor-evidence-modal.css.less";
@import "./diagnose/vendor-status-modal.css.less";
@import "./diagnose/vendor_trace.css.less";
@import "./diagnose/cookie-details-modal.css.less";
@import "./dashboard/navigation.less";
@import "./dashboard/login.less";
@import "./dashboard/reports.less";
@import "./diagnose/vendor-details.css.less";


@import "../../styleguide/styleguide.less";
