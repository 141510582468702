    // @font-main: Proxima Nova Rg Regular;
    .request-type-container{
        display: flex;
        align-items: center;
        .data-request-select-container{
            flex: 1
        }
        p {
            margin:0px;
            
        }
        .data-request-select-container{
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: large;
        }
        .ant-select-selection{
            margin-left: 10px;
        }
        .ant-input-lg{
            font-size: 14px;
            height: 35px;
        }
       
    }

    .ccpa-filter{
        display: flex;
        align-items: center;
       
    }

    .ant-radio-button-wrapper{
        height:35px;
        line-height: 2.5;
    }

   .request-data-table{
       margin-top: 20px;
       table thead > tr > th{
           background-color:#F3F8FC;
           text-align: center;
           font-size: 10px;
           color:black;
           font-family: "Proxima-Nova Rg Regular"
       }

       .ant-table-placeholder{
           display: none;
       }
       
    
   }
   .request-data-table-section{
       margin-top: 20px;
       .ant-collapse-header{
           background-color: #F3F8FC;
       }

       table{
          display: flex;
       }

       table>th{
            flex:1;
            margin-left: 10px;
       }
       .ant-table-column-has-filters .left-align{
        text-align: center
    }
   }

   .request-tab-container{
       
        p{
            padding-top: 10px;
        }
        .title{
            font-size: 14px;
        }
        .value{
            font-size: 18px;
            
        }
   }

   .data-request-tab{
        .ant-tabs-tab{
            margin-right: 0;
            color: #212f43;
            border: 1px solid #dad4eb;
            font-size: 18px;
             background: #fff
        }

        .ant-tabs-tab.ant-tabs-tab-active {
    background: #108ee9;
    color: #fff !important;
    border: 1px solid #108ee9 !important;
   }
}
.data-request-tab-container{
    .ant-tabs.ant-tabs-card > .ant-tabs-bar .ant-tabs-nav-container{
        height: 77px;
    }

    .ant-table-tbody{
        text-align: center
    }

    .ant-tabs-nav .ant-tabs-tab{
        margin-right: 0;
        color: #212f43;
        border: 1px solid #dad4eb;
        font-size: 14px !important;
         background: #fff
    }
}

    
