  table.add-campaign {
    .ant-select-selection {
      min-width: 250px;
      border: 1px solid #abbdcc;
      border-radius: 3px;
      padding: 4px 10px 5px 8px;
      width: 100%;
      max-width: 200px;
      background: white;
      height: 33px;
      font-size: 16px;
    }

    .ant-select {
      min-width: 250px;
    }

    &> tbody > tr {
      > td, > td > input {
        color: #337ab7;
        font-size: 16px;
        line-height: 22.8571px;
        padding-top: 4px;
      }
    }
  }

  .campaign-add.ant-select-dropdown.ant-select-dropdown--single.ant-select-dropdown-placement-bottomLeft {
    min-width: 250px;
    font-size: 16px;
    width: 100%;
    position: fixed;


    .ant-select-dropdown-menu-item {
      overflow: visible;
    }
  }
