  .custom-reposts-container {
    font-family: "Proxima-Nova";
    .main {
      background-color: white;
      padding: 30px;

      p.title {
        color: #000000;
        font-weight: bold;
        margin-bottom: 5px;
      }

      p.descr {
        color: #808080;
        font-size: 11px;
        line-height: 15px;
      }

      label {
        margin-bottom: 0
      }

      .section {
        padding-top: 30px;
        display: flex;

        > div:first-child {
          width: 360px;
        }

        &.send-report {
          padding-bottom: 30px;
        }
      }

      .main-title {
        .title {
          font-size: 18px;
          line-height: 18px;
        }
        .descr {
          font-size: 14px;
          line-height: 14px;
        }
      }

      .title {
        display: flex;
        flex-direction: column;

        .title {
          font-size: 13px;
          line-height: 16px;
        }
      }

      .report-description {
        width: 330px;
        margin-left: 70px;
      }

      .param, .param > div, .param > span {
        width: 300px;
        margin-bottom: 15px;
      }

      .ant-select-selection--multiple .ant-select-selection__rendered {
        display: flex;

        ul {
          margin-bottom: 3px;
        }
      }

      .ant-input-lg {
        display: flex;
        height: 30px;

        .ant-calendar-range-picker-separator {
          display: flex;
        }
      }
    }
  }

  .bottom {
    padding: 20px 30px;
    background-color: #F7F8FA;
  }
