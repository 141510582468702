@import './steps/us_privacy_steps.less';
@import './steps/privacy_choices.less';
@import './steps/opt_in_opt_out_events.less';
@import './steps/usnat_vendor_management.less';
@import './preferences.less';

#avocado{
  .us-privacy-regulation-edit {
    .back-link{
      color: @primary-color;
      text-decoration: underline;
    }
    > div.header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .spsg-btn.btn-secondary.cancel {
        border: none;
      }
      div {
        font-size: 25px
      }
    }
    .ant-tabs-nav{
      padding-top: 0px;
      margin-bottom: var(--ant-margin)
    }
    .ant-tabs-tabpane.edit-us-privacy-component {
      .us-privacy-step-generics();
      .privacy-choices-mixin();
    }
    .opt-in-out-hooks-mixin();
    .vendor-management-mixin();
    .edit-preferences-mixin();
  }
}
