.ant-modal.us-privacy-edit-purpose {
  width: 750px !important;
  .ant-modal-body{
    padding: 10px 20px !important
  }
  .spsg-select .ant-select{
    min-width: min-content;
  }
  .geo-consent-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    justify-content: space-between;
    .geotargeting-container {
      .geotargeting-select-container {
        display: flex;
        flex: 1;
        align-items: center;
        .geotargeting-bool {
          flex: 0.15;
        }
        .geotargeting-list {
          flex: 0.5;
        }
      }
    }
  }
  .purpose-delete {
    display: none;
  }
  .implementation-type-container {
    .ant-tabs-content-holder{
      padding: 0px 3px;
    }
  }
  .ant-tabs-nav {
    padding-top: 0px;
  }
  .modal-title {
    text-align: left;
    font: bold 16px/26px @primary-font;
    color: #272727;
    margin-bottom: 15px;
  }
  .condition-title {
    text-align: left;
    font: normal 600 14px/26px @primary-font;
    color: #272727;
    margin-top: 20px;
  }
  .radio-tabs {
    display: flex;
    flex: 1;
    border-radius: 5px;
    background: #D3D9DD 0% 0% no-repeat padding-box;
    width: 70%;
    position: relative;
    left: 12%;
    .ant-radio-button-wrapper {
      flex: 1;
      background: #D3D9DD 0% 0% no-repeat padding-box;
      border-radius: 5px;
      span {
        color: #787A7D;
        font: normal 600 14px @primary-font;
        display: flex;
        justify-content: center;
        margin: 13px;
      }
      &:hover {
        color: @primary-color;
      }
    }
    .ant-radio-button-wrapper:not(:first-child)::before{
        display: none;
      }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      border: 1px solid #dae4eb;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 5px;
      margin:5px;
      span {
        color: @primary-color;
        display: flex;
        justify-content: center;
        margin: 8px;
      }
    }
  }
  .edit-purpose-tab-container .consent-actions {
    .tag-manager {
      margin-top: 25px;
    }

    .tag-manager-key-val-container {
      display        : flex;
      flex-direction : row !important;
      justify-content: space-between;

      input,
      textarea {
        width     : 380px;
        margin-top: 5px;
      }
    }

    label.url {
      margin-top: 25px;
    }
    .cookies-container .cookies-table-container .table-headers-container{
      //this selector has to be this long for specificity 
      .name-header, .value-header {
        width: 27%;
      }
      .domain-header {
        width: 12%;
      }
    }
  }
  .ant-modal-content{
    .ant-modal-footer{
      display: flex;
      justify-content: space-between;
    }
  }
}

.opt-in-out-hooks-mixin {
  .ant-table-wrapper.spsg-table.opt-in-out-hooks {
    border: 1px solid #D3D9DD;
    background-color: #FFFFFF;
    .ant-table-thead > tr > th {
      &:not(:last-child){
        border-right: 0px;
      }
      &::before {
        display: none;
      }
      border-color: #D3D9DD;
      background-color: #E9DFFF;
      font-size: 14px;
    }
    &.th-white{
      .ant-table-thead > tr > th {
        background-color: transparent;
      }
    }
    .ant-table-tbody > tr {
      > td {
        background: transparent;
        font-size: 14px;
        line-height: 24px;
        border-color: #D3D9DD;
        &/*:not(:last-child)*/{
          border-right: 0px;
          border-left: 0px;
        }
        span.more-categories{
          background-color: #E9DFFF;
          padding: 0px 5px;
          border-radius: 5px;
          margin-left: 5px;
        }
        span.redirect svg {
          vertical-align: baseline;
          margin-left: 5px;
          height: 14px;
          width: 14px;
        }
        button{
          height: 34px;
          line-height: 0;
          &.icon{
            padding: 0px;
            height: 32px;
            width: 32px;
          }
        }
        &.actions{
          button {
            margin-left: 5px;
          }
        }
      }
    }
    .ant-table-pagination.ant-pagination {
      margin: 24px 0px;
      .ant-pagination-total-text{
        margin-right: 12px;
      }
      .ant-pagination-options{
        margin-left: 12px
      }
    }
  }
  .ant-tabs.ant-tabs-top.spsg-tabs.spsg-normal.opt-in-out-hooks{
    .ant-tabs-content-holder{
      padding: 0  ;
    }
    .ant-tabs-nav{
      padding: 0px;
      .ant-tabs-tab.ant-tabs-tab-active{
        background-color: transparent;
      }
    }
  }
}
