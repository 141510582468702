  .no-type-warning {
    color: #f5222d;
    font-size: 13px;
  }
  .partitionset.card {
    .partition-campaign-type {
      font-size: 18px;
      color: #333;
      font-weight: 500;
      .ant-select {
        width: 215px;
      }
      .campaign-type-label {
        color: #8699B3;
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      .campaign-type-value {
        font-size: 14px;
      }
    }
  }
  .set-type-btn {
    color: #411F90; 
    border: 1px solid #411F90; 
    padding: 5px 20px 5px 20px; 
    height: auto; 
    margin-left: 2%;
    border-radius: 5px;
  }
  .edit-cancel-btn {
    border: 1px solid rgb(217, 217, 217);
    color: rgba(0, 0, 0, 0.65);
    margin-left: 5px;
  }
  .campaign-types-selection {
    display: flex;
    margin-top: 2%;
    margin-bottom: 20px;
    align-items: flex-start;
    .ant-checkbox-wrapper {
      letter-spacing: 0.22px;
      color: #411F90;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 1px solid #411F90;
      padding: 7px;
      margin-right: 2%;
      margin-top: 10px;
      font-size: 12px;
      border-radius: 4px;
      .ant-checkbox-input {
        position: absolute;
      }
      .ant-checkbox-indeterminate .ant-checkbox-inner::after{
        background-color: #411F90;
      }
      .ant-checkbox + span {
        font-weight: 500;
      }
    }
    .ant-checkbox-group {
      flex: 1;
      .ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner,
      .ant-checkbox-checked::after {
        border-color: #411F90;
      }

      .ant-checkbox-checked .ant-checkbox-inner{
        background-color: #411f90;
        border-color: #411f90;
      }
    }
  }

// .dismiss-error {
//   .ant-modal-close {
//     color: #fff;
//   }
//   .ant-modal-header {
//     background-color: #212f43;
//     .ant-modal-title {
//       font-weight: 500;
//       color: #fff;
//     }
//   }
//   .primary-action-class {
//     background-color: #411f90;
//     border: 1px solid #411f90;
//     color: #fff;
//   }
// }