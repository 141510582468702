@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');
@import "../dashboard/avocado/properties_selection.less";
@import "../../../styleguide/styleguide.less";

#avocado{
.manage-v2 {
  .filter-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .filters {
      flex: 1;
      > span {
        font-family: Open Sans;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0px;
        color: #2D2D2D;
      }
      .spsg-filterbox {
        margin-left: 10px;
      }
    }
  }

  .manage-table {
    margin-top: 30px;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px #0000001F;
    border: 1px solid #f4f4f4;
    border-radius: 18px;
    position: relative;
    .ant-pagination-options {
      position: absolute;
      left: 0px;
      bottom: 0px;
      margin-left: 0;
    }
    .rec-plus {
      font-weight: bold;
      color: #6565A9;
      background: #F5F1FF 0% 0% no-repeat padding-box;
      border: 0.5px solid #6565A9;
      border-radius: 4px;
      padding: 2px;
    }
    .totals {
      position: absolute;
      right: 30px;
      bottom: 30px;
      margin-left: 0;
      font-family: Open Sans;
      font-weight: normal;
      font-size: 14px;
      letter-spacing: 0px;
      line-height: 18px;
      color: #000000A6;
    }
    .manage-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      > span {
        padding: 2px 10px;
        cursor: pointer;
        svg {
          color: #899AB4;
          font-size: 14px;
        }
        &:hover > svg {
          color: #411F90;
        }
      }
    }
    .spsg-table{
      td{
        .count-container {
          width: 36px;
          height: 28px;
          background: #6565a9;
          border-radius: 5px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

}

.request-v2 {
  padding-bottom: 84px;
  .spsg-headline {
    margin-bottom: 10px !important;
  }
  .back-link {
    font-size: 18px;
    color: #411f90;
    font-weight: 600;
    .anticon.anticon-left {
      padding-right: 0px;
    }
  }
  .ant-picker-range {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #0000001f;
    border: 1px solid #D4D4D4;
    border-radius: 5px;
    opacity: 1;
    padding: 0 8px;
    height: 40px;
    max-width: 320px;
  }
  .report-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
    color: #2D2D2D;
    .headline {
      font: normal normal bold 20px/14px Open Sans;
      letter-spacing: 0.36px;
      margin-bottom: 16px;
      display: flex;
      svg{
        font-size: 16px;
      }
    }
    >div:not(.headline) {
      display: flex;
      >div.section-item{
        display: flex;
        flex-direction: column;
        min-width: 350px;
        margin-right: 10px;
        margin-top: 20px;
        .spsg-select .ant-select{
          width: 100%;
          .ant-select-selector{
            height: 35px;
          }
        }
        .sub-title{
          color: #D9D9D9;
        }
      }
      // input {
      //   background: #FFFFFF 0% 0% no-repeat padding-box;
      //   box-shadow: 0px 2px 3px #0000001F;
      //   border: 1px solid #D4D4D4;
      //   border-radius: 5px;
      //   height: 40px;
      //   line-height: 40px;
      //   max-width: 200px;
      // }
      // >div.spsg-filter-select {
      //   margin-left: 24px;
      // }
    }
    .spsg-select .ant-select{
      width: 350px;
      .ant-select-selector{
        height: 35px;
      }
    }
    .error{
      font-size: 12px;
      color: #ff2626;
      margin: 0px;
    }
  }
  .main-report {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000001F;
    border: 1px solid #D4D4D4;
    border-radius: 18px;
    opacity: 1;
    padding: 32px;
    margin-top: 32px;
    
    > p {
      font: normal normal normal 14px/17px Open Sans;
      letter-spacing: 0.14px;
      color: #000000;
      margin-top: 24px;
      margin-bottom: 16px;
      padding: 0;
    }
    .report-filters {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: 32px;
      >.spsg-tabs.spsg-normal{
        width: 70vw;
        .ant-tabs-nav{
          padding-top: 0px;
        }
        .ant-tabs-content-holder{
          padding: 0px;
        }
      }
      .properties_selection-mixin();
      .select-regions-mixin();
      .regions-section{
        border: 1px solid #d3d9dd;
      }
      .properties-section .selected-properties, .regions-section .selected-regions {
        border: 1px solid #d3d9dd;
        border-radius: 0px 5px 5px 0px;
        border-left: none;
      }
    }
    .break-down {
      margin-bottom: 32px;
      .ant-checkbox-wrapper {
        margin-right: 16px;
      }
    }
    .frequency {
      margin-bottom: 24px;
    }
    .metrics {
      display: inline-flex;
      flex-direction: row;
      border: 1px solid #D4D4D4;
      margin-bottom: 32px;
      > .metric {
        border-right: 1px solid #D4D4D4;
        &:last-child {
          border-right: 0;
        }
        > .headline {
          font: normal normal 600 14px/17px Open Sans;
          letter-spacing: 0.14px;
          color: #000000;
          text-transform: capitalize;
          opacity: 1;
          border-bottom: 1px solid #D4D4D4;
          padding: 16px;
          svg {
            color: #2D2D2D;
            font-size: 14px;
            opacity: 0.75;
            cursor: pointer;
            margin-left: 16px;
          }
        }
        > .options > div {
          font: normal normal normal 13px/14px Open Sans;
          letter-spacing: 0.13px;
          color: #000000;
          text-transform: capitalize;
          opacity: 1;
          padding: 8px 16px;
        }
        > .options {
          padding: 8px 0;
        }
      }
    }
  }
  .request-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 60px;
    position: fixed;
    bottom: 0;
    width: calc(100% - 60px);
    background: white;
    right: 0;
    transition: width 0.2s linear;
    padding: 0 56px;
    z-index: 99999;
    border-top: 1px solid #411F9059;
    button {
      margin-left: 16px;
    }
  }
  .relative-tip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .date-picker-container{
      display: flex;
      align-items: center;
      .ant-picker.ant-picker-range{
        height: 40px;
        border-radius: 5px;
        box-shadow: 0px 2px 3px #0000001F;
        min-width: 250px;
        .ant-picker-suffix{
          color: #411F90
        }
        .ant-picker-active-bar{
          background: #411F90;
        }
        &.ant-picker-focused, &:hover{
          border-color: #411F90;
        }
      }
      .report-quota-warning {
        display: inline;
        margin-left: 12px;
        color: #3B3B3B;
        height: 35px;
        line-height: 35px;
        padding: 0px 10px 0px 10px;
        background: #EFEFEF 0% 0% no-repeat padding-box;
        border-radius: 4px;
        >.bold {
          font-weight: 600;
        }
        &.error {
          color:#ff2626;
        }
      }
    }

    > .tip {
      // background: #F0EAFD;
      // border: 1px solid #411F9054;
      font: normal normal normal 14px/17px Open Sans;
      border-radius: 4px;
      // padding: 8px 12px;
      letter-spacing: 0.14px;
      color: #3B3B3B;
      opacity: 1;
      max-width: 44vw;
    }
    >.error-warning{
      color: #ff2626;
      margin-top: 12px;
      font-size: 12px;
      margin-bottom: 12px;
    }
  }
}

.spt-main.opened {
  .request-v2 .request-actions {
    width: calc(100% - 240px);
  }
}

.spt-main.closed {
  .request-v2 .request-actions {
    width: 100%;
  }
}  
}
