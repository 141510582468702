@import (css) url('https://fonts.googleapis.com/css2?family=@primary-font-family:wght@200;400&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@primary-color: #4f28b3;
@primary-light-color: #9380BE;
@primary-font-family: Open Sans;

button.ant-btn.ant-btn-default.spsg-btn {
  font-family: @primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #090E15;
  padding: 9px 16px;
  height: 36px;
  box-shadow: none;
  background: #FFFFFF;
  border: 1px solid #D7DDE9;
  border-radius: 5px;
  &:hover {
    color: #090E15;
    background: #F5F7FB;
    border: 1px solid #D7DDE9;
  }

  &[disabled] {
    opacity: 1.0 !important;
    color: #ADB5C5 !important;
    background: #E7ECF4 !important;
    border: 1px solid #E7ECF4 !important;
  }
  > svg {
    font-size: 16px;
    margin-right: 10px;
  }
  &.btn-primary {
    background: #411F90;
    border: 1px solid #411F90;
    color: #fff;
    &:hover {
      color: #fff;
      background: #6565A9;
      border: 1px solid #6565A9;
    }
    &[disabled] {
      opacity: 0.7 !important;
    }
  }
  &.btn-secondary {
    background: #fff;
    border: 1px solid #411F90;
    color: #411F90;
    font: normal 600 15px @primary-font-family;
    line-height: 0px;
    &:hover {
      color: #6565A9;
      background: #fff;
      border: 1px solid #6565A9;
    }
    &[disabled] {
      opacity: 0.7 !important;
    }
  }
  &.btn-tertiary {
    background: #D9D9D9;
    border: 1px solid #D9D9D9;
    color: #272727;
    &:hover {
      color: #818181;
      background: #E4E4E4;
      border: 1px solid #E4E4E4;
    }
    &[disabled] {
      opacity: 0.6 !important;
    }
  }
  &.btn-normal-secondary {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    color: #272727;
    &:hover {
      color: #8A8A8A;
      background: #E4E4E4;
      border: 1px solid #E4E4E4;
    }
    &[disabled] {
      opacity: 0.7 !important;
    }
  }
}

button.ant-btn.ant-btn-sm.spsg-btn {
  font-family: @primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 0 16px;
  height: 35px;
  line-height: 0px;
}

button.ant-btn.spsg-btn {
  &.btn-opensans {
    font-family: Open Sans;
    font-weight: 500;
    font-size: 14px;
  }
}

button.ant-btn.spsg-btn {
  &.btn-link {
    padding: 5px;
    margin: 0;
    border: none;
    color: #411F90;
    font: normal 600 13px Open Sans;
    letter-spacing: 0px;
    opacity: 1;
    cursor: pointer;
    white-space: nowrap;
    &:hover, span:hover {
      background: #FFFFFF;
      text-decoration: underline;
    }
  }
}
