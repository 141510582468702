@import '../../../../../../../styleguide/styleguide.less';

.select-territories-section-mixin{
  .singatory-id {
    margin: 15px 20px;
    >div {
      margin-top: 10px;
      .ant-tag-processing {
        height: 32px;
        line-height: 2;
      }
    }
    .name{
      color: #666
    }
  }
  .select-regions-mixin();
}

.ant-modal.signatory-modal {
  font-family: @primary-font;
  .signatory-name {
    color: #666;
    .green{
    color: var(--ant-color-success)
    }
    &.error{
      color: var(--ant-color-error)
    }
    b{
      font-weight: 600;
    }
  }
  .signatory-input {
    display: flex;
    justify-content: flex-start;
    .spsg-input{
      width: 100%
    }
    >div:not(.spsg-input) {
      margin-left: 100px;
      display: flex;
      >button.icon{
        margin-left: 10px;
        padding: 0px;
        height: 32px;
        width: 32px;
        &.nobackground{
          background: transparent;
          border-color: transparent;
          height: 40px;
          &:hover{
            border-color: transparent;
          }
        }
      }
    }
  }
  .mspa-covered-transactions {
    padding: 10px;
    border: 1px solid #D3D9DD;
    border-radius: 5px;
    margin: 10px 0px;
    >div {
      margin: 10px 0px;
    }
    >label{
      margin-bottom: 0px;
    }
    >button{
      margin-bottom: 3px;
    }
  }
}
