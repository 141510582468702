  @font-headers: myriad-pro, MyriadPro, Helvetica, Arial;
  @font-main: proxima-nova, ProximaNova, Helvetica, Arial;

  .add-import-menu-container .error-container {
    width: 1200px;
    margin: 0 auto;
    text-align: right;
    position: absolute;
    left: 0;
    right: 0;
    padding: 15px 20px;
  }

  .create-button {
    color: white;
    background: #1370fb;
  }

  // .top-button-container {
  //   float: none;
  //   position: absolute;
  //   top: 82px;
  //   right: 277px;
  //   z-index: 1000;
  //   background-color: red;
  //
  //   button {
  //     float: none;
  //     position: fixed;
  //     padding: 7px;
  //     padding-left: 11px;
  //     padding-right: 11px;
  //   }
  // }

  .create-button-container,
  .import-button-container {
    float: none;
    top: 135px;
    right: 122px;
    z-index: 1000;

    button {
      border: none;
      border-radius: 3px;
      padding: 11px 15px;
      font-family: @font-main;
      font-size: 14px;
      line-height: 14px;

      &.create-button {
        float: none;
        position: fixed;
        max-width: 145px;
        right: 20px;

        &:hover {
          background: #1265e0;
        }
      }
    }
  }

  .import-button-container {
    right: 232px;
    .import-button {
      padding: 10px 15px;
      border: 1px solid #c5cdd9;
      font-weight: normal;
      position: fixed;
      right: 175px;
    }
  }

  .cant-delete-container-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .sg-names-container {
      width: 90%;
      border: 1px solid #8080803d;
      padding: 10px;
      margin: 15px 0;
    }

    .ant-tag {
      margin: 3px;
      cursor: default;
    }
  }

  .top-button-container {
    float: none;
    position: absolute;
    top: 135px;
    z-index: 1000;
    right: 308px;

    button {
      float: none;
      position: fixed;
      padding: 7px;
      padding-left: 11px;
      padding-right: 11px;
      right: 175px;
    }
  }

  .add-import-menu-container,
  .import-container {
    position: fixed;
    top: 130px;
    left: 0;
    background-color: #f8fcff;
    z-index: 1000;
    border: none;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd;
    margin-left: 1px;
    width: 100%;

    table.add-campaign,
    table.add-menu,
    table.import-table {
      padding: 0 10px;
      border: none;
      margin: auto;
      max-width: 1200px;
      background-color: #f8fcff;

      select {
        min-width: 250px;
      }

      tbody tr > td {
        background-color: #f8fcff;
      }

      tr td:nth-child(1) {
        min-width: 300px;
        width: 25%;
      }

      tr td:nth-child(2) {
        min-width: 260px;
        width: 23%;
      }

      tr th {
        text-align: left;
        padding: 20px 5px 0 15px;
        color: #8699b3;
        border-bottom: none;
        font-size: 10px;
        font-family: @font-headers;
      }

      tr td {
        padding: 15px 5px 15px 15px;
        vertical-align: middle;
        border: none;
      }

      tr td input,
      tr td select {
        min-width: 250px;
        border: 1px solid #abbdcc;
        border-radius: 3px;
        padding: 8px 10px 5px 13px;
        width: 100%;
        max-width: 200px;
      }

      tr td input::-webkit-input-placeholder {
        font-style: italic;
      }

      tr td input:focus {
        box-shadow: 3px 0px 0px #3d7de1 inset;
      }

      .create-cancel-button-container {
        float: right;
      }
    }
  }

  table.add-campaign > tbody > tr {
    > td,
    > td > input {
      color: #337ab7;
      font-size: 16px;
      line-height: 22.8571px;
      padding-top: 4px;
    }
  }

  .ant-dropdown-trigger,
  #avocado .ant-dropdown-link {
    padding-left: 5px;
  }

  .sites-container p.title {
    line-height: 0;
    display: inline-block;
  }

  .filter-icon {
    display: inline-block;
  }

.import-form-modal {
  .ant-modal {
    width: 400px !important;
  }
  .ant-modal-header {
    background-color: #212f43;
    .ant-modal-title {
      color: #fff;
      font-weight: 100;
    }
  }

  //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
  // .ant-modal-close-x {
  //   color: #fff;
  // }
  .ant-modal-body {
    .spsg-select .ant-select {
      width: 100%;
      margin-bottom: 10px;
      .ant-select-selector {
        display: flex;
        align-items: center;
        height: 40px;
      }
    }
    .import-toggle-wrapper {
      display: flex;
      margin-bottom: 10px;
      .ant-checkbox-wrapper {
        margin-right: 2%;
      }
    }
  }
  .ant-modal-footer {
    border: 0;
    .ant-btn-primary {
      background-color: #7f92bd;
      border: 1px solid #7f92bd;
      color: #fff;
      font-weight: 100;
    }
  }
}

.scenarios-container {
  .add-import-menu-container {
    position: absolute !important;
    top: 116px !important;
  }
}

.scen-box {
  position: relative;
}

.scenarios-container .ant-collapse span.anticon-right.ant-collapse-arrow {
  top: 30px !important;
  right: -30px !important;
}

.property-groups-content-container {
  .ant-input-search-button {
    padding: 0 !important;
  }
}

//GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
// .ant-modal .ant-modal-close-x {
//   color: #000;
// }

.consent-layout .vendor-lists-container p {
  line-height: 30px !important;
}
