@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');

  .flex-space-between {
    display: flex;
    justify-content: space-between
  }

  .ant-select-dropdown.rules-dropdown {
    height: 270px;

    .ant-select-dropdown-menu {
      max-height: 270px;
    }
  }

  .ant-input-search-icon{
    padding: 5px 10px 0px 0px;
    line-height: initial;
    margin: 5px;
    &:before {
      border: none;
    }
  }

  .ant-select-arrow{
    color: black;
  }

  //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
  // .ant-modal-close-x{
  //   width: 20px;
  //   height: 20px;
  //   font-size: 14px;
  //   line-height: initial; 
  //   color: white;
  // }

  .ant-popover.std-details-popover{
    width: 500px;
    left: 500px !important;
    .rule-popover{
      .rule-title-popover{
        font-weight: bold;
      }
    }
    .rule-container{
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
      img{
        max-width: 225px;
      }
    }
  }
  .regulation-types{
    background: white;
    max-width: 200px;
    padding: 10px;
    label{
      display: block;
      margin-left: 0;
      padding: 5px 0;
      letter-spacing: 0px;
      color: #232A58;
      font-size: 12px;
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #411F90;
        border-color: #411F90;
      }
    }
    .regulation-search{
      height: 30px;
      background: #FFFFFF;
      box-shadow: 0px 2px 3px #0000001F;
      border: 1px solid #D4D4D4; 
      margin-bottom: 10px;
      .ant-input{
        margin-bottom: 0;
      }
      .ant-input-suffix{
        display: none;
      }  
      
    }
    .clear-and-apply{
      margin-top: 10px;
      border-top: 1px solid #70707030;
      line-height: 30px;
      padding-top: 10px;
      font-family: 'Oswald';
      letter-spacing: 0px;
      .clear{
        color: #411F90;
        opacity: 0.5;
        cursor: pointer;
      }
      button{
        float: right;
        background: #411F90;
        border: none;
        border-radius: 5px;
        span{
          color: white;
        }
      }
    }
  }

  p.rules-found {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .ant-modal.new-standard {
    width: auto !important;
    margin: 0 auto;
    top: 20px;
    font: normal normal normal 14px/19px Open Sans;
    .ant-alert-warning {
      margin: 10px;
      .anticon-exclamation-circle {
        font-size: 20px;
      }
      .standard-name {
        font-weight: bold;
      }
      font: normal normal normal 16px/22px Open Sans;
    }
    .warning-tag {
      padding: 3px 5px;
      margin-right: 5px;
      border: 1px solid #FFD2CF;
      border-radius: 6px;
      background: #FF910070 0% 0% no-repeat padding-box;
      font: normal normal 600 14px/19px Open Sans;
      white-space: normal;
    }

    .rules-container {
      .rule-container {
        display: flex;
        position: absolute;
        img {
          width: 320px;
          z-index: 9;
          margin-right: 20px;
        }
      }
      .ant-collapse {
        background-color: transparent;
        .ant-collapse-arrow {
          left: 0;
        }
        .ant-collapse-header {
          padding: 0;
          padding-left: 25px;
        }

        .ant-collapse-item-active {
          height: 300px;
        }

        .ant-collapse-item {
          border-bottom: none;
        }
      }
      .rules-validation-error {
        color: #FF2626;
        font-size: 12px;
      }
    }
    .ant-progress.red {
      .ant-progress-bg {
        background-color: #ff6b6b;
      }
    }

    .ant-modal-content {
      width: 890px;
      margin: 0 auto;
      padding: 0;

      .ant-modal-body {
        margin-top: 20px;
        padding: 0;

        .rules-bordered {
          .ant-tag-volcano {
            position: absolute;
            top: 10px;
            left: 215px;
            border: none;
            font-size: 10px;
            background-color: transparent;
            text-transform: uppercase;
          }
          .search-row{
            padding: 10px 0;
            .spsg-input.rule-search{
              max-width: 300px;
              box-shadow: 0px 2px 3px #0000001f;
              input {
                height: 100%;
              }
            }
            .rule-count{
              text-align: right;
              letter-spacing: 0px;
              color: #7C828A;
              line-height: 30px;
              float: right;
              span{
                font-weight: 600;
                color: #43425D;
              }
            }
          }
          
          .ant-select-selection-item {
            display: none;
          }
          
          table {
            max-height: 500px;
            overflow: auto;
            width: 850px;
            td {
              padding: 10px;
              .td-rules {
                display: flex;
                align-items: center;
              }
              .rule-title, .rule-type{
                max-width: 250px;
                font-size: 13px;
                margin: 0;
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              } 
              .standard-weights{
                width: 50px;
                text-align: center;
              }
            }
            tr.category-header {
              background-color: #E9DFFF;
              td {
                background-color: #E9DFFF;
                border-right: none;
                border-left: none;
              }
            }
            .ant-table-cell > .rule-option {
              margin-left: 25px;
            }
            thead {
              th {
                background-color: #6565A9;
                color: white;
                text-transform: none;
                font-size: 12px;
                font-weight: normal;
                border: none;
                padding: 10px !important;
                .regulation {
                  line-height: 12px;
                }
              }
            }
          }
          .anticon{
            padding-right: 5px;
            cursor: pointer;
          }
        }

        .modal-container {
          padding: 20px;
          padding-top: 10px;

          .inventory {
            .ant-progress-text{
              font-weight: 700;
              color: black;
              .anticon-check-circle{
                color: #52c41a;
              }
            }
            .ant-progress {
              width: 300px;
            }
            p.status-title {
              font-size: 12px;
            }
            .refresh-icon{
              padding-right: 5px;
              width: 15px;
            }
            .refresh-btn{
              border: 1px solid #411F90;
              color: #411F90;
              border-radius: 5px;
              box-shadow: 0px 2px 3px #0000001F;
              font-family: 'Oswald';
              margin-left: 30px;
            }
            p.comment {
              margin-top: 10px;
              font-size: 10px;
            }
          }

          input.name {
            border: none;
            border-bottom: 1px solid lightgray;
            border-radius: 0;
            width: 400px;
            font-weight: bold;
            margin-top: -10px;
            margin-left: 10px;
          }

          textarea {
            margin-bottom: 20px;
          }

          .ant-radio-wrapper {
            margin-top: 5px;
            span {
              font-size: 10px;
            }
          }
          .title-2 {
            padding-top: 20px;
            font-weight: bold;
            margin-bottom: 0;
          }

          .title-3 {
            p {
              font-size: 10px;
            }
            span {
              margin-left: 14px;
            }
          }

          .region-menu {
            width: 170px;
          }

          // .region-menu {
          //   width: 300px;
          //   li {
          //     width: 0;
          //     height: 0;
          //     color: white;
          //   }
          // }

          .ant-select {
            width: 300px;

           .ant-select-selection__rendered {
            li {
                width: 0;
                height: 0;
                color: white;
              }
           }
      
            
          }
          .validation-errors {
            padding: 10px 5px;
            width: max-content;
            margin-top: 20px;
            background: #ffd6d6;
            max-width: 100%;
            ul {
              padding-inline-start: 14px;
            }
            .ant-alert-icon {
              margin-right: 10px;
              font-size: 20px;

            }
          }
          div.spsg-input.standard-name{
            input {
              max-width: 300px;
              box-shadow: 0px 2px 3px #0000001f;
            }
          }
        }
      }
      >.ant-modal-footer {
        padding: 10px 16px;
        .ant-alert-content {
          text-align: left;
          .save-confirmation {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            .save-confirmation-column {
              display: flex;
              justify-content: flex-end;
            }
          }
        }
        .ant-btn.cancel {
          border: none;
          color: #411F90;
          background: transparent;
        }
      }
      .ant-modal-close-x svg {
        color: white;
        font-size: 20px;
      }
    }
  }


  .ant-modal-body{
    .standard-title-modal{
      font-family: 'Oswald';
      font-family: 'Oswald';
      color: white;
      padding: 10px;
      background: #411F90;
      font-size: 18px;
      &:hover {
        color: white !important;
      }
    }
    .standard-name-modal{
      font-size: 16px;
      font-weight: 600;
      &:not(.standard-impact *) {
        font-family: 'Oswald';
      }
    }

    .description-modal{
      font-size: 14px;
      padding: 10px 0;
    }
    .standard-impact{
      background: #e9dfff80;
      padding: 20px;
      margin: 20px 0;
    }
    .ant-table-cell{
      .ant-radio-button-wrapper {
        height: 20px;
        width: 20px;
        padding: 0;
        border-radius: 30px;
        margin: 0 auto;
        display: block;
        border-color: #411F90;
      }
      .ant-radio-button-wrapper-checked{
        border-color: #411F90 !important;
        background: radial-gradient(#411F90 45%, rgba(255, 0, 0, 0) 51%);
      }

    }

  }
   //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
  // .ant-modal-footer{
  //   font-family: 'Oswald';
  //   .ant-btn{
  //     border: none;
  //     letter-spacing: 0px;
  //     color: #411F90;
  //   }
  //   .ant-btn-primary{
  //     background-color: #411F90;
  //     color: white;
  //     &:disabled {
  //       background: #f5f5f5;
  //       color: #411F90;
  //     }
  //   }
  // }
