  
  .dialogue-container {
    .ant-dropdown {
      .ant-dropdown-menu-item {
        padding: 0;

        .avo-dialogue-icons {
          padding: 7px 26px;
          margin: 0;
        }
      }
    }

    .ant-modal {
      top: 300px;
    }
  }
  @font-main: proxima-nova, ProximaNova, Helvetica, Arial;

  .save-and-cancel-container {
    display: flex;
    justify-content: flex-end;
    .confirm {
      margin-left: 10px;
      background: #1370fb;
      color: white;
    }
  }

  .scenario.panel.panel-default.card.v1 {
    .anticon.anticon-ellipsis.ant-dropdown-trigger {
      margin: 0;
    }
  }

  .scenarios-container {
    .anticon-ellipsis:before {
      font-size: 30px;
    }

    .anticon.anticon-ellipsis.ant-dropdown-trigger {
      position: absolute;
      z-index: 9;
      right: 50px;
      margin-top: -40px;
    }

    .ant-collapse {
      margin-bottom: 30px;
      .ant-collapse-content.ant-collapse-content-active {
        overflow: visible;
        padding: 0;

        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }

    .v2-description {
      position: absolute;
      margin-top: 13px;
      margin-left: 30px;
      z-index: 9;
      width: 500px;
    }

    .v2-header {
      position: absolute;
      display: flex;
      margin-top: 40px;
      margin-left: 30px;

      .active-status {
        display: inline-flex;
        padding-right: 5px;
      }

      .date-created {
        display: inline-flex;
        margin-top: 3px;
      }
    }

    .ant-collapse {
      background: white;
      .ant-collapse-header {
        font-size: 15px;
        font-family: @font-main;
        font-weight: bold;
        line-height: 15px;
        color: black;
        margin: 25px 0;
        padding-top: 0;
        padding-left: 30px;
      }

      .ant-collapse-item-active {
        i.arrow {
          transform: rotate(-90deg);
        }
      }

      span.anticon-right.ant-collapse-arrow {
        transform: rotate(90deg);
        position: absolute;
        left: 90%;
        top: 0px;
      }
    }

    .scenario-v2 {
      background: white;
      .panel-body {
        padding: 0;
        .panel-table.table {
          margin-bottom: 0;
        }
      }

      .v2-buttons {
        padding: 20px 30px;
        button {
          size: 14px;
          line-height: 14px;
          font-family: @font-main;
          padding: 10px 15px;

          &:hover {
            color: black;
          }
        }

        .cancel-save-container {
          float: right;

          .save {
            margin-left: 15px;
            background: #0ad326;
            color: white;
          }
        }
      }

      .no-scenario {
        display: flex;
        border-top: 1px solid #dce2e9;
        height: 100px;
        background-color: #f4f6f9;
        z-index: 0;
        p {
          font-size: 16px;
          margin: 30px;
          align-self: center;
        }
      }

      .scenario-row {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #dce2e9;
        height: auto;
        padding: 0 30px;
        background-color: #f4f6f9;
        z-index: 0;

        .ant-select.ant-select-enabled,
        .ant-cascader-picker,
        .ant-input-number,
        .ant-input {
          width: 270px;
          margin-bottom: 10px;
        }
        .ant-input-number-handler-wrap {
          z-index: 9;
        }

        &.editing {
          &:hover {
            .delete-button.row {
              display: block;
            }
          }

          .scenario-block-container {
            &:hover {
              background: #f7f9fc;
              cursor: pointer;
            }
          }
        }

        .delete-button {
          position: absolute;
          color: red;
          margin-top: -55px;
          margin-left: 100px;
          display: none;

          &.row {
            margin: 0 20px 0 -40px;
          }
        }

        .row-index {
          margin-top: 30px;
          display: inline-flex;
          margin-right: 30px;
        }

        &:last-child {
          border-bottom: 1px solid #dce2e9;
        }

        .scenario-block-container {
          z-index: 0;
          overflow: visible;
          margin: 30px 30px 30px 0;
          background: white;
          max-width: 200px;
          height: 40px;
          display: inline-flex;
          border-top: none;
          box-shadow: 0 1px 2px 0 #c0cfdf;
          border-radius: 5px;
          justify-content: center;
          align-items: center;
          padding: 15px;

          .step-block-editing {
            display: inline-flex;
            flex-direction: column;

            &.message-freq-cap {
              :nth-child(1) {
                order: 1;
              }
              .limit {
                order: 4;
              }
              .freq {
                order: 5;
              }
              .seconds {
                order: 6;
              }
              .show-message {
                order: 2;
              }
              .ant-select {
                order: 3;
              }
            }
          }

          .country-type-select-container {
            width: 270px;
            justify-content: space-between;
            display: inline-flex;

            .ant-cascader-picker {
              width: 180px;
              margin-right: 10px;

              .ant-input.ant-cascader-input {
                width: inherit;
              }
            }
          }

          .ant-select {
            align-items: center;
            margin-bottom: 10px;
          }

          &.block-editing {
            z-index: 99;
            width: 300px;
            height: 40px;

            .block-internal {
              height: auto;
              background: white;
              padding: 15px;
              margin-top: 94px;
              border-radius: 5px;
              box-shadow: 0 1px 2px 0 #c0cfdf;
            }

            &:hover {
              .delete-button {
                display: block;

                &.editing {
                  margin-top: -20px;
                  margin-left: 150px;
                }
              }
            }
          }
        }

        .generic-step-block-container {
          border-top: none;
          margin: 20px;
          z-index: 1;
          .ant-cascader-picker-label {
            display: none;
          }

          &.editing {
            z-index: 2;
          }

          .add-event {
            height: 42px;
            background: transparent;
          }

          .event-container {
            background: white;
            border-radius: 5;
            box-shadow: 0 2px 10px 0 rgba(71, 97, 124, 0.1);
            padding: 15px;
          }
        }
      }
    }

    table.table thead {
      tr > th:nth-child(1) {
        width: 50px;
      }

      tr > th:nth-child(2) {
        width: 40%;
      }
    }

    select {
      position: relative;
      max-width: 250px;
    }

    input {
      position: relative;
    }
  }
