@charset "UTF-8";

  @font-path: "../../fonts/";
  @font-name: "sp-portal-icons";
  
  @font-face {
    font-family: "sp-portal-icons";
    src        : url("@{font-path}@{font-name}.eot");
    src        : url("@{font-path}@{font-name}.eot?#iefix") format("embedded-opentype"),
    url("@{font-path}@{font-name}.woff") format("woff"),
    url("@{font-path}@{font-name}.ttf") format("truetype"),
    url("@{font-path}@{font-name}.svg") format("svg");
    font-weight: normal;
    font-style : normal;
  }

  [data-icon]:before {
    font-family            : "sp-portal-icons" !important;
    content                : attr(data-icon);
    font-style             : normal !important;
    font-weight            : normal !important;
    font-variant           : normal !important;
    text-transform         : none !important;
    speak                  : none;
    line-height            : 1;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  [class^="avo-"]:before,
  [class*=" avo-"]:before {
    font-family            : "sp-portal-icons" !important;
    font-style             : normal !important;
    font-weight            : normal !important;
    font-variant           : normal !important;
    text-transform         : none !important;
    speak                  : none;
    line-height            : 1;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .avo-sp-users:before {
    content: "\61";
  }

  .avo-sp-sites:before {
    content: "\62";
  }

  .avo-sp-reports:before {
    content: "\63";
  }

  .avo-sp-messages:before {
    content: "\64";
  }

  .avo-sp-logoff:before {
    content: "\65";
  }

  .avo-sp-logo:before {
    content: "\66";
  }

  .avo-sp-docs:before {
    content: "\67";
  }

  .avo-sp-dashboard:before {
    content: "\68";
  }

  .avo-sp-adblock:before {
    content: "\69";
  }

  .avo-sp-add-user:before {
    content: "\6a";
  }

  .avo-sp-api-keys:before {
    content: "\6b";
  }

  .avo-sp-campaign:before {
    content: "\6c";
  }

  .avo-sp-mask:before {
    content: "\6d";
  }

  .avo-sp-account:before {
    content: "\6e";
  }

  .avo-sp-account-new:before {
    content: "\6f";
  }

  .avo-sp-preview:before {
    content: "\70";
  }

  .avo-sp-remove:before {
    content: "\71";
  }

  .avo-sp-view:before {
    content: "\72";
  }

  .avo-sp-copy:before {
    content: "\73";
  }

  .avo-sp-delete:before {
    content: "\74";
  }

  .avo-sp-edit:before {
    content: "\75";
  }

  .avo-sp-collapse:before {
    content: "\76";
  }

  .avo-sp-expand:before {
    content: "\77";
  }
