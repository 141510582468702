  .campaigns-container.multi-campaigns {
    .pg-diversion {
      cursor: pointer;
      margin-bottom: 1%;
    }
    .ant-btn.ant-btn-primary {
       margin-bottom: 0;
       color: #fff;
       letter-spacing: 0.22px;
    }
    .campagin-btn-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .property-group-label {
        font-size: 17px;
        letter-spacing: 0px;
        color: #000000;
        .domain {
          font-weight: bold;
        }
      }
    }
    .create-campaign {
      display: flex;
      align-items: center;
      padding: 2% 0%;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 10px;
      margin-bottom: 2%;
      flex-wrap: wrap;
      .campaign-warning,
      .campaign-create-error {
        flex: 1 0 100%;
        color: #FF2626;
        margin-top: 10px;
      }
      .campaign-warning {
        color: #000000a6;
      }
      .ant-btn-primary[disabled] {
        color: #bfbfbf;
      }
      .campaign-inner-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 1%;
        label {
          letter-spacing: 0px;
          color: #4B4B4B;
        }
        .spsg-select .ant-select{
          width: 100% !important;
        } 
        .ant-input {
          height: 32px !important;
        }
      }
      .ant-btn {
        margin-top: 2%;
      }
      .ant-btn.ant-btn-ghost {
        margin-right: 1%;
      }
    }
    .campaigns-wrapper {
      display: flex; 
      margin-top: 2%;
      margin-bottom: 1.5%;
      .campaign-list {
        .ended-campaign-btn {
          float: right;
          background: #6565A9 0% 0% no-repeat padding-box !important;
          border-radius: 5px;
          letter-spacing: 0.22px;
          color: #FFFFFF !important;
        }
        .action-buttons{
          display: flex;
          justify-content: space-between;
          flex: 0.23;
          div{
            cursor: pointer;
          }
        }
        .action-header{
          display:flex;
          align-items:baseline;
          justify-content:space-between;
          .ant-checkbox-indeterminate .ant-checkbox-inner::after {
            background-color: #411F90
          }
          .campaign-selected{
            font: 600 14px/14px Open Sans;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
        }
      }
      .campaign-header {
        margin-bottom: 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .public {
        margin-left: 2%;
      }
      .campaign-details {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #6565A929;
        border: 2px solid #D4D4D4;
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
        .ant-btn {
          border: 1px solid #1370FB;
          letter-spacing: 0.2px;
          color: #1370FB;
          opacity: 1;
          margin-top: 20px;
        }
        .ant-btn:hover {
          opacity: 1;
        }
      }
      .campaign-priority + .campaign-details {
        border-radius: 16px 0px 16px 16px;
      }
      
      .campaign-priority {
        display: flex;
        justify-content: flex-end;
        span {
          padding: 1% 4%;
          color: #411F90;
          background: #fff 0% 0% no-repeat padding-box;
          border-radius: 12px 12px 0px 0px;
          opacity: 1;
          letter-spacing: 0px;
          opacity: 1;
          border: 2px solid #D4D4D4;
          border-bottom: 0px;
          font: 600 13px Open Sans;
          position: relative;
          top:3px;
        }
        span:hover{
          box-shadow: 0px 3px 6px #6565A929;
          border: 2px solid #B5B3F2;
        }
      }
      .campaign-type {
        font-weight: bold;
        font-size: 16px;
        flex:1;
        //font: normal normal 600 22px/27px Proxima Nova;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      .campaign-label {
        color: #5975A9;
      }
      .campaign-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        padding-left: 2%;
        padding-top: 2%;
        .campaign-name {
          font-weight: 600;
          font-size: 18px;
          letter-spacing: 0px;
          color: #000000;
          flex: 1;
          text-transform: uppercase;
        }
        .campaign-type-label {
           background: #E9DFFF 0% 0% no-repeat padding-box;
           border-radius: 5px;
           padding: 1% 4%;
           letter-spacing: 0px;
           color: #272727;
        }
        .legacy {
          color: #FF2626;
          background: #fff;
          border: 1px solid #FF2626;
          .anticon-warning {
            margin-right: 3px;
          }
        }
      }
      .ant-divider-horizontal {
        margin: 10px 0;
        border-top: 1px solid #DAE4EB;
      }
      .campaign-start-date {
        margin-bottom: 4%;
      }
      .campaign-card-details {
        display: flex;
        flex-direction: column;
        .campaign-details-block {
          display: flex;
          flex-direction: row;
          flex:1;
          padding-left: 2%;
          .detail-1,
          .detail-2{
            display: flex;
            flex-direction: column;
            flex:0.5;
          }
        }
        .detail-1,
        .detail-2,
        .detail-3,
        .detail-4 {
           padding: 1% 5% 1% 0;
           .detail-inner-wrapper {
             display: flex;
             flex-direction: column;
             margin-top: 10px;
           }
           .title {
            text-align: left;
            font: 600 14px Open Sans;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
           }
           .value {
             letter-spacing: 0px;
             color: #272727;
           }
        }
        .detail-3 {
           display: flex;
           align-items: flex-end;
           .detail-inner-wrapper {
             margin-top: 0px;
           }
           .detail-inner-wrapper.left{
             margin-left: 20%;
           }
        }
        .detail-vl{
          display: flex;
          align-items: center;
          margin-top: 5px;
          .detail-inner-wrapper {
            flex: 1;
            flex-direction: column;
            display: flex;
          }
        }
      }
      .action-container{
        display: flex;
        background: #F4F6F8;
        border-radius: 0px 0px 12px 12px;
        margin-top: 10px;
        padding: 2%;
        justify-content: space-between;
        .link{
          text-decoration: underline;
          font: 14px Open Sans;
          color: #411F90;
          opacity: 1;
          cursor: pointer;
        }
      }
      .campaign-stats {
        display: flex;
        margin-bottom: 4%;
        .stats-wrapper {
          flex-direction: column;
          display: flex;
          margin-right: 2%;
        }
      }
    }
    .campaigns-type-selection-wrapper {
      display: flex;
      margin-top: 2%;
      .ant-checkbox-group {
        flex: 1;
      }
      .ant-checkbox-wrapper {
       letter-spacing: 0.22px;
       color: #411f90;
       background: #FFFFFF 0% 0% no-repeat padding-box;
       border: 1px solid #411f90;
       padding: 7px;
       margin-right: 2%;
       font-size: 12px;
       border-radius: 4px;
       .ant-checkbox-indeterminate .ant-checkbox-inner::after{
        background-color: #411F90;
        }
       .ant-checkbox+span {
        font-weight: 500;
       }
      }
    }
  }

  .compliance-dropdown{
    display: flex;
    align-items: baseline;
    margin-top: 20px;
    .dropdown-title{
      text-align: left;
      font: 600 16px Open Sans;
      color: #000000;
      opacity: 1;
    }
    .spsg-treeSelect .ant-select-selector {
      margin-left: 10px;
      .ant-select-selection-overflow {
        flex-wrap: nowrap;
        .ant-select-selection-overflow-item:first-child {
          width: auto;
          max-width: 125px;
          .selected-purpose-tag {
            white-space: nowrap;
            background: #e9dfff;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0;
            font: normal 12px Open Sans;
            letter-spacing: 0px;
            color: #272727;
            opacity: 1;
            padding: 5px;
          }
        }
        .ant-select-selection-overflow-item-rest {
          .ant-select-selection-item {
            background: #411F9014 0% 0% no-repeat padding-box;
            border-radius: 3px;
            opacity: 1;
            width: 35px;
            .purpose-count {
              font: normal 600 12px Open Sans;
              letter-spacing: 0px;
              color: #411F90;
            }
          }
        }
      }
  }
  }

  // common component for all dialogue sub-menus once property group is selected
  .campaigns-applied-properties {
    margin-top: 1%;
    margin-bottom: 1%;
    .properties-wrapper {
        margin-top: 1%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 10px;
        padding: 2% 0%;
        display: flex;
        .properties-block {
            display: flex;
            align-items: baseline;
            label {
              margin-right: 1%;
              letter-spacing: 0.16px;
              color: #272727;
              width: 110px;
              height: 30px;
              display: flex;
              align-items: center;
            }
            .search-wrapper {
               flex: 1;
              .property-tag{
                text-decoration: underline;
                font: 14px Open Sans;
                color: #411F90;
                margin-left: 8px;
                cursor: pointer;
              }
              .spsg-input{
                margin-bottom: 10px;
                width: 200px;
              }
              .ant-input-group-wrapper {
                width: 260px;
                margin-bottom: 2%;
                border: 1px solid #EEEEEE;
                .ant-input {
                  border: 0;
                }
                .ant-input-group-addon {
                  border: 0;
                  background-color: #fff;
                }
              }
              .ant-tag {
                background: #E0EDFE 0% 0% no-repeat padding-box;
                border-radius: 5px;
                letter-spacing: 0.13px;
                color: #1370FB;
                border: 0;
                padding: 5px 5px;
                cursor: pointer;
                margin-bottom: 1%;
              }
              .ant-btn {
                padding: 0 5px;
                border: 1px solid rgb(24, 144, 255);
                color: rgb(28, 107, 227);
              }
            }
           }
        .action-block {
            flex: 0.2;
            .ant-btn {
              border: 1px solid #1370FB;
              letter-spacing: 0.22px;
              color: #1370FB;
              float: right;
              width: 90px;
            }
        }
    }
  }
  
  .property-type-tag {
    font-weight: 500; 
    margin-left: 2px; 
    background-color: rgb(243, 248, 252); 
    color: #555758;
  }

  .camp-sel{
    font: 600 16px Open Sans;
    color: #272727;
    margin-top:20px;
}
.camp-container{
  margin-top: 10px;
  .camp-name{
    font: 600 14px Open Sans;
    color: #272727;
    margin-top: 20px;
    span{
      color: #411F90;
    }
  }
  .camp-input{
    height: 45px;
    width: 300px;
    margin-top: 10px;
  }
}
.camp-header{
  color: #272727;
  font: 16px Open Sans;
}

.refresh-camp-header{
  color: #272727;
  font: 16px Open Sans;
  margin: 10px;
}
.subheader-container{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.campaigns-msgs-pageview {
  font: normal 600 16px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  display: flex;
  align-items: center;
  .max-msgs-per-view {
    margin-left: 10px;
    width: 60px !important;
  }
}

.ended-campaigns-modal,
.campaign-details-modal {
  width: 55% !important;
  min-width: 600px;
  font-family: @primary-font;
  .ant-table table {
    border-collapse: collapse !important;
  }
  tr > th {
    background: #F3F8FC 0% 0% no-repeat padding-box;
    text-transform: capitalize !important;
    letter-spacing: 0.14px !important;
    color: #000000 !important;
    font-weight: 600 !important;
    font-size: 11px !important;
  }
  tr > td {
    padding: 10px 2px;
    font-size: 11px;
  }
  td.campaign-link {
    letter-spacing: 0.14px;
    color: #0294FF;
  }
  .ant-table-content,
  tr {
    border: 1px solid #EEEEEE;
  }
  .ant-table-expanded-row {
    .ant-table {
      margin: auto !important;
      padding: 1%;
    }
  }
  .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
    color: #411f90;
    border: 1px solid #411f90;
  }
  .ant-table-row-expand-icon-cell .ant-table-row-expand-icon:hover {
    color: #6565a9;
    border: 1px solid #6565a9;
  }
  .ant-table-column-sorters {
     padding: 10px 2px;
  }
  .anticon-close {
    color: #fff;
  }
  .ended-campaign-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2%;
    .ant-select {
      margin-bottom: 0 !important;
    }
    .ant-input-affix-wrapper {
      .ant-input {
        margin-bottom: 0;
      }
    }
    .ant-input-group-wrapper {
      flex: 0.3;
      .ant-input {
        margin-bottom: 0 !important;
        border: 1px solid #EEEEEE;
        border-right: 0;
        font-size: 11px;
        height: 30px;
        padding: 1% 0% 1% 5%;
      }
      .ant-input-group-addon {
        border: 1px solid #EEEEEE;
      }
    }
    .filter-wrapper {
      display: flex;
      flex: 0.6;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      font-family: 'Open Sans';
      .filter-selection {
        display: flex;
        margin-left: 1%;
        .filter-option {
          padding: 1%;
          border: 1px solid #E3E3E3;
          height: 30px;
          width: 85px;
          text-align: center;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .filter-option {
          background-color: #FFFFFF;
          border: 1px solid #B5B3F2;
          color: #272727;
          &.active {
            border: 1px solid #411F90;
            color: #411F90;
          }
        }
      }
      .ant-select {
        flex: 0.6;
        margin-left: 1%;
        font-size: 12px;
      }
    }
  }
  .ant-modal-header {
    background: #212F43 0% 0% no-repeat padding-box;
    .ant-modal-title {
      letter-spacing: 0.2px;
      color: #FFFFFF;
      font-weight: 100;
    }
  }
  .campaign-details-header {
    font-weight: 500;
    margin-bottom: 3%;
    letter-spacing: 0px;
    color: #000000;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    .priority-label {
      background: #6565A9 0% 0% no-repeat padding-box;
      border-radius: 6px;
      color: #fff;
      letter-spacing: 0px;
      font-size: 12px;
      padding: 5px 20px;
      font-weight: 600;
    }
  }
  .campaign-stats {
    display: flex;
    margin-bottom: 4%;
    .partition-wrapper,
    .stats-wrapper {
      flex-direction: column;
      display: flex;
      margin-right: 2%;
      flex: 0.2 0 0;
      letter-spacing: 0px;
      color: #272727;
      .campaign-label {
        letter-spacing: 0px;
        color: #89949D;
        text-transform: uppercase
      }
      .campaign-priority {
        padding: 1%;
        background: #164C74;
        color: #fff;
        text-align: center;
        width: 60%;
       }
    }
    .partition-wrapper {
      flex: 1 0 0;
    }
  }
}

.ended-campaigns-modal {
  width: 70% !important;
  min-width: 600px;
}

.campaign-launch-error-modal,
.campaign-confirm-modal {
   width: 400px !important;
   .ant-modal-header {
     border: 0;
     padding: 16px 24px 6px 24px;
     .ant-modal-title {
       display: flex;
       align-items: center;
       letter-spacing: 0px;
       color: #4B4B4B;
       .anticon-warning {
         color: #F5222D;
         font-size: 20px;
         margin-right: 2%;
       }
     }
   }
   .ant-modal-body {
     padding: 6px 24px 45px 24px;
     letter-spacing: 0px;
     color: #4B4B4B;
     font-size: 14px;
     .redirection-to-vl {
       cursor: pointer;
       color: #1370fb;
       font-weight: 500;
       .avo-dialogue-icons {
          color: #1370fb !important;
          opacity: 1 !important;
          padding: 0;
          margin: 0 !important;
	   }
     }
     .vl-name a{
       color: #1370fb !important;
     }
   }
   .ant-modal-footer {
      border: 0;
      padding: 16px 24px;
      .ant-btn {
        border: 1px solid #D9D9D9;
        letter-spacing: 0.2px;
        color: #4B4B4B;
        padding: 0% 7%;
      }
      .ant-btn-primary { 
        background: #7F92BD 0% 0% no-repeat padding-box;
        color: #fff;
      }
   }
   .ant-modal-content {
     border-radius: 6px;
   }
}

.campaigns-partition-details {
   .ant-table-title {
     letter-spacing: 0px;
     color: #000000;
     padding: 16px 0;
     font-weight: 600;
     font-size: 16px;
   }
   tr > th {
     background: #F3F8FC 0% 0% no-repeat padding-box;
     text-transform: capitalize !important;
     letter-spacing: 0.14px !important;
     color: #000000 !important;
     font-weight: 600 !important;
     font-size: 11px !important;
     padding: 10px 10px;
   }
   tr > td {
     padding: 10px 10px;
     font-size: 11px;
   }
   tr {
     border: 1px solid #EEEEEE;
   }
   .ant-table-column-sorters {
     padding: 10px 2px;
   }
    .ant-table-content {
       border: 1px solid #EEEEEE;
    }
    hr {
      margin-top: 2px;
      margin-bottom: 2px;
    }
}

.multi-campaign-privacy {
  .ant-modal-close-x {
    color: #fff;
  }
  .ant-modal-header {
    background: #212F43 0% 0% no-repeat padding-box;
   .ant-modal-title {
      letter-spacing: 0.2px;
      color: #FFFFFF;
      font-weight: 100;
   }
  }
  .ant-btn {
    background-color: #1370FB;
    border-color: #1370FB;
  }
  .campaign-note {
    font-size: 14px !important; /*overriding needed*/
    font-weight: 500;
  }
  .campaign-title {
    font-size: 12px !important; /*overriding needed*/
    margin-bottom: 5px;
  }
  .subheading-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    font-size: 12px;
  }
  .campaign-subheading {
    display: flex;
    flex: 1;
    font-weight: 600;
  }
  .blank-div {
    display: flex;
    flex: 0.5;
  }
  .content-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    font-size: 12px;
    margin-top: 10px;    
  }
  .content-title {
    display: flex;
    flex: 1;
  }
  .content-description {
    display: flex;
    flex: 1;
    justify-content: center;
  }
}