#avocado {
  .bulk-actions {
    .ant-dropdown-menu-item {
      font-size: 12px !important;
    }
  }
  .admin-privacy-lens {
    p.message {
      color: #7f7d7d;
      font-size: 12px;
      padding-right: 20px;
    }
    button {
      margin-right: 20px;
      padding-left: 10px;
    }
    table {
      .ant-table-column-sorters {
        padding-left: 0;
      }
      .ant-table-filter-column-title {
        padding-left: 0;
        padding-right: 20px;
      }
      .ant-table-filter-trigger-container {
        right: -9px;
      }
    }
  }
}