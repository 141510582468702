  
  .redesign {
    @dark-four: #161d26;
    @dark-three: #1c2430;
    @greenish-teal: #3fcc97;
    @hover-greenish-teal: #33a67a;
    @main-input-grey: #293547;
    @dark-grey-blue-two: #37465c;
    @text-color-light-grey: #acbdca;
    @bluey-grey: #8699b3;
    @has-error: #FE2B59;
    @hover-border: #40526e;
    @dark-sky-blue: #3d7de1;
    @hover-disabled-grey: #232D3C;
    @blue-hover: #3265b8;
    @dark-blue-grey: #20314a;

    .message-edit-page {
      position: fixed;
      background: @dark-four;
      height: 100%;
      z-index: 9999;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .editor-open-veil {
      position: absolute;
      top:-70px;
      right:0;
      bottom:0;
      left: ~'calc(100% - 300px)';
      opacity: .7;
      background: black;
      z-index: 2;
    }

    //XXX remove margin-bottom, affects all ant componets
    // then remove ant-modal rule bellow
    //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
    // .ant-btn.ant-btn-primary {
    //   padding-top: 6px;
    //   padding-bottom: 6px;
    //   font-size: 13px;
    //   line-height: 13px;
    //   margin-bottom: 20px;
    // }

    // .ant-btn.ant-btn-primary:not([disabled]) {
    //   background-color: #1370FB;
    //   border-color: #1370FB;
    // }

    .ant-modal {
      .ant-btn.ant-btn-primary {
        margin-bottom: 0 !important;
      }
    }

    .ant-spin.ant-spin-spinning.ant-spin-show-text {
      position: fixed;
      top: 15px;
      left: 300px;
      color: #3FCC97;

      .ant-spin-dot {
        i {
          background-color: #3FCC97;
        }
      }

      .ant-spin-text {
        margin-top: -16px;
        margin-left: 40px;
      }
    }

    .message-preview-container {
      top: 60px;
      left: 30px;
      margin: 0 60px;
      float: left;
      max-height: 100%;
      height: 85%;
      width: ~'calc(100% - 490px)';
      background: rgba(255,255,255, 1);
      border-radius: 3px;
      background-color: white;

      ::-webkit-scrollbar {
       width: 0px;
      }

      .site-screenshot-container {
        border-radius: 3px;
      }

      .preview-iframe {
        max-width: none;
        &.editor-open {
          margin-bottom: 300px;
        }

        &.bottom-subtype {
          height: 250px;
          bottom: 0;
        }

        &.top-subtype {
          top: 0;
        }
      }

      &.is-redirect {
        background: @dark-three;
      }

      .site-screenshot {
        max-width: none;
        top: 0px;
        overflow: hidden;
        &.no-url {
          background: rgba(255,255,255, 0.6);
        }
      }
    }

    .redirect-not-available {
      width: 425px;
      height: 100px;
      opacity: 0.9;
      border-radius: 3px;
      background: #273548;
      display: block;
      position: absolute;
      color: white;
      padding: 30px 55px;
      margin: auto;
      left: 20%;
      top: 250px;

      h1 {
        color: white;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.0;
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
        text-align: center;
        position: unset;
      }

      h2 {
        font-size: 12px;
        margin: 0;
        text-align: center;
      }

      h3 {
         display: block;
      }

      input, textarea {
         background-color: #fff;
      }
    }

    .options-header-bar {
      z-index: 1;
      position:fixed;
      left:0;
      right:0;
      top:0;
      width:100%;
      height: 0;

      .container {
        float: right;
        width: 300px;
        background: @dark-three;
        padding: 25px 20px;
        display: flex;
        justify-content: space-between;

        button {
          width: 112px;
          height: 40px;
          font-size: 16px;
          padding: 10px 15px;
          font-weight: 500;
          border-radius: 3px;
          border: none;
          color: white;
          flex-grow: 1;

          &.save-button {
            margin-left: 20px;
            background: @greenish-teal;

            &:hover {
              background: @hover-greenish-teal;
            }
          }

          &.back {
            background: @main-input-grey;

            &:hover {
              background: @dark-grey-blue-two;
            }
          }
        }
      }
    }

    .options-color-picker, .options-copy-font-size-and-opacity, .options-z-index {
      display: inline-flex;
      position: relative;
      border: 1px solid @main-input-grey;
      background-color: @main-input-grey;
      border-radius: 3px;
      height: 30px;
      padding: 0px;
      color: white;
      font-size: 12px;
      width: 100%;
    }

    .options-color-picker, .options-z-index {
      input[type="text"] {
        height:30px;
        padding:0 10px;
        width:inherit;
        border-left:2px solid transparent;
        border-radius:2px;
        background-color: transparent;
        border: 1px solid @main-input-grey;

        &:hover {
          border: 1px solid @hover-border;
        }

        &:focus {
          border: 1px solid @dark-sky-blue;
        }
      }
    }

    .options-color-picker {

      input[type="color"] {
        position: absolute;
        top: 1px;
        right: 1px;
        cursor:pointer;
        opacity: 0;
        display: block;
        height:28px;
        width:34px;
        z-index:100;
      }

      span {
        position: absolute;
        top:6px;
        right:8px;
        padding:0 0 2px 0;
        font-size:16px;
        color:#999999;
        text-align:center;
        background-color:transparent;
        width: 18px;
        height: 18px;
        border-radius: 3px;
      }
    }

    .options-copy-font-size-and-opacity {
      &::after {
        position:absolute;
        top:7px;
        right:10px;
        content:"px";
        color: @bluey-grey;
      }

      .percent {
        position: absolute;
        top: 7px;
        left: 31px;
      }

      &.opacity::after {
        position:absolute;
        top:7px;
        right:10px;
        content:"opacity";
        color: @bluey-grey;
      }

      input {
        border: 1px solid @main-input-grey;
        height: 30px;
        padding:7px 10px;
        width: inherit;
        margin:0;
        border-radius:3px;
        text-align:left;
        background-color: @main-input-grey;
        color: white;

        &:hover {
          border: 1px solid @hover-border;
        }

        &:focus {
          border: 1px solid @dark-sky-blue;
        }
      }
    }

    .message-edit-page-container {
      position: absolute;
      top: 70px;
      right: 0px;
      height: 100vh;
      width: 300px;
      background: @dark-three;
      overflow: auto;
      padding: 25px 20px 100px;

      &.editor-open {
        padding-bottom: 340px;
      }

      h3 {
        margin-left: 0;
        margin-bottom: 8px;
        font-size: 11px;
        font-weight: 400;
        font-family: var(--font-main);
        color: @text-color-light-grey;
      }

      h4 {
        margin: 5px 0 30px;
        font-size: 12px;
        color: white;
      }

      .ant-collapse {
        background: @dark-three;
        border: none;

        .ant-collapse-item {
          border-bottom: 1px solid @main-input-grey;

          .ant-collapse-header {
            left: -30px;
            margin: 10px 0 10px;
            font-weight: 600;
            font-size: 12px;
            letter-spacing: 1px;
            color: @bluey-grey;
            .arrow {
              color: @bluey-grey;
              transform: rotate(90deg);
              left: 270px;
              position: absolute;
              display: inline-table;
            }

            &:hover {
              color: white;
              :last-child {
                color: white;
              }
            }

            // the antd css that controls accordion active has !important, so it must be included here to overwrite
            // .ant-collapse > .ant-collapse-item > .ant-collapse-header:active { background-color: #eee!important; }
            &:active {
              background: @dark-three !important;
            }
          }

          &.ant-collapse-item-active {
            i.arrow {
              transform: rotate(-90deg);
            }
            padding-bottom: 15px;
            .ant-collapse-header {
              color: white;
              .arrow {
                color: white;
              }
            }

            .ant-collapse-content-box {
              padding: 0;
            }
          }
        }

        .ant-collapse-content {
          background-color: inherit;
          padding: 0;
        }
      }

      .antd-form-item {
        margin-bottom: 0;
        width: 100%;

        .ant-form-item-control-wrapper {
          height: 40px;
          width: inherit;
          margin-bottom: 10px;
          .ant-form-item-control {
            &.has-error {
              input {
                border: 1px solid @has-error;

                &:hover {
                  border: 1px solid @has-error;
                }

                &:focus {
                  border: 1px solid @has-error;
                }
              }
            }
          }

          input {
            font-size: 12px !important;
            border: 1px solid @main-input-grey;
            padding: 8px 10px;
            height: 30px;
            font-size:  12px;
            background: @main-input-grey;
            color: white;
            font-size: small;
            border-radius: 3px;

            &:hover {
              border: 1px solid @hover-border;
            }

            &:focus {
              border: 1px solid @dark-sky-blue;
            }
          }
        }
      }

      textarea {
        background: @main-input-grey;
        border: 1px solid @main-input-grey;
        color: white;
        font-size: 12px;
        padding: 10px 15px;
        height: 145px;

        &:hover {
          border: 1px solid @hover-border;
        }

        &:focus {
          border: 1px solid @dark-sky-blue;
        }
      }

      .update-message-button-container {
        width: 100%;
        margin-top: -10px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: row-reverse;

        .button {
          background: @dark-sky-blue;
          color: white;
          border: 1px solid @main-input-grey;
          border-radius: 3px;
          font-family: var(--font-main);
          font-size: 12px;
          flex-grow: 1;
          padding: 10px 15px;
          line-height: 12px;

          &:disabled {
            background: @hover-disabled-grey;
          }

          &:hover {
            background: @blue-hover;
          }

          &.no-url {
            background: #293547;
            margin-right: 8px;
          }
        }
      }

      .options-z-index, .options-z-index input[type="text"] {
        width: 120px;

        .ant-input.ant-input-lg {
          top: -1px;
        }

        .ant-form-explain {
            width: 200px
        }
      }

      //1st accordion with Message Name and Type starts here
      .message-description {
        .message-name {
          margin-top: 20px;
        }

        .triggers {
          h3 {
            display: inline-block;
          }
          .delay-select {
            margin-left: 20px;
            width: 179px;
            padding-bottom: 10px;

            .ant-select-selection.ant-select-selection--single {
              background: @main-input-grey;
              color: white;
              border: none;
            }
          }

          .exit-intent {
            display: inline-block;
            margin-left: 20px;

            .ant-form-item-control-wrapper {
              margin-bottom: 0;
            }
          }

          margin-bottom: 20px;
          .percent {
            color: white;
            position: absolute;
            right: 35px;
            top: 220px;
          }
        }

        .screenshot-url {
          margin-bottom: 0;
        }
      }

      //Type & Position Tiles
      .type-and-position {
        .type-tiles {
          width: 300px;
          margin-top:10px;
          margin-left: -8px;


          h5 {
            color: @bluey-grey;
            margin: 15px 0 0 0;
            font-size: 12px;
          }

          .tiles-col {
            margin: 0;
            padding: 0;

            ul.options-tiles li {
              float:left;
              list-style-type: none;
              padding: 10px;
              text-align:center;

              img {
                width: 46px;
                height: 53px;
                margin: 0px;
              }

              button {
                &.active {
                  h5 {
                    color: white;
                  }
                }
              }
            }

            .type-pos-tiles-tile > .ant-btn {
              border-radius: 3px;
              background: @dark-three;
              border: 1px solid @main-input-grey;
              padding: 15px;
              width: 115px;
              height: 115px;
              opacity: .7;

              &:hover {
                opacity: .8;
                border: 1px solid @hover-border;
              }

              &.active {
                border: 1px solid @dark-sky-blue;
                background: @dark-blue-grey;
                opacity: 1;
              }
            }
          }
        }
      }

      .destination-selector, .redirect-destination-url, .custom-html {
        width: 253px;
        display: inline-block;
        margin-top: 30px;
        margin-bottom: 30px;
        h4 {
          line-height: 16px;
        }
      }

      .remove-page-elements-selector {
        h4 {
          line-height: 16px;
        }
      }

      //2nd accordion with Headlines and Message starts here
      .headline-message {
        .copy-block {
          margin-top: 20px;
          margin-bottom: 10px;

          .headline {
            .ant-form-item-control-wrapper {
              height: 80px;

              textarea {
                height: 80px;
              }
            }
          }

          .ant-form-item-control-wrapper {
            height: 145px;
            textarea {
              height: 145px;
            }
          }
        }

        .header {
          display: flex;
          justify-content: space-between;
        }

        .headline-color-font-pickers
        {
          margin-bottom: 10px;
          display: inline-flex;
          width: 100%;
          .options-copy-font-size-and-opacity:last-child {
            margin-left: 8px;
          }
        }
      }

      //3rd accordion Choice Options starts here
      .choice-option-item {
        border-bottom: 1px solid @main-input-grey;
        margin: 30px 0;

        h4 {
          margin-bottom: 10px;
          display: inline-block;
        }

        h5 {
          display: inline-block;
          margin-left: 25px;
          margin-right: 5px;
          font-size: 12px;
          color: @bluey-grey;
          letter-spacing: 0;
          line-height: 12px;
        }

        .position-arrow-container {
          display: inline-flex;
          float: right;
          .ant-btn.ant-btn-icon-only.ant-btn-background-ghost {
            padding: 3px 2px;
            border: none;
          }
        }

        .avo-dialogue-icons.avo-sp-delete {
          display: inline-flex;
          float: right;
          background: transparent;
          color: white;
        }

        .antd-form-item {
          margin-bottom: 10px;
          .ant-form-item-control-wrapper {
            height: 70px;
          }
        }

        .ant-select.ant-select-enabled {
          .ant-select-selection.ant-select-selection--single {
            background: @main-input-grey;
            border: 1px solid @main-input-grey;
            color: white;
            height: 30px;
            &:hover {
              border-color: @hover-border;
            }
          }
        }

        .choice-option-select-label {
          line-height: 32px;
        }

        .has-error .ant-select.ant-select-enabled .ant-select-selection.ant-select-selection--single {
          border-color: @has-error;
        }

        .comp-timing-wrapper {
          .has-error .ant-form-explain {
            margin-left: 90px;
          }
        }

        .messaging-select {
          margin-bottom: 30px;
        }

        .choice-option {
          margin-top: 0px;
          border-bottom: 1px solid @main-input-grey;

          &:last-child {
            border: none;
            overflow: auto;
          }

          .label-title {
            margin-top: 10px;
            margin-bottom: 10px;

            &.enabled {
              h5 {
                color: white;
                margin-left: 11px;
              }
            }

            &:hover {
              cursor: pointer;
            }

            img {
              float: right;
              position: relative;
              top: 15px;
              right: 10px;
            }

            .choice-option-checkbox {
              display: inline-block;
              input[type="checkbox"] {
                display: none;
              }

              input[type="checkbox"]:checked {
                display: inline-block;
              }

              .dummy-checkbox {
                position: absolute;
                margin-top: -11px;
                height: 14px;
                width: 15px;
                border-radius: 2px;
                background: @main-input-grey;
                &.enabled {
                  display: none;
                }
              }
            }
          }

          div {
            .comp-timing-wrapper {
              display: inline-block;
              height: 80px;

              .comp-timing {
                label {
                  width: 140px;
                }

                input {
                  width: 100px;
                  margin-left: 10px;
                }
              }

              .comp-timing-icon {
                color: white;
                float: right;
                position: relative;
                top: -83px;
                right: 115px;
              }
            }

            .message-language-select {
              .ant-select-selection.ant-select-selection--single {
                background: @main-input-grey;
                border: 1px solid @main-input-grey;
                color: white;
              }
              width: 150px;
            }
          }

          div > label {
            color: @text-color-light-grey;
            font-size: 11px;
            font-weight: 400;
            width: 100px;
            margin: 0;
          }
        }
      }

      //4th accordion Card Styles starts here
      .styles-table {
        overflow:auto;

        h3 {
          margin: 10px 0;
        }

        .style-color-font-pickers {
          width: 100%;
          display: flex;

          &.single {
            .options-styles-property {
              width: 47%;
            }
          }
        }

        .options-styles-property {
          margin: 0 8px 25px 0;
          display: inline-flex;
        }

        .antd-custom-styles-textarea {
          margin-left:0px;
          .ant-form-item-control-wrapper {
            width: 100%;

            textarea {
              height: 315px;
            }
          }
        }
      }
    }

    .custom-css {
      .add-custom-css-button {
        height: 40px;
        width: 100%;
        border: none;
        border-radius: 3px;
        padding: 10px 15px;
        margin-top: 20px;
        background: @main-input-grey;
        color: @bluey-grey;
        font-size: 16px;
        font-weight: 500;
        font-family: var(--font-main);
        text-align: center;

        &:hover {
          background: @dark-grey-blue-two;
        }
      }

      .header {
        display: flex;
        justify-content: space-between;
      }

      .custom-css-editor {
        z-index: 3;
        position: fixed;
        .bumper {
          position: fixed;
          height: 30px;
          width: 100%;
          left: 0;
          bottom: 340px;
          background: @dark-three;
        }

        #brace-editor {
          z-index: 100;
          width: ~'calc(100% - 300px)';

          .ace_search {
            background: @dark-grey-blue-two;
            margin-right: 86px;
            top: 50px;
            border-radius: 3px;
            border: none;
          }

          position: inherit;
          left: 0;
          bottom: 0;
          background: @dark-three;
          padding-top: 30px;
          .ace_layer.ace_gutter-layer.ace_folding-enabled {
            background: @dark-three;
          }
        }

        .veil {
          top: ~'calc(100% - 370px)';
          right: 0;
          bottom: 0;
          left: ~'calc(100% - 300px)';
          z-index: 999;
          position: inherit;
          background-color: rgba(16,21,35,1);
        }

        .save-and-close-container {
          position: inherit;
          z-index: 999999999999;
          bottom: 228px;
          right: 283px;

          p {
            width: 300px;
            color: white;
            padding-top: 20px;
          }

          .button {
            width: 100px;
            height: 40px;
            border-radius: 2px;
            border: none;
            margin: 20px 20px 0 0;
            font-family: var(--font-main);

            &.css-close {
              background: @main-input-grey;

              &:hover {
                background: @dark-grey-blue-two;
              }
            }

            &.save {
              background: @greenish-teal;

              &:hover {
                background: @hover-greenish-teal;
              }
            }
          }
        }

        .variables-container {
          z-index: 9999;
          position: inherit;
          bottom: 498px;
          right: 23px;
          width: 255px;

          .button {
            width: 100px;
            height: 40px;
            border-radius: 2px;
            border: none;
            margin: 20px 20px 0 0;
            font-family: var(--font-main);

            &.css-close {
              background: @main-input-grey;

              &:hover {
                background: @dark-grey-blue-two;
              }
            }

            &.save {
              background: @greenish-teal;

              &:hover {
                background: @hover-greenish-teal;
              }
            }
          }

          .custom-style-warning-message {
            font-family: var(--font-main);
            color: white;
            margin-top: 20px;
            position: absolute;
            font-size: 12px;
          }

          .variables-header {
            color: white;
            position: absolute;
            top: 154px;
            font-size: 16px;
            font-weight: bold;
            line-height: 20px;
          }

          .no-variables-container {
            position: absolute;
            color: white;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            top: 220px;

            .no-variables-header {
              font-size: 16px;
              font-weight: bold;
              line-height: 20px;
            }

            p {
              font-size: 12px;
            }

            button {
              padding: 12px 25px;
              margin-top: 10px;
            }
          }
        }
      }

      .unsaved-changes {
        left: 0;
        right: 0;
        margin: auto;
        width: 500px;
        bottom: 194px;
        z-index: 4;
        position: fixed;
        color: white;
        background: @dark-three;
        text-align: center;
        border: 1px solid white;
        border-radius: 5px;
        padding: 20px 9px;

        h2 {
          font-size: 18px;
          margin-bottom: 30px;
          font-family: var(--font-main);
        }

        .button {
          border: none;
          width: 230px;
          padding: 10px;
          margin: 5px;
          height: 40px;
          border-radius: 3px;
          font-family: var(--font-main);

          &.close-without-saving {
            background: @main-input-grey;

            &:hover {
              background: @dark-grey-blue-two;
            }
          }

          &.css-back-to-editor {
            background: @greenish-teal;

            &:hover {
              background: @hover-greenish-teal;
            }
          }
        }
      }
    }


    @media (max-width: 764px) {
      .content-container {
        width: 100%;
      }
    }

    @media (min-width: 768px) {
      .container {
        width: 100%;
      }
    }
    @media (min-width: 992px) {
      .container {
        width: 100%;
      }
    }
    @media (min-width: 1200px) {
      .container {
        width: 100%;
      }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .message-preview-container .preview-iframe.bottom-subtype {
        bottom: 30px;
      }
    }
  }

  .message-popover{
    width:500px;
    position: fixed;
    top: 54px !important;

    .ant-popover-content{
      max-height: 100vh;
      overflow: hidden;
    }

    .ant-switch-checked{
      background-color: #52C41A
    }

    .ant-popover-title{
      border-bottom: 0px
    }
    
    .ant-popover-arrow{
      top:52px
    }  
  }
  .btn-multiSelect-wrapper{
      width: 150px;
      margin-right: 110px;
      .ant-select-selector{
        background: var(--ant-color-primary) !important; /* overridding needed*/
        color: white !important; /* overridding needed*/
        width: 260px !important; /* overridding needed*/
      }
    .ant-select-arrow{
      color:white
    }
  }

 .sampleModal{
  .ant-modal-close {
    color: #fff;
  }
  .ant-modal-header {
    background: #212f43;
    .ant-modal-title {
      color: #fff;
    }
  }
  }
