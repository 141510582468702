.redesign {
  height: 100%;
  width: 100%;
}

.auth-container {
  height: 100%;
  width: 100%;
  background-color: #252e35;
  color: gray;
  padding-top: 200px;


  .sign-in-user-container {
    display: flex;
    flex-direction: column;
  }

  input {
    width: 200px;
    margin: 0 auto;
  }

  button.ant-btn {
    width: 200px;
    margin: 0 auto;
  }

  p {
    padding-top: 20px;
    align-self: center;
  }
}