  @steel-blue: #5875a3;

  .message-preview-page {
    position: fixed;
    background: lightgrey;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 9999;

    .message-preview {
      background-color: lightgrey;
      height: ~'calc(100% - 40px)';
    }

    .message-preview-container {
      top: 70px;
      width: 100%;
      max-width: 1200px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0 auto;
    }

    .iframe-full-screen-container {
      top: 40px;
    }

    .preview-header-bar {
      bottom:0;
      left:0;
      right:0;
      width:100%;
      background-color:#efefef;
      padding: 10px;
      min-height: 40px;

      .close-preview {
        color: @steel-blue;
        cursor: pointer;
        position: absolute;
        z-index: 9;

        i {
          padding-right: 3px;
        }
      }

      .message-name {
        position: absolute;
        width: 100%;
        text-align: center;
      }

      .buttons-group {
        float: right;
      }
    }

    .inline-placeholder {
        top: 47%;
    }

    .preview-iframe.full-screen {
      width: 100%;
      height: 100%;
      position: absolute;
      border: none;
    }
  }
