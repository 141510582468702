.message-preview-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 129px;

    .preview-iframe {
        width: 100%;
        max-width: 1200px;
        position: absolute;
        border: none;

        &.bottom-subtype {
          height: 250px;
          bottom: 181px;
        }

        &.top-subtype {
            top: 0px;
        }

        &.modal-subtype, &.inline-subtype {
            top: 0;
            height: 100%;
        }

        &.inline-subtype {
            min-width: 490px;
            min-height: 200px;
            position: absolute;
            top: 46%;
            left: 12%;
            width: 49%;
            bottom: 0;
            display: block;
            max-height: 52.6%;
        }
    }

    .inline-placeholder {
        background-color: white;
        min-width: 490px;
        min-height: 200px;
        position: absolute;
        top: 45%;
        left: 12%;
        width: 49%;
        bottom: 0;
        display: block;
    }

    .site-screenshot {
        top: 39px;
        height: 100vw;
        position: relative;
        max-width: 1200px;
        max-height: 1200px;
        background-image: none;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center top;
    }

    .site-screenshot-container {
        overflow: auto;
        height: 100%;
    }
}
