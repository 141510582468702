.diagnose-range-calendar .ant-picker-panel-container .diagnose-picker-layout,
#avocado .ant-picker-panel-container .diagnose-picker-layout {
  .ant-picker-panel-layout > div {
    display: flex;
    flex-direction: column-reverse;
  }
  .ant-picker-footer {
    border-bottom: 1px solid @border-color;
    .ant-radio-group{
      border: none;
      display: flex;
      justify-content: space-between;
      height: 30px;
      .ant-radio-button-wrapper{
        height: 100%;
        font-weight: 700!important;
        border: none;
        display: flex;
        align-items: center;
        width: auto;
        &:hover{
          color: @primary-color;
        }
        .ant-radio-button-checked{
          background: none;
        }
        .ant-radio-button-checked::after {
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: @primary-color;
          content: '';
        }
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
        color: @primary-color;
      }
      .ant-radio-button-wrapper:not(:first-child)::before {
        display: none;
      }
    }
    .ant-picker-footer-extra:not(:last-child){
      border: none;
    }
    .ant-picker-ranges{
      display: none;
    }
  }
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }
  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }
  .ant-picker-panel-container, .ant-picker-footer {
    width: 100% !important;
  }
  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
    background: #e9dfff;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after{
    border-color: @primary-color;
  }
  .ant-picker-week-panel-row-selected td, .ant-picker-week-panel-row-selected:hover td,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: @primary-color;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border-color: @primary-color;
  }
  .ant-picker-cell-inner .anticon.anticon-exclamation{
    padding: 0;
    width: 2px;
    color: @primary-color;
    position: absolute;
    right: 6px;
  }
  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after{
    right: 0px;
  }
  table.ant-picker-content {
    font-size: 14px;

    tbody > tr > td {
      padding-top: 3px;
      padding-bottom: 3px;
    }
    tr > th {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: black;
      text-transform: initial;
      letter-spacing: normal;
    }
  }
}
.diagnose-range-input {
  &.ant-picker.ant-picker-range {
    height: 42px;
    border: 2px solid #DAE4EB;
    border-radius: 3px;
    max-width: 100%;
    width: 100%;
  
    .ant-picker-suffix {
        color: @primary-color
    }
  
    .ant-picker-active-bar {
        background: @primary-color;
    }
  
    &.ant-picker-focused,
    &:hover {
        border-color: @primary-color;
        border-width: 1px;
        box-shadow: 0px 2px 3px #0000001F;
    }
  }
}
