@import "./diagnose.css.less";

.vendor-status-modal {
  .ant-modal {
    .ant-spin-dot-item {
      background-color: @primary-color;
    }
    .ant-modal-close {
      top: 18px;
      right: 24px;
      .ant-modal-close-x {
        padding: 8px;
        margin: 0;
        svg {
          color: white;
        }
      }
    }
    .ant-modal-content {
      border-radius: 10px;
      padding: 0;
      .ant-modal-header {
        border-radius: 10px 10px 0 0;
        background: @primary-color;
        padding: 14px 24px;

        .ant-modal-title {
          color: #FFFFFF;
          font-weight: 400;
          span {
            color: #FFFFFF;
            font-size: 14px;
          }
        }
      }

      .ant-modal-body {
        font-size: 14px;
        padding: 8px 24px 16px;
        min-height: 600px;
        .ant-alert{
          left: 300px;
          position: absolute;
          top: -20px;
          z-index: 2;
        }
        .ant-btn-primary {
          background-color: @primary-color;
          border-color: @primary-color;
          font-size: 14px;

          &:hover {
            opacity: 0.8;
            transition: all 0.2s;
          }
        }

        .detailed-info-block {
          display: flex;

          .detailed-info-filters {
            width: 25%;
            border-right: 1px solid #DADADA;
            min-height: 580px;

            .detailed-info-property-filter {
              .detailed-info-property-filter-header {
                margin-bottom: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                font-weight: 600;
                line-height: 22px;
                margin-left: 5px;
                .detailed-info-property-filter-title{
                  display: flex;
                  align-items: center;
                  .check-all-property-checkbox{
                    margin-right: 10px;
                    .ant-checkbox-indeterminate .ant-checkbox-inner::after{
                      background-color: @primary-color;
                    }
                    .ant-checkbox-indeterminate.ant-checkbox-checked .ant-checkbox-inner{
                      background-color: white;
                    }
                    .ant-checkbox-inner{
                      border-color: @primary-color;
                    }
                  }
                }
                .detailed-info-property-filter-btn-container{
                  display: flex;
                  margin-right: 10px;
                  .detailed-info-property-filter-btn-block{
                    height: 21px;
                    width: 21px;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    &:hover{
                      cursor: pointer;
                    }
                    .isvg{
                      height: 18px;
                    }
                    &.multiple-active{}
                  }
                }
              }
              .detailed-info-property-filter-items {
                max-height: 544px;
                overflow: scroll;
                .ant-menu-vertical{
                  border: none;
                }
                .ant-menu-title-content{
                  display: flex;
                  align-items: center;
                }
                .ant-menu-item-selected{
                  .property-filter-checkbox{
                    background: @primary-color;
                    &:after {
                      position: absolute;
                      display: table;
                      border: 2px solid #fff;
                      border-top: 0;
                      border-left: 0;
                      transform: rotate(45deg) scale(1) translate(-50%, -50%);
                      opacity: 1;
                      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
                      content: ' ';
                      height: 10px;
                      width: 6px;
                      top: 6px;
                      left: 3px;
                    }
                  }
                }
                .property-filter-checkbox{
                  position: relative;
                  width: 16px;
                  height: 16px;
                  background: #FFFFFF;
                  border: 1px solid @primary-color;
                  border-radius: 2px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 10px;
                }
                .status-icon{
                  padding-right: 5px;
                }
                .vendor-status-tag{
                  margin: 0 12px;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  position: absolute;
                  right: 0;
                  &.blue-tag{
                    background: #00B0FF;
                  }
                  &.yellow-tag{
                    background: #FFD500;
                  }
                }
                .website-name{
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  max-width: 210px;
                  font-size: 18px;
                  &.property-filter-active {
                    max-width: 190px;
                  }
                }
                .ant-menu {
                  .ant-menu-item-selected {
                    color: @primary-color;
                    background-color: #E9DFFF;
                  }

                  .ant-menu-item {
                    height: 40px;
                    line-height: 40px;
                    padding: 0 5px;
                    margin-right: 5px;
                    margin-bottom: 0;
                    border-radius: 5px;

                    &:hover {
                      color: @primary-color;
                    }
                  }

                  .ant-menu-item-active {
                    color: @primary-color;
                  }
                }
              }
            }
          }
          .detailed-info-results{
            width: 75%;
            max-height: 580px;
            overflow: scroll;
            .ant-spin-blur::after{
              opacity: 1;
            }
            .ant-empty{
              display: flex;
              flex-direction: column;
              min-height: 500px;
              align-items: center;
              justify-content: center;
              .ant-empty-description{
                font-size: 16px;
                color: #272727;
              }
              .multi-select-icon-block{
                margin-bottom: 30px;
                .multi-select-icon-row{
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  .isvg{
                    height: 20px;
                    width: 20px;
                    margin-right: 16px;
                  }
                  .multi-select-icon-line{
                    background-color: #6565A9;
                    height: 20px;
                    border-radius: 11px;
                    &.sm{
                      width: 78px
                    }
                    &.md{
                      width: 110px
                    }
                    &.lg{
                      width: 133px
                    }
                  }
                }
              }
              .multi-select-operation-container{
                .multi-select-operation-title{
                  font-size: 25px;
                }
              }
            }
            .detailed-info-status-block{
              border-bottom: 1px solid lightgrey;
              width: 100%;
              display: flex;
              flex-direction: column;
              padding-left: 12px;
              padding-bottom: 12px;
              transition: all 0.5s ease-out;
              position: sticky;
              top: 0;
              background: white;
              z-index: 2;
              &.active{
                .detailed-info-status-block-property{
                  background: #EFF4FF;
                  border-radius: 5px;
                  padding: 6px;
                  .detailed-info-status-block-property-buttons{
                    display: flex;
                  }
                }
                .detailed-info-set-comments-block{
                  display: block;
                }
              }
              .detailed-info-status-block-property, .detailed-info-set-status-block, .detailed-info-set-comments-block{
                .detailed-info-status-title{
                  font-size: 16px;
                  margin-right: 8px;
                  font-weight: 600;
                  margin-bottom: 10px;
                }
                .vendor-status-dropdown-select{
                  height: 43px;
                  margin: 0;
                  .ant-select-selection-item{
                    padding-right: 30px;
                    .isvg{
                      padding-right: 10px;
                    }
                  }
                }
                .ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
                  height: 45px;
                }
                .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
                  line-height: 43px;
                }
                .ant-select {
                  min-width: 180px;
                  font-size: 16px;
                  .ant-select-selector {
                    box-shadow: 0px 2px 3px #0000001F;
                    border: 1px solid #DAE4EB;
                    border-radius: 5px;
                  }
                }
              }
              .detailed-info-status-block-property{
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #212F43;
                margin-bottom: 12px;
                .detailed-info-status-title{
                  margin-bottom: 0!important;
                }
                .detailed-info-status-block-property-content{
                  display: flex;
                  align-items: center;
                }
                .detailed-info-status-block-property-buttons{
                  display: none;
                  align-items: center;
                  .ant-btn-default{
                    border: 1px solid @primary-color;
                    border-radius: 5px;
                    color: @primary-color;
                    margin-right: 5px;
                  }
                  .ant-btn-primary{
                    border-radius: 5px;
                  }
                }
                .detailed-info-status-property-item{
                  background-color: #E9DFFF;
                  border-radius: 5px;
                  padding: 0 10px;
                  font-size: 16px;
                  margin-right: 8px;
                  display: flex;
                  align-items: center;
                  position: relative;
                  line-height: 30px;
                  &>div{
                    max-width: 92px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                  &.list{
                    &:hover{
                      cursor: pointer;
                      .property-list-dropdown{
                        display: flex;
                      }
                    }
                  }
                  .property-list-dropdown{
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #DBDBDB;
                    border-radius: 9px;
                    background-color: #FFFFFF;
                    position: absolute;
                    padding: 6px;
                    font-size: 12px;
                    top: 30px;
                    left: 0;
                    z-index:2;
                    display: none;
                    flex-direction: column;
                    .property-list-item{
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      white-space: nowrap;
                    }
                  }
                  .ant-btn-primary{
                    min-width: 12px;
                    width: 12px;
                    height: 12px;
                    margin-left: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  .isvg{
                    svg{
                      height: 12px;
                      width: 12px;
                    }
                  }
                }
                .detailed-info-status-item{
                  padding: 0 10px;
                  border-radius: 5px;
                  display: flex;
                  align-items: baseline;
                  position: relative;
                  &.new{
                    background: #CCECFF;
                    .status-item-name{
                      color: #187EE3;
                    }
                  }
                  &.change{
                    background: #FFF0CC;
                    .status-item-name{
                      color: #B87B16;
                    }
                  }
                  &:hover{
                    cursor: pointer;
                    .status-item-btn, .status-item-tooltip{
                      display: block;
                    }
                  }
                  .status-item-name{
                    font-size: 16px;
                    line-height: 30px;
                  }
                  .status-item-btn{
                    margin-left: 4px;
                    display: none;
                    color: #656565;
                    font-size: 12px;
                    &:hover{
                      text-decoration: underline;
                      cursor: pointer;
                    }
                  }
                  .status-item-tooltip{
                    display: none;
                    position: absolute;
                    background: #CCECFF;
                    border-radius: 5px;
                    width: 280px;
                    padding: 6px;
                    font-size: 12px;
                    line-height: 16px;
                    top: 30px;
                    left: 0;
                    z-index:2;
                    margin-top: 9px;
                    .status-item-name{
                      font-size: 12px;
                      line-height: 12px;
                      font-weight: 600;
                    }
                    &.change{
                      background: #FFF0CC;
                    }
                  }
                }
              }
              .detailed-info-set-status-block{

              }
              .detailed-info-set-comments-block{
                display: none;
                .ant-input{
                  border: 1px solid #DADADA;
                  border-radius: 5px;
                }
              }
            }
            .comment-for-all-properties-container{
              padding: 12px 0 0 12px;
              .ant-checkbox-wrapper.is-comment-for-all-properties-checkbox{
                width: 100%;
                border-bottom: 1px solid lightgrey;
                padding-bottom: 10px;
                font-weight: 400;
                .ant-checkbox-inner{
                  border-color: #6565A9;
                }
              }
              .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-checked::after{
                border-color: @primary-color;
              }
              .set-comments-for-all-properties-block{
                padding-top: 10px;
                .set-comments-for-all-properties-title{
                  font-size: 16px;
                  margin-right: 8px;
                  font-weight: 500;
                }
              }
            }
            .detailed-info-activities-block, .multi-select-operation{
              padding-left: 12px;
              padding-top: 12px;
              .detailed-info-activities-block-title{
                font-size: 16px;
                font-weight: 500;
                padding-bottom: 12px;
              }
              .detailed-info-activity-item{
                border: 0.5px solid @light-grey-color;
                border-radius: 4px;
                padding: 10px;
                margin-bottom: 12px;
                .activity-row{
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  padding-bottom: 10px;
                  .activity-date{
                    margin-right: 4px;
                  }
                  .updated-by{
                    display: flex;
                    align-items: center;
                    .updated-by-title{
                      margin-right: 4px;
                    }
                    .updated-by-system{
                      display: flex;
                      align-items: center;
                      color: @light-grey-color;
                      img{
                        height: 12px;
                        margin-right: 4px;
                      }
                    }
                  }
                }
                .activity-statuses-row{
                  display: flex;
                  align-items: center;
                  padding-bottom: 10px;
                  font-weight: 600;
                  .anticon-arrow-right{
                    margin: 0 6px;
                    padding: 0;
                  }
                  .activity-prev-status, .activity-current-status{
                    padding: 0 10px;
                    border-radius: 5px;
                    &.approved-status{
                      background: rgba(3, 162, 51, 0.1);
                    }
                    &.action-required-status{
                      background: rgba(255, 9, 9, 0.1);
                    }
                    &.under-review-status{
                      background: rgba(118, 96, 234, 0.1);
                    }
                    &.unreviewed-status{
                      background: rgba(112, 112, 112, 0.1);
                    }
                  }
                  .activity-dismissed-tag-row{
                    display: flex;
                    align-items: center;
                  }
                  .activity-current-tag, .activity-prev-tag{
                    padding: 0 10px;
                    border-radius: 5px;
                    &.new{
                      background-color:#CCECFF;
                      color:#187EE3;
                    }
                    &.change-detected{
                      background-color: #FFF0CC;
                      color: #B87B16;
                    }
                    &.dismiss{
                      color:@light-grey-color;
                    }
                  }
                  .activity-prev-tag{
                    text-decoration: line-through;
                  }
                }
                .activity-comment-row{
                  .activity-comment-title{
                    font-weight: 500;
                    font-size: 16px;
                  }
                  .activity-comment-block{
                    border: 0.5px solid #DADADA;
                    border-radius: 4px;
                    padding: 6px;
                    min-height: 35px;
                    background-color: white;
                   }
                }

              }
            }
            .multi-select-operation{
              .detailed-info-activity-item{
                .activity-row{
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .property-title-block{
                    display: flex;
                    align-items: center;
                    .property-title{
                      font-weight: 500;
                      margin-right: 10px;
                    }
                    .property-name{
                      background-color: #E9DFFF;
                      padding: 4px 10px;
                      border-radius: 9px;
                      max-width: 180px;
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                }
                .activity-statuses-row{
                  padding-bottom: 0;
                }
                .activity-comment-block{
                  color:@light-grey-color;
                  border: 1px solid #DADADA;
                  border-radius: 5px;
                }
              }

            }
            .detailed-info-activities-block-collapse-btn{
              background: #EDEBF3;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: @primary-color;
              padding: 8px;
              margin-left: 12px;
              margin-top: 12px;
              cursor: pointer;
              font-size: 16px;
              transition: all ease 0.3s;
              &:hover{
                background-color: #D8D6E0;
              }
              .anticon{
                margin-left: 4px;
                svg path{
                  fill: @primary-color;
                }
              }

            }
          }
        }
      }
    }
  }
}

.vendor_status_custom_filter,
.vendor-status-filter-container{
  .ant-checkbox-wrapper{
    .vendor-status-tag{
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}

.vendor-status-filter-container{
  width: 210px;
  .ant-checkbox-wrapper + .ant-checkbox-wrapper, .ant-checkbox-group-item{
    margin-left: 0
  }
  .ant-checkbox-wrapper{
    font-weight: 400;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 10px 4px;
    &:after{
      display: none;
    }
    .ant-checkbox + span{
      padding-left: 4px;
    }
    .isvg{
      margin-right: 10px;
    }
    .ant-checkbox-indeterminate.ant-checkbox-checked .ant-checkbox-inner{
      background-color: white;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner{
      border-color: @primary-color;
      &:after{
        background-color: @primary-color;
      }
    }
    .ant-checkbox-checked .ant-checkbox-inner{
      background-color: @primary-color;
      border-color: @primary-color;
    }
    .ant-checkbox-inner{
      border-color: @primary-color;
    }
  }
  .ant-divider-horizontal{
    margin: 10px 0 20px 0;
  }
  .ant-checkbox-group{
    display: flex;
    flex-direction: column;
  }
  .vendor-status-filter-buttons{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .ant-btn{
      padding: 4px 10px;
    }
    .ant-btn-default{
      border-color: @primary-color;
      color: @primary-color;
    }
    .ant-btn-primary {
      background-color: @primary-color;
      border-color: @primary-color;
    }
  }
}


.avocado-modal div.ant-select.spsg-select-multiple {
  margin-bottom: 0;

  div.ant-select-selection-search {
    display: none;
  }

  .anticon.anticon-check-square {
    display: none;
  }

  .anticon.anticon-search.ant-select-suffix {
    display: none;
  }
}
