@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

.spsg-tooltip {
  .ant-tooltip-inner {
    padding: 8px 12px;
    background: #747474;
    color: #fff;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border-radius: 10px;
    box-shadow: 0px 2px 10px #00000029;
    max-height: 360px;
    overflow: auto;
  }
  .ant-tooltip-arrow {
    border-top-color: #747474;
  }
}
