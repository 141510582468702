@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.spsg-chip {
  line-height: 33px;
  padding: 0 12px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #272727;
  border-radius: 18px;
  border-color: #E9DFFF;
  background: #E9DFFF;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  user-select: none;
  span.anticon {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left: 10px;
    border-radius: 9px;
    background: #411F90;
    svg {
      color: #fff;
    }
  }
}
