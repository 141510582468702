#avocado {
  div {
    div.ant-select-dropdown.golden-set-rules {
      width: 598px !important;
      top: 169px !important;
      // height: 412px !important;

      .rc-virtual-list-holder {
        max-height: 310px !important;
      }

      // .rc-virtual-list-holder {
      //   max-height: 600px !important;
      //   .rc-virtual-list-holder-inner {
      //     height: 500px !important;
      //   }
      // }
    }

    &.hidden {
      display: none;
    }
  }

  .ant-modal.new-domain-set.new-rule.golden-set {
    width: 650px !important;
    top: 30px;
    
    // .ant-modal-content {
    //   height: 603px;
    // }

    .ant-select-selector {
      width: 400px;
    }

    .ant-pagination-options-size-changer {
      .ant-select-selector {
        width: 100px;
      }
    }

    .ant-select-selector {
      // display: none;
      .ant-select-selection-search {
        display: none;
      }
      border: none;
      .ant-select-selection-item {
        display: none;
      }
    }

    .rules {
      height: 458px;
      border: 1px dashed lightgray;
      p {
        margin-top: 10px;
        padding-left: 12px !important;
        font-size: 14px;
        background-color: lightblue;
        padding: 5px;
        font-weight: bold;

        span {
          color: gray;
          font-weight: normal;
        }
      }
    }

    .ant-modal-footer {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      background-color: #e5e4e4;
    }

    .ant-modal-footer.ant-btn-primary{
      background: #7F92BD;
    }
  }


  .golden-set {
    .ant-tabs-nav {
      padding-top: 0;
      margin-top: -16px;
      border-bottom: 1px solid lightgray;
      margin-bottom: 0;
    }

    button.create-button {
      top: 204px;
    }

    a > p {
      position: absolute;
      right: 20px;
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .golden-set-detail {
    button.create-button {
      top: 135px;
    }

    .link-back {
      cursor: pointer;
    }

    .top-lane {
      margin-bottom: 10px;
      margin-top: 45px;

      input {
        border: none;
        width: 200px;
        background-color: transparent;
        font-weight: bold;
      }
  
      .policy {
        margin-left: 10px;
        position: absolute;
        top: 210px;
      }
  
      .ant-select {
        width: 170px;
        
        .ant-select-selection-item {
          font-weight: bold;
        }
  
        .ant-select-selector {
          background-color: transparent;
          border: none;
          width: 170px;
        }
      }

      button {
        font-size: 14px;
        margin-left: 20px;
      }
    }
  }

  .golden-set-comparison {

    .ant-table-content {
      overflow: auto hidden;
    }

    table {

      .ant-table-cell.rule-column.more-than-four {
        width: 120px;
      }

      td.rule-column, th.rule-column {
        text-align: center;
      }

      td:first-child, td:nth-child(2), td:nth-child(3) {
        border-right: 1px solid #F1F3F6;
      }

      .icon {
        width: 23px;
        background-color: #99eac7;
        padding: 1px 2px;
        font-size: 11px;
        border-radius: 5px;
        text-align: center;
        margin: 0 auto;
      }
  
      .icon.green {
        background-color: #99eac7;
      }
  
      .icon.red {
        background-color: #fecaca;
      }
    }
  }

  .rule-performance-table {

    .ant-table-content {
      overflow: auto hidden;
    }
  }

  .top-container-gs-performance {
    background-color: #F3F8FC;
    padding: 20px;
    border: 1px solid #DDDDDD;
    margin-bottom: 60px;

    .underperforming {
      border-top: 1px solid lightgrey;
    }

    .avg {
      padding-bottom: 20px;
    }

    .ant-collapse {
      border: none;

      span {
        left: 0;
        font-size: 16px;
      }

      .ant-collapse-header {
        padding-left: 30px;
        background-color: #F3F8FC;
        color: black;
        font-weight: bold;
        font-size: 16px;
      }

      .ant-collapse-content {
        border: none;
      }

      .ant-collapse-item {
        border: none;
      }

      .ant-collapse-content-box {
        padding-top: 0;
        background-color: #F3F8FC;
        padding-bottom: 0;
      }
    }

    p.title {
      color: black;
      font-weight: bold;
      font-size: 16px;
      padding-top: 0;
    }

    .avg-list {
      p {
        font-size: 13px;
      }

      > div {
        padding-right: 40px;

        > div {
          font-size: 17px;
          font-weight: bold;
        }
      }
    }

    .underperforming-list {
      p {
        font-size: 13px;
        color: black;
      }

      > div {
        padding-left: 20px;
        padding-right: 20px;
        border-right: 1px solid lightgrey;

        &:last-child {
          border-right: none;
        }
      }

      .rule-name {
        color: grey;
        margin-left: 10px;
      }
    }
  }

  .golden-sets-table {
    table {

      td {
        width: 20%
      }

      td:last-child, th:last-child {
        text-align: center;
      }

    }
  }

  .golden-set-detail-table {

    .ant-table-content {
      overflow: auto hidden;
    }

    table {
      table-layout: fixed !important;

      .ant-table-cell.rule-column.more-than-four {
        width: 120px;
      }

      td:first-child {
        border-right: 1px solid #F1F3F6;
      }
  
      td:last-child {
        border-left: 1px solid #F1F3F6;
      }

      td.rule-column, th.rule-column {
        text-align: center;
      }
    }
  }

  .golden-set-tooltip {

    .ant-tooltip-content {
      width: 500px;
      margin-left: -300px;
    }

    p {
      font-size: 11px;

      &.tultip-title {
        font-weight: bold;
      }
    }
  }
  
  .golden-set-tooltip-wrapper {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 635px;
    right: 20px;
    p {
      font-size: 12px;
      margin-right: 10px;
    }

    span {
      font-size: 12px;
    }

    .tooltip-color {
      span {
        &.icon {
          width: 23px;
          background-color: #99eac7;
          padding: 1px 2px;
          font-size: 11px;
          border-radius: 5px;
          text-align: center;
          margin: 0 auto;
          height: 18px;
        }
    
        &.icon.green {
          background-color: #99eac7;
        }
    
        &.icon.red {
          background-color: #fecaca;
        }
      }
    }
  }
}