  @font-main: proxima-nova, ProximaNova, Helvetica, Arial;

  .navbar-container {
    background: #212F43;
    z-index: 1000;
    width: 100%;
    height: 70px;
    position: fixed;
  }

  .main-navbar {
    background: #212F43;
    max-width: 1200px;
    padding: 25px 20px 0;
    margin: auto;

    .dashboard-recovery-message-group {
      align-items: flex-start;
      display: inline-flex;
      list-style: none;

      li {
        &:first-child {
          padding-left: 0;
        }

        padding: 0 15px;

        &:hover {
          a {
            color: white;
          }
        }

        &.active {
          a {
            border-bottom: 4px solid #0294FF;
            color: white;
          }
        }
      }

      a {
        padding: 23px 0;
        font-family: @font-main;
        font-size: 15px;
        font-weight: 100;
        color: #BDC1C6;
        line-height: 15px;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .account-group {
      align-items: flex-start;
      display: inline-flex;
      float: right;

      .masq {
        margin-right: 15px;
        svg #Main-Navigation___-759794523 {
          transition: 100ms;
          transition-timing-function: ease-in-out;
          -webkit-transition: 100ms;
          -webkit-transition-timing-function: ease-in-out;
        }
        svg:hover #Main-Navigation___-759794523 {
          fill: white;
        }
      }

      .dropdown-container {
        &.visible {
          svg #Main-Navigation___1107285175 {
            fill: white;
          }
        }

        svg #Main-Navigation___1107285175 {
          transition: 100ms;
          transition-timing-function: ease-in-out;
          -webkit-transition: 100ms;
          -webkit-transition-timing-function: ease-in-out;
        }
        svg:hover #Main-Navigation___1107285175 {
          fill: white;
        }
      }
    }

    .status-incident {
      a {
        // Make sure to override the "active" coloring if necessary
        color: orange !important
      }
    }
  }

  .blueberry-navbar {
    &.ant-dropdown-menu.ant-dropdown-menu-vertical.ant-dropdown-menu-light.ant-dropdown-menu-root {
      margin-top: 10px;
      padding-top: 10px;
      width: 190px;
      background: white;
      box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);

      .triangle-icon {
        position: absolute;
        left: 89px;
        top: -13px;
      }

      li {
        &:hover {
          background-color: #F0F3F6;
        }

        &.account {
          padding-top: 10px;
        }

        // third to last child which is the last one before HR
        &:nth-last-child(3) {
          padding-bottom: 10px;
        }

        a, div {
          padding: 10px 50px 10px 35px;
          color: black;
          font-family: 'Helvetica Neue';
          font-size: 14px;
          line-height: 14px;

          &:hover {
            text-decoration: none;
          }
        }

        div {
          padding-left: 25px;
        }
      }

      .sign-out {
        padding: 15px 0 15px;

        .sign-out-icon {
          display: inline-flex;
          margin-left: 35px;
        }

        .sign-out-link {
          display: inline-flex;
          margin-left: -25px;
        }
      }
    }
  }
