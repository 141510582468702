@import (css)
  url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

.admin-settings-page-new {
  font-family: Open Sans;
  margin-top: var(--ant-margin);
  .ant-tabs-nav {
    padding-top: 0;
  }
  .ant-tabs-card{
    .ant-tabs-nav{
      .ant-tabs-tab-active{
        border-bottom-color: var(--ant-color-border-secondary);
      }
    }
  }
  .avocado.ant-empty {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: white;
    border-radius: 18px;
  }
  // .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  //   color: #362360;
  // }
  // .ant-tabs-ink-bar {
  //   position: absolute;
  //   background: #000000;
  //   pointer-events: none;
  //   color: #362360;
  // }
  // .ant-tabs-tab {
  //   font: normal normal 300 20px/42px Oswald;
  //   color: #43425D;
  // }
  .title-admin-settings {
    font-size: 28px;
    font-weight: 400;
    margin-top: 24px;
    margin-bottom: 24px;

    span {
      font-weight: 200;
    }
  }

  .search-container-admin {
    top: 153px;
    left: 119px;
    margin: 25px 0px;
    display: flex;
    align-items: center;

    .clear-button {
      margin-left: 10px;
      padding: 6px;
    }

    .save:hover {
      opacity: 0.75;
    }
  }
}
//GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
// .ant-tabs-nav {
//   padding-top: 30px;
// }

.features-table__wrapper {
  background: #ffffff;
  box-shadow: 0px 5px 10px #0000001f;
  border: 1px solid #d4d4d4;
  border-radius: 18px;
  opacity: 1;
  padding: 25px;
  margin-bottom: 22px;
}
//
.ant-table-filter-dropdown {
  border-radius: 5px;
  padding: 5px;
  .account-features-dropdown {
    padding: 10px;
    font-weight: normal;
    label.ant-checkbox-wrapper {
      font-weight: 400;
    }
    .ant-checkbox .ant-checkbox-inner {
      border: 1px solid #411f90;
    }
    .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
      background-color: #411f90;
      border: 1px solid #411f90;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: #411f90;
      border: 1px solid #411f90;
      border-radius: 2px;
    }
    .ant-checkbox.ant-checkbox-checked.ant-checkbox-indeterminate
      .ant-checkbox-inner {
      background-color: transparent;
    }
    .account-features-checkbox {
      display: flex;
      flex-direction: column;
      span {
        font-weight: 400;
      }
    }
    .ant-divider.ant-divider-horizontal {
      margin: 15px 0px 15px 0px;
    }
    .btn-primary {
      background: #411f90;
      border: 1px solid #411f90;
      color: #fff;
    }
  }
}

.vl-editable-row {
  .rowStyle {
    word-wrap: break-word;
    word-break: break-word;
  }
}
.ant-badge-status-text {
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  .scan-status-successful {
    color: var(--ant-color-success);
  }

  .scan-status-failed {
    color: var(--ant-color-error);
  }

  .scan-status-pending {
    color: var(--ant-color-warning);
  }
}

.ant-modal.generate-sdk-modal {
  .ant-modal-body {
    .title {
      font-size: large;
    }
    .info-message {
      font-size: medium;
      margin-top: 10px;
    }
    .anticon.anticon-info-circle {
      color: @primary-color;
    }
  }

  .ant-modal-header {
    border-bottom: 0px;
  }
  .ant-modal-footer {
    border-top: 0px;
  }
}
