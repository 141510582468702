@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');

#avocado {
  .ant-dropdown-menu-item{
    &:hover{
      background: #E9DFFF;
    }
  }
  .privacy-lens.standards-container {
    .ant-select-selector {
      border: 1px solid #D4D4D4;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 3px 0 rgba(0, 0, 0, 0.10);
      height: 32px;
      padding: 0px 20px 0px 10px;
      border-radius: 5px;
      &:before {
        content: "Status";
        display: inline-block;
        margin-right: 0px;
        line-height: 32px;
        color: #2D2D2D;
        opacity: 0.65;
      }
      .ant-select-selection-item{
        flex: none;
        margin: 6px;
        padding: 0px 7px;
        background: #2D2D2D;
        border-radius: 5px;
        opacity: 0.65;
        color: white;
        line-height: inherit;
      }
    }

    .ant-input-affix-wrapper{
      padding: 0px 10px !important;
    }
    .ant-select-arrow{
      color: #2D2D2D;
      padding-left: 5px;
      top: 25%;
      margin: 0;
    }
    .standards-types {
      cursor: pointer;
      border-top: 1px solid rgb(232, 232, 232);
      margin-left: 5px;
      font-size: 24px;
      padding: 10px 0;
      letter-spacing: 0px;
      color: #2D2D2D;
      font-family: 'Oswald';
      font-weight: 200;
      .dropdown-icon{
        content: "";
        border: solid #2b4a67;
        border-width: 0 0 1px 1px;
        display: inline-block;
        padding: 7px;
        margin: 0 0 5px 7px;
        margin-right: 10px;
      }
      .standards-size {
        font-weight: 400;
      }
    }
    .privacy-lens-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 600px){
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 1000px) {
        grid-template-columns: repeat(3, 1fr);
      }
 
      gap: 20px;
      padding-bottom: 30px;

      .account{
        .ant-switch{
          background-color: #78788073;
        }
      }

      .sp{
        .ant-switch{
          background-color: #EDEDEE69 !important;
        }
      }

        .property-applied {
          background: #F1EBFF 0% 0% no-repeat padding-box;
          box-shadow: 0px 1px 3px #00000029;
          border: 1px solid #7070702E;
          border-radius: 4px;
          width: 300px;
          margin: 10px;
          padding: 10px;
          .sets-container, .no-property {
            font: normal normal 600 14px/18px Open Sans;
          }
        }

        .standards-card{
          color: black;
          cursor: pointer;
          border: 1px solid #D4D4D4;
          border-radius: 20px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
          min-width: 380px;
          width: 25vw;
          .title-container {
            justify-content: space-between;
            display: flex;
            width: 100%;
            .flex-row {
              >.copy-icon-card{
                margin-right: 8px;
              }
            }
          }
          .standards-card-header {
            border: 0.5px solid #8F8F8F;
            border-radius: 12px 12px 0px 0px;
            background-color: white !important;
            width: 100%;
            color: #411F90 !important;
            display: flex;
            justify-content: space-between;
            padding: 10px 5px 10px 15px;
            .activate-button {
              color: white;
              cursor: pointer;
              background: #411F90 0% 0% no-repeat padding-box;
              border: 1px solid #411F90;
              border-radius: 6px;
              padding: 1px 7px;
            }
            .standards-card-title {
              display: inline-block;
              font-weight: 600;
              font-size: 15px; 
              letter-spacing: 0px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              font: normal normal 600 16px/22px Open Sans;
              .ant-dropdown-menu-item{
              display: none;
            }
            }
          }
          .standards-card-body{
            height: 121px;
            padding: 10px 5px 10px 15px;
            box-shadow: 0px 7px 19px #0000001F;
            border: 0.5px solid #8F8F8F;
            border-radius: 0px 0px 12px 12px;

            strong.bold-number {
              font-weight: 900;
            }
          }
        .region-title {
          padding: 10px 0;
        }
        .must-have-rules {
          border-radius: 5px;
          background-color: #F5F5F5;
          width: 286px;
          padding-left: 10px;
          img{
            width: 20px;
          }
          span:nth-of-type(1) {
            border-right: 1px solid #DDDDDD;
          }
          span {
            padding: 5px 10px;
            display: inline-block;
            background-color: #F5F5F5;
          }
        }
        .standards-applied {
          padding: 10px 0;
          span {
            // display: block;
            font-weight: bold;
          }
          .extra-hidden-properties{
            background-color: #6565A9;
            border-radius: 4px;
            color: white;
            font-size: 12px;
            font-weight: 500;
            padding: 2px;
            margin: 0 10px;
          }
          .no-property{
            line-height: 22px;
            img {
              margin-right: 8px;
              margin-bottom: 3px;
            }
          }
        }

        .percentage-completed {
          font-size: 12px;
        }
      }
    }
    >.search-container > .spsg-input {
      width: 300px;
      box-shadow: 0px 2px 3px #0000001f;
    }
  }
  .ant-popover.more-property-sets-popover {
    .ant-popover-inner-content {
      max-height: 200px;
      overflow-y: scroll;
      font-size: 14px;
    }
  }
}
