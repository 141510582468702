  .navbar-form .masquerade {
    width: 800px;
  }

  .navbar-form .masquerade tbody tr:hover {
    background: #DCDCDC;
    cursor: pointer;
  }

  .caution-bar {
    border-radius: 10px;
    z-index: 100;
    margin-left: 20px;
    background: #FF8983;
    .ant-alert {
      padding: 6px 12px;
      .anticon {
        display: none;
      }
      .ant-alert-message {
        white-space: nowrap;
      }
    }
  }
  @media screen and (min-width: 1500px) {
    .caution-bar{
      right: 420px;
    }
  }

  @media screen and (min-width: 2000px) {
    .caution-bar {
      right: 820px;
    }
  }
