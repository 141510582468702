@fallback-fonts: Arial, Helvetica, sans-serif;

.redesign {
  @images-path: '../../../images/';
  strong {
    font-family: 'Open Sans', @fallback-fonts;
  }

  h3 {
    margin-bottom: 20px;
  }

  /* buttons */
  @btn-default-color: #7c91ac;
  @btn-success-color: #3FCC97;

  .btn {
    padding-right: 15px;
    padding-left : 15px;
    font-family  : helvetica;
  }

  .save-button {
    padding-left: 16px;
  }

  .btn.activate-campaign,
  .btn.cancel-activate-campaign,
  .btn.cancel-import {
    margin-right: 10px;
  }

  .btn-success {
    background-color: @btn-success-color;
    border-color    : transparent;
    border-radius   : 3px;

    &:hover {
      background-color: darken(@btn-success-color, 10%);
    }
  }

  .btn-plain,
  .btn[disabled] {
    color     : black;
    background: #F7F7F7;
    background: -webkit-linear-gradient(white, #F2F4F7);
    background: -o-linear-gradient(white, #F2F4F7);
    background: -moz-linear-gradient(white, #F2F4F7);
    background: linear-gradient(white, #F2F4F7);

    font-family: helvetica;
    font-weight: bold;

    border-color : #C3CCD4;
    border-radius: 3px;

    &:hover {
      background: #F2F4F7;
      background: -webkit-linear-gradient(white, #E8EAED);
      background: -o-linear-gradient(white, #E8EAED);
      background: -moz-linear-gradient(white, #E8EAED);
      background: linear-gradient(white, #E8EAED);
    }
  }

  .btn[disabled] {
    opacity: 0.5;
  }

  .btn-default {
    background-color: @btn-default-color;
    border-color    : transparent;
    border-radius   : 3px;

    &:hover {
      background-color: darken(@btn-default-color, 10%);
    }

    color: #ffffff;
  }

  /* icons */
  @icon-default-color: #BAC5D4;
  @icon-hover-color  : darken(@icon-default-color, 10%);

  .action {
    cursor: pointer;
    color : @icon-default-color;

    &:hover {
      color: @icon-hover-color;
    }
  }

  .action-lg {
    font-size: 1.4em;
  }

  .delete-partition {
    padding-left: 12px;
  }

  /* panel */
  .panel {
    background-color: #ffffff;
    border          : 1px solid #F5F5F5;
    border-radius   : 0;
    max-width       : 1200px;

    .btn {
      text-transform: uppercase;
      font-size     : 12px;
    }

    .panel-heading {
      background-color: transparent;
      border-bottom   : 1px solid #eee;
      padding         : 5px 30px 10px;
      font-family     : "Myriad Pro", @fallback-fonts;
      font-weight     : 500;
      margin          : 10px 0;
      margin-bottom   : 0;
      min-height      : 37px;

      h4 {
        margin   : 0;
        float    : left;
        font-size: 18px;
        color    : #333;
      }

      .action {
        float: right;
      }
    }

    .panel-body {
      padding: 0px;
    }

    #partition-table.panel-table {
      border-collapse: collapse;
      margin         : 0;

      thead>tr>th {
        font-weight   : 600;
        text-transform: uppercase;
        letter-spacing: 1px;
        text-align    : left;
        padding       : 5px 5px;
        color         : #8699B3;
        border-bottom : none;
        font-size     : 10px;
        font-family   : "Myriad Pro", @fallback-fonts;
        padding-left  : 30px;
      }

      tbody {
        background: transparent;
        border    : none;

        tr>td {
          border          : none;
          background-color: #F3F8FC;
          padding         : 10px 5px;
          vertical-align  : middle;
          padding-left    : 30px;
        }
      }

      tbody tr:nth-child(even)>td {
        background-color: #EDF4FA;
      }

      td:last-child {
        position: relative;
      }
    }

    .panel-buttons {
      display: inline;

      text-align: left;
      padding   : 10px 15px;

      .btn {
        margin : 10px;
        padding: 4px 15px 2px 15px;
      }

    }
  }

  .panel.scenario-in-campaign {
    background-color: #fff;
    border          : none;
    margin          : 0;
    box-shadow      : none;
    max-width       : 1200px;

    tr {
      border: none !important;
    }

    .panel-body {
      padding: 10px 0;
    }

    .table {
      margin: 0;

      td:nth-child(1) {
        width    : 60px;
        max-width: 60px;
      }
    }

    &.ended {
      .panel-table {
        margin: 0;

        tbody {
          border: none;
        }
      }
    }
  }

  span.hovertip {
    border-bottom: 1px dotted;
    position     : relative;

    &::before {
      content       : attr(data-tip);
      font-size     : 10px;
      font-family   : "Myriad Pro", @fallback-fonts;
      font-weight   : bold;
      position      : absolute;
      display       : none;
      z-index       : 1000;
      white-space   : nowrap;
      left          : 0;
      background    : #000;
      color         : #e0e0e0;
      padding       : 0px 7px;
      line-height   : 24px;
      height        : 24px;
      opacity       : 0.7;
      border-radius : 3px;
      letter-spacing: 0.1em;
    }

    &:hover::before {
      display: block;
      bottom : 15px;
    }

    &.campaign-stats-hover {
      border: none;

      &:hover::before {
        bottom: 25px;
      }
    }

    &.info {
      border        : none;
      letter-spacing: normal;

      &::before {
        font-size: 12px;
      }

      &:hover::before {
        bottom     : 26px;
        font-weight: normal;
      }

      .info-tooltip {
        font-family: consolas;
        font-weight: bold;
        font-size  : 21px;
        padding    : 0 5px;
      }
    }
  }

  tr.expanded {
    background: #fafafa;
    border    : 1px solid #ddd;
  }

  tbody.active>tr.expanded {
    background: #F2F5FC;
  }

  div.partitionset {
    max-width       : 1200px;
    background-color: #ffffff;
    padding         : 0;
    position        : relative;
    border          : 1px solid #F5F5F5;
    margin-bottom   : 20px;

    input {
      margin: 0;
    }

    input,
    .panel input {
      border       : 1px dashed #abbdcc;
      border-radius: 5px;
      padding      : 5px 10px 2px;
      width        : 100%;
      max-width    : 200px;
    }

    input:focus,
    .panel input:focus {
      border-color: #35CD96;
    }
  }

  .partitionset {

    table.tiny {
      margin: 0;

      input {
        margin: 10px 0px;
      }

      th {
        text-align   : left;
        padding      : 5px 5px;
        color        : #8699B3;
        border-bottom: none;
        font-size    : 10px;
        font-family  : "Myriad Pro", @fallback-fonts;
      }

      th:nth-child(1) {
        padding-left: 30px;
      }

      tbody>tr>td {
        text-align      : left;
        padding         : 10px 5px;
        border          : none;
        background-color: #F3F8FC;
        vertical-align  : middle;
      }

      tbody>tr>td:nth-child(1) {
        padding-left: 30px;
      }

      tbody>tr:nth-child(even)>td {
        background-color: #EDF4FA;
      }
    }
  }

  .custom-icon.i-sm {
    background-size    : 14px 14px;
    height             : 40px;
    width              : 40px;
    background-position: center;
  }

  .arrow-container {
    height: 40px;
    width : 40px;
    float : right;
    cursor: pointer;
  }

  .arrow-container:hover {
    background: #397AE4
  }

  .arrow-container:hover .sortButton {
    color  : white;
    opacity: 1;
  }

  .sortButton {
    padding-right: 5px;
    padding-left : 12px;
    padding-top  : 13px;
    color        : black;
    opacity      : .35;
  }

  .scenario .panel-table td:last-child {
    min-width: 130px;
  }

  .custom-icon {
    outline            : 0;
    min-width          : auto;
    color              : transparent;
    background-color   : transparent;
    border             : none;
    background-size    : 16px 16px;
    background-repeat  : no-repeat;
    height             : 46px;
    width              : 46px;
    background-position: 15px 14px;
    border-radius      : 0;
    opacity            : .4;
    margin             : 0 !important;
    padding            : 0;
  }

  .custom-icon.i-expand,
  .custom-icon.i-collapse {
    outline            : 0;
    height             : 22px;
    width              : 22px;
    background-size    : 6px 6px;
    background-position: 8px 8px;
    z-index            : 1000;
    background-color   : #eee;
  }

  .custom-icon.i-message-preview {
    height             : 30px;
    width              : 44px;
    background-position: 14px 7px;
  }

  .custom-icon.i-chart {
    background-size: 20px 20px;
    opacity        : 0.43
  }

  .custom-icon.i-chart[disabled] {
    background-repeat  : no-repeat;
    background-position: 15px 14px;
  }

  .custom-icon.show-hide-scenario {
    background-color: #ced7e4;
  }

  .custom-icon:hover {
    background-color: transparent;
    opacity         : 0.5;
  }

  .custom-icon.i-expand:hover,
  .custom-icon.i-collapse:hover,
  tr:hover>td>.custom-icon.i-expand,
  tr:hover>td>.custom-icon.i-collapse {
    background-color: #a8b7cc;
    opacity         : 1;
  }

  .custom-icon.i-edit {
    background-image: url('@{images-path}icon-edit.png');
  }

  .custom-icon.i-delete {
    background-image: url('@{images-path}icon-delete.png');
  }

  .custom-icon.i-view {
    background-image: url('@{images-path}icon-view.png');
  }

  .custom-icon.i-collapse {
    background-image: url('@{images-path}icon-collapse.png');
  }

  .custom-icon.i-expand {
    background-image: url('@{images-path}icon-expand.png');
  }

  .custom-icon.i-chart {
    background-image: url('@{images-path}icon-chart.png');
  }

  .custom-icon.i-message-preview {
    background-image: url('@{images-path}icon-message-preview.svg');
    opacity         : 1;
  }

  .custom-icon.i-collapse:hover,
  tr:hover>td>.custom-icon.i-collapse {
    background-image: url('@{images-path}icon-collapse-hover.png');
  }

  .custom-icon.i-expand:hover,
  tr:hover>td>.custom-icon.i-expand {
    background-image: url('@{images-path}icon-expand-hover.png');
  }

  .custom-icon.i-edit:hover {
    background-image: url('@{images-path}icon-edit-hover.png');
    opacity         : 1;
    background-color: #397AE4;
  }

  .custom-icon.i-view:hover {
    background-image: url('@{images-path}icon-view-hover.png');
    opacity         : 1;
    background-color: #397AE4;
  }

  .custom-icon.i-delete:hover {
    background-image: url('@{images-path}icon-delete-hover.png');
    // background-image: url('@{images-path}icon-delete-hover.png');
    opacity         : 1;
    background-color: #cf0f15;
  }

  .custom-icon:active {
    opacity   : 0.7;
    box-shadow: none;
  }

  .partitionset .custom-icon.i-edit,
  .scenario .custom-icon.i-edit {
    top  : 0;
    right: 55px;
  }

  .partitionset .custom-icon.i-delete,
  .scenario .custom-icon.i-delete {
    top  : 0;
    right: 10px;
  }

  .partitionset .custom-icon.i-delete.i-sm {
    top  : 14px;
    right: 14px;
  }

  .scenario .custom-icon.i-delete.i-sm {
    top     : 7px;
    right   : 14px;
    position: static;
    float   : right;
  }

  .scenario {
    position: relative;

    input,
    .panel input {
      border       : 1px dashed #abbdcc;
      border-radius: 5px;
      padding      : 5px 10px 2px;
      width        : 100%;
      max-width    : 200px;
      margin       : 0;
      margin-right : 5px;
    }

    input:focus,
    .panel input:focus {
      border-color: #35CD96;
    }

    label {
      margin-right: 5px;
    }

    select {
      margin-right: 5px;
    }

    .step.continue-halt {
      select {
        min-width: 0;
        width    : 100px;
      }
    }

    .step.message-show-n-times,
    .step.message-show-always,
    .step.message-show-once,
    .step.message-frec-cap {
      select {
        max-width: 150px;
      }
    }

    .step.kv-gate,
    .step.message-show-n-times {
      input {
        min-width: 0;
        width    : 80px;
      }
    }

    .step.message-frec-cap {
      input.times {
        width: 40px;
      }

      input.seconds {
        width: 70px;
      }
    }
  }

  @axis-width: 40px;

  .graph-container {
    position: relative;

    .graph {
      position: relative;
      left    : @axis-width;
    }

    .y-axis {
      position: absolute;
      width   : @axis-width;
      top     : 0;
      bottom  : 0;
    }

    .x-axis {
      position: relative;
      left    : @axis-width;
      height  : 20px;
    }
  }

  .campaign-overview {
    position    : relative;
    margin-right: 0;

    h4 {
      color    : #108ee9;
      font-size: 17px;
      color    : #108ee9;
      padding  : 20px 0 10px 30px;
    }

    .tab-content {
      border          : 1px solid #ddd;
      border-top      : none;
      padding-top     : 2px;
      background-color: #fff;
      z-index         : 100;
      margin-bottom   : 30px;
    }
  }

  .campaign-overview>button {
    position: absolute;
    top     : 0px;
    right   : 0px;
  }

  .table .table {
    background: none;
  }

  .loading {
    margin-top: 100px;
    width     : 100%;

    p {
      text-align: center;
      font-size : 16px;
    }
  }

  .error {
    color      : red;
    display    : inline;
    margin-left: 20px;
  }

  .partition-header {
    h5 {
      font-family     : "Myriad Pro", @fallback-fonts;
      font-weight     : 500;
      padding         : 5px 8px 2px;
      margin          : 15px 22px 0;
      border-radius   : 3px;
      background-color: #a8b7cc;
      color           : #fff;
      text-transform  : uppercase;
      font-size       : 1em;
      display         : inline-block;
    }

    table.tiny.table thead th {
      padding     : 10px;
      padding-left: 5px;
    }

    div>table.tiny.table {
      margin-bottom: 0;

      tbody {
        border: none;

        td {
          vertical-align: middle;
        }

        td.scenario {
          padding: 0;

          table {
            width: 100%;

            tbody {
              border: none;

              td {
                background-color: transparent;

                &:nth-child(2) {
                  width    : 300px;
                  min-width: 200px;
                }

                &:nth-child(3) {
                  width    : 700px;
                  min-width: 250px;
                }
              }
            }
          }
        }
      }

      tbody:nth-child(odd) tr td {
        background-color: rgba(255, 255, 255, .1);
      }

      tbody:nth-child(even) tr td {
        background-color: rgba(255, 255, 255, .3);
      }

      tr.expanded th {
        padding-top: 16px;
      }
    }
  }

  .tiny.table tbody {
    background-color: transparent;
  }

  .btn.toggle-old-campaigns {
    margin-bottom: 20px;
  }

  .stop-button,
  button.activate-diff-env {
    float : right;
    margin: 5px 0 5px 5px;
  }
}

div.redesign>div>div>div>div>div>div>div>div>div>#campaigns-table.table.tiny.table,
div.redesign>div>div>div>div>div>div>div>div>div>#partition-table.table.tiny.table {
  border          : none;
  background-color: transparent;
  margin-bottom   : 20px;

  th {
    text-align   : left;
    color        : #8699B3;
    border-bottom: none;
    font-size    : 10px;
    font-family  : "Myriad Pro", @fallback-fonts;
  }

  >thead {
    background-color: transparent;
    border-bottom   : 1px solid #E6EAEE;

    tr {
      border          : 1px solid #E6EAEE;
      border-bottom   : none;
      background-color: white;
    }

    >tr>th {
      text-align    : center;
      padding-top   : 12px;
      padding-bottom: 12px;
    }

    >tr>th:first-child {
      text-align: left;
    }
  }

  th:first-child {
    padding-left: 30px;
  }

  >tbody {
    background-color: #fff;

    >tr>td {
      width     : 15%;
      text-align: center;
    }

    >tr>td:first-child {
      width     : 30%;
      text-align: left;
    }

    tr {
      border: 1px solid #E6EAEE;
    }

    tr.partitionset-row {
      position: relative;
    }

    tr.partitionset-row>td:last-child,
    tr.partition-row>td:last-child {
      padding   : 19px 15px;
      text-align: right;
    }

    tr.partition-row>td:last-child {
      padding: 15px 13px;
    }

    td {
      text-align: left;
      padding   : 20px 5px 15px;
      // border :none;
    }

    td:first-child {
      padding-left: 30px;
    }

    >tr.expanded {
      background-color: #fff;
      border          : none;

      >td:first-child {
        padding-left: 0;
      }

      >td.partition-header {
        padding         : 0;
        background-color: #FAFAFA;
      }
    }

    &.opened::after,
    &.opened::before,
    &.active::after {
      content         : " ";
      display         : block;
      height          : 20px;
      background-color: var(--canvas);
    }

    &.opened+tbody.opened::before {
      display: none;
    }

    &:first-child::before {
      display: none;
    }

    &:first-child {
      height: 200px;
    }

    td.scenario {
      td {
        padding-top   : 10px;
        padding-bottom: 10px;
        padding-right : 40px;
        color         : #7286a2;
      }

      td:nth-child(1) span {
        padding-right: 30px;
        position     : relative;
        text-align   : center;
        color        : rgba(114, 134, 162, .8);
      }

      td:nth-child(1) span::after {
        content         : " ";
        width           : 18px;
        height          : 18px;
        background-color: rgba(114, 134, 162, .15);
        border-radius   : 11px;
        position        : absolute;
        top             : -2px;
        left            : -5.6px;
      }
    }

    .panel-table {
      width: auto;
    }
  }

  .panel-table th {
    color: #bbb;
  }
}

.campaign-overview+.tiny.table>tbody::before {
  display: none !important;
}

.label {
  padding  : 0.4em 1em .3em;
  font-size: 90%;
}

.label-success {
  background-color: #7c91ac;
}

.label-default {
  background-color: #BAC5D4;
}

//XXX remove after Jay's new styling
.partitionset-name,
.campaign-id {
  margin-left: 10px;
}

/* Campaign Title */

td.partionset-row-header {
  font-size: 16px;
}

div.redesign>div>div>table.tiny.table>tbody td.partionset-row-header:first-child {
  padding-left  : 30px;
  padding-top   : 13px;
  padding-bottom: 13px;
}

span.campaign-detail {
  display   : block;
  font-size : 10px;
  margin-top: 4px;
}

span.campaign-detail span {
  font-size       : 10px;
  font-weight     : bold;
  background-color: #f5f5f5;
  padding         : 3px 10px;
  border-radius   : 5px;
  color           : #999;
  margin-left     : 0;
  margin-right    : 10px;
}

.redesign .partition-header h5 {
  display: none;
}

.hidden-json-button {
  position: fixed;
  top     : 68px;
  left    : 80px;
  z-index : 3000;
  width   : 20px;
  height  : 20px;
}

.inline-lock {
  padding-bottom: 6px;
}

.inline-lock p:nth-child(2)+input {
  margin-bottom: 35px;
}

.secret-custom-scenario {
  width   : 60px;
  height  : 40px;
  position: fixed;
  top     : 85px;
  z-index : 9999;
  right   : 0;
}

.scenario-json-textarea {
  width: 100%;
}

.scenario-code-container {
  position: relative;

  pre {
    max-height: 200px;

    &.expanded {
      max-height: none;
    }
  }

  button.btn.custom-icon.i-expand.i-md {
    position       : absolute;
    background-size: 15px 15px;
    width          : 30px;
    height         : 30px;
    top            : 0;
    right          : 0;
  }
}

.scenario.panel textarea {
  height : 200px;
  padding: 15px;
}

.no-campaigns-message {
  font-size    : 16px;
  margin-bottom: 20px;
}

.icon-wrapper {
  width  : 46px;
  height : 46px;
  display: inline-block;

  .anticon-copy:before {
    color      : black;
    opacity    : 0.4;
    font-size  : 19px;
    padding-top: 13px;
  }
}

.icon-wrapper:hover {
  cursor          : pointer;
  background-color: #397AE4;

  .anticon-copy:before {
    color  : white;
    opacity: 0.7;
  }
}

.scenario .icon-wrapper,
.partitionset .icon-wrapper {
  padding-left: 13px;
}

.scenario .buttons-group,
.partitionset .buttons-group {
  float: right;
}

.buttons-group {

  >div,
  >button {
    float: left;
  }
}

.campaigns-table-wrapper {
  .campaigns-table-header {
    float: left;
  }

  h4 {
    color    : #337ab7;
    font-size: 20px;
  }

  >table {

    >tbody {
      border-top: none;
    }
  }
}

// SHARED STYLES

.message-to-preview {
  border-bottom: 1px dashed #7286a2;
}