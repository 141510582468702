@fallback-fonts: Arial, Helvetica, sans-serif;

.redesign {
  @images-path: "../../../images/";

  .code-container {
    overflow     : auto;
    border       : 1px solid #cccccc;
    border-radius: 3px;
    z-index      : 2000;

    &.stats {
      width     : 529px;
      max-height: ~"calc(100% - 178px)";
      top       : 67px;
      position  : fixed;
      left      : 1120px;
    }

    pre {
      border: none;
    }
  }

  .non-clickable-icon.i-mobile {
    background-image:i url('@{images-path}icon-mobile.png');
  }

  .non-clickable-icon.i-desktop {
    background-image: url('@{images-path}icon-desktop.png');
  }

  .non-clickable-icon.i-desktop-and-mobile {
    background-image: url('@{images-path}icon-desktop-and-mobile.png');
  }

  .non-clickable-icon.i-mobile-dark {
    background-image: url('@{images-path}icon-mobile-dark.png');
  }

  .non-clickable-icon.i-desktop-dark {
    background-image: url('@{images-path}icon-desktop-dark.png');
  }

  .non-clickable-icon.i-desktop-and-mobile-dark {
    background-image: url('@{images-path}icon-desktop-and-mobile-dark.png');
  }

  .non-clickable-icon {
    background-size    : 17px 17px;
    background-repeat  : no-repeat;
    height             : 41px;
    width              : 41px;
    background-position: 15px 14px;
    opacity            : 1;
    position           : absolute;
    right              : 10px;
    top                : -5px;
  }

  .non-clickable-icon.i-desktop-and-mobile-dark,
  .non-clickable-icon.i-desktop-and-mobile {
    background-size: 19px 17px;
  }

  .non-clickable-icon.i-mobile-dark,
  .non-clickable-icon.i-mobile {
    background-size: 11px 17px;
  }




  .campaign-table-stats-container {
    width           : 1043px;
    max-height      : ~"calc(100% - 100px)";
    top             : 67px;
    position        : fixed;
    left            : 79px;
    overflow        : auto;
    border          : 1px solid #cccccc;
    border-radius   : 3px;
    background-color: white;
    z-index         : 20000;
    padding         : 27px;

    .partition-stats {
      width     : 982px;
      border    : 1px solid lightgray;
      margin-top: 30px;

      .message-table:first-child table {
        border-top: none;
      }
    }

    h4 {
      font-family : "Myriad Pro", @fallback-fonts;
      padding-left: 5px;
    }

    table.table-bordered {
      border      : none;
      width       : 100%;
      table-layout: fixed;
      font-family : "Myriad Pro", @fallback-fonts;

      thead>tr>th {
        background : #dddddd;
        color      : black;
        border     : none;
        border-left: 1px solid white;
        padding    : 5px;
        font-size  : 11px;
        text-align : center;

        &:first-child {
          text-align : left;
          border-left: none;
        }
      }

      tbody>tr {
        &.total {
          background-color: #edf1f5;
          border-top      : 1px solid #bcbcbc;

          &.post-choice,
          &.message {
            background-color: #e6ebf1;
          }
        }

        &>td {
          font-size  : 13px;
          text-align : center;
          padding    : 3px;
          border     : none;
          border-left: 1px solid white;

          &:first-child {
            text-align : left;
            border-left: none;
          }
        }

        td[colspan="5"] {
          height: 24px;
        }
      }


      &.choice {

        th:first-child,
        td:first-child {
          border-left: 1px solid white;
        }
      }
    }

    .messaging-tables,
    .post-choice-table {
      margin-top: 50px;
    }

    .message-table {

      table {
        float     : left;
        border-top: 1px solid #bcbcbc;

        &.message-view-count {
          width: 280px;
        }

        &.choice {
          width: ~"calc((100% / 7) * 5)";
        }
      }

      &:nth-child(2) table {
        border-top: none;
      }
    }
  }

  .x-close {
    position : fixed;
    top      : 65px;
    left     : 1098px;
    cursor   : pointer;
    font-size: 26px;
    z-index  : 30000;
  }
}