#avocado .diagnose_dashboard {
.diagnose_filters {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(4, minmax(150px, 1fr)) minmax(75px, 0.5fr);
    align-items: start;
    padding: var(--ant-margin) 0px;

    &.extended-grid {
      grid-template-columns: repeat(4, minmax(150px, 1fr)) minmax(120px, 0.75fr);
    }

    &.isApp{
          grid-template-columns: repeat(3, minmax(150px, 1fr)) minmax(75px, 0.5fr);
    }
    .ant-form-item {
      max-width: 100%;
    }

    .create-button {
      height: 40px;
      margin-top: 30px;
      margin-top: 30px;
      border-radius: 3px;
      border-color: transparent;
      background-color: @primary-color;
      border-color: @primary-color;
      font-size: 16px;
  
      &:hover {
        opacity: 0.8;
        transition: all 0.2s;
      }
    }

    .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
      border-color: @primary-color !important;
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      color: @primary-color;
    }
  }
}

.diagnose_filters {
    .ant-select {
        width: 100%!important;
        min-width: 100%;
        max-width: 100%;
    }
}

@media (max-width: 1120px) {
    #avocado .diagnose_dashboard #diagnose_filters {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) minmax(75px, 0.5fr);
    }
}

.diagnose-modal.avocado-modal {
  div.ant-select.spsg-select-multiple .anticon.anticon-check-square  {
    display: inline-flex;
  }
}