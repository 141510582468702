.language-translation-mixin {
  .supported-language {
    margin-bottom: var(--ant-margin);
    gap: var(--ant-margin);
    display: flex;
    flex-direction: column;
    &.no-padding {
      padding: 0px;
    }
    .header-container {
      display: flex;
      align-items: baseline;
      flex-direction: row;
      margin-bottom: 15px;
    }
    .sub-heading {
      font: normal 14px/19px @primary-font;
      letter-spacing: 0.42px;
      color: #707070;
      margin-bottom: 10px;
    }
    .ant-select {
      width: 250px;
    }
    .selected-language-container {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    .site-layout-background {
      background: #ffffff;
    }
    .bulk-upload {
      margin-top: 10px;
    }
  }

  .language-tags {
    height: 34px;
    background: #e9dfff;
    border-radius: 50px;
    padding: 6px;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.13px;
    color: #272727;
    margin: 2px;
  }
  .dropdown-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: transparent;
    .heading {
      font: normal normal 600 14px/19px @primary-font;
      letter-spacing: 0.42px;
      color: #434343;
      margin-bottom: 5px;
    }
  }
  .language-translation-tabs {
    .ant-tabs-nav {
      margin-bottom: 0px;
      .ant-tabs-tab {
        min-width: 120px;
        justify-content: center;
      }
      &::before {
        display: none;
      }
    }

    // .tab-title {
    //   text-align: left;
    //   font: normal normal normal 16px @primary-font;
    //   color: #272727;
    //   margin-right: 5px;
    // }
    // .tab-icon {
    //   color: #899ab4;
    //   margin-left: 5px;
    // }
    .translation-container {
      display: flex;
      flex: 1;
      border: 1px solid var(--ant-color-border);
      border-radius: 6px;
      height: 50vh;
      background-color: var(--ant-color-bg-base);
      .menu {
        flex: 0.2;
        // border: 1px solid #dedede;
        height: 100%;
        hr {
          margin: 0px;
        }
        .menu-title {
          padding: 16px;
          font: normal 600 14px/19px var(--ant-font-family);
          color: #272727;
          background: var(--ant-color-fill-quaternary);
          border-right: 1px solid var(--ant-color-border);
          height: 60px;
          border-bottom: 1px solid var(--ant-color-border-secondary);
          border-top-left-radius: 6px;
        }
      }
      .ant-menu {
        width: 320px;
        max-height: calc(-70px + 50vh);
        overflow-y: auto;
        overflow-x: hidden;
        &:not(.ant-menu-sub) {
          &::-webkit-scrollbar {
            display: none;
          }
        }
        &::-webkit-scrollbar {
          display: none; /* Safari and Chrome */
        }
        .ant-menu-item, .ant-menu-submenu {
          width: 100%;
          margin: 0;
          border-bottom: 1px solid var(--ant-color-border-secondary);
          &.selected {
            background-color: var(--ant-menu-item-selected-bg);
          }
          >div.ant-menu-submenu-title {
            margin: inherit;
            width: inherit;
            &:hover {
              background-color: inherit;
            }
          }
          .ant-menu-title-content {
            flex: auto;
            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 0;
            margin-right: 16px;
          }
          .ant-menu-item-icon {
            position: absolute;
            right: var(--ant-margin);
          }
        }
      }
      // .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
      // .ant-menu-submenu-selected {
      //   background: #e9dfff;
      //   color: #272727;
      // }
      // .ant-menu-item-active {
      //   color: #272727;
      // }
      // .ant-menu-submenu-title:hover {
      //   color: #272727;
      // }
      // .ant-menu-item::after {
      //   border: 0px;
      // }
      // .selected {
      //   background: #e9dfff !important;
      //   color: #272727 !important;
      //   font-weight: 600;
      // }
      // .notselected {
      //   background: #f8f8f8 !important;
      //   color: #272727 !important;
      //   font-weight: normal;
      // }
      .expand-icon {
        position: absolute;
        left: 5px;
        border: 1px solid @primary-color;
        color: @primary-color;
      }
      .ant-menu-sub.ant-menu-inline {
        > .ant-menu-item,
        > .ant-menu-submenu > .ant-menu-submenu-title {
          // padding-left: 30px !important;
          // line-height: 18px;
          height: auto;
          white-space: pre-wrap;
          word-break: break-word;
          padding: 5px;
          border-bottom: 1px solid var(--ant-color-border-secondary);
          font: normal 13px/20px @primary-font;
          &.ant-menu-item-selected {
            font-weight: 600;
          }
        }
      }
    }
    .content {
      flex: 0.8;
      flex-direction: column;
      display: flex;
      // .ant-table .translation-column {
      //   font: normal 14px/20px @primary-font;
      //   flex: 1;
      // }
      .ant-table .spsg-input {
        transition: none;
      }
      .ant-table-wrapper {
        width: 100%;
        flex: 1;
        border-left: 1px solid var(--ant-color-border-secondary);
        .ant-table-body {
          height: calc(-70px + 50vh);
        }
        table {
          table-layout: auto !important;
        }
        table thead > tr > th {
          height: 60px;
        }
        .ant-table-tbody > tr > td {
          border-bottom: 0px;
          &.language-name {
            min-width: 200px;
            width: 200px;
          }
        }
        span {
          transition: none;
        }
        .icon-container {
          display: flex;
          flex-direction: row;
        }
        .icon {
          margin: 2px;
        }
        .no-translation {
          font: italic normal 14px/20px @primary-font;
          color: #bbbbbb;
        }
        .redo {
          transform: rotate(45deg);
        }
        .default {
          font: italic normal 12px @primary-font;
          color: #707070;
        }
      }
      .footer {
        border: 1px solid #d3d9dd;
        border-radius: 0 0 5px 0;
        background: white;
        padding: 5px 15px;
        width: 100%;
        display: flex;
        align-items: center;
        .message-tone-container {
          display: flex;
          align-items: center;
          flex: 1;
          height: 34px;
          .anticon-info-circle {
            margin-left: 5px;
          }
          span {
            font-weight: 600;
          }
        }
      }
      .default-tone-container {
        display: flex;
        align-items: center;
        padding: 10px;
        background: #e1faff;
        border: 1px solid #dee2e5;
        border-radius: 0px 0px 5px 0px;
        letter-spacing: 0.42px;
        color: #272727;
        .anticon-info-circle {
          margin-left: 5px;
        }
        span {
          font-weight: 600;
        }
        span.anticon-info-circle.usp-infotip svg {
          color: #58b5ff !important;
          margin-right: 10px;
        }
      }
    }
    .empty-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }
}

.ant-modal-wrap.spsg-modal.bulk-upload {
  .ant-modal-body {
    padding: 10px;
  }
  .ant-select {
    width: 300px;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .main-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    .sub-title {
      text-align: left;
      font: normal 600 18px/26px @primary-font;
      color: #272727;
    }
    .dropdown-container {
      display: flex;
      margin-top: 15px;
      .select-container {
        flex: 1;
        .spsg-select {
          width: 90% !important;
        }
      }
    }
    .csv-container {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .success-alert {
        margin-left: 20px;
        flex: 0.9;
        svg {
          color: white;
          border-radius: 100%;
          background: #43ba54 0% 0% no-repeat padding-box;
          width: 18px;
          height: 18px;
          margin-top: 0px;
          padding: 4px;
          transform: translateY(2px);
        }
        .success-text {
          font-family: @primary-font;
          color: #43ba54;
          padding-left: 6px;
        }
      }
    }
    .upload-container {
      margin-top: 10px;
      .upload-title {
        font: normal 600 16px/12px @primary-font;
        color: #272727;
      }
      .sub-title {
        font: normal 14px/12px @primary-font;
        color: #272727;
        margin-top: 10px;
      }
      .upload-div {
        display: block;
        margin-top: 10px;
        background: #e9dfff 0% 0% no-repeat padding-box;
        height: 200px;
        font: normal 600 16px/12px @primary-font;
      }
    }
  }
}

.spsg-popconfirm.usp-regulation-popconfirm {
  .ant-popover-inner-content {
    width: 300px;
  }
}

.ant-modal-wrap.preview-modal {
  .ant-modal-body {
    padding: 0px;
    margin: 10px;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .ant-modal-content {
    border-radius: 10px;
    .sub-title {
      text-align: left;
      font: normal 14px/20px @primary-font;
      letter-spacing: 0.42px;
      color: #272727;
      padding: 5px;
    }
    .section-tabs {
      flex: 1;
      display: flex;
      align-items: center;
      z-index: 2;
      position: sticky;
      top: 0;
      background: #fff;
      button {
        border-radius: 5px;
        color: #434343;
        margin: 10px;
        height: 32px;
        padding: 0px 16px;
      }
      .active {
        background: #e1ffe5;
        mix-blend-mode: darken;
        color: #434343;
        font: normal 600 14px/19px @primary-font;
        border: 1px solid #d5ffdb;
      }
      .inactive {
        border: 1px solid #d4d4d4;
        border-radius: 5px;
        font: normal normal normal 14px/19px @primary-font;
        color: #434343;
        background: #fff;
      }
    }
    .msg-container {
      background: #fafafa;
      border: 1px solid #dbdbdb;
      border-radius: 9px;
      margin: 10px;
      .selected {
        background: #e1ffe5;
      }
      .not-selected {
        background: #fff;
      }
      .desc-container {
        border-radius: 5px;
        padding: 10px;
        font: normal 14px/18px @primary-font;
        color: #272727;
      }
      .privacy-container {
        ul {
          height: 250px;
          overflow-y: scroll;
        }
        .bullet-container {
          border-radius: 5px;
          margin: 5px;
          padding: 5px;
          display: flex;
          align-items: center;
        }
      }
      .privacy-link {
        margin: 10px;
      }
    }
  }
}
