@import './Checkbox.less';

.spsg-tree{
  .search-select-all-container{
    margin: var(--ant-margin-xs);
    label.ant-checkbox-wrapper{
      gap: 12px;
      margin-left: 24px;
      span{
        padding: 0px;
      }
    }
  }

  .ant-tree{
    overflow-y: scroll;
    overflow-x: clip;
    height: 400px;
  }
  .ant-tree-switcher-leaf-line {
    display: none;
    // &::after {
    //     display: none;
    // }
    // &::before {
    //     display: none;
    // }
  }
  // .ant-tree-show-line .ant-tree-indent-unit::before {
  //   display: none;
  // }

  // .ant-tree-checkbox  {
  //   position: absolute;
  //   right: 10px;
  // }
  .ant-tree-list {
    width: 300px;
    .ant-tree-list-holder {  
      .ant-tree-treenode.ant-tree-treenode-switcher-open {
        position: sticky;
        z-index: 10;
        background: inherit;
        top: 0;
        width: 300px;
        background-color: #fff;
      }
    }
  }
  .ant-tree-switcher{
    >span>svg {
      color: @primary-color;
    }
  }
}
