@import "./diagnose.css.less";
.cookie-details-modal{
  .ant-modal {
    .ant-modal-close {
      top: 18px;
      right: 24px;
      .ant-modal-close-x {
        padding: 8px;
        margin: 0;
        svg {
          color: white;
        }
      }
    }
    .ant-select{
      margin-bottom: 0;
    }
    .ant-spin-dot-item{
      background-color: @primary-color;
    }
    .ant-modal-content{
      border-radius: 10px;
      padding: 0;
      .ant-modal-header{
        border-radius: 10px 10px 0 0;
        background-color: @primary-color;
        padding: 16px 24px;
        .ant-modal-title{
          color: white;
          font-size: 20px;
          font-weight: 200;
          span {
            color: #9c9c9c;
            font-size: 14px;
          }
        }
      }
      .ant-modal-body{
        font-size: 14px;
        padding: 8px 24px 16px;
        .ant-btn-primary{
          background-color: @primary-color;
          border-color: @primary-color;
          font-size: 14px;
          &:hover{
            opacity: 0.8;
            transition: all 0.2s;
          }
        }
        .ant-select{
          min-width: 250px;
          .ant-select-selector{
            height: 40px;
            border-radius: 5px;
            box-shadow: 0px 2px 3px @boxshadow-color;
          }
          &.ant-select-focused, &:hover{
            .ant-select-selector{
              border-color: @primary-color;
            }
          }
        }
        .ant-table{
          font-size: 12px;
          .ant-table-thead > tr > th{
            background-color: #e9dfff;
            font-size: 12px;
            font-weight: 600;
            text-transform: capitalize;
            color: @font-color;
            border: 1px solid @table-border-color;
            padding: 20px 20px 4px 16px;
            position: relative;
            &:not(:last-child){
              border-right: none;
            }
            .ant-table-column-sorter{
              color: #957fc7;
              .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active{
                color: @primary-color;
                font-size: 18px;
              }
            }
          }
          .ant-table-tbody > tr > td {
            border: 1px solid @table-border-color;
            border-top: none;
            padding: 5px 10px;
            &:not(:last-child){
              border-right: none;
            }
          }
          .anticon.anticon-info-circle{
            color: @info-circle-color
          }
          .ant-table-filter-trigger.active{
            color: @primary-color;
          }
          .ant-dropdown-trigger{
            font-size: 8px;
            .anticon {
              padding: 0;
            }
          }
          .ant-table-filter-trigger{
            color: #6565A9;
            top: 4px;
            right: 4px;
            border: 1px solid #6565A9;
            height: 16px;
            padding: 3px;
            position: absolute;
            margin: 0;
            svg{
              height: 8px;
            }
          }
        }
        .ant-pagination{
          .ant-pagination-item{
            border-radius: 4px;
          }
          .ant-pagination-item-active{
            border-color: @primary-color;
            color: @primary-color;
          }
          .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
            border-radius: 4px;
          }
          .ant-pagination-item-active a{
            color: @primary-color;
          }
          .ant-pagination-options{
            .ant-select{
              min-width: 120px;
              .ant-select-selector{
                height: 32px;
              }
            }
          }
          .ant-pagination-item:focus-visible, .ant-pagination-item:hover{
            border-color: @primary-color;
            a {
              color: @primary-color;
            }
          }
        }
        .filters_block{
          margin-bottom: 10px;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          button.ant-btn {
            border-color: @primary-color;
            color: @primary-color;
            border-radius: 10px;
            &:not(:first-child){
              margin-left: 10px;
            }
            .anticon{
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.cookie-details-property-applied-dropdown .rc-virtual-list{
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color: #f6f2ff;
  }
}
