button.show-alt-pay-sold {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999999;
}

.redesign .code-container.altpay {
  position: fixed;
  left: 66px;
  right: 0;
  top: 127px;
  height: ~'calc(100% - 127px)';
  background-color: #f5f5f5;
}
