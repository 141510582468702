@sp-blue: #3D7DE1;
@fallback-fonts: Arial, Helvetica, sans-serifl;
@primary-font: Open Sans;

.secret {
    display:none;
}

.clickable {
    cursor: pointer;
}

.clearfix {
    clear:both;
}

input[type="radio"] {
    display:none;
}

input[type="radio"] + label span {
    display:inline-block;
    width:21px;
    height:21px;
    margin:-3px 10px 0 0;
    vertical-align:middle;
    cursor:pointer;
    -moz-border-radius:  50%;
    border-radius:  50%;
}

input[type="radio"] + label:hover, input[type="radio"] + label span:hover {
    cursor:pointer;
}

input[type="radio"] + label span {
     background-color:#fff;
     border:1px solid #979797;
}

input[type="radio"]:checked + label span{
     background-color: @sp-blue;
     border:1px solid #979797;
     box-shadow: inset 0px 0px 0px 4px #fff;
}

input:focus, textarea:focus, select:focus, button:focus, div:focus
{
    outline: none;
}

.options-error, .options-error:focus
{
    border:1px solid red;
    border-left-width:3px;
}

.options-color-picker.options-error input[type="text"]:focus,
.options-copy-font-size-and-opacity.options-error input:focus
{
    border-left:none;
    padding-left:12px;
}

.choice-option
{
  margin-top: 15px;
}

#avocado {
    .btn-primary {
        background-color: @sp-blue;
    }

    .btn-primary:hover {
        background-color: #366BBF
    }

    .btn-create {
        float: right;
        margin-bottom: 20px;
    }

    table {
        //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
        // font-size: 0.929em;

        // thead > tr > th {
        //     font-size: 0.714em;
        //     font-weight: 600;
        //     color: #9B9B9B;

        //     text-transform: uppercase;
        //     letter-spacing: 1px;
        // }

        tbody > tr > td {
             //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
            // padding-top: 10px;
            // padding-bottom: 10px;

            &.status {
                color: #3FCC97;
                font-weight: 600;
                min-width: 223px; // hack!

                span {
                    display: none;
                }
            }
        }
    }

    td.plus-minus {
      font-weight: bold;
      font-family: monospace;
      color: #9B9B9B;
    }

    tbody.active {
        background-color: #F2F5FC;
    }

    .badge {
        padding: 6px 6px;
        text-transform: uppercase;
        border-radius: 3px;

        &.published {
            width: 52px;
            background-color: #3FCC97;
        }
        &.draft {
            width: 52px;
            border: 1px solid #BAC5D4;
            color: #BAC5D4;
            background-color: #fff;
        }
    }

    .manage-add {
        .placeholder(@rules) {
            &::-webkit-input-placeholder { @rules(); }
            &:-moz-placeholder { @rules(); }
            &::-moz-placeholder { @rules(); }
            &:-ms-input-placeholder { @rules(); }
        }

        text-align: center;

        input {
            font-size: 2.5em;
            padding: 55px 75px;
            border: none;

            .placeholder({
                font-style: italic;
                color: #C1C1C1;
            })
        }

        .btn {
            margin-bottom: 15px;
        }
    }

    .redesign .manage-add {

        border: 1px solid #ddd;
        border-radius: 3px;
        background-color: white;
        display: flex;
        margin-bottom: 20px;


        input {
        	  flex-grow: 1;
            font-size: 1.5em;
            padding: 14px 20px 8px;
            border-radius: 3px;
            font-weight: 100;
            font-family: "Myriad Pro", @fallback-fonts;
        }

        input:focus {
        	  box-shadow: 3px 0px 0px #397AE4 inset;
        }

        button {
          	margin-bottom: 0;
          	border-radius: 2px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            font-weight: bold;
            font-size: 18px;
        }
    }

    .redesign h3 {
          font-family: "Myriad Pro", @fallback-fonts;
          font-weight: 100;
          color: #333;
          margin-bottom: 15px;
    }

    .redesign h1 {
      	position: fixed;
        top: 13px;
        left: 219px;
        z-index: 10000;
        font-size: 17px;
        padding-left: 10px;

        i {
        	  font-style: normal;
        }
    }
    //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
    // h5 {
    //     font-size: 1.2em;
    // }

    .partition {
        padding: 10px;
        margin-bottom: 30px;
        background-color: #fafafa;
        border: 1px solid #f5f5f5;
        border-radius: 5px;
    }
}

.partition-header > div > table > thead {
    tr > td:nth-child(2),
    tr > td:nth-child(3),
    tr > td:nth-child(4) {
        width: 15%;
    }

    tr > td:nth-child(6) {
    	  width: 60px;
    }
}

select {
  	border-radius: 3px;
  	min-width:150px;
  	background-color: #fff;
    border: 1px solid #abbdcc;
    height: 33px;
}

.ant-modal.site-group-error {
  margin-top: -80px;
}
