@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');

.spsg-modal > .ant-modal {
  transition: width 0.2s linear;
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-content {
    border-radius: 12px;
    overflow: hidden;
    padding: 0px;
  }
  .spsg-modal-actions {
    text-align: right;
    > button {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .ant-modal-header {
    background: #411F90;
    border: 0;
    padding: 14px 20px;
    margin-bottom: 0px;
    .ant-modal-title {
      font-family: Open Sans;
      font-size: 20px;
      font-weight: normal;
      color: #fff;
    }
  }

  .ant-modal-close {
    right: 20px;
    top: 9px;
    margin: 0;
    height: 32px;
    width: 32px;
    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      background: transparent;
      width: 32px;
      border-radius: 16px;
      &:hover {
        opacity: 0.7;
      }
      .anticon {
        padding-right: 0 !important;
      }
      svg {
        font-size: 16px;
        color: #FFF !important;
      }
    }
  }

  .ant-modal-footer {
    padding: 12px 30px 30px 30px;
    border: 0;
    margin-top: 0px;
    button {
      margin-left: 20px;
    }
    button.ant-btn {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #090E15;
      padding: 9px 20px;
      height: 40px;
      box-shadow: none;
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      border-radius: 5px;
      margin-left: 20px;
      min-width: 92px;
      &:hover {
        color: #090E15;
        background: #F5F7FB;
        border: 1px solid #D7DDE9;
      }
      &:focus {
        color: #090E15;
        background: #E7ECF4;
        border: 1px solid #E7ECF4;
      }
      &[disabled] {
        opacity: 1.0 !important;
        color: #ADB5C5 !important;
        background: #E7ECF4 !important;
        border: 1px solid #E7ECF4 !important;
      }
      > svg {
        font-size: 16px;
        margin-right: 10px;
      }
      &.ant-btn-primary, &.btn-primary {
        background: #411F90;
        border: 1px solid #411F90;
        color: #fff;
        &:hover {
          color: #fff;
          background: #6565A9;
          border: 1px solid #6565A9;
        }
        &:focus {
          color: #fff;
          background: #6565A9;
          border: 1px solid #6565A9;
        }
        &[disabled] {
          opacity: 0.6 !important;
        }
      }
      &.btn-secondary {
        background: #fff;
        border: 1px solid #411F90;
        color: #411F90;
        &:hover {
          color: #6565A9;
          background: #fff;
          border: 1px solid #6565A9;
        }
        &:focus {
          color: #6565A9;
          background: #fff;
          border: 1px solid #6565A9;
        }
        &[disabled] {
          opacity: 0.7 !important;
        }
      }
    }
  }

}

.spsg-modal-close {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #090E15;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    background: #e5e7eb;
  }
}

.ant-modal-wrap.spsg-modal.operation-confirmation {
  .error-wrapper {
    font-family: Open Sans;
    display: flex;
    .anticon-warning {
      font-size: 20px;
      color: #f5222d;
    }
    .message-block {
      font-size: 14px;
      padding: 0 10px;
      color: #000;
      .note {
        margin-top: 14px;
        font-size: 14px;
        font-weight: normal;
      }
    }
    span.anticon-warning {
      display: none;
    }
  }
  .ant-modal-footer {
    padding: 10px 16px;
    text-align: right;
    background: transparent;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    .ant-btn {
      font-family: Open Sans;
      &:first-child {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: none;
        letter-spacing: 0;
        color: #411f90;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .ant-modal .ant-modal-content {
    border-radius: 10px;
  }
}

.ant-modal-wrap.spsg-modal.operation-confirmation.dismiss-error {
  .ant-modal{
    width: 730px !important;
  }
  .ant-modal-close {
    .ant-modal-close-x{
      background-color: #411F90;
      svg {
        font-size: 16px;
        color: #FFF !important;
      }
    } 
  }
  .ant-modal-header {
    background-color: #411F90;
    .ant-modal-title {
      font-weight: 300;
      color: #FFF;
      font-family: 'Open Sans';
    }
  }
  .primary-action-class {
    background-color: #411F90;
    border: 1px solid #411F90;
    color: #fff;
  }
}

.ant-modal-wrap.spsg-modal.warning-message {
  .ant-modal {
    min-width: 520px;
    max-width: 60%;
    margin: auto;
    width: fit-content !important;
    .ant-modal-content {
      .ant-modal-body {
        svg{
          color: red;
        }
      }
    }
  }
  .ant-btn-primary {
    background: #411F90;
    border: 1px solid #411F90;
    color: #fff;
    font: normal 400 16px, 'Open Sans';
    line-height: 21px;
    text-align: center;
    padding: 9px 16px;
    height: 40px;
    box-shadow: none;
    border-radius: 5px;
  }
  .ant-btn-default{
  font: normal 400 16px Oswald;
  line-height: 21px;
  text-align: center;
  color: #090E15;
  padding: 9px 16px;
  height: 40px;
  box-shadow: none;
  background: #FFFFFF;
  border: 1px solid #D7DDE9;
  border-radius: 5px;
  }
}

.ant-modal.new-standard .ant-modal-content .ant-modal-body .modal-new-standard{
  padding: 0;

.new-set-container {
  border: none;
  padding: 0;
  margin: 0;

  .action-title{
   font-size: 14px;
  }
  .add-properties{
    padding: 5px 0px; 
    cursor: default;
  }

  .wrap {
    margin-top: -8px;
    .arrow, .arrow-cover{
      left: 25px;
    }
    .box{
      a{
        text-decoration: none;
      }
    }
  }

  .warning-url{
    padding-left: 10px;
    vertical-align: middle;
  }
  .delete-icon {
    float: right;
    height: 16px;
    transform: scale(0.9);
    cursor: pointer;
  }
  .warning-text{
    font-size: 14px;
    width: auto;    
  }
  }
}

.ant-modal-root > .ant-modal-wrap > .ant-modal.clone-modal {
  width: 730px !important;
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-body {
  
      .clone-modal-container {

        .clone-modal-name{
          font-size: 16px;
          font-weight: 600;
          font-family: 'Oswald';
        }
        .warning-message{
          background: #FBFFD6 0% 0% no-repeat padding-box;
          border-radius: 4px;
          font: normal 15px Open Sans;
          color: #3B3B3B;
          padding: 10px;
          margin-top: 10px;
          .icon {
            color: #ff9100;
            margin-right: 10px;
            font-size: 18px;
          }
        }
        p {
          margin-top: 20px;
          text-align: left;
          font: normal normal normal 14px/20px Open Sans;
          letter-spacing: 0px;
          color: #000000;
        }
        .clone-modal-input {
          margin-top: 5px;
          max-width: 350px;
        }
      }
    }
    .ant-modal-footer {
      border-top: none;
      .spsg-btn {
        &.cancel {
          border: none;
          color: #411F90;
          background: transparent;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}


.fullscreen-modal {
  max-width: 100vw;
  margin: 0;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  padding: 0;
  .ant-modal-body {
    background-color: #f5f5f5;
    padding-bottom: 16px;
    .scan-details-progress{
      width: 200px;
      padding: 10px;
    }
  }
  .ant-modal-content {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100vw;
    height: 100%;
  }
  .ant-modal-body {
    flex: 1;
    padding-bottom: 0;
  }
  .ant-modal-footer {
    background-color: white;
    padding: 12px 24px;
  }
}
