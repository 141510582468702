  .header-container {
    display: flex;

    div {
      display: flex;

      p.title {
        font-weight: bold;
      }
    }

    input {
      width: 300px;
      position: absolute;
      right: 20px;
    }
  }

  .ant-table-wrapper.dsar-requests {

    .ant-table-row.not-clickable:hover td {
      background-color: transparent;
    }

    .ant-table-row.clickable {
      cursor: pointer;
    }

    td .ant-checkbox-wrapper,
    td .ant-checkbox.ant-checkbox-checked, td input[type="checkbox"], td .ant-checkbox-input {
      cursor: not-allowed;
    }

    table thead > tr > th {
      font-size: 10px;
      color: black;
      background-color: #d8edf8;
    }
  }

  .ant-upload-list-item.ant-upload-list-item-done {
    display: none; 
  }
  .ant-upload-list-item.ant-upload-list-item-done:last-child {
    display: block 
  }

  .ant-modal.dsar-document-modal {
    .ant-modal-close-x {
      color: white;
      margin: 10px;
    }

    .ant-modal-body {
      margin: 0;
      padding: 10px;

      p.file-message {
        border: 1px solid #118000cf;
        border-radius: 5px;
        color: 118000cf;
        color: #118000cf;
        padding: 10px;
      }
  
      p.request-id {
        text-align: center;
        margin-bottom: 30px;
      }
  
      button:first-child {
        margin-right: 20px;
      }
  
      p.title {
        font-weight: bold;
        background-color: #212F43;
        color: white;
        padding: 15px;
        border-radius: 5px;
        text-transform: capitalize;
        margin-right: 0;
      }
    }
  }
