:root {
	--metallic-blue: #566c87;
	--white: #ffffff;
	--main-input-grey: #293547;
	--bluey-grey: #8699b3;
	--dark-grey-blue: #354052;
	--dark-sky-blue: #3d7de1;
	--dark-three: #1c2430;
	--dark-blue-grey: #20314a;
	--grey-blue: #677ea7;
	--dark-four: #161d26;
	--greenish-teal: #3fcc97;
	--light-navy-blue: #2c518a;
	--dark-grey-blue-two: #37465c;
	--hover-border: #40526e;
	--hover-greenish-teal: #33a67a;
	--has-error: #FE2B59;
	--blue-hover: #3265b8;
	--hover-disabled-grey: #232D3C;
	--text-color-light-grey: #acbdca;
}

/* Text styles */

.large-button-dark {
	font-family: ProximaNova;
	font-size: 16px;
	font-weight: 600;
	line-height: 0.88;
	text-align: center;
	color: #8699b3;
	color: var(--bluey-grey);
}

.large-button-light {
	font-family: ProximaNova;
	font-size: 16px;
	font-weight: 600;
	text-align: center;
	color: #ffffff;
	color: var(--white);
}

.accordian-selected {
	font-family: MyriadPro;
	font-size: 14px;
	font-weight: 600;
	letter-spacing: 1px;
	color: #ffffff;
	color: var(--white);
}

.100-width-text {
	font-family: ProximaNova;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	color: #8699b3;
	color: var(--bluey-grey);
}

.message-saved-notice {
	font-family: MyriadPro;
	font-size: 14px;
	color: #3fcc97;
	color: var(--greenish-teal);
}

.accordian {
	font-family: MyriadPro;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 1px;
	color: #8699b3;
	color: var(--bluey-grey);
}

.small-button {
	font-family: ProximaNova;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.0;
	color: #8699b3;
	color: var(--bluey-grey);
}

.input-label {
	font-family: ProximaNova;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.0;
	color: #ffffff;
	color: var(--white);
}

.legend-text-active {
	font-family: ProximaNova;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.0;
	text-align: center;
	color: #3d7de1;
	color: var(--dark-sky-blue);
}

.legend-text {
	font-family: ProximaNova;
	font-size: 12px;
	font-weight: 600;
	line-height: 1.0;
	text-align: center;
	color: #8699b3;
	color: var(--bluey-grey);
}

.text-style-14 {
	font-family: ProximaNova;
	font-size: 12px;
	font-weight: 600;
	text-align: right;
	color: #8699b3;
	color: var(--bluey-grey);
}

.input-text-small {
	font-family: ProximaNova;
	font-size: 12px;
	line-height: 1.5;
	color: #ffffff;
	color: var(--white);
}

.text-style-11 {
	font-family: ProximaNova;
	font-size: 12px;
	text-align: center;
	color: #ffffff;
	color: var(--white);
}

.small-input {
	font-family: ProximaNova;
	font-size: 12px;
	line-height: 1.17;
	color: #ffffff;
	color: var(--white);
}

.input-text-small-centered {
	font-family: ProximaNova;
	font-size: 12px;
	line-height: 1.5;
	text-align: center;
	color: #ffffff;
	color: var(--white);
}
