.create-new-partitionset {
  .ant-modal {
    width: 750px !important;
    .error-msg {
      color: red; 
      margin-top: 1%; 
    }
    .ant-modal-close-x {
      color: #fff;
    }
    .ant-modal-header {
      background-color: #212f43;
      .ant-modal-title {
        color: #fff;
        font-weight: 100;
      }
    }
    .campaign-types-select-dropdown {
      width: 250px;
    }
    .description-wrapper {
      display: flex;
      align-items: center;
      .ant-input {
        width: 100%;
        margin: 0 2% 0 0.5% !important; // overriding needed
      }
      .add-partition-btn:hover {
        background-color: #6565A9;
      }
    }
    .ant-modal-footer .ant-btn-primary {
      background-color: #7f92bd;
      border: 1px solid #7f92bd;
      color: #fff;
      font-weight: 100;
    }
    .ant-divider-horizontal {
      margin: 20px 0;
    }
    .partitions-wrapper {
      margin-top: 20px;
      padding: 2%;
      background: #F4F6F8;
      .add-partition-label {
        font-weight: bold;
      }
      .table {
        margin-top: 10px;
        tr > th {
          letter-spacing: 0.12px !important; //overriding needed
          color: #4a4a4a !important; //overriding needed
          border-bottom: 2px solid #a29e9e2e;
          width: 33%;
        }
        tr > td {
          border-top: none;
        }
        input[type='text'],
        input[type='number'] {
          -webkit-appearance: auto;
          width: 100%;
          height: 30px;
        }
        select {
          height: 30px;
          width: 100%;
        }
        .add-partition-btn {
          color: #ffffff;
          font-size: 13px !important; // overriding needed
          background: #411F90;
          height: 20px;
          width: 20px;
          min-width: auto;
        }
        .add-partition-btn:hover{
          background-color: #6565A9;
          border-color: #6565A9;
        }
        td.no-action-delete > .avo-sp-delete{
          opacity: 0 !important; // overriding needed
          cursor: auto;
        }
        td:last-child{
          display: flex;
          align-items: center;
          border: none;
        }
      }
    }
  }
  #select-campaign-type{
    display: flex;
    align-items: center;
    .campaign-type-label {
      color: #272727;
      font-weight: 500;
      margin-right: 1%;
    }
    .ant-select{
      margin-bottom: 0;
      .ant-select-selector{
        display: flex;
        align-items: center;
        height: 35px;
      }
    }
  }
}

.partitionsets-container {
  .partitionset .panel-heading,
  .partition-campaign-type, .campaign-type-value, 
  #set-campaign-type, #set-type, .buttons-group, .ant-select-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  }
  .sort-filter-wrapper{
    .spsg-input{
      width: 100%;
      margin-right: 10px;
      .ant-input-affix-wrapper{
        height: 35px;
        padding: 5px; 
      }
      .ant-input-affix-wrapper:hover,
      .ant-input-affix-wrapper-focused{
        border-color: #43BA54;
        box-shadow: none;
      } 
    }
    .ant-btn{
      display: flex;
      align-items: center;
      margin-right: 5px;
    }
  }
  #partiton-sets .partitionset{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #0000001F;
    border: 1px solid #D4D4D4;
    border-radius: 18px;
    max-width: 100%;
    .panel-heading{
      padding: 10px 0px;
      margin: 10px 15px;
      .partition-campaign-type{
        h3{
          font: normal normal 600 16px Open Sans;
          letter-spacing: 0px;
          color: #000000;
          margin: 0;
        }
        .partitonset-name{
          font: normal normal normal 14px Open Sans;
        }
        .campaign-type-label{
          font: normal normal 600 14px Open Sans;
          letter-spacing: 0px;
          color: #000000;
          text-transform: none;
        }
      }
    }
    .panel-body{
      margin: 15px;
      opacity: 0.7;
      table {
        thead > tr {
          background: #E9DFFF;
          > th {
            height: 35px;
            width: 33%;
            font: normal normal 600 12px Open Sans;
            letter-spacing: 0px;
            color: #2D2D2D;
            text-transform: none;
            padding-bottom: 10px;
          }
        }
        tbody > tr > td,
        tr:nth-child(even) > td {
          background: #F4F6F8;
        }
      } 
    }
    .panel-buttons{
      .ant-btn.spsg-btn{
        margin: 0 10px 10px 0;
      }
    }
  }
  .campaign-type-value {
    .no-type-warning{
      width: 80px;
      margin-left: 5px;
    }
    #set-type{
      margin-top: 2px;
      .ant-btn{
        height: 32px;
        line-height: 0;
        padding: 4px 7px 4px 7px;
        span {
          font-size: 13px;
        }
      }
    }
    #set-campaign-type{
      .ant-select-selector, .ant-btn {
        height: 35px;
        line-height: 0px;
      }
    }
  }
}
