@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.spsg-table {

  .ant-table-body {
    background: #fff;
  }

  .ant-table-thead > tr {
    >th, >td, >th.ant-table-column-has-sorters{
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      padding: 10px 16px;
      border-color: #c5bbdd;
      border-right: 1px solid #c5bbdd;
      background: #E9DFFF;
      text-transform: none;
      .ant-table-column-sorter {
        position: relative;
        top: 2px;
        // background: #E9DFFF;
        .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
          color: var(--ant-color-primary);
        }
      }
      .ant-table-filter-column {
        .ant-table-filter-trigger {
          >.anticon-filter{
            color: #513397;
          }
          &.active {
            >.anticon-filter{
              color: #65c582;
            }
          }
        }
      }
      &.ant-table-cell-scrollbar {
        background: transparent;
        border: 0px;
      }
      &:nth-child(1) {
        border-left: 1px solid #d3d9dd;
      }
    }
  }
  .ant-table-tbody > tr > td {
    font-style: normal;
    font-weight: normal;
    font-family: Open Sans;
    font-size: 12px;
    line-height: 18px;
    color: #272727;
    padding: 10px 12px;
    border-color: #d3d9dd;
    border-right: 1px solid #d3d9dd;

    //Ecpandible row icon
      .ant-table-row-expand-icon{
        color: #861d92;
        border: 1px solid #861d92;
      }
      .ant-table-row-expand-icon:hover{
        color: #861d92;
        border: 1px solid #861d92;
      }
  }

  // row selection
  span.ant-checkbox {
    &.ant-checkbox-checked, &.ant-checkbox-checked:hover{
      span.ant-checkbox-inner{
        background-color: var(--ant-color-primary);
        border-color: var(--ant-color-primary)
      }
      &::after{
        border-color: #411f90;
      }
    }
    &.ant-checkbox-indeterminate {
      span.ant-checkbox-inner{
        background-color: #fff;
        border-color: var(--ant-color-primary);
        &::after {
        background-color: var(--ant-color-primary);
      }
      }
    }
    &:hover .ant-checkbox-inner,.ant-checkbox-input:focus + .ant-checkbox-inner{
        border-color: var(--ant-color-primary);
    }
  }
  .ant-table-tbody > tr {
    td:nth-child(1) {
      border-left: 1px solid #d3d9dd;
    }
    &.ant-table-row-selected {
      td{
        background:  #F5F1FF;
      }
  }
  }
  .ant-table-container {
    border-color: rgba(65,31,144,0.3);
    .ant-table-content > table {
      border-top: 1px solid #c5bbdd;
    }
  }

  .ant-table-column-sorter {
    position: relative;
    top: -3px;
  }

  tr:hover td, tr.ant-table-row-hover td {
    background: #F5F1FF;
    color: #272727;
  }

  // /* PAGINATION */
  .ant-pagination {
    .ant-pagination-total-text {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .ant-pagination-options {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .ant-spin .ant-spin-dot-item {
    background-color: var(--ant-color-primary);
  }
}

.spsg-table.bulk-sets-table {
  .ant-spin-container .ant-pagination-options .ant-select{
    width: 100%;
  }
  .ant-pagination-options{
    .ant-pagination-options-size-changer{
      margin-bottom: 0;
    }
  }
  .ant-pagination{
    .ant-pagination-prev.ant-pagination-disabled,
    .ant-pagination-next.ant-pagination-disabled,
    .ant-pagination-next.ant-pagination-disabled:hover,
    .ant-pagination-prev.ant-pagination-disabled:hover{
      .ant-pagination-item-link, 
      .anticon.anticon-left, 
      .anticon.anticon-right{
        color: #ADB5C5;
        border-color:#ADB5C5;
        cursor: no-drop;
      }
    }
  }
}

.spsg-table.account-features-table{
  thead{
    height: 84px;
    max-height: 84px;
  }
  th, td {
    border: 1px solid rgba(65, 31, 144, 0.3);
  }

  thead.ant-table-thead > tr > th.ant-table-cell {
    color: #FFFFFF; 
    text-align: center;
    letter-spacing: 0px;

    .ant-checkbox-input{
      border: 2px solid #FFFFFF;
    }
  }

  thead.ant-table-thead > tr:first-child{
    th.ant-table-cell:nth-child(n) {
    height: 86px;
    max-height: 86px;
    background: #6D53A8;
    opacity: 1;
    color: #FFFFFF;
    font-size: 12px;
   

    span.ant-table-column-title{
      color: #FFFFFF;
      flex: 0.6 0;
    }

    .ant-table-filter-column {
      justify-content: center;
      align-items: center;
    
      .ant-table-filter-trigger {
        span.anticon svg {
          color: #FFFFFF;
          width: 15px;
          height: 15px;
          vertical-align: middle;
          margin-bottom: 5px;
        }
    
        &:hover, &.active:hover {
          background: transparent;
          span.anticon svg {
            color: var(--ant-color-primary-bg-hover);
          }
        }
    
        &.active span.anticon svg {
          color: var(--ant-color-primary);
        }
      }
    }    
  }
  
    th.ant-table-cell:nth-child(1){
        background: #E9DFFF;
        opacity: 1;

        span.anticon svg{
          color: var(--ant-color-primary);
          width: 12px;
          height: 12px;
          margin-bottom: 0px;
          opacity: 0.7;
        }

        span.ant-table-column-title{
          color: #2D2D2D;
          text-align: left;
          font-size: 12px;

        }
      }

      th.ant-table-cell .ant-table-column-sorters{
          position: absolute;
      }
      span.ant-table-column-title{
        color: #2D2D2D;
        text-align: left;
        font-size: 12px;

      }
  }

  thead.ant-table-thead > tr + tr{
    th.ant-table-cell:nth-child(n) {
      background: #826EB1 0% 0% no-repeat padding-box;
      opacity: 1;
      color: #FFFFFF;
      text-align: left;
      transition: all 1.5s ease cubic-bezier(0.075, 0.82, 0.165, 1);
      span.anticon svg{
        color: #FFFFFF;
      }
      span.ant-table-column-title{
        color: #FFFFFF;
        flex: 0.6 0;
      }
      .ant-table-filter-column{
        justify-content: center;
      }
    }
  }

  td.ant-table-cell{
    .ant-checkbox .ant-checkbox-inner{
      border: 1px solid #411f90;
    }
    .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner{
      background-color: var(--ant-color-primary);
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after{
      background-color: var(--ant-color-primary);
      border: 1px solid var(--ant-color-primary);
      border-radius: 2px;
    }
    .ant-checkbox.ant-checkbox-checked.ant-checkbox-indeterminate .ant-checkbox-inner{
      background-color: transparent;
    }
  }

  .ant-table-pagination-right{
    justify-content: center;
  }

  .ant-table-pagination{
    li.ant-pagination-prev {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 3px;
      opacity: 1;
      }

    li.ant-pagination-item{
      background: #FFFFFF;
      border: 1px solid #D9D9D9;
      border-radius: 3px;
      opacity: 1;
    }

    li.ant-pagination-item:hover{
      border: 1px solid var(--ant-color-primary);
    }

    .ant-pagination-item a,
    .ant-select.ant-pagination-options-size-changer{
      color: #949494;
    }

    li.ant-pagination-item-active{
      border: 1px solid var(--ant-color-primary);
      border-radius: 3px;
      opacity: 1;
    }

    li.ant-pagination-item-active a {
      color: var(--ant-color-primary);
    }

   .anticon-left,
   .anticon-right{
      color: #949191;
    }

   .ant-pagination-disabled .ant-pagination-item-link .anticon {
      color: rgba(0, 0, 0, 0.25);
      border-color: #D9D9D9;
      cursor: not-allowed;
    }
  }

}

.ant-table-wrapper {
  .ant-pagination {
    .ant-pagination-total-text {
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .ant-pagination-options {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child.selected {
    border-radius: 0px;
  }
}