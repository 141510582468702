body.index-index, body {
    @axis-width: 40px;
    @legend-height: 30px;

    @adblock-color: #6f3794;
    @injection-color: #bf5151;

    @desktop-color: #397AE4;
    @mobile-color: #35CD96;

    div.row {
        overflow: hidden;
    }

    .row {
        margin:0;
    }

    .graph-container {
        position: relative;

        .loading {
            display: none;
            z-index: 10000;
        }

        margin-bottom: 35px;
        margin-left:20px;
    }

    .graph {
        position: relative;
        left: @axis-width;

        path.desktop {
            stroke: @desktop-color;
        }

        path.mobile {
            stroke: @mobile-color;
        }
    }

    .y-axis, .y2-axis {
        text {
            fill: #949494;
            font-size: 14px;
            opacity: 1;
        }

        position: absolute;

        width: @axis-width;
        height: 100%;

        top: 0;
        bottom: 0;
    }

    .x-axis {
        .tick {
            stroke: none;
        }

        text {
            fill: #949494;
            font-size: 14px;
            opacity: 1;
        }

        position: relative;
        left: @axis-width;
        height: 20px;
    }



    .legend {
        .action {
            display: none;
        }

        font-family: 'Proxima-Nova Semibold';
        font-size: 13px;

        padding: 0;
        margin: 0;
        background-color: inherit;
        color: inherit;

        ul {
            float: right;
            li {
                display: inline-block;
            }
        }

        width: 100%;
        height: @legend-height;
        max-height: @legend-height;

        .desktop {
            color: @desktop-color;

            .swatch {
                background-color: @desktop-color;
            }
        }


        .mobile {
            color: @mobile-color;

            .swatch {
                background-color: @mobile-color;
            }
        }

    }

    .stats-container {
        @border-color: #eee;

        border-top: 1px solid @border-color;

        &:last-of-type {
            border-bottom: 1px solid @border-color;
        }
    }

    .stats-bar {
        width: 100%;

        .stat {
            height: 100px;

            display: table;

            text-transform: uppercase;

            font-size: 10px;
            font-weight: bold;

            color: #bbb;

            .content {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
            }
        }

        .title {
            font-weight: bold;
            font-size: 21px;
            line-height: 20px;
            text-transform: capitalize;

            .glyphicon {
                margin-right: 3px;
                font-size: 24px;
            }

            .content {
              text-align: left;
              padding-left: 40px;
            }

            .content span {
                vertical-align: middle;
            }

            &.desktop {
                color: @desktop-color;
            }

            &.mobile {
                color: @mobile-color;
            }
        }

        .number {
            font-weight: bold;
            font-size: 26px;
            vertical-align: middle;

            color: black;
        }

        .rate {
            color: #A00;
        }
    }
}
