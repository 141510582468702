
.avocado-modal {
    &.diagnose-modal {
      .ant-modal-close {
        right: 20px;
        .ant-modal-close-x {
          margin: 0;
          .anticon {
            vertical-align: 0;
          }
          .anticon-close, .anticon-close svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .ant-modal-content {
        border-radius: 16px;
      }
      .ant-modal-header {
        background-color: transparent;
        h4 {
          margin: 0;
  
          .ant-btn.ant-btn-default {
            border: 1px solid #411F90;
            color: #411F90;
            &.ant-btn-icon-only {
              border-radius: 10px;
              padding: 0;
              margin-left: 5px;
              height: 24px;
              width: 24px;
              align-items: center;
              justify-content: center;
              display: inline-flex;
  
              .anticon {
                width: 12px;
                height: 12px;
              }
            }
  
          }
        }
      }
      .ant-modal-body {
        label {
            font-weight: 400;
        }
        a {
          color: var(--color-primary);
        }
      }
      .ant-modal-footer {
        .actions {
          display: inline-flex;
          justify-content: flex-end;
          gap: 20px;
        }
      }
    }
  }