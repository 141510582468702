.privacy-choices-mixin() {
  .privacy-choice {
    .title {
      text-align: left;
      font: normal 600 18px/22px @primary-font;
      letter-spacing: 0.48px;
      opacity: 1;
      &.sm{
        font-size: 16px;
        margin-top: 20px;
      }
    }
    .sub-title {
      text-align: left;
      font: normal 14px/19px @primary-font;
      letter-spacing: 0.42px;
      margin-top: 20px;
      &.sm {
        margin-top: 10px;
      }
    }
    // .choice-alert {
    //   margin-top: 20px;
    //   .info-icon{
    //     font-size: 18px;
    //     position: relative;
    //     color: #58B5FF;
    //   }
    // }
    .ant-tabs {
      .container-mixin();
      &.validation-error {
        border-color: @red-color;
        margin-top: 0px;
      }
      .ant-tabs-nav{
        padding-top: 0;
        margin-bottom: 0;
      }
      .ant-tabs-content-holder{
        padding: 0px !important;
      }
      margin-bottom: 20px;
    }
    .opt-container {
      .info-message{
          text-align: left;
          font: normal 14px/19px @primary-font;
      }
      &.disabled {
        .opt-out-sub-container{
          pointer-events: none;
          opacity: 0.5;
        }
        .heading{
          display: flex;
          justify-content: space-between;
        }
      }
      margin-top: 20px;
      .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font: normal 600 14px/19px Open Sans;
        letter-spacing: 0.42px;
        color: var(--ant-color-text-secondary);
      }
      .left {
        margin-left: 10px;
        height: 12px;
        width: 12px;
      }
      .opt-in-sub-container {
        .container-mixin();
        >div{
          display: flex;
          flex: 1;
          align-items: flex-end;
          justify-content: space-between;
          margin: 5px 0px;
          padding: 5px 0px;
          &.privacy-choice-link{
            justify-content: flex-start;
            >div{
              width: 400px;
              margin-left: 20px;
              height: 32px;
              div.spsg-input{
                width: 30vw;
              }
            }
          }
        }
        .sensitive-purposes {
          flex: 0.5;
          span {
            text-align: left;
          }
          .spsg-select {
            margin-top: 10px;
            > div.ant-select.spsg-select-multiple.opt-in-select.ant-select-multiple {
              width: 300px;
            }
          }
        }
      }
      .opt-out-sub-container {
        .container-mixin();
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .box-parent {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 0.5;
        }
        .mid-text-container{
          display: flex;
          flex-direction: column;
          flex:0.5;
          align-items: center;
          .mid-text {
            flex: 0.15;
            text-align: center;
            font: italic normal 600 14px/19px @primary-font;
            letter-spacing: 0.42px;
            color: #707070;
          }
          .vertical-line {
            border-left: 1px solid lightgray;
            height: 30px;
          }
        }

        .info-text {
          text-align: left;
          font: italic normal 13px/12px @primary-font;
          color: var(--ant-color-text-secondary);
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .opt-out-sub-sub-container {
         
          >div{
            flex-direction: row;
            display: flex;
            flex: 1;
            align-items: center;
            margin: 5px 0px;
            padding: 5px 0px;
            &.privacy-choice-link{
              justify-content: flex-start;
              >div{
                width: 400px;
                margin-left: 20px;
                div.spsg-input{
                  width: 30vw;
                }
              }
            }
          }
        }
        .gpc-container {
          background: #EFF9FF 0% 0% no-repeat padding-box;
          border-radius: 5px;
          text-align: left;
          font: normal 14px/19px @primary-font;
          letter-spacing: 0.42px;
          padding: 20px;
          height: 72px;
          display: flex;
          justify-content: space-between;
          >div{
            display: flex;
            align-items: center;
            >a {
              color: var(--ant-color-link);
            }
            .anticon-export {
              color: var(--ant-color-link);
            }
          }
          &.disabled {
            background-color: #ECECEC;
          }
        }
      }
      .partition-line{
        margin: 0px;
        width: 100%;
        margin-bottom: 15px;
      }
      .box-container {
        display: flex;
        flex: 0.5;
        border-radius: 5px;
        opacity: 1;
        padding: 10px;
        font: normal 14px/19px @primary-font;
        letter-spacing: 0.42px;
        // color: @primary-color;
      }
      .empty-custom-choices{
        .container-mixin();
        display: flex;
        justify-content: center;
        align-items: center;
        height: 250px;
        >div {
          text-align: center;
          >b {
            font-size: 20px;
          }
        }
       
      }
      .ant-table-wrapper{
        .ant-table-thead > tr > th {
          &.choice-name{
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

.ant-select-dropdown.opt-in-dropdown.spsg-select-multiple-drodown .ant-select-item.ant-select-item-option .ant-select-item-option-content span.spsg-select-dropdown-label {
  white-space: normal;
  line-height: normal;
}

.opt-in-select.ant-select{
  width: 300px !important;
  .ant-select-selection-item{
      max-width: 200px;
    .spsg-select-dropdown-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.ant-select.regions-select.spsg-select-multiple{
  min-width: 360px;
}

