  button.do-not-sell-settings {
    margin-left: 10px;
  }
  .ant-modal.do-not-sell-modal {
    .ant-modal-body {
      margin-left: 20px;

      .ant-select-selection {
        width: 300px;
      }

      input {
        margin-left: 0;
        // width: ~'calc(100% - 80px)';
        width: 300px;
      }

      p.title {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 30px;
      }

      p.partition-title {
        font-size: 15px;
        font-weight: bold;
        text-decoration: underline;
        margin-top: 20px;
      }

      p.no-message {
        font-size: 11px;
        width: 300px;
        border: 1px solid lightgray;
        border-radius: 3px;
        padding-top: 10;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 5px;
      }

      p, div.site {
        font-size: 14px;
        margin-right: 15px;
      }

      .row {
        display: flex;
      }

      label {
        display: flex;
      }

      button {
        font-size: 14px;
        margin-top: 20px;
      }
    }
  }
