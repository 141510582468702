  .popover-title {
    font-size: 11px;
    display: flex;
    justify-content: center;
    font-weight: 100;
  }

  .export-dialog {
    display: flex;
    justify-content: center;
    flex-direction: "row";
  }

  .iconcsv {
    font-size: 20px;
    color: blue;
  }

  .iconexel {
    font-size: 20px;
    color: green;
  }
