  .preview-container {
    .iframe-container {
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
    iframe {
      height: 100%;
      width: 100%;
      top: 100px;
    }
    z-index: 9999;
    .preview-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #FFFFFF;
      height: 50px;

      .buttons-container {
        max-width: 1200px;
        margin: 0 auto;

        .panel-buttons {
          position: absolute;
          top: 0;
          right: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }

      .environment-container {
        position: absolute;
        top: 0;
        left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 120px;
      }

      p {
          padding-top: 20px;
          color: #000000;
          font-family: "Proxima-Nova";
          font-size: 16px;
          font-weight: bold;
          line-height: 16px;
          text-align: center;
      }
    }
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
