.vl-list-mixin() {
  .us-privacy-list-layout {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #434343;
      font-family: @primary-font;
    }
    h2 {
      padding-bottom: 10px;
      font-weight: 600;
    }
    h5 {
      padding-bottom: 30px;
      font-weight: 400;
    }
    .us-privacy-search-add {
      display: flex;
      justify-content: space-between;
      .spsg-input {
        width: 30%;
      }
      .add-new {
        margin-right: 0px;
      }
    }
    .us-privacy-table {
      width: 100%;
      .ant-table-thead > tr > th {
        // // text-align: left;
        // // font: normal 600 16px/22px @primary-font;
        // // letter-spacing: 0.48px;
        // // color: #000000;
        // // background: transparent;
        // // text-transform: capitalize;
        // // border-right: 0px;
        // // border-left: 0px !important;
        &::before {
          display: none;
        }
        // .ant-table-filter-column,
        // .ant-table-column-sorters {
        //   // justify-content: flex-start;
        //   .ant-table-column-title {
        //     flex: none;
        //   }
        // }
      }
      table tbody > tr {
        > td {
          // padding-top: 15px;
          // padding-bottom: 15px;
          // background: transparent;
          // border-left: 0px !important;
          // border-right: 0px;
          // font: normal normal 14px/19px @primary-font;
          &::before {
            display: none;
          }
          &.name-column {
            text-align: left;
            // font-weight: 600;
          }
          &.last-modified-colum {
            // letter-spacing: 0.42px;
          }
          &.status-column {
            > span {
              margin-left: 10px;
            }
          }
          &.tcfV-column{
            display: flex;
            justify-content: flex-end;
            >div {
              border-radius: 4px;
              background-color: #1F87EF1A;
              color: #1F87EF;
              height: 32px; 
              line-height: 32px;
              padding: 0px 5px;
              width: 40px;
              font-weight: 600;
              position: relative;
              text-align: center;
              i{
                font-size: 8px;
                position: absolute;
                top: -2px;
                right: -1px;
              }
            }
          }
          // We are using AntD's Tags now
          // .count-container {
          //   width: 36px;
          //   height: 28px;
          //   background: #6565a9;
          //   border-radius: 5px;
          //   color: #ffffff;
          //   display: flex;
          //   align-items: center;
          //   justify-content: center;
          //   &:hover{
          //     cursor: pointer;
          //   }
          // }
        }
        &:hover {
          > td {
            background-color: #f4f6f8;
          }
        }
      }
    }
  }
}
.spsg-filter-dropdown.us-privacy-filter {
  min-width: 250px;
}

.ant-modal-wrap.activate-confirmation.dismiss-error {
  .ant-modal-content {
    width: 650px;
  }
  .note {
    color: #434343;
    font-size: 14px;
    text-align: left;
    font: normal 600 14px/24px @primary-font;
  }
  .note-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dedede;
    border-radius: 6px;
    padding: 15px;
    margin-top: 15px;
  }
  .sub-note {
    margin-top: 10px;
    border-radius: 6px;
    padding: 8px;
    background: #fbffd6;
    font: normal 14px/20px @primary-font;
    letter-spacing: 0.42px;
    color: #3b3b3b;
    .icon {
      color: #ff9100;
      margin-right: 10px;
      font-size: 18px;
    }
  }
  .sub-note-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dedede;
    border-radius: 6px;
    padding: 15px;
    margin-top: 15px;
    max-height: 250px;
    overflow-y: scroll;
    .regulation-site-error {
      margin-bottom: 5px;
      .reg-title {
        text-align: left;
        font: normal 14px/24px @primary-font;
        color: #929292;
        margin-right: 10px;
      }
    }
  }
  .bottom-note {
    margin-top: 10px;
    text-align: left;
    font: normal 14px/24px Open Sans;
    color: #434343;
  }

  button.ant-btn.ant-btn-default.spsg-btn {
    background: @primary-color;
    color: #ffffff;
  }
}

.ant-modal-wrap.properties-detail.dismiss-error {
  .ant-modal-content {
    width: 650px;
    .search {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      > span {
        margin-left: 10px;
      }
      .spsg-input {
        width: 40%;
        margin: 20px 0px;
      }
      .ant-input {
        margin-bottom: 0px !important;
      }
    }
    .selected {
      background: #6565a9;
      font: normal 12px @primary-font;
      letter-spacing: 0.13px;
      color: #ffffff;
      border-radius: 50px;
      height: 34px;
    }
  }
  .title {
    font: normal 18px/24px @primary-font;
    letter-spacing: 0.54px;
    color: #434343;
  }
  button.ant-btn.ant-btn-default.spsg-btn {
    background: @primary-color;
    color: #ffffff;
  }
  .sub-heading {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .circle {
      background: #6565a9 0% 0% no-repeat padding-box;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      display: block;
      margin-right: 5px;
    }
  }

  .property-container {
    background: #ffffff;
    border: 1px solid #dedede;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    align-items: center;
    > div {
      max-height: 400px;
      overflow-y: scroll;
    }
  }
}
