.error-page {
  p {
    font-size: 18px;
  }
}
.uncaught-error-page { 
  margin: 0 -48px;
  height: 100%;
  padding: 24px;
  background-color: #F5F5F5;
}

.uncaught-error-container {
  background-color: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}