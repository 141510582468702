@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');

.auth-container {
  background: #F7F7F7;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  .sign-in-user-container {
    width: 400px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #0000001F;
    border: 0.5px solid #D4D4D4;
    border-radius: 20px;
    opacity: 1;
    margin-top: 75px;
    padding: 80px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    .ant-alert {
      border-radius: 5px;
      .ant-alert-content {
        font-family: Open Sans;
        font-size: 12px;
        max-width: 300px;
      }
    }
  }
  .spl-logo {
    margin-bottom: 40px;
    text-align: center;
    img {
      width: 190px;
    }
  }
  .spl-footer {
    .spl-copy {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      color: #A4A4A4;
      margin-bottom: 20px;
      b {
        font-weight: 600;
      }
    }
    .spl-links a {
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #411F90;
      margin: 0 16px;
      white-space: nowrap;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .spl-inputs {
    input {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 3px #0000001F;
      border: 1px solid #D4D4D4;
      border-radius: 5px;
      opacity: 1;
      font: normal normal 600 12px/14px Open Sans;
      letter-spacing: 0px;
      color: #2D2D2D;
      height: 34px;
      line-height: 34px;
      width: 240px;
    }
  }
  .spl-btn {
    background: #411F90 !important;
    border-radius: 5px;
    color: #fff;
    font-family: Oswald;
    font-size: 14px !important;
    letter-spacing: 0px;
    color: #FFFFFF;
    border-color: #411F90 !important;
    height: 34px;
    margin-top: 20px;
    margin-bottom: 28px;
    width: 240px;
    &:hover {
      opacity: 0.85;
    }
  }
  .spl-bottom-link {
    font: normal normal 600 12px/14px Open Sans;
    margin-top: 10;
    margin-bottom: 0;
    letter-spacing: 0px;
    color: #411F90;
    cursor: pointer;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  .spl-info {
    text-align: center;
    font: normal normal 600 12px/18px Open Sans;
    letter-spacing: 0px;
    color: #2D2D2D;
    padding: 0;
    margin-bottom: 24px;
  }
}