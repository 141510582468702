#avocado {
  .golden-set-performance {
    .select-title {
      font-size: 12px;
      color: grey;
      font-weight: bold;
      margin-top: 30px;
    }

    .ant-select {
      width: 200px;
      background-color: transparent;

      .ant-select-selector {
        background-color: transparent;
        font-weight: bold;
        border: none;
      }
    }

    button {
      top: 351px;
    }

    button.selected {
      color: white;
      background-color: #1370fb;;
    }

    .icon {
      width: 23px;
      background-color: #99eac7;
      padding: 1px 2px;
      font-size: 11px;
      border-radius: 5px;
      text-align: center;
    }

    .icon.green {
      background-color: #99eac7;
    }

    .icon.red {
      background-color: #fecaca;
    }
  }
}