@import "./vl_lists.less";

#avocado {
  .pointer {
    cursor: pointer;
    user-select: none;
  }
  .consent-layout {
    margin-top: 24px;

    .btn-success:hover,
    .btn-success {
      background-color: #411f90;
      color: #fff;
      font-family: Oswald;
    }

    .create-button-container .create-button {
      background-color: #411f90 !important; /* overriding needed */
      color: #fff;
      font-family: Oswald;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #411f90;
      border-color: #411f90;
    }

    .ant-checkbox-checked.ant-checkbox-indeterminate .ant-checkbox-inner {
      background-color: #fff;
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
      background-color: #411f90;
    }

    .ant-select-dropdown-menu-item,
    .ant-select-selection-selected-value,
    .ant-table-row {
      .li-tag {
        display       : inline-block;
        font-weight   : bold;
        border-radius : 4px;
        padding       : 4px 8px;
        font-size     : 12px;
        padding-bottom: 0;
        padding-top   : 0;
        margin-top    : 5px;
      }

      .li-tag.li-none {
        background: none;
        color     : #000;
      }

      .li-tag.li-consent {
        background: #e7f0fd;
        color     : #1e74f7;
      }

      .li-tag.li-interest {
        background: #fff4e0;
        color     : #fd9034;
      }

      .li-tag.li-applicable {
        background    : #f2f2f2;
        color         : #000;
        margin-left   : 10px;
        padding-top   : 8px;
        padding-bottom: 8px;
      }

      .ant-select-selection-item {
        text-overflow: clip;
        //GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
        // height: 100%;
        .li-tag.li-applicable {
          margin-left   : 0;
          padding-top   : 0;
          padding-bottom: 0;
        }
      }

      .li-tag.li-disclosure {
        background: #cef3d4;
        color     : #405143;
      }

      .li-tag.li-allowed {
        color     : #c36459;
        background: #fa807261;
      }
    }

    .vendor-filter {
      width: 160px;
      input {
        font-weight: 400;
      }
    }

    .not-aplicable-container {
      padding: 10px 15px;
      height : 100%;

      &.legal-basis-change {
        background-color: #ffc0c082;
      }
    }

    .vlist-li-select {
      display: block;
      padding: 10px 15px;
      height: 100%;
      &.legal-basis-change {
        background-color: #ffc0c082;
      }

      .ant-select-arrow {
        right: 30px;
      }

      .ant-select-selector {
        height: 44px;
      }

      .ant-select-selection-selected-value {
        float        : none;
        padding-right: 20px;
      }

      .ant-select-selection__rendered {
        line-height: inherit;
      }
    }

    .purpose-delete,
    .purpose-delete:hover {
      background  : #eb4d4b;
      border-color: #eb4d4b;
      color       : #fff;
    }

    .vendor-header {
      padding-bottom: 20px;
      border-bottom : 1px solid #ddd;

      .vl-details-second-row{
        display: flex;
        justify-content: flex-start;
        .vl-id{
          margin-left: 40px;
          font-weight: 600;
          font-size: medium;
          color:#411f90
        }
  
        .vendors-count{
          font-weight: 600;
          margin-left: 40px;
          >span{
            padding: 2px 5px;
            background-color: #E9DFFF;
            color: @primary-color;
            border-radius: 5px;
          }
        }
      }
      
      .active-status {
        padding-left: 8px;
      }

      .vendor-top {
        display        : flex;
        align-items    : center;
        margin-top     : 20px;
        justify-content: space-between;

        .vendor-top-left {
          display    : flex;
          align-items: center;
        }

        .vendor-top-right.export {
          font-size   : 16px;
          margin-right: 15px;

          > span {
            display    : inline-flex;
            align-items: center;
          }

          .anticon-export {
            color: #000;
          }
        }

        .edit-wizard {
          font-size   : 16px;
          padding-left: 1%;
          color       : #411f90;
          cursor      : pointer;
          i::before {
            vertical-align: -webkit-baseline-middle;
          }

          i {
            height       : 30px;
            width        : 30px;
            border       : 1px solid;
            border-radius: 100%;
            text-align   : center;
          }
        }
      }

      .vendor-header-nav {
        display        : flex;
        justify-content: space-between;
        align-items    : center;

        a {
          color: #411f90;
        }

        .actions-wrapper {
          display    : flex;
          align-items: center;
          .menu-container{
            display: flex;
            .edit{
              display    : inline-flex;
              flex-direction: column;
              margin-right: 20px;
              color: @primary-color;
              .anticon {
                font-size: 20px
              }
            }
          }
          .vl-version-update-alerts{
            margin: 0px 5px;
            display: flex;
            .alerts, .tcf-updates-btn{
              border-radius: 4px;
              border: 1px solid #d9d9d9;
              height: 32px;
              line-height: 32px;
              padding: 0px 5px;
              font-weight: 600;
              cursor: pointer;
            }
            .tcf-updates-btn{
              margin-right: 5px;
              border-color: #FF9100;
              background-color: #FFEBCC;
              .anticon{
                font-size: 10px;
                position: absolute;
                top: 0;
                &.anticon-info-circle{
                  color: #FF9100
                }
                &.anticon-check-circle{
                  color: #1F87EF
                }
              }
              &.reviewed{
                border-color: #1F87EF;
                background-color: #1F87EF1A;
              }
            }
          }
        }

        .share-root-domain-switch {
          flex      : 1;
          text-align: right;
        }
 
        .active-switch {
          position       : relative;
          top            : auto;
          width          : auto;
          justify-content: unset;
          max-width      : unset;
          display        : inherit;
          margin         : 0 15px;
        }

        button.advanced-settings {
          top          : auto;
          border-color : #411f90;
          border       : 1px solid #411f90;
          border-radius: 5px;
          box-shadow   : 0px 2px 3px #0000001F;;
          font-family  : Oswald;
        }
      }

      .title-editor {
        position: relative;

        .ant-input-affix-wrapper {
          background-color: transparent;
          border          : none;          
        }

        input {
          color           : #000000;
          font-family     : 'Proxima-Nova';
          font-size       : 24px;
          font-weight     : 800;
          line-height     : 25px;
          background-color: transparent;
          border          : none;
          margin          : 0;
          width           : 100%;
          padding         : 5px;
        }

        i {
          color       : #899ab4;
          font-size   : 18px;
        }
      }
    }

    .vendor-edit-table {
      margin-bottom: 30px;
      margin-top   : 20px;
      .symbol-msg{
        margin-bottom: 5px;
        span{
          margin-right: 10px;
          .fa-circle{
            color       : #fcae43;
            margin-right: 8px;
          }
          .fa-circle.is-apple-broker{
            color       : #7d7d7d;
            margin-right: 8px;
          }
        }
      }

      .ant-table-cell {
        height: 60px;

        &.tag-selector {
          padding: 0 !important;
          .ant-table-filter-column{
            height: 100%;
          }
        }
      }

      .ant-table-wrapper {
        box-shadow    : 0 3px 5px 0 #dde3ec;
        border-top    : 1px solid #e0f0f0;
        border-radius : 10px;
        overflow      : hidden;
        padding-bottom: 30px;
        position      : relative;

        .ant-table-body>table {
          border: 0;
        }

        .ant-table-thead>tr>th {
          border-bottom: 1px solid #e9e9e9;
          font-weight  : bold;
          height       : 100px;
          min-width    : 190px;
          margin       : 0 auto;
          border-right : 1px solid #e9e9e9;
          vertical-align: -moz-middle-with-baseline;

          &.has-stack {
            height: 100px;
          }

          .stack {
            background-color: #fce294;
            position        : absolute;
            height          : 50px;
            text-align      : center;
            border-right    : 1px solid lightgray;
            cursor          : pointer;
            z-index         : 1;

            div {
              padding: 16px;
              font-size : 11px;
              text-align: center;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }
          span.ant-table-column-title{
            z-index: inherit;
          }
        }

        .ant-table-thead .th-container {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          i {
            display : none;
            top     : 1px;
            position: absolute;
            left    : -15px;

            &.in-stack {
              margin-top: 50px;
            }
          }

          .purpose-th {
            max-width    : 170px;
            white-space  : nowrap;
            overflow     : hidden;
            text-overflow: ellipsis;
            cursor       : pointer;
            position     : relative;
            text-align   : center;
            padding      : 16px;
            &.in-stack {
              margin-top: 50px;
            }
            .new-purpose{
              background: rgb(31, 135, 239);
              color: white;
              margin-bottom: -10px;
              height: 25px;
              margin-top: 0px;
              border-radius: 10px;
            }
          }

          >.vendor-count{
            text-align: center;
            padding: 10px 0px;
            border-top: 1px solid transparent;
            border-image: linear-gradient(to right, transparent 0% 10%,#C7C7C7 10% 100%);
            border-image-slice: 1;
            >span{
              margin-left: 5px;
              padding: 2px 5px;
              background-color: #FFF;
              border-radius: 5px;
            }
          }
        }

        th.vendor-name-column {
          padding-left: 8px !important; /* overriding needed */
          .ant-table-filter-column{
            align-items: center;
          }
        }

        td.vendor-name-column {
          padding-left: 0 !important; /* overriding needed */
        }

        th.ant-table-selection-column,
        td.ant-table-selection-column {
          border-right: 0 !important; /* overriding needed */
        }

        .ant-table-filter-trigger {
          margin: -4px 0px -4px 0px;
          padding-left: 0px;
          display: flex;
          align-items: flex-start;
          height: 36px;
          .anticon-filter{
            padding: 17px 0px;
          }
        }

        .ant-table-thead .stack-column {
          height: 150px;
          .ant-table-filter-trigger {
            margin: 50px 0px 0px -4px;
            .anticon-filter{
              padding: 17px 0px;
            }
          }
        }

        .ant-table-filter-trigger.active {
          color: #00BF1A;
        }

        .vendor-filter {

          input,
          .ant-input-suffix {
            z-index: auto;
          }
        }

        .ant-table-thead .th-container:hover {
          position: relative;

          i {
            display: block;
            color  : #108ee9;
          }

          .purpose-th {
            color: #108ee9;
          }
        }

        .td-container {
          position: relative;
          left    : 8px;

          i.td-icon {
            display : none;
            top     : 1px;
            position: absolute;
            left    : -15px;
          }

          .vendor-td {
            white-space  : nowrap;
            overflow     : hidden;
            text-overflow: ellipsis;
            cursor       : pointer;
            position     : relative;
          }
        }

        .td-container:hover {
          position: relative;

          i.td-icon {
            display: block;
            color  : #108ee9;
          }

          .vendor-td {
            color: #108ee9;
          }
        }

        .ant-table-tbody {
          >tr>td {
            font-weight : bold;
            border-right: 1px solid #e9e9e9;
          }

          background: #fff;
        }

        .fas.fa-circle {
          color       : #fcae43;
          margin-right: 8px;
        }

        .fas.fa-circle.is-apple-broker {
          color: #7d7d7d;
          margin-right: 8px;
        }

        .ant-table-thead > tr > th {
          color: #000;
          padding: 16px;
        }

        .ant-table-tbody>tr>td {
          color  : #000;
          padding: 8px 15px;
        }

        .ant-table-title {
          .flexbox{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
          }
          .count {
            height: 23px;
            width: 23px;
            background: #8D8B86;
            border-radius: 100%;
            color: #fff;
            font-weight: 600;
            vertical-align: middle;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            margin-right: 3px;
          }
          .count.highlight {
            background: #DD6666;
          }
          .ant-select {
            width: 120px;
          }
          .add-vendor-btn {
            margin        : 0px;
            border        : 1px solid #411f90;
            border-radius : 5px;
            box-shadow    : 0px 2px 3px #0000001F;;
            font-family   : Oswald;
          }
          .ant-divider {
            height     : 2em;
            border-left: 1px solid #C7C7C7;
            opacity    : 0.5;
            margin     : 0 7px;
          }
          .bulk-action-note {
            letter-spacing: 0px;
            color         : #000000A6;
            font-size     : 12px;
            margin-right  : 10px;
          }
          .custom-tabs {
            display      : inline-flex;
            border-radius: 4px;
            div {
              padding       : 5px 10px;
              border        : 1px solid #C7C7C7;
              display       : flex;
              align-items   : center;
              cursor        : pointer;
              white-space   : nowrap;
              overflow      : hidden;
              text-overflow : ellipsis;
              max-height    : 36px;
              font-size     : 12px;
            }
            div:last-child {
              border-radius: 0px 4px 4px 0px;
            }
            div:first-child {
              border-radius: 4px 0px 0px 4px;
            }
            div.active {
              border       : 1px solid #411f90;
              border-radius: 0px 4px 4px 0px;
              color        : #411f90;
            }
          }
        }

        .ant-table-footer {
          border-radius: 0;
          border-top   : 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          background   : #fff;

          .add-purpose-btn {
            font-size          : 14px;
            border-radius      : 5px;
            color              : rgba(0, 0, 0, 0.65);
            margin-right       : 15px;
            border-color       : #411f90;
            border             : 1px solid #411f90;
            box-shadow         : 0px 2px 3px #0000001F;
            font-family        : Oswald;
          }

          .add-iab-checkbox-container,
          .auto-scan {
            float       : right;
            margin-top  : 8px;
            margin-right: 15px;
          }
        }

        .ant-table-pagination {
          .ant-pagination-total-text{
            left:10px;
          }
          .ant-pagination-options{
            right: 10px;
          }
        }
      }
    }

    .vendor-sites {

      span.red-circle {
        background           : red;
        border-radius        : 0.8em;
        -moz-border-radius   : 0.8em;
        -webkit-border-radius: 0.8em;
        color                : #ffffff;
        display              : inline-block;
        font-weight          : bold;
        line-height          : 1.6em;
        margin-right         : 15px;
        text-align           : center;
        width                : 1.6em;
        margin-right         : 0;
        margin-left          : 10px;
      }

      button.gvl-update {
        position  : absolute;
        margin-top: 2px;
        right     : 410px;
      }

      .gvl-update-message {
        background      : #FFF1F0;
        border          : 1px solid #FFD2CF;
        padding         : 15px;
        border-radius   : 2px;

        .ant-divider {
          border-bottom: 1px solid #C7C7C7;
          opacity      : 0.5;
          margin       : 10px 0;
        }

        .error-wrapper {
          display: flex;
        }

        b {
          letter-spacing: 0.2px;
          color         : #4B4B4B;
        }

        .anticon {
          flex     : inherit;
          color    : #F5222D;
          font-size: 16px;
        }

        .anticon-close {
          color: #000;
        }

        .message {
          flex          : 1;
          letter-spacing: 0.2px;
          color         : #4B4B4B;
          font-size     : 12px;
          font-weight   : normal;
        }

        .action-btns {
          display        : flex;
          flex           : 0.4;
          justify-content: flex-end;
 
          .ant-btn:first-child {
            background    : #FFFFFF 0% 0% no-repeat padding-box;
            border        : 1px solid #8394BC;
            letter-spacing: 0.2px;
            color         : #566892;
            font-weight   : 600;
            border-radius : 4px;
            padding       : 4px 25px;
          }

          .ant-btn:last-child {
            margin-left   : 15px;
            background    : #566892 0% 0% no-repeat padding-box;
            border-radius : 4px;
            letter-spacing: 0.2px;
            color         : #FFFFFF;
          }
        }
      }

      .scan-for-new-vendors {
        text-decoration: underline;
        color          : #411f90;
        cursor         : pointer;
        margin-left    : 15px;
      }

      .vendor-sites-filter {
        .vendor-sites-select {
          width      : 150px;
          margin-left: 5px;
        }
      }

      .ant-select-selection-item {
        background-color: rgb(224, 237, 254);
        color           : #1C6BE3;
        font-weight     : bold;
        margin-right    : 10px;
        font-size       : 12px;

        &:last-child {
          display         : list-item;
          background-color: white;
          color           : #411f90;
          font-weight     : bold;
          border-color    : #f0f0f0;
        }

        .li-tag.li-applicable {
          margin-left   : 0;
          padding-top   : 0;
          padding-bottom: 0;
        }
      }

      .ant-select-selection {
        ul {
          margin-bottom: 0;
        }

        .ant-select-selection--multiple .ant-select-selection__rendered {
          margin-bottom: 0;
          line-height  : 30px;
        }

        .ant-select-selection__placeholder {
          display: block !important;
        }
      }

      .vendor-site-list {
        padding        : 15px 0 5px 0;
        display        : flex;
        justify-content: space-between;
        align-items    : center;

        span {
          font-weight : bold;
          font-size   : 13px;
          margin-right: 10px;
          align-self  : flex-end;
        }

        .ant-tag {
          color        : #1c6be3;
          font-weight  : bold;
          margin-bottom: 10px;

          .anticon {
            color   : #1c6be3;
            position: relative;
            top     : 1px;
          }
        }

        .filter-wrapper {
          display: flex;
        }
      }

      .select-sites-container {
        flex: 1;
      }
    }

    .top-of-the-table-container {
      display           : flex;

      &.no-sites {
        justify-content: flex-end;
      }

      .sites-container {
        display    : flex;
        padding-top: 0;

        .sites {
          display  : flex;
          flex-wrap: wrap;

          >div {
            display         : flex;
            margin          : 10px;
            padding         : 5px;
            border-radius   : 3px;
            background-color: #e0edff;
            color           : #1267e6;
            font-size       : 12px;
            font-weight     : bold;
            line-height     : 12px;
            margin-top      : 0;
            padding-bottom  : 7px;
          }

          .delete-site {
            padding-left: 5px;
            cursor      : pointer;
          }
        }
      }

      .ant-select .ant-select-selection-item-remove {
        vertical-align: 0.8em;
      }
    }

    .ant-dropdown-menu-item {
      padding: 0;

      .menu-item-container {
        border-radius: 4px;
        padding      : 7px 16px;
      }
    }

    .vendor-lists-container {
      .vl-list-mixin();
      .vl-search-container{
        display: flex;
        flex-direction: row;
        align-items: center;
      .vl-search {
        display: flex;
        align-items: center;
        flex:1;
        > div {
          margin-right: 10px;
          color: #272727;
          &.spsg-select {
            .ant-select {
              width: 150px !important 
            }
          }
        }
        .ant-select-selection-item {
          font-weight: bold;
        }
        margin-bottom: 10px;
        > .spsg-input {
          width: 300px;
          .ant-input-affix-wrapper{
            font-weight: bold !important;
            height: 32px !important;
          }
        }
      }
      }
      .vl-search-result {
        color: #272727;
        margin-bottom: 10px;
      }
      ul {
        padding-left   : 0;
        list-style-type: none;
      }

      p {
        margin-bottom: 0;
        font-family  : 'Proxima-Nova';
        font-size    : 12px;
        line-height  : 12px;
        color        : #000000;
      }

      .main-title {
        font-size      : 25px;
        font-weight    : 800;
        line-height    : 25px;
        padding-bottom : 20px;
        display        : flex;
        justify-content: space-between;
        align-items    : center;

        .ant-btn {
          background-color: #411f90;
          color: #fff;
          font-family: Oswald;
          border-radius: 4px;
        }
      }

      .vendor-list-container {
        display         : flex;
        justify-content : space-between;
        background-color: white;
        padding         : 25px 30px;
        align-items     : center;
        border          : 1px solid #e6eaee;
        border-bottom   : none;
        border-radius   : 5px 5px 0 0;
        box-shadow      : 0 2px 6px 0 rgba(33, 47, 67, 0.05);

        .edit {
          display: none;
        }

        &:hover {
          background: #f7f9fc;
          cursor    : pointer;

          .edit {
            display    : inline-flex;
            flex-direction: column;
            margin-left: 25px;
            color: @primary-color;
            .anticon {
              font-size: 24px
            }
          }
        }

        .list-container,
        .ant-select {
          padding-left: 5%;
          display     : flex;
          width       : 45%;
          flex-wrap   : wrap;

          .ant-select-selection {
            width: 100%;
          }
        }

        .name-container {
          width: 25%;

          &.pm {
            width: 70%;
          }
        }

        .menu-container {
          display: flex;
        }

        .name {
          font-size    : 15px;
          font-weight  : bold;
          line-height  : 15px;
          margin-bottom: 10px;
        }

        .descr {
          color: #667a93;
        }

        .vendor-container {
          background-color: #f2f5f8;
          margin          : 2px;
          line-height     : 20px;
          background-color: #f3f3f3;
          border-radius   : 4px;
          padding         : 6px 8px;
        }

        .vendor {
          color: rgba(0, 0, 0, 0.65);
        }
      }

      .vendor-list-container.editing {
        background-color: #f7f9fc;
      }
    }

    .no-lists-message-container {
      width           : 100%;
      padding         : 50px;
      border          : 1px solid #e6eaee;
      border-radius   : 5px;
      background-color: #ffffff;
      box-shadow      : 0 2px 6px 0 rgba(33, 47, 67, 0.05);
      display         : flex;
      flex-direction  : column;
      align-items     : center;

      .title {
        color      : #000000;
        font-size  : 20px;
        font-weight: 800;
        line-height: 24px;
        text-align : center;
      }

      .descr {
        color        : #30425c;
        font-size    : 13px;
        line-height  : 18px;
        margin-top   : 10px;
        margin-bottom: 20px;
        text-align   : center;
      }

      .create-button {
        background-color: #411f90 !important; /*overriding needed*/
        color: #fff;
        font-family: Oswald;
      }
    }

    .ant-select {
      width: 100%;
    }

    .add-vendors-container {
      width           : 100%;
      padding         : 40px;
      border          : 1px solid #e6eaee;
      border-radius   : 5px;
      background-color: #ffffff;
      box-shadow      : 0 2px 6px 0 rgba(33, 47, 67, 0.05);

      p.or {
        display        : flex;
        justify-content: center;
        padding-top    : 10px;
      }

      .add-vendors-button {
        height    : 50px;
        border    : 1px dashed #ccd5e0;
        width     : 50%;
        margin    : 0 auto;
        text-align: center;

        p {
          padding: 15px;
          cursor : pointer;
          color  : @primary-color;
        }
      }
    }

    .vendor-list-table {
      table thead>tr>th {
        color         : black;
        text-transform: none;
        letter-spacing: 0;
        font-size     : 12px;
      }

      td {
        background-color: white;
      }

      td,
      th {
        padding-left: 20px;
        font-weight : bold;
      }

      td {
        font-size: 13px;
        position : relative;
      }

      th:first-child {
        width: 20%;
      }

      th>span {
        display        : flex;
        width          : 100%;
        justify-content: space-between;
        height         : 40px;

        p {
          margin-bottom: 0;
        }

        div {
          display        : flex;
          width          : 100%;
          justify-content: space-between;

          >div.column-title {
            display        : flex;
            width          : 100%;
            justify-content: space-between;
          }
        }
      }

      i.anticon-search {
        position : absolute;
        font-size: 14px;
        color    : #686868;
      }

      .table-footer {
        display        : flex;
        justify-content: space-between;
      }
    }

    .switchContainer {
      display    : inline-flex;
      white-space: nowrap;

      .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        height: 34px;
      }

      .geoContainer {
        display     : inline-block;
        margin-right: 10px;

        .geoTitle {
          color      : #000;
          font-weight: 600;
          text-align : left;
        }

        .select-locations {
          width        : 350px;
          padding      : 5px;
          text-align   : left;
          background   : white;
          border-radius: 4px;
          border       : 1px solid #d9d9d9;
          white-space  : normal;

          .title {
            font-size   : 12px;
            color       : #8c8c8c;
            margin-right: 2px;
          }

          .tagStyle {
            background: #E9DFFF;
            border-radius: 4px;
            font: normal 600 11px Open Sans;
            letter-spacing: 0px;
            color: #2D2D2D;
            padding: 4px;
            margin: 4px;
          }

          .showMore {
            border : 1px solid rgb(24, 144, 255);
            padding: 0px 2px;
            color  : #1c6be3;
            width: 30px;
            display: block;
            text-align: center;
            cursor: pointer;
          }

          .hideTag {
            display: none;
          }

          .divider.ant-divider.ant-divider-horizontal {
            margin: 5px;
          }

          .tagContainer {
            display       : flex;
            flex-direction: row;
            flex          : 1;
            align-items   : center;
            flex-wrap     : wrap;
          }

          .cross-circle {
            margin-left: auto;
            color      : #b8c2d1;
          }
        }
      }

      .multiselect-geo-container.ant-select {
        width       : 250px;
        margin-right: 10px;
      }

      div.title {
        display     : inline-block;
        padding-top : 5px;
        color       : #000;
        font-weight : bold;
        font-size   : 13px;
        margin-right: 10px;

        i {
          color       : #899ab4;
          margin-right: 5px;
          position    : relative;
        }
      }

      div.ant-select {
        width       : 120px;
        margin-right: 10px;

        .ant-select-selection-item {
          font-weight: 600;
          font-size  : 13px;
          color      : #000;
        }
      }
    }

    .select-sites-container {
      display       : flex;
      padding-bottom: 10px;

      .ant-select{
        width: 100% !important;
      }
      .spsg-select {
        width: 100% !important;
      }

      p {
        padding-top: 7px;
        font-weight: bold;
        font-size  : 13px;
      }
    }

    .tooltip-title {
      font-weight: bold;
      font-size  : 14px;
    }

    .vendor-title {
      justify-content: flex-start !important;
      display        : flex;

      .iab-star {
        font-size  : 26;
        color      : #faad14;
        font-size  : 26px;
        line-height: 28px;
        margin     : 0 5px;
      }
    }

    th .vendor-title {
      margin-left: 20px;
    }

    button.delete-vendor {
      margin-left: 10px;
    }

    .display-flex {
      display: flex;
      cursor : pointer;
    }

    .vendor-titile-container {
      display        : flex;
      justify-content: space-between;
    }

    button.delete-vendor.ant-btn-circle {
      position     : absolute;
      top          : 36px;
      width        : 16px;
      height       : 16px;
      padding      : 0;
      font-size    : 10px;
      border-radius: 50%;
      margin-left  : 0;
    }

    label.auto-scan {
      display   : flex;
      margin-top: 15px;

      >span {
        display: flex;
      }

      .anticon-info-circle {
        padding-top: 2px;
        margin-left: 5px;
        color      : gray;
      }
    }

    .add-iab-checkbox-container {
      margin-top: 15px;

      i {
        color: gray;
      }

      span>label.ant-checkbox-wrapper {
        margin-right : 0;
        padding-right: 0;
      }

      span {
        margin-right : 0;
        padding-right: 5px;
      }
    }
  }
}
.ant-modal.edit-purpose, .ant-modal.us-privacy-edit-purpose {
  width: 480px;

  .icon {
    cursor: pointer;

    &:hover>svg {
      transition: all 100ms ease-in-out;
      transform : scale(1.25);
    }
  }

  .icon.disabled {
    opacity: 0.5;

    &:hover>svg {
      transform: none;
    }
  }

  .addremove-item-container {
    display        : flex;
    justify-content: flex-end;
    width          : 100%;

    &>div {
      display    : flex;
      align-items: center;
      padding    : 10px;
      cursor     : pointer;

      &>div {
        font-size  : 12px;
        margin-left: 5px;
      }
    }
  }

  .cookies-config-table-container {
    input {
      height: 32px;
    }

    .ant-table {
      .ant-modal-root {
        .ant-modal-mask, .ant-modal-wrap {
          position: absolute;
        }
        .ant-modal-wrap {
          padding: 0;
          margin: 0;
          .ant-modal {
            width: auto !important;
            top: auto;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #C9C9C9;
            padding: 0;
            .ant-modal-content {
              height: 165px;
              padding: 0;
              border-radius: 0;
              .ant-modal-close{
                display: none;
              }
              .ant-modal-body {
                padding: 15px;
                .form-container .ant-form {
                  .main-container {
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
      .ant-table-container {
        .ant-table-body table {
          height: 150px;
        }
      }
    }

    .cookie-category {
      .display-count {
        color: @primary-color;
        background: #e9dfff;
        padding: 0px 2px;
        font-weight: 600;
        border-radius: 3px;
        cursor: pointer;
      }
    }

    .symbol-msg {
      width      : 100%;
      display    : flex;
      align-items: center;
      padding    : 0 10px 10px;
      font-size  : 12px;

      .fa-lock {
        color : #9B9B9B;
        margin: 0 7px;
      }
    }

    .ant-table-thead>tr:first-child>th:first-child {
      padding-left: 15px;
    }

    .ant-select-selection {
      margin: 0;
    }

    .interval-container {
      width: 65px;

      input {
        width: 70px !important;
      }
    }

    .category-select .ant-select-selection {
      width : 215px;
      margin: 0;
    }

    .duration-container {
      display       : flex;
      text-transform: capitalize;

      .ant-select-selection-item {
        width : 71px;
        margin: 0;
      }

      .ant-select-selection {
        width : 65px;
        margin: 0;
      }
    }
    .update-container{
      display: flex;
      justify-content: flex-end;
      .cookie-table-edit-icon {
        color: @primary-color;
      }
    }
  }

  .cookie-detail-tab {
    overflow: auto;

    .ql-header {
      display: none;
    }

    .description-container {
      padding: 0 25px;

      .title {
        font-weight: bold;
        font-size  : 14px;
        margin-top : 10px;
      }
    }

    .details-container {
      margin-left: 30px;

      .title {
        font-weight: bold;
        font-size  : 14px;
        margin-top : 10px;
      }
    }

    .vendor-title {
      display        : flex;
      justify-content: space-between !important;

      .icons {
        margin-right: 30px;
        margin-top  : 10px;

        i {
          font-size  : 14px;
          color      : gray;
          margin-left: 10px;
          cursor     : pointer;
        }
      }
    }

    .vendor-description {
      margin: 10px 30px 15px 30px;
    }

    .cookies-title {
      width           : 100%;
      background-color: #f7f9fc;
      box-shadow      : inset 0 1px 0 0 #e6eaee, inset 0 -1px 0 0 #e6eaee;
      display         : flex;

      p {
        font-size    : 13px;
        font-weight  : 600;
        line-height  : 12px;
        padding      : 11px 0 12px 30px;
        margin-bottom: 0;

        &.cookie-name {
          width: 40%;
        }
      }
    }

    .collapse-header {
      display: flex;

      p:first-child {
        width: 44%;
      }
    }

    .vendor-name {
      font-size   : 18px;
      font-weight : bold;
      padding-left: 30px;
    }

    .vendor-id {
      margin-left: 30px;
      margin-top : 8px;
      font-size  : 14px;
      font-weight: normal;
    }

    .exclude-vendor-count-checkbox{
      margin-bottom: 10px;
      margin-top: -10px;
    }

    .domain-classification {
      display: flex;

      div {
        border-radius   : 3px;
        background-color: #e6eaee;
        font-size       : 10px;
        font-weight     : 600;
        line-height     : 10px;
        text-align      : right;
        padding         : 5px;
        margin          : 0 0 30px 30px;
      }
    }

    .ant-collapse {
      .ant-collapse-header {
        padding-left: 30px;
      }

      .urls {
        display       : flex;
        flex-direction: column;

        a:first-child {
          padding-top: 10px;
        }
      }

      .ant-collapse-item-active {
        i.arrow {
          transform: rotate(-90deg);
        }

        .ant-collapse-content.ant-collapse-content-active {
          border-top: none;
          background: #f7f9fc;
          padding   : 0 30px;
          box-shadow: inset 0 -1px 0 0 #e6eaee, inset 0 1px 0 0 #e6eaee;
        }

        .ant-collapse-content-box {
          padding: 0;
        }
      }

      i.arrow {
        transform: rotate(90deg);
        position : absolute;
        left     : 90%;
      }

      .cookie-detail-container {
        display       : flex;
        flex-direction: column;
        width         : 100%;

        >div {
          display        : inline-flex;
          justify-content: space-between;
          border-bottom  : 1px solid #e6eaee;

          p {
            padding: 10px;
          }
        }
      }
    }
  }

  .url-mapping-container {
    display        : flex;
    align-items    : center;
    justify-content: center;
    flex-direction : column;
    min-height     : 120px;

    .ant-select {
      text-transform: uppercase;
    }

    .no-patterns-msg-container {
      color: rgba(0, 0, 0, 0.45);
    }

    .patterns-container {
      width     : 85%;
      max-height: 300px;
      overflow-y: scroll;

      .pattern-container {
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        margin-bottom  : 10px;
        background     : #80808012;

        .header-container {
          width        : 100%;
          margin-bottom: 10px;

          h4 {
            width       : 100px;
            padding-left: 13px;
          }
        }

        .pattern-headers-container {
          width         : 85%;
          display       : flex;
          padding-bottom: 5px;
          border-bottom : 1px solid #0000002e;
          margin-bottom : 5px;
          text-transform: uppercase;

          .boolean-header {
            width: 125px;
          }

          .rule-header {
            width: 126px;
          }
        }

        .fixed-height {
          height: 30px;
        }

        .statement-container {
          display        : flex;
          justify-content: space-between;
          width          : 85%;
        }

        .icon {
          font-size: 10px;
        }

        .bool-container {
          .ant-select-selection {
            width : 100px;
            margin: 0;
          }
        }

        .url-pattern-container {
          width : 200px;
          height: 31px;

          input {
            width : 100%;
            height: 100%;
          }
        }

        .type-container {
          .ant-select-selection {
            width : 110px;
            margin: 0;
          }
        }

        .remove-statement-container {
          height  : 31px;
          width   : 30px;
          position: relative;

          .position-left {
            position: absolute;
            right   : 25px;
          }
        }
      }
    }
  }

  .ant-tabs-nav {
    font-size  : 13px;
    margin-left: 15px;
    padding-top: 0px;
  }

  .ant-modal-body {
    padding: 0;
  }

  .edit-purpose-tab-container {
    // padding    : 30px;
    padding-top: 0;

    .geotargeting-list {
      .ant-select-selection-item {
        width: inherit;
      }
      .ant-select-selection-item-remove  {
        padding: 0;
        span {
          padding: 0;
        }
      }
    }

    .consent-actions {
      .cookies-container {
        max-height: 205px;
        height    : 120px;
        overflow  : scroll;

        .no-cookies-msg-container {
          display        : flex;
          flex-direction : column;
          justify-content: center;
          align-items    : center;
          height         : 100%;
        }

        .icon {
          align-items: center;
          display    : flex;
          font-size  : 16px;
          margin-left: 5px;
          .add-cookie-text {
            margin-left: 5px;
            font-size: 14px;
          }
        }
        .icon[disabled] {
          pointer-events: none;
          opacity: 0.5;
        }

        .cookies-table-container {
          .table-headers-container {
            display: flex;

            .name-header,
            .domain-header {
              width: 22%;
            }

            .value-header {
              width: 23%;
            }
          }

          .domain-select,
          .expiration-select {
            padding     : 0;
            margin-right: 10px;
          }

          .domain-select {
            .ant-select-selection.ant-select-selection--single {
              width: 120px;
            }
          }

          .cookie-container {
            display    : flex;
            align-items: flex-start;

            .name-input,
            .value-input {
              width       : 25%;
              margin-right: 2%;
            }

            .ant-select-selection {
              margin-left: 0;
              width      : 105px;
            }

            .ant-select-selection--single {
              height: 28px;
            }

            .icon {
              padding-top: 5px;
              margin-left: 10px
            }
          }
        }
      }

      .implementation-type-container {
        #brace-editor * {
          padding: 0;
        }

        .url-consent-actions-container {
          max-height: 115px;
          overflow  : scroll;
        }
        .ant-tabs-content-holder{
          padding: 15px 3px;
        }
      }

      .geotargeting-container {
        .geotargeting-select-container {
          display: flex;

          .ant-select {
            .ant-select-selection {
              width      : 100%;
              white-space: nowrap;
              max-height : 65px;
              overflow   : auto;
              margin-left: 0;

              .ant-select-selection__choice {
                padding: 0 20px 0 10px !important;
              }

              ul {
                margin-bottom: 0;
              }

              ul>li {
                background-color: rgb(224, 237, 254);
                color           : #1c6be3;
                font-weight     : bold;
                margin-right    : 10px;
                margin-bottom   : 3px;

                &:last-child {
                  display         : list-item;
                  background-color: white;
                  color           : #1c6be3;
                  font-weight     : bold;
                }
              }

              .ant-select-selection--multiple .ant-select-selection__rendered {
                margin-bottom: 0;
                line-height  : 30px;
              }

              .ant-select-selection__placeholder {
                display: block !important;
              }
            }
          }

          .geotargeting-list.ant-select {
            width: fit-content;
            min-width: 300px;
          }

          .geotargeting-bool.ant-select {
            width: 100px;
          }
        }

        .tag-manager {
          margin-top: 25px;
        }

        .tag-manager-key-val-container {
          display        : flex;
          flex-direction : row !important;
          justify-content: space-between;

          input,
          textarea {
            width     : 380px;
            margin-top: 5px;
          }
        }

        label.url {
          margin-top: 25px;
        }
      }
    }

    .iab-map-title {
      display       : flex;
      flex-direction: row;
      margin-top    : 20px;

      i {
        display    : flex;
        align-items: center;
        margin-left: 5px;
        padding-top: 6px;
      }
    }

    .button-container {
      align-items: flex-end;
      margin-top : 5px;
    }

    .checkbox-container {
      display  : flex;
      flex-flow: row wrap;

      .iab-purpose-checkbox {
        font-weight: bold;
        margin-top : 10px;
        width      : 48%;
      }
    }

    .title {
      font-weight: normal;
      font-size  : 14px;
    }

    .labe {
      font-weight: bold;
    }
  }
}

.geo-scope-popover {
  padding: 0px;
  width  : 350px;
  height : 200px;

  .ant-popover-inner-content {
    padding: 0px;
  }

  .header-panel {
    .anticon.anticon-check {
      margin-left: 245px;
    }
  }

  .select-all.header-panel {
    .anticon.anticon-check {
      margin-left: 225px;
    }
  }

  .ant-collapse-header {
    display: flex;
  }

  .ant-collapse-arrow {
    margin-left: -20px;
  }

  .popover-content-container {
    padding: 15px;
    .spsg-tree .ant-tree{
      height: inherit;
      max-height: 30vh;
      overflow-y: scroll;
      margin: var(--ant-margin-xs);
    }
    .spsg-tree label.spsg-checkbox{
      margin-left: 2px
    }
    .select-all {
      padding  : 8px 0px;
      font-size: 13px;
      color    : #767676;
      .spsg-select {
        .ant-select {
          width: 320px;
          .ant-select-selector {
            box-shadow: none;
            outline: 0;
            border: 1px solid #DAE4EB;
            border-radius: 3px;
          }
          &:hover .ant-select-selector, &:focus .ant-select-selector {
            border: 1px solid #DAE4EB;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
            outline: 0;
          }
          &.ant-select-open.ant-select-show-search{
            border: 1px solid #43BA54;
          }
        }
        .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid #DAE4EB;
        }
      }
    }

    .search-location {
      width: 100%;
    }

    .divider {
      margin: 0px;
    }
    .divider.footer-divider {
      margin: 15px 0px;
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .ant-btn{
        font-family: Oswald;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 12px;
        text-align: center;
        padding: 9px 16px;
        height: 35px;
        box-shadow: none;
        background: #FFFFFF;
        border: 1px solid #D7DDE9;
        border-radius: 5px;
        &.btn-primary {
          background: @primary-color;
          border: 1px solid @primary-color;
          color: #fff;
          &:hover {
            color: #fff;
            background: #6565A9;
            border: 1px solid #6565A9;
          }
          &:focus {
            color: #fff;
            background: #6565A9;
            border: 1px solid #6565A9;
          }
          &[disabled] {
            opacity: 0.7;
          }
        }
        &.btn-secondary {
          background: #fff;
          border: 1px solid @primary-color;
          color: @primary-color;
          &:hover {
            color: #6565A9;
            background: #fff;
            border: 1px solid #6565A9;
          }
          &:focus {
            color: #6565A9;
            background: #fff;
            border: 1px solid #6565A9;
          }
          &[disabled] {
            opacity: 0.7;
          }
        }
      }
    }

    .btn-footer {
      display        : flex;
      flex           : 1;
      margin-top     : 10px;
      flex-direction : row;
      align-items    : center;
      justify-content: flex-end;
      padding        : 5px;

      .btn-apply {
        margin-left: 5px;
      }
    }

    .header-panel {
      display       : flex;
      flex          : 1;
      flex-direction: row;
      align-items   : center;
      color         : #707070;
      font-size     : 13px;
      justify-content: space-between;
      .region-title {
        font: normal bold 13px Open Sans;
        letter-spacing: 0px;
        color: #4F556D;
        opacity: 1;
      }
      .ant-select-selector {
        display: flex;
        align-items: center;
        .ant-select-selection-placeholder{
          font: normal 600 12px Open Sans;
          letter-spacing: 0px;
          color: #2D2D2D;
          opacity: 0.4;
        }
      }
      .ant-checkbox-group {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .country-title,
    .ccpa-country-title,
    .search-title {
      flex  : 1;
      cursor: pointer;
      font: normal 13px Open Sans;
      letter-spacing: 0px;
      color: #4F556D;
      opacity: 1;
    }
    .ccpa-country-title + .ant-checkbox-wrapper {
      .ant-checkbox {
        margin-right: 21px;
      }
    }
    .all-region-title {
      font: normal 600 13px Open Sans;
      letter-spacing: 0px;
      color: #4F556D;
      opacity: 1;
    }
    .all-region-title + .ant-checkbox-wrapper,
    .search-title + .ant-checkbox-wrapper {
      margin-right: 21px;
    }
    .spsg-checkbox {
      &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner,
      .ant-checkbox-checked::after {
        border-color: @primary-color;
      }
      &.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: @primary-color ;
        border-color: @primary-color;
      }
      .ant-checkbox-indeterminate .ant-checkbox-inner::after{
        background-color: @primary-color;
      }
      .ant-checkbox.ant-checkbox-checked.ant-checkbox-indeterminate .ant-checkbox-inner{
        background-color: transparent;
      }
    }
    .select-title {
      flex       : 1;
    }

    .select-title + span.anticon {
      padding-right: 26px;
    }

    .select-icon {
      color      : #411f90;
      font-weight: 600;
      svg {
        width: 15px;
        height: 15px;
        vertical-align: middle;
      }
    }

    .deselect-icon {
      color: lightgray;
      svg {
        width: 15px;
        height: 15px;
        vertical-align: middle;
      }
    }

    .ant-collapse {
      background : transparent;
      line-height: 0px;
      border     : 0px;
    }

    .ant-collapse-content-box {
      padding-top   : 2px;
      padding-bottom: 2px;
      height        : 200px;
      overflow-y    : scroll;
    }

    .ant-collapse-content-box::-webkit-scrollbar {
      display: none;
    }

    .ant-list-item {
      padding-top   : 5px;
      padding-bottom: 5px;
      border        : 0px;
      display       : flex;
      flex          : 1;
      align-items   : center;
      color         : #707070;
      font-size     : 13px;
      .ant-checkbox-group {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ant-collapse-item {
      padding: 5px;

      span.anticon.plus-icon,
      span.anticon.minus-icon {
        svg {
          width: 15px;
          height: 15px;
          color: @primary-color;
          vertical-align: middle;
          margin-bottom: 12px;
        }
      }
      .ant-collapse-header-text {
      display: contents;
      }
    }

    .ant-collapse-content {
      border : 0px;
      padding: 5px 0px 0px 11px;
    }

    .ant-collapse-header {
      line-height : 25px;
      padding-left: 15px;
      height      : 25px;
      border      : 0px;

      .arrow {
        line-height: 25px;
        left       : 0px;
      }
    }
  }
}

.add-vendors .ant-modal-body {
  padding-right: 145px;

  .ant-select.ant-select-enabled {
    width: 100%;
  }

  .ant-checkbox-wrapper {
    margin-top: 10px;
  }

  .ant-list-item {
    cursor : pointer;
    padding: 12px 5px;
  }

  .ant-list-item:hover {
    background-color: #e6f7ff;
  }
}

.ant-modal.ant-confirm.ant-confirm-confirm.confirm-remove-sites {
  width: 865px !important;
}

.ant-modal.save-warning {
  p {
    margin-top   : 30px;
    margin-bottom: 30px;
  }

  .mult-errors p {
    margin-top   : 10px;
    margin-bottom: 10px;

    &:first-child {
      margin-top: 30px;
    }
  }

  .ant-confirm-btns {
    margin-top: 0;
  }

  .config-tab {
    .ant-tabs-nav.ant-tabs-tab {
      padding: 12px 0px
    }
  }

}

.export-vendor-list {
  .ant-popover-inner-content {
    padding: 0;

    .export-row {
      padding: 3% 4%;

      .ant-input {
        margin-bottom: 10px;
      }
    }

    .export-row.download {
      font-size: 14px;
      a {
        color: #411f90;
      }
    }

    .export-row.disabled {
      pointer-events: none;
      cursor        : not-allowed;
    }

    hr {
      margin: 0;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #411f90;
      border-color    : #411f90;
    }
  }
}

.vl-purpose-filter {
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    padding: 10px;
    .ant-checkbox-group-item {
      margin-bottom: 8px;
      font-size: 12px;
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #411f90;
        border-color: #411f90;
      }
    }
  }
  .filter-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-top: 1px solid #eee;
    .ant-btn {
      height: 27px;
      padding: 4px 10px;
      font-size: 12px;
      border-radius: 4px;
    }
    .ant-btn:first-child {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: none;
      letter-spacing: 0;
      color: #411f90;
    }
    .ant-btn:nth-child(2) {
      background-color: #411f90;
      color: #fff;
      border-radius: 4px;
    }
  }
}

.vl-bulk-edit {
  min-width: 700px;
  max-width: 950px;
  .ant-modal-body {
    padding: 15px;
  }
  .th-bulk-actions {
    display: flex;
    flex-direction: column;
  }
  .ant-modal-close-x {
    color: #fff;
  }
  .ant-modal-header {
    background: #212F43 0% 0% no-repeat padding-box;
    color: #fff;
    font-size: 18px;
    font-family: Oswald;
    font-weight: 400;
    background-color: #411f90;
    .ant-modal-title {
      color: #fff;
      letter-spacing: 0.2px;
      font-weight: normal;
    }
  }
  .info {
    color: #566892;
    font-weight: normal;
    margin-bottom: 15px;
    font-size: 12px;
    .note {
      color: #000000;
      font-weight: 600;
    }
  }
  table {
    border-left: 0;
    th {
      font-size: 13px;
      padding: 10px;
      vertical-align: baseline;
    }
    th.ant-table-cell-fix-left {
      font-weight: 600;
    }
    td {
      font-size: 13px;
      padding: 0px;
      .fa-circle {
        color: #fcae43;
        margin-right: 5px;
      }
      .fas.fa-circle.is-apple-broker {
        color: #7d7d7d;
      }
      .anticon-check {
        color: #00BF1A;
        font-weight: 600;
        margin-right: 5%;
      }
      .anticon-close {
        color: #566892;
        margin-right: 5%;
      }
      .td-custom {
        padding: 10px;
      }
      .not-supported {
        background: #F2F2F2;
        color: #000;
        opacity: 0.6;
      }
      .supported {
        color: #000000;
        font-weight: 600;
      }
    }
    th.ant-table-cell-fix-left,
    td.ant-table-cell-fix-left {
      border-left: 1px solid #D9D9D9;
    }
    td.ant-table-cell-fix-left {
      padding: 10px;
    }
  }

  .unsupporting-warning {
    background: #FFF1D4 0% 0% no-repeat padding-box;
    border: 1px solid #D9C08D;
    color: #000000A6;
    border-radius: 3px;
    font-size: 12px;
    padding: 1px 5px;
    width: fit-content;
    font-weight: normal;
    margin-top: 10px;
    .vendor-count {
      font-weight: 600;
    }
  }
  .ant-modal-footer {
    .ant-btn-primary {
      background-color: #411f90;
      border-radius: 4px;
      letter-spacing: 0.2px;
      color: #FFFFFF;
      font-size: 13px;
    }
    .ant-btn:first-child {
      letter-spacing: 0.2px;
      font-size: 13px;
      border-right: 4px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: none;
      letter-spacing: 0;
      color: #411f90;
    }
  }
}

.operation-confirmation {
  font-family: Open Sans;
  .error-wrapper {
    display: flex;
    .anticon-warning {
      font-size: 20px;
      color: #F5222D;
    }
    .message-block {
      font-size: 14px;
      padding: 0 10px;
      color: #000;
      .note {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
  .ant-modal-footer {
    .ant-btn:first-child {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: none;
      letter-spacing: 0;
      color: #411f90;
      font-family: Open Sans;
    }
  }
}

.bulk-action-dropdown {
  .ant-select-item-option-content {
    letter-spacing: 0px;
    color: #4B4B4B;
    .anticon-delete,
    .fa-edit {
      color: #566892;
    }
  }
}

.inner-wrapper{
  display: flex;
}

.ant-modal {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @primary-color;
    border-color: @primary-color;
  }
  .ant-tabs-ink-bar {
    background: @primary-color;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: @primary-color;
  }
}

.ant-select-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: @primary-color;
  }
}

.spsg-infotip.vendor-list-wizard {
  .ant-popover-inner > .ant-popover-inner-content {
    p {
      font-size: 12px;
      overflow: hidden;
      margin-bottom: 12px;
    }
    line-height: 18px;
    overflow-y: scroll;
    max-height: 300px;
  }
  &.small {
    .ant-popover-arrow {
      display: block;
    }
    .ant-popover-inner > .ant-popover-inner-content {
      p {
        font-size: 12px;
      }
      overflow-y: scroll;
      width: 200px;
      line-height: 18px;
    }
  }
  &.arrow {
    .ant-popover-arrow {
      display: block;
    }
  }
}

.vl-alerts.ant-dropdown{
  .ant-collapse{
    font-family: @primary-font;
    width: 30vw;
    .ant-collapse-header{
      color: @primary-color;
      background-color: #fff;
      font-weight: 600;
      .ant-collapse-expand-icon{
        position: absolute;
        right: 0;
      }
    }
    .ant-collapse-content-box{
      background: #F1F5F8;
      margin: 10px;
      border-radius: 5px;
      .action-btns{
        display: flex;
        justify-content: flex-end;
        button{
          border-radius: 5px;
          background-color: @primary-color;
          border: 1px solid @primary-color;
          color: #fff;
          &:hover {
            color: #fff;
            background: #6565A9;
            border: 1px solid #6565A9;
          }
        }
      }
    }
  }
}

.spsg-modal .tcf-updates-modal{
  width: 45vw !important;
  max-width: 800px;
  .tcf-update{
    margin-top: 10px;
    background: #F1F5F8;
    padding: 10px;
    border-radius: 5px;
    .ant-alert-message{
      font-size: 14px;
    }
    .title{
      background: #E1E1EE;
      color: @primary-color;
      width: max-content;
      padding: 5px;
      border-radius: 5px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .footer{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 16px;
      margin-top: 10px;
      .anticon{
        font-size: 18px;
        &.anticon-check-circle{
          color: #1F87EF;
        }
        &.anticon-clock-circle{
          color: #FF9100;
        }
      }
      button{
        margin-left: 20px;
      }
    }
  }
}
