@primary-font: var(--ant-font-family); 
@primary-color: var(--ant-color-primary);
@primary-text-color: var(--ant-color-text);
@secondary-color: #EAE4F2;
@secondary-chip-color: #E2F4E4;
@red-color: var(--ant-color-error);
@subtle-red-color: #FFF1F0;
.container-mixin() {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D3D9DD;
  border-radius: 5px;
  padding: var(--ant-padding);
  margin-top: 10px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03), 0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
}
