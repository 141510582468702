
.spsg-filter-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #0000001F;
  border: 1px solid #D4D4D4;
  border-radius: 5px;
  opacity: 1;
  padding: 0 8px;
  height: 40px;
  max-width: 320px;
  .place {
    font: normal normal 600 12px/14px Open Sans;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 0.65;
  }
  .box-place {
    flex: 1;
    font: normal normal 600 12px/14px Open Sans;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 0.25;
    margin: 0 10px;
  }
  .box {
    background: #2D2D2D 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 0.5;
    font: normal normal normal 12px/14px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 2px 6px;
    margin: 0 10px;
    display: inline-block !important;
    overflow: hidden;
    white-space: pre-wrap;
    margin-top:5px !important;
    text-overflow: ellipsis;
    max-height: 35px;
    max-width: 150px;
  }
  .box-wrapper {
    flex: 1;
  }
  .arrow {
    svg {
      color: #2D2D2D;
      font-size: 12px;
    }
  }
  &.dropped .arrow > svg {
    transform: rotate(180deg) !important;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}