.vl-publisher-purpose {
  .ant-modal-body {
    padding: 20px;
  }
  .ant-modal-header {
    background: #411F90;
    font-family: Oswald;
  }
  .ant-modal-title {
    color: white;
    font-weight: 200;
  }
  .ant-modal-content {
    border-radius: 7px 7px 0px 0px;
  }
  .ant-modal-close {
    color: white;
  }
  .content-title {
    margin-left: 0px !important;
    cursor: pointer;
    color: #411F90;
    .title {
      font-size: 13px;
      margin-left: 5px;
    }
    .arrow-style {
      cursor: pointer;
    }
  }
  .content-subTitle {
    margin-top: 10px;
    font-size: 12px;
    color: #000000a6;
  }

  .footer-container{
    display: flex;
    flex:1;
    flex-direction: row;
    align-items: center;
    .footer-btn-container{
      flex:1;
    }
  }
  .ant-btn-primary {
    background: #7f92bd;
    border: #7f92bd;
  }

  .content-subTitle-two {
    margin-top: 10px;
    font-size: 12px;
    color: #000;
    font-weight: 600;
  }

  .config-popup-header {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    background: #6565A9;
    margin-top: 10px;
    margin-bottom: 10px;
    .header-title-one {
      flex: 0.8;
      font-size: 12px;
      font-weight: 600;
      color: #fff;
    }
    .header-title-two {
      font-size: 12px;
      font-weight: 600;
      display: contents;
      color: #fff;
    }
  }
  .purpose-list-parentContainer {
    height: 270px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .purpose-list-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 2px;
    align-items: baseline;
    .ant-select-selection.ant-select-selection--single {
      width: initial;
    }
    .purpose-select {
      flex: 0.3;
      .ant-select-selector{
        width: 175px;
        .li-tag.li-consent {
          background: #e7f0fd;
          color: #1e74f7;
          padding: 5px;
        }
        .li-tag.li-legitimate {
          background: #fff4e0;
          color: #fd9034;
          padding: 5px;
        }
        .li-tag.li-applicable {
          background: #f2f2f2;
          color: #000;
          padding: 5px;
        }
      }
    }

    .purpose-name {
      flex: 0.7;
      font-size: 12px;
      color: #000000a6;
    }
  }
  .config-popup-footer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    margin-top: 10px;

    .apply-btn-style {
      margin-left: 10px;
      background: #7f92bd;
      color: #fff;
    }
  }
}
