@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

.spsg-infotip {
  .ant-popover-inner {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00000029;
    border: 1px solid #DDE1E4;
    border-radius: 10px;
    padding: 0;
  }
  .ant-popover-inner > .ant-popover-title {
    border: 0;
    padding: 20px 20px 10px 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #272727;
  }
  .ant-popover-inner > .ant-popover-inner-content {
    border: 0;
    padding: 20px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #272727;
    max-width: 300px;
  }
  &.maxWidth {
    .ant-popover-inner > .ant-popover-inner-content {
      max-width: 300px;
    }
  }
  .ant-popover-arrow {
    display: none;
  }
  .spsg-checkbox.purple {
    &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked::after {
      border-color: #411F90;
    }
    &.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #411F90 !important;
      border-color: #411F90 !important;
    }
  }

  button.spsg-btn {
    font-family: Oswald;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #090E15;
    padding: 9px 16px;
    height: 40px;
    box-shadow: none;
    background: #FFFFFF;
    border: 1px solid #D7DDE9;
    border-radius: 5px;
    &:hover {
      color: #090E15;
      background: #F5F7FB;
      border: 1px solid #D7DDE9;
    }
    &:focus {
      color: #090E15;
      background: #E7ECF4;
      border: 1px solid #E7ECF4;
    }
    &[disabled] {
      opacity: 1.0 !important;
      color: #ADB5C5 !important;
      background: #E7ECF4 !important;
      border: 1px solid #E7ECF4 !important;
    }
    > svg {
      font-size: 16px;
      margin-right: 10px;
    }
  }
  button.ant-btn.spsg-btn {
    &.btn-primary {
      background: #411F90;
      border: 1px solid #411F90;
      color: #fff;
      &:hover {
        color: #fff;
        background: #6565A9;
        border: 1px solid #6565A9;
      }
      &:focus {
        color: #fff;
        background: #6565A9;
        border: 1px solid #6565A9;
      }
      &[disabled] {
        opacity: 0.7 !important;
      }
    }
  }
  .spsg-select {
    .ant-select {
      width: 100%;
      .ant-select-selector {
        box-shadow: none;
        outline: 0;
        border: 1px solid #DAE4EB;
        border-radius: 3px;
      }
      &:hover .ant-select-selector, &:focus .ant-select-selector {
        border: 1px solid #DAE4EB;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
        outline: 0;
      }
      &.ant-select-open.ant-select-show-search{
        border: 1px solid #43BA54;
      }
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: 1px solid #DAE4EB;
    }
  }
}

.spsg-infotip.tooltip-popover{
  .ant-popover-inner{
    border-radius: 8px;
  }

  .ant-popover-inner > .ant-popover-inner-content {
    padding: 15px;
    font-size: 12px;
    span.anticon{
      cursor: none;
      color: #f5222d;
    }
  }
  .ant-popover-arrow {
    display:block;
  }
  .ant-switch.spsg-toggle{
    background: #ECEFF1;
    height: 14px;
    max-height: 14px;
    &.ant-switch-checked {
      background: #D3EED6;
    }
    &[disabled] {
      opacity: 0.2 !important;
    }
  
    .ant-switch-handle {
      top: -2px;
    }
  
    .ant-switch-handle::before {
      background: #A7B3BF !important;
    }
  
    &.ant-switch-checked .ant-switch-handle::before {
      background: #43BA54 !important;
    }

    .ant-click-animating-node {
      display: none;
    }
  } 
}
