    .config-popup-container{
        width: 400px;
        padding: 10px 0px;
        .config-checkbox-container{
            font-size: 12px;
            width: 100%;
            color:#000000A6;
            font-weight: 600;
        }
        .config-checkbox{
            margin-right: 10px; 
        }
        .line-style{
            border-bottom: 0.5px solid lightgray;
            width: 100%;
            margin-top: 5px;
        }
        .config-popup-header{
            display: flex;
            flex: 1;
            justify-content: flex-start;
            align-items: center;
            padding: 2px;
            .header-title-one{
                flex: 0.8; 
                font-size: 14px;
                font-weight: 600;
                color:#272727;
            }
            .header-title-two{
                font-size: 14px;
                font-weight: 600;
                display: contents;
                color:#272727;
            }         
        }
        .purpose-list-parentContainer{
            height:370px;
            overflow-y:scroll;
        }
        .warning-message-container{
            padding: 5px; 
            background: #FFF1F0;
            border: 1px solid #FFD2CF;
            margin: 5px;
        }
        .purpose-list-container{
            display: flex;
            flex: 1;
            flex-direction: row;
            justify-content: space-between;
            padding: 2px;
            .purpose-select{
                height: 45px;
                width: 100%; 
                display: flex;
                align-items: center;
                flex: 0.5;               
                .ant-select-selection.ant-select-selection--single {
                    margin-left: 30%;
                }
                .li-tag.li-consent{
                    background: #e7f0fd;
                    color: #1e74f7;
                    padding: 5px;
                }
                .li-tag.li-legitimate{
                    background: #fff4e0;
                    color: #fd9034;
                    padding: 5px;
                }
                .li-tag.li-applicable{
                    background: #f2f2f2;
                    color: #000;
                    padding: 5px;
                }             
            }
            .purpose-select-disabled{
                height: 45px;
                width: 100%; 
                flex: 0.5;

                .ant-select-selection.ant-select-selection--single {
                    border: 0px;
                    background: transparent;
                    margin-left: 30%;
                }
                .ant-select-arrow{
                    display: none
                }
                .li-tag.li-consent{
                    background: #e7f0fd;
                    color: #1e74f7;
                    padding: 5px;
                }
                .li-tag.li-legitimate{
                    background: #fff4e0;
                    color: #fd9034;
                    padding: 5px;
                }
                .li-tag.li-applicable{
                    background: #f2f2f2;
                    color: #000;
                    padding: 5px;
                }             
            }
            .purpose-name{
                flex:0.5;
                flex-grow: inherit;
            }
        }
        .config-popup-footer{
            display: flex;
            flex: 1;
            justify-content: flex-end;
            margin-top: 10px;

            .apply-btn-style{
                margin-left: 10px;
                background: #7F92BD;
                color:#fff;
            }
        }       
    }

    .container-style{
        width:500px;
    }

    .add-vendors.edit-vendor-compensationv2{
        .vendor-tab-container{
            flex: 1;
            flex-direction: row;
            display: flex;
            justify-content: space-between;

            .ant-tabs-nav.ant-tabs-tab {
                padding: 12px 0px;
                color: #566892;
            }

            .tab-title{
                font-size: 13px
            }

            .title-3.top-title {
                flex:1;
                font-weight: 600;
                color:#000;
              }

              .vendor-item-container{
                flex:1;
                font-size:13px;
                color:#000000A6
              }
        }
        .vendor-tab-subcontainer{
            width: 520px;
            border-right: 1px solid lightgray;

            .ant-tabs-tab-active.ant-tabs-tab{
                padding: 12px 5px;
                color: #566892;
                font-weight: 600;
            }
            .ant-tabs-tab {
                padding: 12px 5px;
                color: #566892
            }
            .ant-tabs-nav{
            padding-top: 0px;
            }
            .tab-maintitle{
                color:#000;
                font-weight: 600;
            }
            .google-icon{
                width: 10px;
                height: 10px;
                margin-left: 5px;
                margin-right:5px;
            }
        }
        .vendor-tab{
            flex: 1;
            flex-direction: column;
            display: flex;
        }
        .line-style-new{
            display: flex;
            flex: 1;
            padding: 15px;
        }
        .remove-style{
            cursor: pointer;
            color: #566892;
            font-size: 12px;
        }  
    }
    .no-selected-vendors{
        color: #00000080;
        font-size: 12px;
        width:55%
    }

    .ant-popover.config-popup-vendor{
        top: 109px !important;
        position: fixed;
        .spsg-select {
          .ant-select {
            width: 200px; 
        }
    }
    }

    .setting-title-container{
            padding: 10px;
            color:#566892;
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: center;
            cursor: pointer;
            .setting-icon{
                margin-right: 5px;
            }
    }
    .config-popup-vendor{
    .title-container{
        font-size: 12px;
         cursor: pointer;
    }
}

.ant-modal.ant-modal.edit-vendor-compensationv2.add-vendors {
    width: 1000px !important;
    left: ~"calc((100% - 1000px)/2)";
    position: fixed;
    top: 10px;

    .ant-modal-footer.ant-btn-primary{
        background: #7F92BD;
    }

    p.title {
      margin-bottom: 0;
    }

    .ant-select {
      width: 500px;
      padding-top: 20px;
      margin-left: 30px;
      margin-bottom: 0;

      .ant-select-selection {
        border: none;
        box-shadow: none;
        border-bottom: 1px solid lightgray;
        border-radius: 0;

        li {
          display: none;
        }

        li:last-child {
          display: inline;
        }
      }
    }

    p.title-3 {
      font-size: 14px;
      margin-left: 40px;
      margin-bottom: 0;
      margin-top: 15px;
      width: 370px;
      padding-bottom: 10px;
      border-bottom: 1px solid lightgray
    }

    .selected-vendors, .no-vendors-message {
      margin-left: 10px;
      padding-left: 30px;
      border-left: 1px solid lightgray;
      height: 332px;
      overflow: auto;
      padding-right: 52px;

      div.vendor-container {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid lightgray;
        width: 370px;
        justify-content: space-between;

        div {
          font-weight: bold;
        }

        i {
          color: #c3c2c2;
          font-size: 19px;
        }
      }
    }

    .no-vendors-message {
      font-size: 16px;
      font-weight: bold;
      border-left: none;
      padding-top: 40px;
    }
  }

  .ant-modal.ant-modal.edit-vendor-compensationv2 {
    width: 800px !important;

   .ant-select-dropdown .spsg-input.purple input{
    border: 0px;
   }

    .ant-input-search.vendor-filter-new {
      border: none;
      .ant-input-suffix {
        position: absolute;
        left: 257px;
        top: 3px;
        width: 20px;
        height: 25px;
        i {
          font-size: 10px;
          margin-bottom: 15px;
          color: #bebebe;
        }
      }
    }

    .select-title {
      position: absolute;
      z-index: 9;
      top: 66px;
      font-size: 15px;
      font-weight: bold;
      left: 44px;
    }

    .ant-modal-body {
      margin-top: 20px;
      padding: 0;
      height: 446px;

      p.title {
        background-color: #411F90;
        color: white;
        padding: 15px;
        text-transform: capitalize;
        margin-right: 0;
        font-family: 'Oswald';
      }

      .row-1 {
        justify-content: space-between;
      }

      .row-2 {
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        margin-top: 15px;
        padding-top: 15px;
        margin-bottom: 15px;
      }

      .start-date {
        margin-right: 20px;
        padding-right: 20px;
        span {
          margin-left: 10px;
        }
        border-right: 1px solid lightgray;
        height: 15px;
      }

      span.bold {
        font-weight: bold;
      }

      .ant-select {
        margin-top: -5px;
        margin-left: 10px;
      }

      .ant-select-selection--single {
        width: 100px;
      }
  
      .ant-input {
        width: 60px;
        height: 32px;
        margin-top: -5px;
        margin-left: 10px;
      }
      table {
        border: 1px solid #d3d3d366;
        td {
          background: none;
        }
      }
    }
  }

  .add-vendors.edit-vendor-compensation{
    .main-container{
      display: flex;
      flex:1;
      flex-direction: row;
    }
    .sub-container{
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .switch-vendors{
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    .ant-modal .ant-input {
      margin-bottom: 0px !important
    }
    
  }