@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.spsg-filterbox {
  border: 1px solid #D4D4D4;
  border-radius: 5px;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 16px;
  color: #2d2d2d;
  padding: 6px 15px;
  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
  }
  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: #411F90;
  }
  &.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #411F90 !important;
    border-color: #411F90 !important;
  }
  .ant-checkbox + span {
    padding-right: 0;
    padding-left: 10px;
  }

  &.ant-checkbox-wrapper-checked {
    color: #411F90;
    border-color: #411F90;
  }

}
