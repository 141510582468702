@import (css) url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;600&display=swap');
@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

.spsg-tabs {
  transition: none !important;
  overflow: visible;
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-content-holder {
    position: relative;
    box-shadow: 0px 2px 5px #00000029;
    padding: 32px;
  }
  .ant-tabs-nav {
    z-index:999;
    position: relative;
    transition: none !important;
  }
  .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
    border-left: 1px solid transparent;
    border-top-left-radius: 0;
    z-index: 98;
    position: relative;
    left: -1px;
    margin-left: 0 !important;
  }
  .ant-tabs-tab {
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 0.16px;
    color: #272727;
    background: #fff;
    border: 1px solid #DAE4EB;
    z-index: 98;
    position: relative;
    transition: none !important;
    padding: 12px 30px;
    > div {
      background: transparent;
      color: #272727;
    }
    &:hover {
      background: #F8F8F8;
    }
    &.ant-tabs-tab-active {
      color: #411F90;
      border-color: #411F90;
      background: #F8F8F8;
      z-index: 101 !important;
      border-left: 1px solid !important;
      > div {
        background: transparent;
        color: #411F90;
      }
    }
  }
  .ant-tabs-nav-list > .ant-tabs-tab {
    &:first-child {
      border-top-left-radius: 5px;
    }
  }
  .ant-tabs-nav-list > div {
    &:nth-last-child(2) {
      border-top-right-radius: 5px;
    }
  }

  &.spsg-classic{
    .ant-tabs-nav{
      padding-top: 10px !important;
    }
    .ant-tabs-tab{
      border: 0px;
      border-bottom: 1px solid #00000029;
      font: normal normal normal 14px/18px Open Sans;
      color: #818181;
    }
    .ant-tabs-tab.ant-tabs-tab-active{
      border: 0px;
      border-left:0px !important;
      border-bottom: 4px solid #411F90;
      background: white;
      > div{
        font: normal normal 500 14px/18px Open Sans;
        letter-spacing: 0.16px;
        color: #411F90;
      }
    }
    .ant-tabs-content-holder {
      padding: 10px 0px 0px 0px;
      position: relative;
      box-shadow: 0px 0px 0px #00000029 !important;
    }
  }
}

.spsg-tabs.spsg-normal{
  .ant-tabs-content-holder{
    box-shadow:0px 0px 0px #fff
  }
  .ant-tabs-tab {
    font-family: Open sans;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.16px;
    color: #232A58;
    background: #fff;
    z-index: 98;
    position: relative;
    transition: none !important;
    padding: 10px 20px;
    background: transparent;
    border:0px;
    &.ant-tabs-tab:first-child{
      border-top-left-radius: 0px;
    }
    > div {
      background: transparent;
      color: #272727;
    }
    &:hover {
      background: #F8F8F8;
    }
    &.ant-tabs-tab-active {
      color: #411F90;
      background: #fff;
      font-weight: 600;
      border-bottom: 3px solid #411F90;
      z-index: 101 !important;
      border-left:0px !important;
      > div {
        background: transparent;
        color: #411F90;
      }
    }
  }
  .ant-tabs-nav-list {
    .ant-tabs-ink-bar {
      background: #411F90;
    }
  }
}
