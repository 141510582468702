@primary-font: Open Sans;
@primary-color: #4f28b3;
.confirmation-mixin() {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
  background-color: #FBFFD6;
  margin: 8px;
  border-radius: 4px;
  font-size: 16px;
  font-family: Open sans;
  font-weight: 400;
  >div {
    max-height: 50px;
    padding: 10px;
    display: flex;
    .anticon-warning {
      color : var(--ant-color-warning);
      font-size: 24px;
    }
    hr {
      border-top: 2px solid #939393
    }
  }
  >.button-group{
    display: flex;
    justify-content: space-between;
    margin: 10px;
    .cancel {
      margin-left: 0px;
    }
  }
}
.error-message-mixin() {
  color: var(--ant-color-error);
  font-size: 12px;
  margin-bottom: 10px;
}
.scan-vendors-tabs-mixin() {
  .vlVendorTypeBox{
    .ant-radio-group{
      label.ant-radio-button-wrapper:not(:first-child)::before{
        width: 0px;
      }
      .ant-radio-button-wrapper {
        margin-left: 10px;
        height: 40px;
        font-family: @primary-font;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 38px;
        color: #2D2D2D;
        background: #F5F5F5 0% 0% no-repeat padding-box;
        border: 1px solid #C7C5CB;
        border-radius: 5px;
        &:hover {
          color: @primary-color;
        }
      }
      .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        color: @primary-color;
        border-color: @primary-color;
        background: white;
        border-radius: 5px;
        span{
          color: @primary-color;
        }
      }
    }
  }
}

.vendor-list-wizard {

  font-weight: 100;
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: @primary-color;
  }
  .ant-pagination-item-active {
    border-color: @primary-color;
    a {
      color: @primary-color;
    }
  }
  .no-language-select-error {
    font-size: 12px;
    background: #FFF1F0;
    color: #4B4B4B;
    width: fit-content;
    padding: 5px;
    margin: 10px 0;
    border: 1px solid var(--ant-color-error-border);
    border-radius: 5px;
    .anticon {
      color: var(--ant-color-error);
      font-size: 14px;
    }
  }
  .ant-table-expanded-row > td {
    padding: 0px 0px !important;
  }

  .ant-modal-footer {
    border: 0;
    padding:10px !important;
    .footer-wrapper {
      padding: 12px 0;
      border-top: 1px solid #C7C7C7;
      display: flex;
      justify-content: space-between;
      .ant-btn {
        min-width: 85px;
      }
      .btns-left {
        .btn-cancel {
          background: #FFFFFF 0% 0% no-repeat padding-box;
          border: none;
          letter-spacing: 0;
          color: @primary-color;
        }
        .btn-save {
          background: #fff 0% 0% no-repeat padding-box;
          letter-spacing: 0.22px;
          color: @primary-color;
          border: 1px solid @primary-color;
        }
      }
      .btn-done,
      .btn-next {
        background-color: @primary-color;
        color: #fff;
      }
      .btn-prev {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: none;
        letter-spacing: 0;
        color: @primary-color;
      }
      .confirmation-footer {
        .confirmation-mixin()
      }
    }
  }
  .ant-modal-header {
    background: @primary-color;
    padding: 10px 24px;
    .ant-modal-title {
      color: #fff;
      font-size: 18px;
      font-family: Oswald;
      font-weight: 400;
    }
  }

  .ant-divider {
    margin: 5px 0;
    border-top: 1px solid #C7C7C7;
  }

  .ant-steps {
    .ant-steps-item > .ant-steps-item-container > .ant-steps-item-icon {
      height: 30px;
      width: 30px;
      border-radius: 100%;
      position: relative;
      background: #FFFFFF 0% 0% no-repeat padding-box ;
      border: 2px solid #52C41A;
      top: -9px;
      // margin-left: 0px;
      margin-inline-start: 0px;
      .ant-steps-icon {
        letter-spacing: 0px;
        color: #52C41A;
        font-size: 11px;
        line-height: 26px;
      }
    }
    .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
      margin: 0 0 0 48%;
    }
    .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: transparent;
      border-bottom: 2px solid #E9E9EB;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: transparent;
      border-bottom: 2px solid #52C41A;
    }
    .ant-steps-item-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .ant-steps-item-content {
        width: auto;
        margin-top: 0;
        .ant-steps-item-title {
          font-size: 12px;
          letter-spacing: 0px;
          color: #348A09;
          font-weight: 400;
          .ant-steps-item-subtitle {
            color: #969696;
            font-size: 10px;
          }
        }
      }
    }
    .ant-steps-item.ant-steps-item-process > .ant-steps-item-container >  .ant-steps-item-icon {
      background: #52C41A 0% 0% no-repeat padding-box;
      .ant-steps-icon {
        color: #fff;
        letter-spacing: 0px;
      }
    }
    .ant-steps-item-wait .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
      background: #E9EEFA 0% 0% no-repeat padding-box !important;
      border: 2px solid #E9EEFA;
      .ant-steps-icon {
        letter-spacing: 0px;
        color: #232A58;
        opacity: 0.3;
      }
    }
    >.ant-steps-item-wait > .ant-steps-item-container {
      .ant-steps-item-icon {
        background: #E9EEFA 0% 0% no-repeat padding-box;
        border: 2px solid #E9EEFA;
        .ant-steps-icon {
          letter-spacing: 0px;
          color: #232A58;
          opacity: 0.3;
        }
      }
      .ant-steps-item-icon:hover {
        border: 2px solid #E9EEFA !important;
      }
      .ant-steps-item-content .ant-steps-item-title {
        letter-spacing: 0px;
        color: #232A58;
        text-transform: capitalize;
        opacity: 0.75;
        .ant-steps-item-subtitle {
          letter-spacing: 0px;
          color: #969696;
        }
      }
      .ant-steps-item-content .ant-steps-item-title:hover {
        color: #232A58;
        .ant-steps-item-subtitle {
          color: #969696;
        }
      }
    }
    .ant-steps-item:last-child {
      flex: 1;
    }
    .ant-steps-item.disabledTailClass .ant-steps-item-container > .ant-steps-item-tail::after {
      border-bottom: 2px solid #E9E9EB;
    }
    .ant-steps-item.enabledTailClass .ant-steps-item-container > .ant-steps-item-tail::after {
      border-bottom: 2px solid #52C41A;
    }
  }
  .ant-select {
    margin-bottom: 0 !important;
  }
  .ant-select.ant-select-single {
    height: 35px;
    font-size: 13px;
    .ant-select-selector {
      height: 35px;
      align-items: center;
    }
  }
  .properties-and-scope-wrapper {
    display: flex;
    flex-direction: column;
    .select-locations {
      .tagContainer {
        display: flex;
        align-items: center;
        min-height: 27px;
        padding: 4px 11px;
      }
    }
    .select-locations {
      border: 1px solid #E1E1E1;
      width: 350px;
      margin-bottom: 10px;
      min-height: 27px;
      .title {
        margin-left: 4px;
        font-size: 12px;
        color: #8c8c8c;
        font-family: @primary-font;
        margin-right: 2px;
      }
      .tagStyle {
        background: #E9DFFF;
        border-radius: 4px;
        font: normal 600 11px @primary-font;
        letter-spacing: 0px;
        color: #2D2D2D;
        padding: 4px;
        margin: 4px;
      }
      .showMore {
        border: 1px solid rgb(24, 144, 255);
        padding: 0px 2px;
        color: #1c6be3;
        width: 30px;
        display: block;
        text-align: center;
        cursor: pointer;
      }
      .hideTag {
        display: none;
      }
      .divider.ant-divider.ant-divider-horizontal {
        margin: 0px;
      }
      .tagContainer {
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: center;
        flex-wrap: wrap;
       }
       .cross-circle {
         margin-left: auto;
         color: #b8c2d1;
       }
    }
    .select-locations:hover {
      border: 1px solid #DAE4EB;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.16);
      outline: 0;
    }
    .ant-select.ant-select-single {
      height: 35px;
      width: 130px;
      font-size: 13px;
      margin-bottom: 10px !important;
      .ant-select-selector {
        height: 35px;
        align-items: center;
      }
    }
    .ant-select.ant-select-multiple {
      height: 65px;
      width: 70%;
      font-size: 13px;
      margin-bottom: 10px !important;
      .ant-select-selector {
        height: 65px;
        overflow: auto;
      }
    }
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
      line-height: 27px;
    }
    .ant-input {
      height: 27px;
      width: 400px;
      border: 1px solid #E1E1E1;
    }
    .ant-input-number {
      width: 180px;
      &:hover, &:focus {
        border-color: #43BA54;
      }
    }
    .error-message {
      .error-message-mixin()
    }
    .ant-input.error-input {
      border: 1px solid #FF2626;
      margin-bottom: 0 !important; //overriding needed
    }
    .mandatory-fields-notice {
      letter-spacing: 0.12px;
      color: #000000A6;
      font-size: 10px;
      .asterisk {
        color: #FF2626;
        font-size: 12px;
        margin-right: 2px;
      }
    }
    label {
      letter-spacing: 0.14px;
      color: #000000;
      font-weight: 600 !important;
      font-size: 12px;
      font-family: @primary-font;
      .fa-info-circle {
        margin-left: 2px;
        color: #566892;
      }
      .sub-label {
        letter-spacing: 0.14px;
        color: #000000A6;
        font-weight: 300;
      }
      .asterisk {
        color: #FF2626;
        font-size: 12px;
        margin-right: 2px;
      }
    }
  }
  .iab-settings-wrapper {
    display: flex;
    flex-direction: column;
    .legal-basis-header {
      letter-spacing: 0.18px;
      color: #000000;
      font-size: 13px;
      font-weight: 600;
      font-family: @primary-font;
    }
    .legal-basis-info {
      letter-spacing: 0px;
      color: #000000A6;
      font-size: 10px;
      font-family: @primary-font;
    }
    .legal-basis-note {
      letter-spacing: 0px;
      color: #566892;
      font-size: 10px;
      font-family: @primary-font;
      b {
        letter-spacing: 0px;
        color: #4B4B4B;
        font-weight: 600;
        font-family: @primary-font;
      }
    }
    .ant-radio-group {
      margin: 5px 0;
      .ant-radio-wrapper {
        font-size: 12px;
        font-family: @primary-font;
        letter-spacing: 0px;
        color: #000000;
        .ant-radio-checked .ant-radio-inner {
          border-color: @primary-color;
        }
        .ant-radio-inner::after {
          background-color: @primary-color;
          border: 1px solid @primary-color;
        }
        .ant-radio-inner {
          height: 14px;
          width: 14px;
        }
      }
    }
    .ant-collapse {
      margin-top: 10px;
      font-family: @primary-font;
      .ant-collapse-item {
        border: 1px solid #EEEEEE;
        margin-bottom: 10px;
        .ant-collapse-header {
          // background: #F3F8FC 0% 0% no-repeat padding-box;
          background: rgb(233, 223, 255);
          border-bottom: 1px solid #EEEEEE;
          color: #000000;
          font-size: 12px;
          padding: 8px 16px;
          padding-left: 20px;
          font-weight: 600;
          .ant-collapse-arrow {
            left: 6px;
            font-size: 11px;
          }
          .settings-note {
            letter-spacing: 0.28px;
            color: #566892;
            font-size: 10px;
            font-weight: 100;
          }
        }
        .ant-collapse-content {
          .purpose-container {
            display: flex;
            margin-bottom: 10px;
            .feature {
              margin-left: 1%;
              .title {
                letter-spacing: 0.15px;
                color: #000000;
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 5px;
              }
              .description {
                letter-spacing: 0px;
                color: #000000A6;
                font-size: 10px;
              }
            }
          }
          .error-message {
            .error-message-mixin()
          }
          .ant-checkbox-wrapper {
            letter-spacing: 0.15px;
            color: #000000;
            font-size: 11px;
            .ant-checkbox-inner {
              height: 12px;
              width: 12px;
            }
            .ant-checkbox-checked .ant-checkbox-inner::after {
              width: 4.71428571px;
              height: 7.142857px;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: @primary-color;
              border-color: @primary-color;
            }
		  }
          .ant-select {
            width: 45%;
            font-size: 11px;
            margin-bottom: 0 !important; // overriding needed
            margin-left: 18px;
          }
          .ant-transfer {
            .ant-transfer-operation {
              .ant-btn{
                background: #CBDFFF 0% 0% no-repeat padding-box;
                border-color: #CBDFFF;
                color: #716d6d;
              }
              .ant-btn[disabled] {
                opacity: 0.3;
                color: #000;
              }
            }
            .ant-transfer-list {
              width: 47%;
              height: 300px;
              border: 1px solid #C7C7C7;
              border-radius: 8px;
              .ant-checkbox {
                display: none;
              }
              .ant-transfer-list-header-dropdown {
                display: none;
              }
              .ant-transfer-list-body-search-wrapper {
                margin: 12px 6px 0px 6px;
              }
              .ant-transfer-list-header {
                letter-spacing: 0.16px;
                color: #000000;
                font-size: 12px;
                font-weight: 600;
                border-bottom: 1px solid #C7C7C7;
              }
              .ant-transfer-list-body-search-wrapper .ant-input{
                height: 27px;
                font-size: 11px;
              }
              .ant-transfer-list-content-item-text {
                letter-spacing: 0.14px;
                color: #000000;
                font-weight: 600;
                font-size: 11px;
                overflow: visible;
                white-space: normal;
                .ant-tag {
                  letter-spacing: 0.12px;
                  color: #4B4B4B;
                  font-weight: normal;
                  font-size: 11px;
                  margin: 3px 3px 3px 0px;
                }
              }
            }
          }
          .iab-stacks-info {
            letter-spacing: 0.16px;
            color: #000000;
            font-weight: 600;
            font-size: 12px;
          }
          .iab-stacks-note {
            letter-spacing: 0px;
            color: #566892;
            font-size: 10px;
            margin: 10px 0;
          }
        }
      }
      .ant-collapse-item.iab-settings-panel {
        .iab-settings-row {
          margin-bottom: 10px;
        }
        .ant-collapse-arrow {
          top: 34%;
        }
      }
    }
  }
  .custom-purposes-wrapper {

    .header {
      letter-spacing: 0.18px;
      color: #000000;
      font-size: 13px;
      font-weight: 600;
      font-family: @primary-font;
      margin: 2px 0;
    }
    .note {
      letter-spacing: 0px;
      color: #566892;
      font-size: 10px;
      font-family: @primary-font;
      b {
        letter-spacing: 0px;
        color: #4B4B4B;
        font-weight: 600;
        font-family: @primary-font;
      }
    }
    .info {
      letter-spacing: 0px;
      color: #566892;
      font-size: 10px;
      font-family: @primary-font;
    }
    .sub-header {
      letter-spacing: 0.14px;
      color: #000000;
      font-weight: 600;
      margin-top: 5px;
      font-weight: 600;
      font-size: 11px;
      font-family: @primary-font;
    }
    .language-selection-wrapper {
      display: flex;
      align-items: center;
      .ant-btn.add-new-btn {
        border: 1px solid #8394BC;
      }
      .ant-select {
        width: 180px;
      }
      .anticon.anticon-caret-right {
        margin: 0px 8px;
      }
      span.option-divider {
        margin: 0px 8px;
      }
      .ant-select-multiple .ant-select-selection-placeholder {
        font-size: 11px;
        color: #000;
      }
    }
    .ant-table-wrapper {
      margin-top: 10px;
      .ant-input {
        margin-bottom: 0 !important;
        padding: 2px 6px;
      }
      .ant-btn.cancel-btn {
        border: none;
        color: @primary-color;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        letter-spacing: 0;
      }
      .ant-btn {
        border-radius: 4px;
        width: 75px;
        margin-left:5px
      }
      .ant-btn-background-ghost {
        letter-spacing: 0.22px;
        background: #fff 0% 0% no-repeat padding-box !important;
        color: @primary-color;
        border: 1px solid @primary-color;
        margin-left: 2%;
        margin-right: 3%;
      }
      .ant-table table {
        border-collapse: collapse;
        font-size: 11px !important; //overridding needed
        .ant-table-footer {
          display: flex;
          justify-content: flex-end;
          background: #fff;
          border-top: 1px solid #eee;
        }
        .ant-table-tbody {
          border: 1px solid #eee;
          .ant-table-placeholder {
            color: #566892;
            font-size: 10px;
            text-align: left;
            height: 150px;
            vertical-align: initial;
            td {
              padding: 10px;
            }
          }
        }
        tr.default-row {
          background: #f9fafc 0% 0% no-repeat padding-box;
          
        }
        tr.readonly-default-row {
          background: #f9fafc 0% 0% no-repeat padding-box;
          font-weight: 600;
          color: #000;
        }
        thead > tr {
          border: 1px solid #eeeeee;
          th {
            background: #6565A9 0% 0% no-repeat padding-box;
            padding: 10px 16px;
            font-size: 11px !important;
            font-weight: 400 !important;
            color: #fff !important;
            letter-spacing: 0px !important;
            text-transform: capitalize !important;
          }
        }
        td > span.edit-language {
          font-weight: normal;
          color: #000;
        }
        td > span.edit-language {
          font-weight: normal;
          color: #000;
        }
        td > div.action-btn-wrapper {
          display: flex;
          .delete-icon {
            height: 15px;
          }
          .avo-dialogue-icons {
            color: #C5BED6 !important; /*overriding needed*/
            margin: 0px !important; /*overriding needed*/
            top: 0 !important; /*overriding needed*/
            opacity: 1 !important; /*overriding needed*/
            font-size: 14px;
          }
          .anticon {
            font-size: 15px;
            color: #8394BC;
            margin-left: 6px;
          }
        }
        .ant-table-expanded-row {
          .ant-table-wrapper {
            margin-top: 0;
          }
          .ant-table-tbody {
            border: 0;
            td {
              font-weight: normal;
              color: #000;
              line-height: 0px;
            }
          }
          .ant-table-placeholder {
            display: none;
          }
        }
      }
    }
    .ant-select.ant-select-multiple.language-translation {
      width: 165px;
      font-size: 13px;
      .ant-select-selector {
        border: 1px solid #E1E1E1;
      }
    }
    th.custom-purposes-description {
      display: flex;
      .th-desc {
        width: 40%;
      }
      .th-lgbs {
        width: 40%;
        padding-left: 5%;
      }
      .th-disclosure-only {
        text-align: center;
      }
		}
    td > div > .child-purpose-name {
      margin-left: 0px;
    }
    td > div > .anticon-down,
    td > div > .anticon-right {
      margin-right: 3px;
    }
    td.custom-purposes-description {
      display: flex;
      align-items: center;
      .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #fff;
      }
      .ant-select {
        width: 150px;
        .ant-select-selection-item,
        .ant-select-selection-placeholder {
          line-height: 27px;
        }
        .ant-select-selection-search-input {
          height: 27px;
        }
      }
      .ant-select.hideDropDown{
        visibility: hidden;
      }
      .read-only-legal-basis {
        width: 40%;
        padding-left: 5%;
      }
      .read-only-description {
        width: 45%;
        margin-top: 15px;
        margin-bottom: 15px;
        line-height: normal;
      }
      .read-only-description:only-child {
        width: auto;
      }
      .ant-input {
        width: 60%;
      }
      .ant-input:only-child {
        width: auto;
      }
      .checkbox-disclosure-only {
        //width: 40%;
        //padding-left: 15%;
        display: flex;
        justify-content: center;
        flex: 0.5;
      }
      .checkbox-disclosure-read-only{
        display: flex;
        justify-content: flex-start;
        flex: 0.5;
      }
    }
    td.cookie-category {
      .read-only-cookie-category {
        width: 152px;
        .display-count {
          color: @primary-color;
          background: #e9dfff;
          padding: 0px 2px;
          font-weight: 600;
          border-radius: 3px;
        }
      }
      .ant-select-selector {
        width: 176px;
        height: 35px;
        .ant-select-selection-overflow-item.ant-select-selection-overflow-item-rest {
          display: none;
        }
      }
      .empty-category {
        width: 176px;
      }
      .empty-category-edit {
        width: 153px;
      }
    }
    td.purpose-language {
      p {
        font-size: 12px;
      }
    }
    .language-translations-table {
      .purpose-title {
        padding-right: 0;
        padding-left: 6px;
      }
      .purpose-title .spsg-input {
        margin-left: 5px;
        margin-right: 18px;
      }
      .purpose-language {
        p {
          width: 57px;
          line-height: 11px;
        }
      }
      .purpose-action {
        .empty-action {
          width: 37px;
        }
      }
    }
  }
  .add-vendors-wrapper {
    .scan-vendors-tabs-mixin();
    .ant-tabs-nav{
      padding-top: 10px !important;
    }
    .action-wrapper{
      display: flex;
      margin-bottom: 10px;
      margin-top: 15px;
      .ant-btn{
        margin-left: 10px;
      }
      .ant-input{
        margin: 0px;
      }
    }
    .ant-tabs-content-holder{
      padding: 0px !important;
    }
    .vl-table{
      margin-top: 20px;
      .ant-table-container{
        border: 1px solid lightgray;
      }
      .ant-table-container table>thead>tr:first-child .ant-table-cell-scrollbar{
        display: none;
      }
      .ant-table-filter-column {
        align-items: center;
      }
      .ant-table-selection-column{
        border-right: 0px;
      }
      .ant-checkbox-indeterminate .ant-checkbox-inner{
        border: 1px solid @primary-color;
      }
      .ant-checkbox-indeterminate .ant-checkbox-inner::after{
        background-color: @primary-color;
        border: 1px solid @primary-color;
        border-radius: 2px;
      }
      .ant-checkbox.ant-checkbox-checked.ant-checkbox-indeterminate .ant-checkbox-inner{
        background-color: transparent;
        border: 1px solid @primary-color;
      }
      .ant-dropdown-trigger.ant-table-filter-trigger{
        font-size: 11px;
        border: 1px solid @primary-color;
        box-sizing: border-box;
        padding: 2px;
        margin: 0px;
        height: fit-content;
        .anticon {
          padding-right: 0px;
        }
      }
      table tbody td {
        .fa-circle {
          color: #FCA015
        }
        .more-tag {
          color: @primary-color;
          background: #e9dfff;
          padding: 0px 2px;
          font-weight: 600;
          border-radius: 3px;
          margin-left: 5px;
        }
      }
    }
    .vendors-section {
      flex: 0.75;
      width: 75%;
      .header {
        letter-spacing: 0.18px;
        color: #000000;
        font-size: 13px;
        font-weight: 600;
      }
      .ant-radio-group {
        margin: 5px 0;
        .ant-radio-wrapper {
          font-size: 12px;
          letter-spacing: 0px;
          color: #000000;
          .ant-radio-checked .ant-radio-inner {
            border-color: @primary-color;
          }
          .ant-radio-inner::after {
            background-color: @primary-color;
            border: 1px solid @primary-color;
          }
          .ant-radio-inner {
            height: 14px;
            width: 14px;
          }
        }
      }
      .ant-tabs {
        .ant-tabs-nav {
          padding-top: 0 !important;
          .ant-tabs-ink-bar {
            background: @primary-color;
          }
          .ant-tabs-tab {
            letter-spacing: 0.16px;
            font-size: 12px;
            padding: 7px;
            margin-top: 5px;
            .ant-tabs-tab-btn {
              color: #232A58;
            }
            .bold-title{
              font-weight: 500;
            }
          }
          .ant-tabs-tab + .ant-tabs-tab {
            margin: 5px 0 0 0px;
          }
          .ant-tabs-tab.ant-tabs-tab-active {
            background:rgb(233, 223, 255);
            border-radius: 3px;
            padding: 5px;
            font-size: 12px;
            font-weight: 500;
          }
          .ant-tabs-tab .anticon {
            margin-right: 5px;
          }
          .info-circle{
            padding-left: 5px;
            color:darkgray;
            font-size: 12px;
          }
          .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
            color: @primary-color;
          }
        }
        .ant-tabs-tabpane {
          .ant-table-wrapper {
            margin-top: 10px;
          }
          .note {
            letter-spacing: 0.14px;
            color: #000000A6;
            font-weight: normal;
            font-size: 11px;
            .note-label {
              font-weight: 600;
            }
          }
          .action-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 0px;
            border-top: 1px solid #eee;
            margin-top: 10px;
            .ant-input-search {
              font-size: 12px;
              width: 200px;
              .ant-input-group {
                display: flex;
              }
              .ant-input {
                font-size: 12px;
                margin-bottom: 0 !important;
                border: 1px solid #D9D9D9;
              }
              .ant-input-search-icon::before {
                border-left: 0;
              }
              .ant-input-search-icon {
                padding: 0;
              }
            }
            .ant-btn {
              margin-right: 3%;
              border: 1px solid #D9D9D9;
              letter-spacing: 0.2px;
              color: #4B4B4B;
              font-weight: 600;
              font-size: 12px;
              .anticon {
                color: @primary-color;
              }
            }
            .select-all-vendors {
              border: 1px solid @primary-color;
              border-radius: 5px;
              box-shadow: 0px 2px 3px #0000001f;
              font-family: Oswald;
            }
          }
          .ant-table {
            border: 1px solid #eee;
            border-right: 0;
          }
          th {
            letter-spacing: 0 !important; /* overriding needed */
            font-size: 11px !important; /* overriding needed */
            font-weight: 400 !important; /* overriding needed */
            color: #fff !important; /* overriding needed */
            text-transform: capitalize !important; /* overriding needed */
            background: #6565A9;
            padding: 0 5px;
            .ant-table-filter-column-title {
              padding: 0 10px 0 0;
              padding: 4px 2.3em 4px 0px;
						}
            .ant-table-column-sorters {
              padding: 5px 16px;
            }
            .ant-dropdown-trigger {
              font-size: 12px !important; /* overriding needed */
            }
            .anticon-info-circle {
              color: #fff;
              margin-left: 3%;
            }
            .sub-th {
              color: #fff;
              font-weight: normal;
            }
            .ant-table-column-title {
              overflow: unset;
              text-overflow: unset;
              word-break: unset;
            }
          }
          td {
            font-size: 12px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #000000A6;
            .svg-custom {
              margin-right: 8px;
            }
            .anticon-check {
              color: lightgray;
              cursor: pointer;
            }
            .anticon-check.vendor-selected,
            .anticon-check:hover {
              color: @primary-color;
              font-weight: bolder;
            }
            .freq-medium,
            .freq-high {
              color: #000000;
            }
            .freq-not-seen {
              color: #9F9D9D;
            }
            .freq-low {
              color: #FF8F2D;
            }
            .freq-very-low {
              color: #FF0000;
            }
            .risk-score-scale {
              display: flex;
              align-items: center;
              .risk-wrapper {
                height: 7px;
                width: 7px;
                margin-right: 1px;
              }
              .risk-level1 {
                background-color: #f00;
              }
              .risk-level2 {
                background-color: #E9AA49;
              }
              .risk-level3 {
                background-color: #0098FF;
              }
              .risk-level4 {
                background-color: #00BF1A;
              }
              .risk-level5 {
                background-color: #0f0;
                margin-right: 10%;
              }
              .risk-wrapper.disabled {
                background-color: #C4C4C4;
              }
            }
          }
          td.vendor-name {
            color: #000;
            .fa-circle {
              color: #fcae43;
            }
          }
        }
      }
    }
    .sl-vendors-new{
      background: #6565A9 0% 0% no-repeat padding-box;
      opacity: 1;
      padding: 10px;
      .sv-header{
        text-align: left;
        font: normal normal 600 14px/18px @primary-font;
        letter-spacing: 0.16px;
        color: #FFFFFF;
        opacity: 1;
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 5px;
  
        .bck-btn{
          cursor: pointer;
        }
      }
      .sv-divider{
        margin-top: 10px;
      }
      .sv-note{
        text-align: left;
        font: normal normal normal 10px/15px @primary-font;
        letter-spacing: 0.12px;
        color: #FFFFFF;
        opacity: 1;
        margin-top: 10px;
      }
      .sv-search{
        display: flex;
        flex: 1;
        margin-top: 20px;
        .sv-search-input{
          margin-right: 10px;
          .ant-input{
            margin-bottom: 0px;
          }
        }
      }
      .spsg-table-wrapper{
        margin-top: 20px;
      }
    }
    .selected-vendors {
      flex: 0.25;
      border-left: 1px solid #EEEEEE;
      padding-left: 1%;
      .vendors-wrapper {
        max-height: 75vh;
        overflow: auto;
      }
      .header {
        color: #000000A6;
        font-weight: 600;
        font-size: 12px;
        display: flex;
        justify-content: space-between;
        .remove-all {
          color: #411F9099;
          cursor: pointer;
        }
      }
      .no-vendors {
        color: #000000A6;
        opacity: 0.5;
        font-size: 11px;
        font-weight: normal;
        margin-top: 10px;
      }
      .selected-vendor-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        font-size: 12px;
        font-weight: normal;
        letter-spacing: 0px;
        .anticon-close-circle {
          color: rgba(0, 0, 0, 0.45);
        }
        .fa-circle {
          color: #fcae43;
        }
      }
    }
    .highlight{
      .ant-tabs-tab{
        background:rgb(233, 223, 255);
      }
    }
	}
  .vendor-category-mapping-wrapper {
    display: flex;
    flex-direction: column;
    .header {
      letter-spacing: 0.18px;
      color: #000000;
      font-size: 13px;
      font-weight: 600;
    }
    .recommended-settings-note {
      letter-spacing: 0px;
      color: #566892;
      font-size: 10px;
      margin: 10px 0;
      b {
        letter-spacing: 0px;
        color: #4B4B4B;
        font-weight: 600;
      }
    }
    .cookie-disclosure-wrapper {
      background: #e9dfff 0% 0% no-repeat padding-box;
      margin-bottom: 10px;
      padding: 10px;
      label {
        letter-spacing: 0px;
        color: #000000A6;
        font-size: 12px;
        .ant-checkbox-checked .ant-checkbox-inner {
          border-color: @primary-color;
          background: @primary-color 0% 0% no-repeat padding-box;
          border-radius: 2px;
        }
      }
      .anticon {
        color: #566892;
      }
    }
    .note {
      letter-spacing: 0px;
      color: #566892;
      font-size: 12px;
      margin-bottom: 10px;
    }
    table {
      border: 1px solid #eee;
      thead > tr > th {
        background: #6565A9 0% 0% no-repeat padding-box;
        text-transform: capitalize !important; /* overriding needed */
        font-size: 12px !important; /* overriding needed */
        font-weight: 400 !important; /* overriding needed */
        letter-spacing: 0.16px !important; /* overriding needed */
        color: #fff !important; /* overriding needed */
        padding: 10px;
      }
      td {
        padding: 5px 10px !important;
        font-size: 12px;
        color: #000000;
        font-weight: normal;
        .lb-consent {
          background: #E7F0FD 0% 0% no-repeat padding-box;
          letter-spacing: 0px;
          color: #1370FB;
          font-weight: 400;
          padding: 5px;
          border-radius: 2px;
        }
        .lb-legitimate {
          background: #FFF4E0 0% 0% no-repeat padding-box;
          letter-spacing: 0px;
          color: #FD9034;
          font-weight: 400;
          padding: 5px;
          border-radius: 2px;
        }
        .lb-disclosure {
          background: #cef3d4 0% 0% no-repeat padding-box;
          letter-spacing: 0px;
          color: #405143;
          font-weight: 400;
          padding: 5px;
          border-radius: 2px;
        }
        .ant-select {
          width: 80%;
          font-size: 12px;
          .ant-select-selector {
            border: 1px solid #eee;
            padding: 0;
          }
        }
      }
    }
  }
 
}

.exit-wizard-confirmation {
  width: auto !important;
  .ant-popover-arrow {
    border-right-color: #464545 !important; // overriding needed
    border-bottom-color: #464545 !important; // overriding needed
  }
  .ant-popover-inner {
    background: #464545;
    color: #fff;
    .ant-popover-message {
      color: #fff;
      font-size: 11px;
      .ant-popover-message-title {
        padding-left: 0;
      }
    }
  }
}

.vl-wizard-tooltip {
  p,
  .ant-tooltip-inner {
    font-size: 12px !important; // overriding needed
  }
}

.ant-select-dropdown.predefined-purposes-select {
  padding: 4px 0px 8px 0;
  .ant-divider{
    margin: 1px 0px 10px 0px;
  }
  .ant-btn {
    float: right;
    color: #fff;
    font-size: 11px !important;
    font-weight: normal;
    margin-right: 12px;
  }
  .ant-select-item-option {
    color: #4B4B4B;
    font-size: 11px;
    min-height: auto;
    padding: 8px 12px;
    line-height: initial;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: @primary-color;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #fff;
  }
}

.vl-wizard-info-storage {
  width: auto !important;
  .ant-popover-inner-content {
    letter-spacing: 0px;
    color: #4B4B4B;
    #info-storage-access-popup {
      display: flex;
      flex-direction: column;
      .info-item {
        align-items: center;
        display: flex;
        margin-bottom: 8px;
        span.isvg.loaded {
          display: flex;
          margin-right: 7px;
        }
      }
    }
  }
}


.cookie-disclosure-tooltip {
  .ant-tooltip-arrow-content {
    background-color: #fff;
  }
  .ant-tooltip-inner {
    color: #4B4B4B;
    background: #fff;
    font-size: 12px;
    font-weight: normal;
  }
}

.vendor-list-wizard.add-vendors-v2 {
  font-family: 'Open sans';
  .ant-modal-body {
    .ant-radio-group {
      .ant-radio + span {
        color: @primary-color;
        font-weight: 600;
      }
      .ant-radio.ant-radio-checked {
        &+span {
          font-weight: bold;
        }
      }
    }
  }
  .ant-modal-footer {
    padding: 10px 24px 24px 24px;
    .ant-btn:first-child {
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: none;
      letter-spacing: 0;
      color: @primary-color;
    }
    .ant-btn:last-child {
      background-color: @primary-color;
      color: #fff;
    }
    .ant-btn.ant-btn-default{
      color: #090E15;
      padding: 9px 16px;
      height: 40px;
      box-shadow: none;
      background: #FFFFFF;
      border: 1px solid #D7DDE9;
    }
    >div {
      .confirmation-footer {
        .confirmation-mixin()
      }
    }
  }
}

.custom-dropdown {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: @primary-color;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #fff;
  }
}

.cookie-category-info {
  .ant-popover-content {
    .ant-popover-inner .ant-popover-inner-content {
      padding: 9px;
      font-size: 11px;
    }
  }
}

.cookie-category-dropdown {
  .ant-select-tree-treenode.ant-select-tree-treenode-switcher-close {
    span.ant-select-tree-switcher{
      display: none;
    }
    .ant-select-tree-checkbox{
      position: absolute;
      right: 0;
      z-index: 1;
    }
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
      background-color: @primary-color;
      border-color: @primary-color;
    }
  }
}
.add-cookies-wrapper{
  font-family: 'Open sans';
  .uploaded-cookies{
    font-size: 12px;
    color: #212858;
    label{
      text-align: left;
      font: normal normal 600 16px/19px @primary-font;
      color: #212858;
      opacity: 1;
    }
    .empty-cookies{
      background: #411F900D 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      height:33px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal normal 600 14px/19px @primary-font;
      letter-spacing: 0px;
      color: @primary-color;
    }
  }

  .wrap {
    position: relative;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .arrow {
    width: 10px;
    height: 10px;
    border: 1px dashed #6565A9;
    transform: rotate(45deg);
    position: absolute;
    top: 0%;
    margin-top: -5px;
    z-index: 10;
  }
  .manual{
    left: 50px;
  }
  .import{
    left:180px !important;
  }
  .arrow-cover {
    top: 0%;
    margin-top: -3px;
    left: 50px;
    width: 10px;
    height: 10px;
    border: 1px solid transparent;
    position: absolute;
    background: #F5F0FF;
    z-index: 30;
    transform: rotate(45deg);
  }
  .box {
    background: #F5F0FF !important;
    z-index: 20;
    position: relative;
    border: 1px dashed #6565A9;
    background: #F5F0FF;
    border-radius: 10px;
    .ant-upload.ant-upload-drag{
      background: #F5F0FF !important;
      border-radius: 10px;
      a{
        color: #6565A9;
        font-weight: 500;
      }
      .drop-files{
        padding: 5px;
      }
    }
  }
  .action-wrapper{
    display: flex;
    flex:1;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    .ant-input{
      margin-bottom: 0px;
    }
    .ant-btn.btn-add-cookie.spsg-btn.btn-primary:disabled{
      background: #8F8F8F 0% 0% no-repeat padding-box !important;
      opacity: 1 !important;
    }
    .btn-add-cookie{
      margin-left: 10px;
    }
    .add-manually-btn{
      background: #E9DFFF 0% 0% no-repeat padding-box !important;
      border: 1px solid @primary-color !important;
      border-radius: 6px;
      opacity: 1;
      color: @primary-color !important;
      font-weight: 600;
    }
    .import-btn{
      margin-left: 10px;
    }
  }
  .header-section{
    .cookie-note{
      text-align: left;
      font: normal normal normal 12px/17px @primary-font;
      color: #000000;
    }
  }
  .spsg-table.cookie-table{
    margin-top: 10px;
    .ant-table-row.ant-table-row-level-1 .ant-table-selection-column{
      border-right: 1px solid #d3d9dd;
    }
    .ant-table-row.ant-table-row-level-0.ant-table-row-selected > td {
      background: #FFF 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    .ant-table-row-indent+.ant-table-row-expand-icon{
      margin-right: 0px;
    }
    .ant-table-row.ant-table-row-level-1 > td {
      background: #F9F9F9 0% 0% no-repeat padding-box;
      opacity: 1;
    }
    .ant-table-row.ant-table-row-level-0:hover td,
    .ant-table-row.ant-table-row-level-1:hover td{
      background: #F5F1FF;
    }
    .ant-table-selection-column{
      border-right: 0px;
      border-left: 1px solid lightgray;
    }
    .ant-table-container{
      border: 1px solid lightgray;
      table>thead>tr:first-child th:last-child{
        display: none;
      }
    }
    .ant-table-thead > tr > th {
      &.ant-table-selection-column {
        padding: 16px 8px;
      }
    }
    .ant-table-tbody > tr > td{
      line-height: 15px !important;
      padding: 16px 16px;
      &.ant-table-selection-column {
        padding: 16px 8px;
      }
      span.ant-table-row-indent.indent-level-0 {
        display: none;
      }
      .cookie-td {
        display: flex;
        flex-direction: row;
        .cookie-td-content{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          &.vendor-name{
            min-width: 110px;
            max-width: 10vw;
            display: flex;
            >div {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            >i {
              margin-right: 5px;
              &.fas.fa-circle {
                color: #FCA015
              }
            }
          }
        }
        .cookie-table-edit-icon {
          margin-right: 25px;
          color: @primary-color;
          margin-left: auto;
        }
        .more-tag {
          color: @primary-color;
          background: #e9dfff;
          padding: 0px 2px;
          font-weight: 600;
          border-radius: 3px;
          margin-left: 5px;
        }
      }
    }
    .ant-table-cell.ant-table-cell-with-append{
      display: flex;
      justify-content: space-between;
      align-items: center;
      button{
        order:1;
      }
    }
    .ant-table-tbody > tr.iab-declared {
      .ant-checkbox{
        span.ant-checkbox-inner{
          background-color: #DEDEDE;
          border-color: #B0B0B0;
        }
      }
      .ant-checkbox-wrapper{
        pointer-events: none;
        cursor: not-allowed !important;
      }
    }
  }

  .sl-vendors-new{
    background: #6565A9 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 10px;
    .sv-header{
      text-align: left;
      font: normal normal 600 14px/18px @primary-font;
      letter-spacing: 0.16px;
      color: #FFFFFF;
      opacity: 1;
      display: flex;
      flex: 1;
      justify-content: space-between;
      padding: 5px 20px 5px 0px;

      .bck-btn{
        cursor: pointer;
      }
    }
    .sv-divider{
      margin-top: 10px;
    }
    .sv-note{
      text-align: left;
      font: normal normal normal 10px/15px @primary-font;
      letter-spacing: 0.12px;
      color: #FFFFFF;
      opacity: 1;
      margin-top: 10px;
    }
    .sv-search{
      display: flex;
      flex: 1;
      margin-top: 20px;
      .sv-search-input{
        margin-right: 10px;
      }
      .ant-btn.btn-remove-cookie.spsg-btn.btn-secondary:disabled{
        background: #8F8F8F 0% 0% no-repeat padding-box !important;
        opacity: 1 !important;
        border-color: #8F8F8F !important;
      }
    }
    .spsg-table-wrapper{
      margin-top: 20px;
    }
    .ant-input{
      margin-bottom: 0px;
    }
  }
  .upload-div{
    font-family: 'Open sans';
    .drop-files{
      font-weight: 500;
      font-size: 16px;
    }
    .download-link{
      text-align: center;
      text-decoration: underline;
      font: normal normal 600 14px/19px @primary-font;
      letter-spacing: 0px;
      color: @primary-color;
    }
    .csv-container{
      flex-direction:row;
      flex:1;
      display:flex;
      align-items: center;
    
    .success-alert {
      margin-left: 20px;
      svg {
        color: white;
        border-radius: 100%;
        background: #43ba54 0% 0% no-repeat padding-box;
        width: 18px;
        height: 18px;
        margin-top: 0px;
        padding: 4px;
        transform: translateY(2px);
      }
      .success-text {
        font-family: @primary-font;
        color: #43ba54 ;
        padding-left: 6px;
      }
    }
  }
  }
  .csv-template-downloader{
    background: rgba(65, 31, 144, 0.1) 0% 0% no-repeat padding-box;
    border: 1px solid rgba(112, 112, 112, 0.1);
    border-radius: 5px;
    margin: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .close-icon{
      position:absolute;
      right:10px;
      color: @primary-color;
      font-size: 16px;
    }
    .tag-style{
      background: #F4EFFF 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      color: #212858;
      padding: 5px;
      height: 22px;
      display: flex;
      align-items: center;
      .fa-circle{
        color: #fcae43;
        margin-right: 2px;
      }
    }
    label{
      font: normal normal 600 14px/19px @primary-font;
      letter-spacing: 0.14px;
      color: @primary-color;
      opacity: 1;
    }
    .note{
      text-align: left;
      font: normal normal normal 10px/19px @primary-font;
      letter-spacing: 0.1px;
      color: #212858;
      opacity: 1;
    }
    .select-vendor{
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 3px #0000001F;
      border: 1px solid #ADADAD;
      border-radius: 5px;
      opacity: 1;
      height: 117px;
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-items: flex-end;
      padding: 5px;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;
      .sel-tag-container{
        flex-direction:row;
        flex:1;
        display:flex;
        flex-wrap:wrap;
        height:150px;
        overflow:auto;
        padding-bottom:5px;
      }
      .ant-select-selector{
        border:0px;
        box-shadow: 0px 0px 0px #0000001f;
      }
      .ant-select-selection-placeholder{
        left: 0px;
        right:auto;
      }
    }
  }
  .ant-table {
    .ant-modal-root {
      .ant-modal-mask, .ant-modal-wrap {
        position: absolute;
      }
      .ant-modal-wrap {
        padding: 0;
        margin: 0;
        .ant-modal {
          width: auto !important;
          top: auto;
          box-shadow: 0px 3px 6px #00000029;
          border: 1px solid #C9C9C9;
          padding: 0;
          .ant-modal-content {
            height: 165px;
            padding: 0;
            border-radius: 0;
            .ant-modal-close{
              display: none;
            }
            .ant-modal-body {
              padding: 15px;
              .form-container .ant-form {
                .main-container {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }

}

.form-container {
  .ant-form .main-container{
    display: flex;
    flex: 1;
    padding: 8px;
    justify-content: space-between;
    .ant-input-number{
      height:35px;
    }
    .ant-form-item{
      margin-left: 5px;
      .ant-form-item-required::before{
        display: none;
      }
    }
    .ant-form-item-explain-error{
      font: normal normal normal 11px/19px Open Sans
    }
    .ant-form-item-control-input-content{
      height:35px;
    }
    .ant-col.ant-form-item-label{
      padding: 0px 0px 0px 4px;
    }
    label{
      text-align: center;
      font: normal normal normal 10px/19px @primary-font;
      letter-spacing: 0.1px;
      color: #232A58;
      opacity: 1;
      padding: 0px;
    }
    .ant-select-selector {
      .ant-select-selection-item .fa-circle{
        color: #fcae43;
      }
    }
    .spsg-treeSelect .ant-select-selector {
        .ant-select-selection-overflow {
          flex-wrap: nowrap;
          .ant-select-selection-overflow-item:first-child {
            width: auto;
            max-width: 125px;
            display: block;
            .selected-purpose-tag {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-bottom: 0;
              font: normal 12px @primary-font;
              letter-spacing: 0px;
              color: #272727;
              opacity: 1;
            }
          }
          .ant-select-selection-overflow-item-rest {
            .ant-select-selection-item {
              background: #411F9014 0% 0% no-repeat padding-box;
              border-radius: 3px;
              opacity: 1;
              width: 35px;
              .purpose-count {
                font: normal 600 12px @primary-font;
                letter-spacing: 0px;
                color: @primary-color;
              }
            }
          }
        }
    }
    .set-duration {
      input { 
        margin-bottom: 0px;
        width: 100px;
      }
      .ant-input-group-addon{
        background-color: #fff;
        >.spsg-select >.ant-select{
          width: 100px;
          height: 32px;
          margin: -4px -11px;
        }
      }
    }
  }

  .ant-form .form-footer {
    display: flex;
    justify-content: flex-end;
    padding: 0px 8px;
    button {
      margin-left: 20px;
    }
    .ant-form-item {
      margin-bottom: 15px;
    }
  }
}

.purpose-dropdown {
  width: 272px !important;
  border-radius: 3px;
  .ant-select-tree-switcher{
    display: none;
  }
  .ant-select-tree-checkbox {
    position: absolute;
    right: 5px;
    z-index: 1;
  }
  .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner{
    background-color: @primary-color;
    border-color: @primary-color;
  }
  .ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner{
    border-color: @primary-color;
  }
  .ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after{
    background-color: @primary-color;
  }
  .ant-select-tree-list {
    letter-spacing: 0.14px;
    color: #232A58;
    opacity: 1; 
    .ant-select-tree-treenode:first-child {
      .ant-select-tree-title{
        font: normal bold 12px @primary-font;
      }
    }
    .ant-select-tree-treenode .ant-select-tree-title {
      font: normal 12px @primary-font;
    }
    .ant-select-tree-treenode {
      width: 220px;
      .ant-select-tree-node-content-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.selected-vendors-options {
  .ant-select-item-option-content .fa-circle{
    color: #fcae43;
  }
}

.spsg-infotip.error-info {
  .ant-popover-inner-content {
    color: #FF2626 !important;
    font-size: 11px !important;
    padding: 5px !important;
  }
}

.spsg-filter-dropdown.add-vendors-table-filter {
  min-width: 180px;
}
