@font-color: #2d2d2d;
@primary-color: #4f28b3;
@primary-light-color: #9380be;
@primary-medium-color: #6565a9;
@primary-light-background: #eeeeff42;
@border-color: #d4d4d4;
@boxshadow-color: #0000001f;
@boxshadow-primary-color: #411f9021;
@table-border-color: #cabee4;
@info-circle-color: #808080;
@light-grey-color: #707070;

@import "./modal.css.less";
@import "./filters.css.less";
@import "./scan-setup.css.less";
@import "./vendor-details.css.less";

.diagnose_container {
  left: 0;
  width: 100%;
}
.vendor-status-content {
  display: flex;
  align-items: center;
  .vendor-status-cell-content-approved {
    border: 1px solid #abdbae;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-right: 6px;
    background-color: #e6f5e6;

    .status-icon {
      // height: 15px;
      padding-right: 4px;

      svg {
        height: 11px;
        width: 11px;
      }
    }
  }
  .vendor-status-cell-content-unreviewed {
    border: 1px solid #d3d9dd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-right: 6px;
    background-color: #fbfbfb;

    .status-icon {
      // height: 15px;
      padding-right: 4px;

      svg {
        height: 11px;
        width: 11px;
      }
    }
  }
  .vendor-status-cell-content-action {
    border: 1px solid #e6a297;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-right: 6px;
    background-color: #fff1f0;

    .status-icon {
      // height: 15px;
      padding-right: 4px;

      svg {
        height: 11px;
        width: 11px;
      }
    }
  }
  .vendor-status-cell-content-under {
    border: 1px solid #b198d9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-right: 6px;
    background-color: #eae4f2;

    .status-icon {
      // height: 15px;
      padding-right: 4px;

      svg {
        height: 11px;
        width: 11px;
      }
    }
  }
  .vendor-status-cell-content-risk {
    border: 1px solid #ffe58f;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 0 5px;
    margin-right: 6px;
    background-color: #fffbe6;

    .status-icon {
      // height: 15px;
      padding-right: 4px;

      svg {
        height: 11px;
        width: 11px;
      }
    }
  }
}

#avocado .diagnose_notifications,
#avocado .diagnose_dashboard {

  .ant-tag {
    &.primary {
      background-color: #f5f1ff;
      border: 0.5px solid @primary-medium-color;
      border-radius: 4px;
      color: @primary-medium-color;
      font-weight: bold;
      padding: 4px 7px;
    }
  }

  .diagnose_dashboard-back-and-page-name-block {
    position: sticky;
    top: 0;
    // dd-2778 background flip
    background-color: var(--ant-color-bg-layout);
    box-shadow: 0px 0 0px 10px var(--ant-color-bg-layout);
    display: flex;
    align-items: center;
    padding-bottom: 28px;
    z-index: 6;

    .diagnose_dashboard-page-name-block {
      font-size: 18px;
      line-height: 14px;
      text-transform: uppercase;
      font-weight: 400;
      margin: 0;
    }
  }

  .link_back {
    font-size: 20px;
    line-height: 24px;
    color: @primary-color;
    font-weight: 300;
    display: block;
    width: max-content;
    margin-right: 8px;

    &.small {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .headers {
    &.main_header {
     font-size: 38px;
     font-weight: 700;
     text-transform: uppercase;
     margin: 0 0 33px;
    }

    &.secondary_header {
      font-size: 26px;
      font-weight: 400;
    }
  }

  .btn-primary {
    margin-top: 30px;
    border-radius: 3px;
    border-color: transparent;
    background-color: @primary-color;
    &.ant-btn,
    & > button {
      background-color: @primary-color;
      border-width: 0;
      box-shadow: none;
      border-color: transparent;
      font-size: 16px;
      height: 40px;
      margin-bottom: 0;
      border-radius: 3px;
      color: white;
      padding: 0;
      &::after {
        content: "";
        background-color: white;
      }
      &:first-child {
        width: 100%;
        padding: 0 15px;
      }
      &:hover {
        opacity: 0.8;
        transition: all 0.2s;
      }
    }
    &.ant-btn {
      padding: 0 15px;
    }
    .ant-dropdown-trigger.empty {
      padding-left: 0;
    }
  }

  .diagnose_dashboard-main-filters-hint {
    margin-bottom: 10px;
    font-size: 16px;
  }

  .diagnose_dashboard-main-filters-block {
    z-index: 6;
    position: sticky;
    top: 30px;
    // dd-2778 background flip
    background-color: var(--ant-color-bg-layout);
    box-shadow: 0px 10px 0px 10px var(--ant-color-bg-layout);
  }

  .set-date-range-notification {
    display: flex;
    align-items: center;
    background-color: var(--ant-color-bg-layout);
    width: 100%;
    padding: 12px;
    margin-bottom: 15px;
    border: 1px solid @border-color;
    border-radius: 5px;
    background-color: #fff;
    .ant-btn.ant-btn-link {
      color: @primary-color;
    }

    .ant-btn:not(.ant-btn-link) {
      border-color: @primary-color;
      color: @primary-color;
    }
  }

  .filters_divider {
    border-color: @border-color;
    margin-top: 0;
  }

  .summary_charts_container,
  .overview_container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;

    .card {
      margin-bottom: 10px;
      height: 338px;
      min-width: 330px;
      width: 33%;
      box-shadow: 0 2px 4px @boxshadow-color;
      border: 1px solid @border-color;
      border-radius: 25px;
      padding-bottom: 60px;

      .card_title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border-bottom: 1px solid @border-color;
        padding: 20px;

        b {
          margin-right: 5px;
        }

        .anticon.anticon-info-circle {
          color: @info-circle-color;
        }
      }

      .line_chart_container {
        display: flex;
        height: 100%;

        .line_chart_yaxis_label {
          display: flex;
          align-items: center;
          height: 90%;

          div {
            writing-mode: vertical-lr;
            text-orientation: mixed;
            font-size: 8px;
            transform: rotate(180deg);
            padding-right: 10px;
          }
        }
      }

      .recharts-responsive-container {
        margin: 0 auto 0 0;
      }

      .horizontal-bar-chart-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 95%;

        .horizontal-bar-chart {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
        }

        .horizontal-bar-chart-legend {
          display: flex;

          .horizontal-bar-chart-legend-item {
            display: flex;
            align-items: center;
            margin: 0 10px 10px 0;

            .horizontal-bar-chart-legend-icon {
              height: 10px;
              width: 10px;
              border-radius: 50%;
              margin-right: 5px;
            }

            .horizontal-bar-chart-legend-item-name {
              margin-right: 5px;
            }
          }
        }
      }

      .linear-gauge-chart-container {
        text-align: center;
        margin-top: 20px;

        .linear-gauge-chart-value {
          font-size: 50px;
          font-weight: 700;
        }

        .linear-gauge-chart-name {
          margin-bottom: 20px;
        }

        .linear-gauge-chart-overlay {
          width: 90%;
          display: flex;
          position: relative;
          margin: auto;

          .linear-gauge-chart-pointer {
            position: absolute;
            top: -20px;
          }

          .linear-gauge-chart-step {
            height: 15px;
            width: 25%;
          }
        }

        .linear-gauge-chart-legend {
          display: flex;
          flex-wrap: wrap;
          padding-left: 20px;
          font-size: 10px;
          margin-top: 40px;

          .linear-gauge-chart-legend-item {
            display: flex;
            align-items: center;
            margin: 0 10px 10px 0;

            .linear-gauge-chart-legend-icon {
              height: 10px;
              width: 10px;
              border-radius: 50%;
              margin-right: 5px;
            }

            b {
              margin-right: 5px;
            }
          }
        }
      }

      .relative-score-and-average-count-evolution-chart {
        .recharts-wrapper {
          padding: 0;

          .recharts-legend-wrapper {
            ul > li {
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  .summary_charts_container.overview_container {
    .card {
      width: 45%;
      min-width: 400px;
    }
    .overview-count.card {
      width: calc(55% - 10px);
      margin-left: 10px;
      min-width: 400px;
      padding: 10px 20px;
    }
  }

  .compliance_metrics_container {
    .ant-divider.ant-divider-horizontal {
      border-color: @border-color;
      margin-bottom: 0;
    }

    .ant-table {
      table {
        border-spacing: 0 10px;
      }

      tbody {
        top: -10px;
        position: relative;
      }

      .ant-table-thead > tr > th {
        background-color: #ffffff;
        text-transform: capitalize;
        font-size: 12px;
        font-weight: 700;
        color: @font-color;
        letter-spacing: 0;
        border-color: @border-color;
        border-bottom: none;
      }

      .ant-table-thead > tr > th[colspan="2"] {
        border-bottom: 1px solid @border-color;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(
          .ant-table-row-expand-icon-cell
        ):not([colspan])::before {
        display: none;
      }

      .metrics-row,
      .metrics-row-disabled {
        box-shadow: 0 2px 4px @boxshadow-color;
        border-radius: 10px;

        &:hover {
          cursor: pointer;
          box-shadow: 0 2px 4px @boxshadow-primary-color;

          > td {
            background: @primary-light-background;
            border-color: @primary-light-color;
          }
        }

        td {
          border: 1px solid @border-color;
          padding-top: 19px;
          padding-bottom: 19px;

          &:first-child {
            border-radius: 10px 0 0 10px;
          }

          &:last-child {
            border-radius: 0 10px 10px 0;
          }

          &:not(:last-child) {
            border-right: none;
          }

          &:not(:first-child) {
            border-left: none;
          }

          .recharts-wrapper {
            padding: 0;
          }
        }
      }

      .metrics-row-disabled {
        background-color: var(--ant-color-bg-layout);
        box-shadow: none;

        &:hover {
          cursor: default;
          box-shadow: none;

          > td {
            border-color: @border-color;
            background: var(--ant-color-bg-layout);
          }
        }
      }

      .metrics-row-hidden {
        display: none;
      }
    }
  }

  .compliance_metrics_container_app{
    padding: var(--ant-margin) 0px;
    .ant-table-wrapper{
      .ant-table-column-sorters{
        justify-content: normal;
        .ant-table-column-title{
          flex:0.1
        }
      }
    }
  }

  app-detailed-breakdown-container{

  }

  .detailed-breakdown-container {
    padding-top: var(--ant-margin);
    .ant-divider.ant-divider-horizontal {
      border-color: @border-color;
      margin: var(--ant-margin-sm) 0px;
    }
    .subtext{
       margin: 0px var(--ant-margin) ;
       margin-bottom: 0;
    }

    .detailed-breakdown-table-container {
      box-shadow: 0px 2px 3px #0000001f;
      border: 1px solid @border-color;
      padding: 30px;
      border-radius: 25px;
      margin-top: 30px;

      .ant-table {
        font-size: 12px;

        .ant-table-thead > tr {
          > th,
          > td {
            background-color: #e9dfff;
            font-size: 12px;
            font-weight: 600;
            text-transform: capitalize;
            color: @font-color;
            border: 1px solid @table-border-color;
           // padding: 20px 10px 4px 16px;
            position: relative;

            &.ant-table-cell-scrollbar {
              display: none;
            }

            &:not(:nth-last-child(-n + 2)) {
              border-right: none;
            }

            &:nth-child(2) {
              border-left: none;
            }

            .ant-table-column-sorter {
              color: #957fc7;

              .ant-table-column-sorter-up.active,
              .ant-table-column-sorter-down.active {
                color: @primary-color;
                font-size: 18px;
              }
            }
          }
        }

        .ant-table-thead .ant-table-filter-column .anticon {
          padding: 0;
        }

        .ant-table-tbody > tr.ant-table-expanded-row > td {
          padding: 0 0 0 46px;

          &:first-child:before {
            display: none;
          }

          > .ant-table-wrapper:only-child .ant-table {
            margin: 0;
          }

          .ant-table {
            tr {
              td {
                &:last-child {
                  border-right: none;
                }

                &:first-child:before {
                  display: none;
                }

                &:nth-child(2) {
                  border-left: 1px solid @table-border-color;
                }
              }

              &:last-child {
                td {
                  border-bottom: none;
                }
              }
            }
          }
        }

        .ant-table-tbody > tr > td {
          border: 1px solid @table-border-color;
          border-top: none;
          padding: 5px 10px;

          &:not(:last-child) {
            border-right: none;
          }

          &:nth-child(2) {
            border-left: none;
          }

          .anticon {
            cursor: default;
          }

          &:nth-child(1):before {
            position: absolute;
            top: 50%;
            right: 0;
            width: 1px;
            height: 1.6em;
            background-color: rgba(0, 0, 0, 0.06);
            transform: translateY(-50%);
            transition: background-color 0.3s;
            content: "";
          }

          .ant-table-row-expand-icon:focus,
          .ant-table-row-expand-icon:hover {
            color: @primary-color;
          }

          .ant-table-row-expand-icon:focus,
          .ant-table-row-expand-icon:hover,
          .ant-table-row-expand-icon:active {
            border-color: @primary-color;
          }
        }

        .ant-table-tbody > tr.ant-table-row:hover > td {
          background-color: #f6f2ff;
        }

        .ant-table-filter-trigger.active {
          color: @primary-color;
        }

        .ant-dropdown-trigger {
          font-size: 8px;
        }

        .ant-table-filter-trigger {
          color: @primary-medium-color;
          top: 4px;
          right: 4px;
          border: 1px solid @primary-medium-color;
          height: 16px;
          padding: 3px;
          position: absolute;
          margin: 0;

          svg {
            height: 8px;
          }
        }

        .anticon.anticon-info-circle {
          color: @info-circle-color;
        }

        // .vendor-status-cell-content {
        //   border: 0.5px solid #C9C9C9;
        //   border-radius: 21px;
        //   display: flex;
        //   align-items: center;
        //   padding: 0 5px;
        //   margin-right: 6px;

        //   .status-icon {
        //     height: 15px;
        //     padding-right: 4px;

        //     svg {
        //       height: 11px;
        //       width: 11px;
        //     }
        //   }
        // }
        .is-on_vendor-list-cell {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .cookie-icon-container {
          position: relative;
          width: 18px;
          margin: auto;

          .cookie-refresh-icon,
          .uses-non-cookie-access-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 2;
            border: 1px solid white;
            border-radius: 50%;
            background-color: #00b3a6;
            height: 12px;
            width: 12px;
            bottom: 3px;
            right: -3px;

            .anticon {
              padding: 0;
              color: white;
              display: flex;
              font-size: 6px;
            }
          }

          .uses-non-cookie-access-icon {
            background-color: #ff6600;
          }

          .uses-cookies-icon {
            width: 4px;
            height: 25px;
            transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
            background: #566892 0% 0% no-repeat padding-box;
            border: 1px solid #ffffff;
            opacity: 1;
            position: absolute;
            z-index: 2;
            bottom: 2px;
            left: 7px;
          }
        }

        .reference-vendors-cell {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          overflow: hidden;

          .reference-vendors-cell-content {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100px;
            margin-right: 5px;
          }

          .ant-tag {
            margin: 0;
          }
        }
      }

      .ant-pagination {
        .ant-pagination-item {
          border-radius: 4px;
        }

        .ant-pagination-item-active {
          border-color: @primary-color;
          color: @primary-color;
        }

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          border-radius: 4px;
        }

        .ant-pagination-item-active a {
          color: @primary-color;
        }

        .ant-pagination-options {
          .ant-select {
            min-width: 120px;

            .ant-select-selector {
              height: 32px;
            }
          }
        }
      }

      .filters_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .quick_filters,
        .downloadCSV {
          display: flex;
          align-items: center;

          button.ant-btn {
            border-color: @primary-color;
            color: @primary-color;
            border-radius: 10px;

            &:not(:first-child) {
              margin-left: 10px;
            }
          }

          .anticon {
            padding: 0;
          }
        }
      }

      .vendor-types-legend {
        display: flex;
        align-items: center;
        margin-top: 10px;

        .vendor-types-item {
          display: flex;
          align-items: center;
          margin-right: 10px;

          .vendor-types-item-google {
            margin-right: 5px;
            height: 16px;
          }

          .vendor-types-item-iab {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #fca015;
            margin-right: 5px;
          }

          .vendor-types-item-custom {
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background-color: #6783a7;
            margin-right: 5px;
          }
        }
      }
    }

    .detailed-breakdown-table-container.disclosed-vendors-table-container {
      .disclosed-vendors-tabs-container {
        display: flex;
        align-items: center;
        border-bottom: 1px solid @border-color;
        margin-bottom: 18px;
        padding-bottom: 10px;

        .disclosed-vendors-header {
          display: flex;
          align-items: baseline;
          margin-right: 10px;

          .secondary_header {
            margin-bottom: 0;
          }

          .anticon.anticon-info-circle {
            color: @info-circle-color;
            padding-left: 5px;
            font-size: 17px;
          }
        }

        .disclosed-vendors-tabs {
          .ant-radio-button-wrapper {
            border-left-width: 1px;

            &:not(:first-child)::before {
              display: none;
            }
          }

          label.ant-radio-button-wrapper {
            height: 35px;
            width: auto;
            padding: 0 15px;
            border-radius: 5px !important;
            margin-right: 5px;
            border-color: #dcdade !important;
            background-color: var(--ant-color-bg-layout);
            color: #303030;
            box-shadow: 0px 2px 3px #0000001f;

            .ant-radio-button-checked {
              background: none;
            }
          }

          label.ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
            border-color: @primary-color !important;
            background-color: white;
            color: @primary-color;
          }
        }
      }

      .ant-table {
        .ant-table-thead > tr > td,
        .ant-table-thead > tr > th {
          padding: 10px 30px 10px 10px;
          background-color: white;
          border-color: #efefef;
          height: 58px;

          .ant-table-cell-scrollbar {
            display: none;
          }

          &:not(:last-child):not(.ant-table-selection-column):not(
              .ant-table-row-expand-icon-cell
            ):not([colspan])::before {
            display: none;
          }
        }

        .ant-table-thead > tr:last-child > th {
          border-top: none;

          &:nth-child(2) {
            border-left: none;
          }

          &:nth-child(3) {
            border-left: 1px solid #efefef;
          }
        }

        .ant-table-thead > tr > th:nth-child(2) {
          border-left: 1px solid #efefef;
        }

        .ant-table-tbody > tr > td {
          border-color: #efefef;

          &:first-child {
            border-right: none;
          }

          &:nth-child(2) {
            border-left: none;

            &:before {
              display: none;
            }

            div {
              font-size: 12px;
              font-weight: 500;
              text-align: right;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            max-width: 100px;
          }

          &:nth-child(3) {
            border-left: 1px solid #efefef;
          }
        }

        .ant-table-filter-trigger {
          top: 36%;
        }

        .prevalence-rate-comparison-column {
          width: 40%;
        }

        .prevalence-rate-comparison-cell {
          position: relative;
          cursor: pointer;

          .prevalence-rate-cell {
            top: 5px;
            background-color: #09b3a5;
            position: absolute;
            height: 15px;
          }

          .prevalence-benchmark-rate-cell {
            background-color: #d9e9ff;
            height: 25px;
          }
        }

        .prevalence-title-cell {
          display: flex;
          align-items: center;
        }

        .prevalence-cell,
        .prevalence-benchmark-cell {
          display: flex;
          justify-content: center;
          align-items: baseline;

          > div {
            font-size: 18px;
            font-weight: 500;
            padding-right: 2px;
          }
        }

        .technologies-column {
          width: 15%;
        }

        // TODO: seems to be dublicate of reference-vendors-cell, need to be checked and removed if, yes
        .technologies-cell {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .technologies-cell-content {
            margin-right: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
          }

          .ant-tag {
            margin: 0;
          }
        }
      }
    }
  }

  .ant-spin-dot-item {
    background-color: @primary-color;
  }

  .recommendations-window {
    position: fixed;
    right: 0;
    width: 170px;
    z-index: 10;
    margin-top: 10px;
    transition: all 0.3s linear;

    .recommendations-window-btn {
      width: 100%;
      color: white;
      background-color: @primary-color;
      font-size: 12px;
      padding: 10px;
      border: none;
      border-radius: 5px 0 0 5px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .recommendations-window-info {
      border: 1px solid @border-color;
      border-radius: 0 0 0 5px;
      background-color: white;
      display: none;
      padding: 10px;
      font-size: 12px;
      box-shadow: 0px 2px 4px #0000001f;
      text-align: left;
    }

    &.open {
      width: 230px;

      .recommendations-window-btn {
        border-radius: 5px 0 0 0;
      }

      .recommendations-window-info {
        display: block;
      }
    }
  }

  .ant-collapse {
    border: unset;
    background-color: unset;
    border-radius: unset;

    > .ant-collapse-item {
      border-radius: 10px;
      border: 1px solid @border-color;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      > .ant-collapse-header {
        align-items: center;
        > .ant-collapse-expand-icon .ant-collapse-arrow {
          width: 24px;
          margin-top: 6px;
          transition: all 0.5s ease;

          &.active {
            transform: rotate(-180deg);
          }
        }
      }

      .ant-collapse-content,
      .ant-collapse-header {
        border-radius: 10px;
        border: unset;
        background-color: white;
      }
      // dd-2778 background flip
      &.ant-collapse-item-active {
        .ant-collapse-header {
          border-radius: 10px 10px 0 0;
        }
        .ant-collapse-content {
          border-radius: 0 0 10px 10px;
        }
      }
    }
  }

  .ant-tabs.spsg-tabs.diagnose-overview {
    .ant-tabs-tab {
      font-weight: 500;
    }
    .ant-tabs-nav {
      z-index: initial;
      padding-top: unset;
      .ant-tabs-tab + .ant-tabs-tab {
        border-left: 1px solid #dae4eb;
      }
    }

    .ant-tabs-content-holder {
      box-shadow: none;
      padding: 12px 0;
    }
  }

  .diagnose-dashboard-content-wrapper {
    //background-color: #fff;
    border-radius: 10px;
    padding: var(--ant-margin) 0px;
    .avocado.ant-empty {
      height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media (max-width: 1120px) {
  #avocado
    .diagnose_dashboard
    .compliance_metrics_container
    .ant-table
    .metrics-row
    td {
    padding: 16px 10px !important;
    font-size: 12px;
  }
}

.ant-table-filter-dropdown {
  color: @font-color;

  .filter-button-group,
  .ant-table-filter-dropdown-btns {
    .ant-btn.ant-btn-primary {
      background: @primary-color;
      border-color: @primary-color;
      color: #fff;
    }

    .ant-btn {
      margin-bottom: 0;
      color: @primary-medium-color;
    }
  }

  .ant-dropdown-menu-item {
    font-size: 12px !important;
  }

  .ant-dropdown-menu-item-selected,
  .ant-dropdown-menu-submenu-title-selected {
    background-color: #e9dfff;
    color: @primary-color;

    .ant-checkbox-checked .ant-checkbox-inner {
      background: @primary-color;
      border-color: @primary-color;
    }
  }
}

.tooltip-overlay-wide-350 {
  max-width: 350px;
}

.vendor-status-properties-tooltip-overlay {
  padding-top: 0;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    border: 1px solid #cecece;
    border-radius: 10px;
    background: white;
  }

  .vendor-status-properties-tooltip {
    color: @font-color;

    .property-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;

      .vendor-status-website-name {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-btn-primary {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        min-width: 12px;
        width: 12px;
        height: 12px;
        margin-left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        .anticon {
          font-size: 10px;
          margin-left: 5px;

          path {
            fill: @primary-color;
          }
        }
      }
    }
  }
}

.vendor-status-tooltip-overlay.diagnose--tooltip {
  .ant-tooltip-inner {
    border: 1px solid #cecece;
    border-radius: 10px;
    background: white;
    padding: 0;
  }

  .vendor-status-tooltip {
    min-width: 220px;
    padding: 10px 10px 5px;

    .vendor-status-tooltip-title {
      font: normal normal bold 14px/19px "Open Sans";
      margin-bottom: 10px;

      &.approved-title {
        color: #03a233;
      }

      &.action-required-title {
        color: #ff0909;
      }

      &.under-review-title {
        color: #7660ea;
      }

      &.approved-with-risk-title {
        color: #ff8c33;
      }

      &.unreviewed-title {
        color: #707070;
      }
    }

    .vendor-status-tag-legend {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .vendor-status-tag-legend-item {
        display: flex;
        align-items: center;
        font: normal normal normal 14px/18px "Open Sans";

        &:first-child {
          margin-right: 10px;
        }

        &.new-tag {
          color: #00b0ff;

          .tag-icon {
            background: #00b0ff;
          }
        }

        &.change-detected-tag {
          color: #e7ac05;

          .tag-icon {
            background: #ffd500;
          }
        }

        .tag-icon {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }

    .vendor-list-status-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #272727;
      margin-bottom: 15px;

      .vendor-status-website-name {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font: normal normal normal 14px/17px Open Sans;
      }

      .vendor-status-tag {
        margin-left: 12px;

        &.blue-tag {
          background: #00b0ff;
        }

        &.yellow-tag {
          background: #ffd500;
        }
      }
    }
  }
}

.vendor-status-tag {
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
}

.vendor-status-tooltip-overlay {
  .information-storage-item {
    align-items: flex-start !important;
  }
}

.information-storage-tooltip-overlay,
.vendor-status-tooltip-overlay {
  .information-storage-tooltip {
    color: #272727;
    padding: 20px 20px 20px 10px;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 10px; 

    .information-storage-item {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 14px;
      width: 149px;

      &:last-child {
        margin-bottom: 0;
      }

      .information-storage-item-unreveiwed{
        border-radius:  4px;
        border:  1px solid #D3D9DD;
        background:  #FBFBFB;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        display: flex;
        align-items: center; 
        justify-content: center;
      }
      .information-storage-item-underreviewed{
        border-radius:  4px;
        border:  1px solid #B198D9;
        background:  #EAE4F2;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        display: flex;
        align-items: center; 
        justify-content: center;
        svg{
          color: #4F28B3;
        }
      }
      .information-storage-item-approved{
        border-radius:  4px;
        border:  1px solid #ABDBAE;
        background:  #E6F5E6;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        display: flex;
        align-items: center; 
        justify-content: center;
        svg{
          color: #1F5C21;
        }
      }
      .information-storage-item-action{
        border-radius:  4px;
        border:  1px solid #E6A297;
        background:  #FFF1F0;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        display: flex;
        align-items: center; 
        justify-content: center;
        svg{
          color: #A8071A;
        }
      }
      .information-storage-item-risk{
        border-radius:  4px;
        border:  1px solid #FFE58F;
        background:  #FFFBE6;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        display: flex;
        align-items: center; 
        justify-content: center;
        svg{
          color: #7D4B00;
        }
      }
      .information-storage-item-tech{
        display: flex;
        align-items: center; 
        justify-content: center;
        .ant-image-img{
          width: 30px;
          height: 30px;
          color: #272727;
        }
        
      }
      .information-storage-item-name {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        font-family: "Open Sans";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        span {
          color: var(--Colors-Neutral-Text-colorTextDescription, #8F8F8F);
        }

        b {
          margin-bottom: 2px;
        }
      }

      .vendor-status-tag {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 12px;
      }

      .isvg {
        margin-top: 2px;
      }
    }
  }

  .information-storage-tooltip.server-location-tooltip {
    font-size: 10px;
  }
}

.information-storage-tooltip-overlay,
.vendor-status-tooltip-overlay,
.detailed-breakdown-cell-tooltip-overlay,
.diagnose--tooltip {
  &.ant-tooltip-placement-rightTop,
  &.ant-tooltip-placement-rightBottom,
  &.ant-tooltip-placement-right {
    .ant-tooltip-arrow {
      left: 1px !important;
    }
  }

  &.ant-tooltip-placement-leftTop,
  &.ant-tooltip-placement-leftBottom,
  &.ant-tooltip-placement-left {
    .ant-tooltip-arrow {
      right: 1px !important;
    }
  }

  &.ant-tooltip-placement-topLeft,
  &.ant-tooltip-placement-topRight,
  &.ant-tooltip-placement-top {
    .ant-tooltip-arrow {
      bottom: 1px !important;
    }
  }

  &.ant-tooltip-placement-bottomRight,
  &.ant-tooltip-placement-bottomLeft,
  &.ant-tooltip-placement-bottom {
    .ant-tooltip-arrow {
      top: 1px !important;
    }
  }

  .ant-tooltip-arrow {
    --antd-arrow-background-color: white;
  }

  .ant-tooltip-inner {
    border: 1px solid #cecece;
    border-radius: 10px;
    background: white;
    color: @font-color;
    padding: 16px;
  }

  .detailed-breakdown-cell-tooltip-content {
    color: #232a58;
    padding: 15px;
  }
}

.prevalence-rate-comparison-cell-tooltip-overlay {
  max-width: 300px;

  .ant-tooltip-inner {
    border: 1px solid #cecece;
    border-radius: 10px;
    background: white;
  }

  .prevalence-rate-comparison-cell-tooltip-content {
    color: @font-color;
    padding: 0 15px;

    .tooltip-header {
      font-size: 18px;
      font-weight: 500;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 0;
      font-size: 10px;
      border-bottom: 1px solid @border-color;

      &:last-child {
        border: none;
      }
    }
  }
}

.vendor-details-tooltip-overlay {
  .ant-tooltip-inner {
    border: 1px solid #cecece;
    border-radius: 10px;
    background: white;
  }

  .vendor-details-tooltip {
    color: #232a58;
    padding: 10px;
  }
}

.ant-select-item-option-selected:after {
  display: none;
}

.ant-dropdown {
  // .information_storage_custom_filter, //TODO: unused now
  .dianose-dashboard_filter,
  .prevalence_custom_filter,
  .vendor_status_custom_filter {
    &.spsg-filter-dropdown .list-search,
    .list-search {
      margin: 20px 20px 0;
      padding: 0;
      border-bottom: 1px solid #d3d9dd;

      .anticon-search svg {
        top: 10px;
        right: 10px;
      }
    }

    &.spsg-filter-dropdown .filter-button-group,
    .ant-table-filter-dropdown-btns {
      margin: 5px 20px 20px;
      padding: 0;
      padding-top: 15px;
      border-top: 1px solid #d3d9dd;
    }

    &.spsg-filter-dropdown .ant-dropdown-menu,
    .ant-dropdown-menu {
      padding: 0;
    }

    .ant-dropdown-menu .ant-divider-horizontal,
    .ant-dropdown-menu .ant-divider-horizontal.filter-divider,
    .ant-divider-horizontal,
    .ant-divider-horizontal.filter-divider {
      margin: 5px 20px;
      min-width: unset;
      width: calc(100% - 40px);
      display: flex;
      background-color: #d3d9dd;
    }
  }
}

.prevalence_custom_filter {
  .prevalence-range {
    display: flex;
    justify-content: space-around;
    margin: 20px 20px 0;

    .prevalence-range-from,
    .prevalence-range-to {
      display: flex;

      // padding-right: 5px;
      div:first-child {
        // padding-right: 5px;
      }
    }
  }

  .ant-slider {
    width: 180px;
    margin: 10px 20px 30px;

    .ant-slider-track {
      background-color: @table-border-color;
    }

    .ant-slider-handle {
      border-color: @table-border-color;
    }
  }

  .ant-slider:hover {
    .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: @primary-color;
    }

    .ant-slider-track {
      background-color: @primary-color;
    }
  }
}

.vendor_status_custom_filter {
  width: 230px;

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper {
    padding: 10px 20px;
    margin: 0;
  }

  .ant-checkbox + span {
    padding: 0;
    font-weight: 400;
    display: flex;
    align-items: center;

    .ant-image {
      margin-left: 5px;
      margin-right: 10px;
    }
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: @primary-color;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: @primary-color;
  }

  .ant-checkbox-group {
    padding: 0;
  }

  .ant-checkbox-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    font-weight: 400;

    .ant-checkbox-indeterminate.ant-checkbox-checked .ant-checkbox-inner {
      background-color: white;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: @primary-color;
      border-color: @primary-color;
    }

    &.ant-checkbox-wrapper-checked {
      color: #4f556d;
      background-color: #e9dfff;
    }

    &:after {
      display: none;
    }
  }
}

.anchor-button {
  background-color: unset;
  border: unset;
  color: @primary-color;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
}
//GLOBAL LEVEL STYLE CONFLICTING WITH NEW THEME
// button.ant-btn {
//   &.ant-btn-default {
//     border: 1px solid @primary-color;
//     color: @primary-color;

//     &:active,
//     &:hover,
//     &:focus {
//       border: 1px solid @primary-color;
//     }
//   }

//   &.ant-btn-primary {
//     border: 1px solid @primary-color;
//     background-color: @primary-color;
//     color: white;
//   }
// }

#avocado .base-relative-metrics .spsg-select .ant-select {
  min-width: 30%;
}
div.ant-select.spsg-select-multiple.diagnose-dashboard-select.diagnose-dashboard-select,
#avocado div.ant-select.spsg-select-multiple.diagnose-dashboard-select {
  .ant-select-selection-overflow-item {
    overflow: hidden;
    max-height: 36px;
    max-width: 95%;
  }

  div.ant-select-selection-search {
    display: block;
  }
}

.overview-count {
  width: 55%;
  min-width: 400px;
  display: inline-flex;

  .title {
    font: normal normal bold 16px/20px Open Sans;
    color: #474747;
  }
  .row {
    width: 100%;
    padding: 10px 0 5px;
    &.gap {
      margin-top: 7px;
    }
    &.lightblue {
      background-color: #3cb6fc0d;
    }
    &.lightgray {
      background-color: #9292920d;
    }
  }
  .overview-count-grid {
    display: grid;
    grid-template-columns: 1.75fr 1.25fr 2fr 1.25fr 1.75fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding: 0 10px;
    &.date {
      padding: 2px 10px;
    }
    &.day {
      grid-template-columns: 3fr 3fr 3fr;
      .date.first {
        text-align: center;
        grid-column: 1 / span 3;
      }
    }

    * {
      text-align: center;
    }
    .date {
      line-height: 18px;
      font-weight: 600;
      color: #474747;
      display: inline;

      .highlight {
        background-color: #ffff9a;
        width: auto;
        display: inline-block;
        padding: 4px;
      }
      &.first {
        grid-column: 1 / span 2;
        text-align: right;
      }
      &.last {
        grid-column: 4 / span 2;
        text-align: left;
      }
    }
    .overview-count-description {
      font: normal normal normal 12px/12px Open Sans;
      color: #474747;
      &.gap {
        margin-top: 9px;
      }
    }
    .overview-count-values {
      font: normal normal normal 22px/40px Open Sans;
      color: #474747;
      opacity: 1;
      padding: 0 7px;
      display: inline-flex;
      align-items: center;

      &.highlight {
        border-radius: 5px;
        background-color: #ffff9a;
      }
      &.increase {
        color: #43ba54;
      }
      &.decrease {
        color: #ff2626;
      }
    }
    .dates-divider-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .circle {
        width: 6px;
        height: 6px;
        background-color: #fff;
        border: 1px solid #707070;
        border-radius: 3px;
        &:first-child {
          margin-left: 10px;
        }
        &:last-child {
          margin-right: 10px;
        }
      }
      .dashed-line {
        width: 100%;
        border: 1px dashed #7070704a;
        height: 0;
      }
    }
  }
}
.ant-select-dropdown.spsg-select-multiple-drodown.diagnose-dashboard-select-popup {
  .ant-select-item-group .ant-select-item-option-content {
    span.anticon-check {
      &.selected {
        color: #411f90;
      }
    }
    span.anticon-check-square {
      &.selected {
        border-radius: 3px;
        color: #411f90;

        svg {
          border-width: 0;
        }
      }

      &.intermediate {
        border: 1px solid #411f90;
        padding: 3px;

        svg {
          background-color: #411f90;
          color: transparent;
          width: calc(1em - 6px);
          height: calc(1em - 6px);
        }
      }
    }
  }
}

.vendor-type-cell-content {
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.vendor-type--tooltip.ant-tooltip {
  max-width: 500px;
}

.diagnose-notifications-tooltip,
#avocado .diagnose_notifications {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .ant-btn {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .tab-actions_container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  .tab-bar-extra-content {
    > label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0 10px;
    }
  }

  .ant-tabs.spsg-tabs.diagnose-overview {
    .tab-new .count {
      margin-left: 4px;
      background-color: #c02822;
      border-radius: 15px;
      padding: 3px 6px;
      font-size: 12px;
      font-weight: bold;
      color: white;
    }
    .ant-tabs-nav {
      &::before {
        border: 1px solid #d3d9dd;
      }
      .ant-tabs-tab,
      .ant-tabs-tab + .ant-tabs-tab {
        border-left: none;
        border: none;
        border-radius: 0px;
        border-bottom: 1px solid #d3d9dd;
        background: transparent;
        padding: 12px 24px;
        &:not(.ant-tabs-tab-active) {
          &:hover {
            span:not(.count) {
              font-size: 16px;
              font-weight: 600;
              color: #411f90;
            }
          }
        }

        &.ant-tabs-tab-active {
          border: none;
          border-left: none !important;
          border-bottom: 3px solid;
          border-color: #411f90;
          border-radius: 0px;
          font-size: 16px;
          font-weight: bold;
          color: #411f90;
        }
      }
    }

    > .ant-tabs-content-holder {
      padding: 15px 0 0;
    }
  }

  .ant-collapse.notifications-collapse {
    > .ant-collapse-item {
      border: none;
      border-radius: unset;
      .ant-collapse-header {
        background-color: #fff;
        border: 1px solid var(--ant-color-border);
        border-radius: 2px;
        display: flex;
        padding: 7px 12px;
        align-items: center;
        position: sticky;
        top: 0;
        left: 0;
        z-index: 10;
        .ant-collapse-expand-icon {
          order: 2;
          margin-left: 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .ant-image {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
          .ant-collapse-arrow {
            width: 12px;
            margin: 0;
          }
        }
        .ant-collapse-header-text {
          order: 1;
          flex: none;
        }
      }
      > .ant-collapse-content {
        background-color: transparent;
        > .ant-collapse-content-box {
          padding: 20px 0 0;
        }
      }
    }
  }

  .ant-select-single {
    .ant-select-selection-item {
      line-height: 40px;
    }
  }
}

.spsg-modal > {
  .diagnose-email-preferences-modal {
    .ant-modal-content {
      .ant-modal-close {
        .ant-modal-close-x,
        .ant-modal-close-x:hover {
          background: unset;

          svg {
            color: white !important;
          }
        }
      }

      .ant-modal-body {
        padding: 20px;
        .content {
          p {
            font-size: 14px;
          }
        }
        .actions-container {
          padding: 20px 0 10px;
          .actions {
            display: flex;
            justify-content: flex-end;
          }
        }
        .submit-confirmation {
          border-radius: 4px;
          background-color: #fbffd6;
          padding: 10px;
          .confirmation-actions {
            display: flex;
            justify-content: flex-end;
            column-gap: 10px;
          }
        }
      }
      #notification-list-toggle.spsg-toggle.ant-switch {
        height: 12px;
        margin-right: 20px;
        width: 26px;
        min-width: unset;
        .ant-switch-handle {
          top: 0;
          left: 0;
        }
        &.ant-switch-checked {
          .ant-switch-handle {
            top: 0;
            left: calc(100% - 12px);
          }
        }
      }
    }
    .spsg-table .ant-table {
      .ant-table-cell {
        padding: 20px;
        border: none;
        &::before {
          content: none !important;
        }
      }

      .ant-table-container {
        border: 1px solid #c5bbdd;
        border-radius: 10px;
      }
      .ant-table-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom: 1px solid #c5bbdd;
        .ant-table-cell-scrollbar {
          background-color: #e9dfff;
          box-shadow: unset;
        }
      }
      .ant-table-body {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .spsg-select > .alert-type-select {
      width: 100%;
      margin: 0;
    }
  }
}

.diagnose-notifications-tooltip {
  max-width: 520px;
  width: 520px;
  .diagnose-notifications-tooltip--footer {
    background-color: #f4f6f8;
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
    .ant-btn.spsg-btn.btn-link,
    button.ant-btn.spsg-btn.btn-link:hover,
    button.ant-btn.spsg-btn.btn-link span:hover {
      background-color: transparent;
    }
  }
  .ant-popover-title,
  .ant-popover-inner,
  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-collapse.notifications-collapse {
    > .ant-collapse-item {
      > .ant-collapse-content {
        > .ant-collapse-content-box {
          padding: 0 0 20px;
        }
      }
    }
  }

  .tab-actions_container {
    flex-wrap: wrap;
    padding: 30px 20px 20px;
    margin: 0;
    h4 {
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
    }
    .tab-actions_action {
      width: 50%;
      display: inline-block;

      button.spsg-btn.btn-link {
        font: normal normal 600 16px/22px Open Sans;
      }
    }
    .btn-link {
      text-align: right;
    }
    #notification-list-toggle.spsg-toggle.ant-switch {
      height: 12px;
      margin-right: 20px;
      width: 26px;
      min-width: unset;
      .ant-switch-handle {
        top: 0;
        left: 0;
      }
      &.ant-switch-checked {
        .ant-switch-handle {
          top: 0;
          left: calc(100% - 12px);
        }
      }
    }
    .notification-list-toggle_toggle {
      font: normal normal normal 14px/19px Open Sans;
      color: #707070;
    }
  }
}

.diagnose-notification_details {
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 100%;
  background: #fff;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  .heading {
    border-bottom: 1px solid #cacaca;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .title {
      margin: 0;
    }
    > div {
      display: inline-flex;
      align-items: center;
      gap: 0 15px;
      justify-content: flex-end;
    }
  }
  .content {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    column-gap: 120px;

    .date {
      min-width: 200px;
      margin-left: 10px;
    }
  }
}

.diagnose-popover-notification_details {
  padding: 25px 10px;
  margin: 0 10px;
  border-bottom: 1px solid #dbdbdb;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .title {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
      margin-right: 12px;
    }
  }
  .description {
    width: 100%;
    display: block;
  }
  .gap {
    margin-bottom: 10px;
  }
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.diagnose-popover-notification_details,
.diagnose-notification_details {
  display: block;
  color: unset;
  text-decoration: unset;

  &:hover,
  &:focus {
    color: unset;
    text-decoration: unset;
    outline: unset;
  }

  .diagnose-notification-status {
    display: inline-block;
    &.new {
      &::before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #00b0ff;
      }
    }
  }
  .diagnose-notification-type {
    padding: 5px 10px;
    display: inline-block;
    border-radius: 10px;
  }
  .description {
    color: #666666;
    font-weight: 500;
  }
}

.notifications-collapse-popover {
  max-height: 600px;
  overflow: scroll;
}

.diagnose-notification-highlight {
  --highlight: rgba(255, 255, 154, 0.65);

  &.selector {
    .ant-select-selector {
      background-color: var(--highlight);
    }
  }
  &.highlight {
    background-color: var(--highlight);
    display: inline-block;
    padding: 3px;
  }
  &.background,
  &.ant-select-selection-item {
    .ant-select-selection-overflow-item-rest & {
      background-color: #e9dfff !important;
    }
    background-color: var(--highlight) !important;
  }
  &.ant-table-row {
    .vendor_type,
    .vendor_name {
      background-color: var(--highlight);
    }
  }

  &.diagnose-range-input {
    .ant-picker-input {
      padding: 0 3px;
      background-color: var(--highlight);
    }
  }
}

.diagnose_container {
  .diagnose-spinning.ant-spin.ant-spin-spinning {
    position: initial;
    color: var(--color-primary);

    .ant-spin-text {
      margin-top: 0;
      margin-left: 0;
    }
  }
  .diagnose_dasboard-title {
    display: flex;
    justify-content: flex-start;
    align-items: end;
    // text-align: center;
    .ant-btn {
      margin-left: 20px;
      margin-bottom: 4px;
    }
  }
}

.diagnose-dashboard-select {
  &.ant-select {
    .ant-select-selector {
      min-height: 40px;
      height: 100%;
      border-radius: 5px;
      box-shadow: 0px 2px 3px @boxshadow-color;
    }

    &.ant-select-focused,
    &:hover {
      .ant-select-selector {
        border-color: @primary-color;
      }
    }
  }
}

.ant-modal.add-property-modal{

  width: 600px !important;

  .ant-modal-body{
    padding: 0px;
    margin: 0px var(--ant-margin);
  }
  .ant-tabs-nav-list {
    justify-content: center;
    display: flex;
    flex: 1;
  
    .ant-tabs-tab-btn {
      display: flex;
      >.ant-tabs-tab-icon {
        margin-inline-end: 0;
        margin-inline-end: var(--ant-margin-sm);
        order: inherit
      }
    }
  }
}
