#avocado .metrics-graph.dashboard-page {

  .sites-dropdown-container {
    .ant-select-selection-selected-value {
      font-family: proxima-nova;
      font-weight: bolder;
      font-size: 20px;
      color: #212F43;
      letter-spacing: 0.25px;
    }
    font-size: 30px;
  }
  th {
    background: white;
    font-family: proxima-nova;
    font-weight: bold;
    font-size: 11px;
    color: #566C87;
    text-transform: none;
  }
}
