@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

.spsg-popconfirm {
  .ant-popover-inner {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 15px #00000029;
    border: 1px solid #DDE1E4;
    border-radius: 10px;
  }
  .ant-popover-inner > .ant-popover-title, .ant-popover-inner .ant-popconfirm-message {
    border: 0;
    padding: 10px 10px 10px 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #272727;
  }
  .ant-popover-inner > .ant-popover-inner-content {
    border: 0;
    padding: 0 10px 10px 10px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #272727;
  }
  .ant-popconfirm-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    .ant-popover-message-title {
      padding-left: 7px;
    }
    > .anticon {
      position: relative;
      top: auto;
    }
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popconfirm-buttons {
    button {
      margin-left: 12px;
    }
    button.ant-btn {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 32px;
      text-align: center;
      color: #090E15;
      padding: 0 16px;
      height: 32px;
      box-shadow: none;
      background: #FFFFFF;
      border: 1px solid #D7DDE9;
      border-radius: 5px;
      &:hover {
        color: #090E15;
        background: #F5F7FB;
        border: 1px solid #D7DDE9;
      }
      &:focus {
        color: #090E15;
        background: #E7ECF4;
        border: 1px solid #E7ECF4;
      }
      &[disabled] {
        opacity: 1.0 !important;
        color: #ADB5C5 !important;
        background: #E7ECF4 !important;
        border: 1px solid #E7ECF4 !important;
      }
      > svg {
        font-size: 16px;
        margin-right: 10px;
      }
    }

    button.ant-btn {
      &.ant-btn-primary {
        background: #411F90;
        border: 1px solid #411F90;
        color: #fff;
        &:hover {
          color: #fff;
          background: #6565A9;
          border: 1px solid #6565A9;
        }
        &:focus {
          color: #fff;
          background: #6565A9;
          border: 1px solid #6565A9;
        }
        &[disabled] {
          opacity: 0.6 !important;
        }
      }
    }
  }
}
