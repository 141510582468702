.beta-tag {
  background: #FFA716;
  border-radius: 12px;
  font-family: inherit;
  font-size: 10px;
  color: #FFFFFF;
  line-height: 14px;
  margin-left: 10px;
  padding: 4px 8px;
  font-weight: bold;
  border: 0;
  position: relative;
  top: -2px;
}

.consent-performance {
  .aggregate-data {
    display: flex;
    .card {
      display: inline-flex;
      padding: 10px;
      margin-bottom: 20px;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-around;

      &:first-child {
        margin-right: 20px;
      }

      .data-bundle {
        padding: 10px;
        text-align: center;
        h1 {
          margin: auto;
          font-size: 22px;
        }

        h2 {
          position: absolute;
          margin-top: -25px;
          margin-left: 95px;
          font-size: 16px;
          border-radius: 5px;
          background-color: #E6EAEE;
          padding: 5px;

          &.no-action-total {
            margin-left: 62px;
          }
        }

        p {
          margin: 0;
        }
      }
    }
  }

  .no-show-agg {
    display: none;
  }

  .export-csv-blue {
    position: absolute;
    top: 85px;
    right: 0;
    width:150px;
    .export-csv {
      position: fixed;
      z-index: 1000;
      border: none;
      border-radius: 2px;
      background: #0294FF;
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      padding: 5px 15px;
      i {
        margin-right: 8px;
      }
      &:hover {
        text-decoration: none;
        background: #0284EF;
      }
    }
  }

  .data-grid {
    padding: 20px 0;
    .card {
      width: 18%;
    }
  }

  .tbl-percent {
    color: #5E728E;
    span {
      color: #000;
      font-weight: 600;
      margin-right:10px;
    }
  }

  .agg-card {
    margin-bottom:30px;
    padding:30px 30px 30px 30px;
    h1 {
      display: inline-block;
      margin: 0;
      color: #000000;
      font-family: var(--font-main);
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      position: initial;
    }
    h4 {
      display: inline-block;
      margin: 0;
      color: #000000;
      font-family: var(--font-main);
      font-size: 14px;
      font-weight: bold;
      line-height: 17px;
      margin-bottom:10px;
    }

    .agg-row {
      border-bottom: 1px solid #ddd;
      padding: 12px 10px 9px 10px;
      div:first-child {
        text-align:left;
        color: #30425C;
        font-family: var(--font-main);
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
      }
      div:nth-child(2) {
        text-align:right;
        color: #000000;
        font-family: var(--font-main);
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        padding-right: 8px;
      }
      div:nth-child(3) {
        text-align:right;
        color: #000000;
        font-family: var(--font-main);
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
      }
    }

    .agg-count {
      border-radius: 5px;
      background-color: #E6EAEE;
      padding: 2px 5px;
      font-size: 12px;
      min-width: 44px;
      display: block;
      text-align: center;
      position: relative;
      top: -2px;
    }

    .agg-count {
      border-radius: 5px;
      background-color: #E6EAEE;
      padding: 2px 5px;
      font-size: 12px;
      min-width: 44px;
      display: block;
      text-align: center;
      position: relative;
      top: -2px;
    }

    .agg-col {
      .agg-row:last-child {
        border-bottom: 0;
      }
    }
    .agg-col.agg-condensed {
      .agg-row {
        padding: 5px 10px;
      }
    }

    .agg-col-left {
      border-right: 1px solid #eee;
      padding-right: 50px;
    }
    .agg-col-right {
      padding-left: 50px;
    }
    .agg-row-top {
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
    }
    .agg-row-bottom {
      padding-top: 20px;
    }
    .agg-title-left {
      margin-bottom:23px;
    }

    i.fas {
      color: #889AB5;
      cursor: pointer;
      font-size:12px;
      margin-left: 5px;
    }

  }

  p {
    margin-bottom: 0;
    padding-top: 30px;
    font-family: var(--font-main);
    font-size: 13px;
    text-align: center;
  }

  .stat-card {
    padding:30px 30px 10px 30px;
    margin-bottom:30px;
    flex-direction: row;
    .stat-margin-bottom {
      margin-bottom:20px;
    }
    .stat-left {
      width:400px;
      flex: 3;
    }
    .stat-middle {
      width:1px;
      background: #eee;
      margin-left: 40px;
      margin-right: 40px;
      margin-bottom:20px;
    }
    .stat-right {
      flex: 2;
    }
    .stat-title {
      color: #000000;
      font-family: var(--font-main);
      font-size: 18px;
      font-weight: bold;
      margin-bottom:20px;
    }
  }

  .stat-col {
    margin-bottom:30px;
  }

  .stat-box {
    min-width:190px;
    width:100%;
    text-align: left;
    h1 {
      display: inline-block;
      margin: 0;
      color: #000000;
      font-family: var(--font-main);
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
    }
    h2 {
      display: inline-block;
      color: #000000;
      font-family: var(--font-main);
      font-size: 12px;
      font-weight: 600;
      line-height: 12px;
      text-align: center;
      border-radius: 5px;
      background-color: #E6EAEE;
      padding: 4px 6px;
      margin: 0;
      position: relative;
      top: -5px;
      margin-left: 15px;
    }
    div {
      color: #5f697a;
      margin: 0;
      font-family: var(--font-main);
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
      margin-top:3px;
      i {
        color: #889AB5;
        cursor: pointer;
      }
    }
  }

  .country-select {
    margin-right: 10px;
  }

  .site-select {
    width: auto !important;
    font-size: 14px;
    color: #354052;
    min-width: 150px;
    font-family: var(--font-main);
    .ant-select-selection.ant-select-selection--single {
      height: 35px;

      .ant-select-selection__rendered {
        margin-left: 15px;
        line-height: 33px;
      }
    }
  }

  .locations-select {
    margin-left: 15px;
    font-size: 14px;
    color: #354052;
    font-family: var(--font-main);
    .ant-select-selection.ant-select-selection--single {
      height: 35px;

      .ant-select-selection__rendered {
        margin-left: 15px;
        line-height: 33px;
      }
    }
  }

  .date-range {
    padding-top:4px;
    margin-right:15px;
    .ant-radio-group .ant-radio-button-wrapper {
      padding: 0 8px;
    }
  }

  .line-chart-container {
    padding: 0 !important;
    border: 1px solid #ddd;

    .ant-table-wrapper {
      margin-bottom:60px;
    }
    .ant-pagination {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    table {
      border-top: 0 !important;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  .vendor-chart-container {
    padding: 0 !important;
    border: 1px solid #ddd;
    .chart-toggle {
      cursor: pointer;
      text-align: center;
      i {
        color: #d24d57;
        &:hover {
          color: #c23d47;
        }
      }
    }
    .table-toggle {
      cursor: pointer;
      text-align: center;
      i {
        color: #00b16a;
        &:hover {
          color: #00a15a;
        }
      }
    }
    .chart-circle {
      width: 14px;
      height: 14px;
      display: inline-block;
      border-radius: 7px;
      position:relative;
      top:2px;
    }
    .ant-pagination {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    .ant-table-wrapper {
      margin-bottom:60px;
    }
    table {
      border-top: 0 !important;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
    .vendor-legend {
      .ant-table-wrapper {
        margin-bottom:0 !important;
      }

    }
    .vendor-srch {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid #ddd;
      i {
        position:absolute;
        left: 14px;
        top: 19px;
        z-index:9;
      }
      .ant-select-selection {
        height: 50px;
        border-radius: 0;
        border: 0;
        box-shadow: none;
        .ant-select-selection__rendered {
          height: 50px;
          line-height: 50px;
          margin: 0 20px 0 44px;
        }
      }
    }
  }

  .vendor-chart {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ddd;
    .vendor-graph {
      flex: 1;
      padding:40px 35px 40px 20px;
      .recharts-responsive-container {
        margin-top:40px;
      }
      .vendor-banner {
        position:relative;
        h1 {
          margin:0;
          position:absolute;
          left:20px;
          color: #000000;
          font-family: var(--font-main);
          font-size: 16px;
          font-weight: bold;
        }
        h2 {
          margin:0;
          color: #30425C;
          font-family: var(--font-main);
          font-size: 14px;
          font-weight: 600;
          position:absolute;
          right:10px;
          top:-8px;
          span {
            font-size: 25px;
            font-weight: bold;
            color: #000;
            margin-left: 15px;
            top: 3px;
            position: relative;
          }
        }
      }
    }
    .vendor-legend {
      width:370px;
      background: #FFF;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-left: 1px solid #ddd;
      padding:0;
      position:relative;
    }
  }


  .line-chart {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #ddd;
    .chart-graph {
      flex: 1;
      padding:20px 35px 40px 20px;
    }
    .chart-legend {
      width:270px;
      background: #F1F4FC;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      border-left: 1px solid #ddd;
      padding:20px;
      position:relative;

      h3 {
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        color: #000;
        font-family: var(--font-main);
        margin-bottom: 15px;
        margin-top: 15px;
      }

      div {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      .legend-spacer {
        width: 100%;
        height: 1px;
        background: #e7e8e9;
        margin-bottom:10px;
        margin-top:10px;
      }

      .ant-checkbox-wrapper {
        display: block;
        margin-bottom: 10px;

        .ant-checkbox-wrapper + span, .ant-checkbox + span {
          position: relative;
          top: -3px;
          color: #000000;
          font-family: var(--font-main);
          font-size: 12px;
          font-weight: 600;
          padding-left:10px;
        }

        .ant-checkbox {
          .ant-checkbox-inner {
            width: 20px;
            height: 20px;
            &:after {
              left: 6px;
              top: 3px;
              width: 6px;
              height: 10px;
            }
          }
        }
      }

      .ant-checkbox-wrapper.lg-all {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #000;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #000;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #000;
            border-color: #000;
          }
        }
      }

      .ant-checkbox-wrapper.lg-mu {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #1370FB;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #1370FB;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #1370FB;
            border-color: #1370FB;
          }
        }
      }

      .ant-checkbox-wrapper.lg-na {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #AAAAAA;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #AAAAAA;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #AAAAAA;
            border-color: #AAAAAA;
          }
        }
      }

      .ant-checkbox-wrapper.lg-ca {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #0AD326;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #0AD326;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #0AD326;
            border-color: #0AD326;
          }
        }
      }

      .ant-checkbox-wrapper.lg-cm {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #108B21;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #108B21;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #108B21;
            border-color: #108B21;
          }
        }
      }

      .ant-checkbox-wrapper.lg-cp {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #0CD6C4;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #0CD6C4;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #0CD6C4;
            border-color: #0CD6C4;
          }
        }
      }

      .ant-checkbox-wrapper.lg-ra {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #FF3F69;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #FF3F69;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #FF3F69;
            border-color: #FF3F69;
          }
        }
      }

      .ant-checkbox-wrapper.lg-rm {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #B66677;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #B66677;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #B66677;
            border-color: #B66677;
          }
        }
      }

      .ant-checkbox-wrapper.lg-rp {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #D6A7B1;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #D6A7B1;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #D6A7B1;
            border-color: #D6A7B1;
          }
        }
      }

      .ant-checkbox-wrapper.lg-pa {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #FAAD14;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #FAAD14;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #FAAD14;
            border-color: #FAAD14;
          }
        }
      }

      .ant-checkbox-wrapper.lg-ps {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #B59D6E;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #B59D6E;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #B59D6E;
            border-color: #B59D6E;
          }
        }
      }

      .ant-checkbox-wrapper.lg-pr {
        .ant-checkbox {
          &:hover {
            .ant-checkbox-inner {
              border-color: #E4D246;
            }
          }
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #E4D246;
          }
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background: #E4D246;
            border-color: #E4D246;
          }
        }
      }

    }
  }

  .card.no-sites-message {
    padding-bottom: 60px !important; 

    p {
      font-size: 14px;
    }
  }

}

/* Override necessary for this use-case */
.consent-performance .filters.external .ant-calendar-picker .ant-calendar-picker-input.ant-input.ant-input-lg {
  width:180px !important;
}

.cp-tooltip .ant-tooltip-inner {
  color: #FFFFFF;
  font-family: var(--font-main);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  background-color: #30425C;
}
