.preferences-steps-mixin {
  .tag {
    border-radius: 4px;
  }
  .empty-div {
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: white;
    &.validation-error {
      border: 1px solid  var(--ant-color-error);
    }
  }

  .table-action-section {
    background: transparent;
    margin-bottom: var(--ant-margin-sm);
    display: flex;

    .search-container {
      flex: 1;
      .spsg-input {
        width: 250px;
      }
    }
    .disabled {
      color: #8f8f8f;
    }
  }

  .ant-tabs {
    section.ant-layout.main-container-layout {
      display: flex;
      flex-direction: row;
      flex: 1;
    }
  }
}

.edit-preferences-mixin {
  .edit-preferences-container {
    // margin-top: var(--ant-margin-md);
    padding: var(--ant-padding);
    border-radius: var(--ant-border-radius-lg);
    border: 1px solid var(--ant-color-border-secondary);
    background: var(--ant-color-bg-container);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03),
      0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);

    .ant-space-vertical {
      width: 100%;
      .ant-divider-horizontal {
        margin: 0 -16px;
        width: calc(100% + 2 * var(--ant-padding));
      }
    }

    > .header {
      display: flex;
      justify-content: space-between;
      label {
        font-weight: 600;
        font-size: 16px;
      }
    }
    > hr {
      margin: 10px;
    }
  }
  .preferences-steps-mixin();
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ant-modal-wrap.edit-add-connection {
  .ant-modal-footer {
    padding: 24px;
  }
  .col-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &.footer-left {
    .ant-modal-footer {
      text-align: left;
    }
  }

  .editable-cell {
    position: relative;
  }
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }
  .icon {
    color: @primary-color;
    margin: 0px 5px;
  }
}

.ant-layout.integration-category-mapping-layout {
  // Default height
  height: 70vh;
  .edit-preferences-modal & {
    height: 60vh;
    border: 1px solid var(--ant-color-border-secondary);
  }

  .ant-layout-header {
    background-color: #fafafa;
    border-bottom: 1px solid var(--ant-color-border-secondary);
    padding: 0 var(--ant-padding);
  }

  .ant-layout-sider {
    .ant-menu-light.ant-menu-root.ant-menu-vertical {
      border-inline-end: 0px;
      height: calc(70vh - 67px);

      .edit-preferences-modal & {
        height: calc(60vh - 67px);
      }

      overflow-y: scroll;
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .ant-menu-item {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        .ant-menu-title-content {
          flex: auto;
          min-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 0;
          margin-right: 16px;
        }
        .ant-menu-item-icon {
          position: absolute;
          right: 16px;
        }
      }
      .ant-menu-item-divider {
        margin: 0;
      }
    }
  }

  aside .ant-layout-content {
    border-right: 1px solid var(--ant-color-border-secondary);
  }
  .ant-layout-content {
    background-color: #fff;

    .ant-table-wrapper table {
      thead {
        th {
          height: 64px;
        }
      }

      tbody {
        tr {
          &.ant-table-placeholder {
            height: calc(70vh - 65px);
            border-bottom: 0px;
            .edit-preferences-modal & {
              height: calc(60vh - 65px);
            }
          }

          &.no-bottom-border > td {
            border-bottom: 0px;
          }
        }
      }
    }
  }
}

.ant-modal-wrap.bulk-upload {
  .ant-select {
    width: 300px;
  }
  .ant-modal-content {
    border-radius: 10px;
    .ant-modal-header {
      .ant-modal-title {
        .ant-typography {
          line-height: 28px;
        }
      }
    }
    .ant-modal-body {
      padding-top: 0px;
    }
  }
}

.ant-tabs.preferences-tabs {
  .ant-tabs-nav{
    margin-bottom: var(--ant-margin-xs);
  }
}

.new-us-privacy-regulations-wizard.create-preferences-config {
  .layout {
    padding: var(--ant-margin-lg);
    height: calc(100vh - 120px);
    overflow-y: scroll;
    width: 100%;
    .edit-preferences-mixin();
  }
}

.preferences-category-table {
  .ant-space.tooltip-tag-container {
    width: calc(100% - 36px);
    align-items: flex-start;
    gap: var(--ant-margin-sm);
    .ant-space-item {
      width: 100%;
      max-width: fit-content;
      .ant-tag.tooltip-tag {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: inherit;
        margin-right: 0;
      } 
    }
    &.just-one {
      width: 100%;
    }
  }
}