  .ant-tooltip-inner {
    word-break: normal;
  }

  .ant-modal.add-custom-purpose {
    .ant-modal-body {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 30px;

      input {
        margin-top: -5px;
        margin-left: 10px;
      }

      label {
        display: flex;
      }

      .ant-select { 
        width: 100%;
      }

      .anticon-info-circle {
        padding: 10px 0 0 10px;
      }

      .purpose-container {
        border-bottom: 1px solid lightgray;
        margin-bottom: 20px;
        label {
          height: 10px;
        }
        .purpose {
          margin-left: 10px;
          p {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: normal;
          }
          p.title {
            font-size: 15px;
            font-weight: bold;
          }
        }
      }
      .purpose-container:last-child {
        border-bottom: none;
      }
      .custom-purpose-name + .spsg-details {
        margin: 0px 0px 10px 10px;
      }
    }
  }

  .ant-modal.manage-stacks {
    width: 850px !important;
    top: 20px !important;

    .ant-modal-content{
      padding: 0px;
    }

    .ant-modal-body {
      padding: 0;

      .ant-tabs {
        overflow: hidden;

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #411F90;
        }

        .ant-tabs-ink-bar {
          background: #411F90;
        }

        .ant-tabs-content {
          padding-right: 25px;
          padding-left: 25px;

          //custom stacks tab
          .custom-stacks-panel {
            // margin-left: 25px;
            .ant-select {
              width: 765px;
              ul {
                margin-bottom: 0;
              }
            }


            .editing .ant-collapse-header {
              height: 85px;
            }

            .ant-collapse-content-box {
              width: 750px;
            }

            .ant-collapse-header {
              z-index: 999999999;
              width: 100%;
              background-color: white;
              height: 57px;
              padding-left: 10px;

              .arrow {
                font-size: 15px;
                position: absolute;
                right: 20px;
                left: auto;
              }
            }

            .title-2 {
              font-size: 13px;
              font-weight: bold;
              padding-bottom: 7px;
              padding-top: 5px;
            }

            .new-stack {
              border: 1px solid lightgray;
              margin-top: 20px;
              padding: 0px 10px;
              border-radius: 3px;

              .ant-checkbox {
                margin-right: 30px;
              }

              .title {
                margin-top: 5px;
                margin-bottom: 10px;
              }

              input {
                margin-top: -5px;

                &.name {
                  width: 720px;
                }

                &.description {
                  width: 640px;
                }
              }

              button.create-custom-stack {
                font-size: 13px;
                margin-bottom: 10px;
                margin-left: 300px;
              }
            }

            .add-purpose {
              margin-top: 10px;
              .add-purpose-row {
                display: flex;
                padding-top: 5px;
              }

              button {
                height: 30px;
                margin-top: -5px;
                margin-left: 10px;
              }

              input {
                width: 480px;
                margin-top: -5px;
              }
            }
          }
        }
      }
    }

    .ant-modal-footer{
      padding: 10px 16px;
    }

    .title {
      font-size: 15px;
      font-weight: bold;

      &.not-selected {
        margin-top: 24px;
      }
    }

    .stack-container {
      display: flex;
      margin-top: 5px;
      border-top: 1px solid lightgray;
      justify-content: space-between;

      &.custom {
        margin-top: 0;
        border-top: none;
        padding-top: 5px;
        display: flex;
        flex-direction: column;
      }

      p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        line-height: normal;
      }
      button {
        margin-left: 10px;
      }

      i.anticon-delete {
        font-size: 14px;
        position: absolute;
        right: 47px;
        top: 7px;

        &.closed {
          margin-top: 15px;
        }
      }
    }

    .iab-stacks-not-selected {
      overflow: scroll;
      max-height: 150px;

      button {
        margin-top: 4px;
        margin-bottom: 4px;
        color: #411F90;
        border-color: #411F90;
      }
    }

    .purposes-container {
      display: flex;
      .purpose {
        background-color: #f3f3f3;
        border-radius: 4px;
        margin-right: 10px;
        padding: 5px 9px 5px 9px;
        font-size: 11px;
        color: rgba(0, 0, 0, 0.65);
        line-height: normal;
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-header {
        border-bottom: 1px solid lightgray;
        height: 31px;
      }
    }

    .ant-tabs-nav {
      font-size: 13px;
      margin-left: 15px;
    }

    label {
      display: flex;
      
      input {
        width: 100px;
        margin-left: 10px;
      }
    }

    i {
      margin-top: 10px;
      font-size: 20px;
      color: #808080a3;
      margin-right: 10px;
      cursor: pointer;

      &.anticon-minus-circle {
        margin-right: 20px;
      }
    }

    p.no-iab-stacks-added {
      font-size: 14px;
    }
  }

  .ant-notification {
    z-index: 9999999999;
  }
