.sequential-navigation-mixin {
  @primary-color: var(--ant-color-primary);
  @dot-size: 8px; 
  .sequential-navigation {
    color: @primary-text-color;
    font-family: @primary-font;
    .main-step {
      display: flex;
      align-items: center;
      // margin: 10px 0px 10px 0px;
      height: 70px;
      // .icon-wrapper {
      //   height: var(--ant-control-height);
      //   width: var(--ant-control-height);
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   margin-right: var(--ant-margin);
        .icon {
          height: var(--ant-control-height);
          width: var(--ant-control-height);
          border-radius: 50%;
          display: inline-block;
          text-align: center;
          color: var( --ant-color-text-tertiary);
          background: #f2f2f2;
          line-height: var(--ant-control-height);
          margin-right: var(--ant-margin);
        }
        &:hover {
          color: @primary-color;
          background-color: transparent;
        }
      // }
      .title {
        height: auto;
        word-wrap: break-word;
        white-space: pre-wrap;
        font-size: 16px;
        // letter-spacing: 0.48px;
        color: inherit;
      }
      &.wait {
        color: var( --ant-color-text-tertiary);
        cursor: not-allowed;
        .icon {
          cursor: not-allowed;
        }
      }
      &.process {
        font-weight: 600;
        // .icon-wrapper {
          .icon {
            border-color: @primary-color;
            color: white;
            background-color: @primary-color;
          }
        // }
        &.single-step {
          // background-color: @secondary-chip-color;
          // border-radius: 5px;
          // border-color: @secondary-chip-color;
        }
      }
      &.finish {
        font-weight: 400;
        // .icon-wrapper {
          .icon {
             border: none;
            .anticon-check-circle {
              color: @secondary-color;
              background-color: @primary-color;
              border-radius: 50%;
              font-size: 32px;
              padding-right: 0px;
            }
          }
        // }
      }
      &:not(.process):not(.wait):hover{
        .title{
          color: @primary-color;
        }
      }
    }
    .sub-step {
      display: flex;
      align-items: center;
      // margin: 8px 0px 8px 0px;
      height: 36px;
      // .icon-wrapper {
      //   display: flex;
      //   height: 40px;
      //   width: 50px;
      //   justify-content: center;
      //   align-items: center;
        .icon {
          height: @dot-size;
          width: @dot-size;
          // border: 2px solid #bdbdbd;
          background-color: var( --ant-color-text-tertiary);;
          border-radius: 50%;
          margin-left: 11px;
          margin-right: 28px;
          // color: #222222;
        }
        &:hover {
          background: transparent;
        }
      // }

      .title {
        height: auto;
        word-wrap: break-word;
        white-space: pre-wrap;
        font-size: 14px;
        // letter-spacing: 0.42px;
        color: inherit;
      }
      &.wait {
        color: var( --ant-color-text-tertiary);
        cursor: not-allowed;
        .icon {
          cursor: not-allowed;
          background-color: var(--ant-color-text-tertiary);
        }
      }
      &.process {
        // background-color: @secondary-chip-color;
        // border-radius: 5px;
        // border-color: @secondary-chip-color;
        
        .title{
          font-weight:600;
        }
        // .icon-wrapper{
          .icon{
            border-color: @primary-color;
            background-color: @primary-color;
          }
        // }
      }
      &.finish {
        // .icon-wrapper{
          .icon{
            border-color: @primary-color;
            background-color: @primary-color;
          }
        // }
      }
      &:not(.process):not(.wait):hover{
        .title{
          color: @primary-color;
        }
      }
    }
  }

}