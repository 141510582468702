  
  .select-all-style {
    font-weight: bold;
    color: #108ee9;
  }
  .consent-performance {
    .card:hover {
      box-shadow: none;
    }

    .adblock {
      box-shadow: none;
      border-radius: 10px;
      padding: 25px 20px 25px 20px !important;
    }
    .select-multi-container {
      display: flex;
      justify-content: space-between;
      font-family: 'Proxima-Nova';

      .multiselect-sites-container {
        flex: 0.3;

        .note,
        .multi-select-heading {
          font-size: 10px;
          letter-spacing: 0;
          color: #79858f;
          opacity: 0.88;
        }
        .multi-select-heading {
          font-size: 12px;
        }
        .ant-select-selection {
          border-radius: 0;
          border: 0;
          border-bottom: 1px solid #a4adb6;
        }
      }
      .rightAligned {
        display: flex;
        flex: 0.65;

        .multiselect-rightaligned-container {
          flex: 1;
          margin-right: 15px;
          .multi-select-heading {
            font-size: 12px;
            letter-spacing: 0;
            color: #79858f;
            opacity: 0.88;
          }
          .multiselect-os-container,
          .multiselect-region-container,
          .multiselect-browser-container,
          .multiselect-devices-container {
            .ant-select-selection {
              border-radius: 0;
              border: 0;
              border-bottom: 1px solid #a4adb6;
            }
          }
        }
        .add-criteria {
          display: flex;
          justify-content: center;
          align-items: center;
          button {
            width: 36px;
            height: 36px;
            border-radius: 100%;
            background: #108ee9;
            color: #fff;
            font-size: 27px;
            border: 0;
          }
        }
      }
      .ant-select-selection {
        background-color: #f3f8fc;
        border-bottom: 1px solid #a4adb6;
        .ant-select-selection__rendered {
          line-height: initial;
          margin-left: 10px;
        }
      }
      .ant-select-selection--multiple {
        padding-bottom: 0;
      }
      .ant-select-selection__placeholder {
        color: #555758;
        margin-top: 0;
        // display: block !important;
      }
    }
    .consent-tabs-container {
      font-family: 'Proxima-Nova';
      .ant-tabs-tab-prev,
      .ant-tabs-tab-next {
        display: none;
      }
      .ant-tabs-nav {
        .ant-tabs-tab {
          margin: 0;
          color: #212f43;
          border: 1px solid #dad4eb;
          font-size: 18px;
          background: #fff;
          padding-right: 10px;
          padding-left: 10px;
        }
        .ant-tabs-tab:nth-child(1) {
          border-top-left-radius: 5px;
        }
        .ant-tabs-tab:nth-child(3) {
          border-top-right-radius: 5px;
        }
      }
      .ant-tabs-tab.ant-tabs-tab-active {
        div.ant-tabs-tab-btn {
          color: #fff !important;
        }
        background: #108ee9;
        border: 1px solid #108ee9 !important;
      }
      .ant-tabs-nav-container {
        overflow: initial;
      }
      .ant-tabs-nav {
        border-bottom: 1px solid #dae4eb;
        margin-bottom: 0;
        .ant-tabs-extra-content {
          .filters {
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            .date-range {
              padding-top: 0;
              .ant-radio-group .ant-radio-button-wrapper {
                height: auto;
              }
            }
            //overriding is important for this particular case
            .ant-calendar-picker
              .ant-calendar-picker-input.ant-input.ant-input-lg {
              width: auto !important;
            }
            .quarterly-date-picker-select {
              width: auto;
            }
          }
        }
      }
      .consentblock {
        margin-bottom: 30px;
        .property-tabs {
          display: flex;
          .toggle-line1 {
            border-bottom: 1px solid #108ee9;
          }
          .toggle-line1-active {
            border-bottom: 3px solid #108ee9;
          }
          .toggle-line2 {
            border-bottom: 1px solid #ff950c;
          }
          .toggle-line2-active {
            border-bottom: 3px solid #ff950c;
          }
          .toggle-line3 {
            border-bottom: 1px solid #ff0000;
          }
          .toggle-line3-active {
            border-bottom: 3px solid #ff0000;
          }
          .toggle-line4 {
            border-bottom: 1px solid #a60cff;
          }
          .toggle-line4-active {
            border-bottom: 3px solid #a60cff;
          }
          .toggle-line5 {
            border-bottom: 1px solid #3db725;
          }
          .toggle-line5-active {
            border-bottom: 3px solid #3db725;
          }
          .toggle-line6 {
            border-bottom: 1px solid #0CD6FF;
          }
          .toggle-line6-active {
            border-bottom: 3px solid #0CD6FF;
          }
          .toggle-line7 {
            border-bottom: 1px solid #d35400;
          }
          .toggle-line7-active {
            border-bottom: 3px solid #d35400;
          }
        }
        .property-params,
        .instructionalText {
          flex-grow: 0.3;
          text-align: center;
          padding: 20px 0;
          cursor: pointer;
        }
        .instructionalText {
          border-bottom: 1px solid #dae4eb;
        }
        .property-action {
          flex-grow: 2;
          text-align: right;
          padding: 20px 0;
          padding: 20px 20px;
          border-bottom: 1px solid #dae4eb;
          .consent-right-arrow {
            font-size: 9px;
            color: #337ab7;
          }
          a {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .property-title-container {
          padding: 20px;
          display: flex;
          .graph-desc-container {
            flex: 0.7;
          }
          .icons-list {
            align-self: center;
            flex: 0.3;
            text-align: right;
            .anticon {
              border: 1px solid #dae4eb;
              padding: 6px 15px;
              color: #aeb3ba;
              font-size: 15px;
              cursor: pointer;
            }
            .anticon.active {
              border: 1px solid #108ee9;
              color: #555758;
            }
            .anticon-bar-chart {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
            .anticon-line-chart {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
        .property-title {
          font-weight: bold;
          font-size: 20px;
        }
        .property-desc {
          font-size: 14px;
          color: #808080;
        }
        .recharts-tooltip-wrapper {
          width: 30%;
          background: #fff;
          padding: 10px 20px 10px;
          line-height: 2;
          box-shadow: 0px 0px 8px #ddd;
          display: flex;
          .tooltip-custom {
            flex: 1;
            .tooltip-entity-container {
              display: flex;
              .tooltip-entities {
                width: 35%;
              }
              span {
                width: 65%;
                color: #333;
              }
            }
            .tooltip-entity-container-note {
              font-size: 10px;
              font-style: italic;
            }
            .tooltip-view-more {
              text-align: right;
            }
          }
        }
        .vendors-filter-container {
          display: flex;
          padding: 20px;
          .vendor-property-select-container {
            flex: 0.3;
            .ant-select-selection--single {
              height: 35px;
              .ant-select-selection__placeholder {
                margin-top: 0;
              }
              .ant-select-selection-selected-value {
                margin-top: 3%;
              }
              .ant-select-search--inline .ant-select-search__field {
                margin-top: 3%;
              }
            }
          }
          .vendor-geo-select-container {
            flex: 0.2;
            margin-left: 1%;
            .ant-select-selection--multiple {
              height: 35px;
              .ant-select-selection__placeholder {
                margin: 0;
              }
            }
            // li.ant-select-selection__choice {
            //     display: none;
            // }
            // .ant-select-selection__placeholder{
            //     display: block !important;
            // }
          }
          .ant-select-selection {
            border-radius: 0;
            border: 0;
            border-bottom: 1px solid #a4adb6;
            background-color: #f3f8fc;
            .ant-select-selection__placeholder {
              color: #555758;
            }
            .ant-select-selection__rendered {
              line-height: initial;
              margin-left: 10px;
            }
          }
        }
        .table-title-container {
          display: flex;
          margin: 50px 20px 20px 20px;
          .table-title {
            font-size: 20px;
            color: #000;
            flex: 0.6;
          }
          .table-title-convert {
            flex: 1;
            button:nth-child(1) {
              padding: 5px 20px;
              color: #aeb3ba;
              font-size: 14px;
              background: transparent;
              border-right: 0;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              border: 1px solid #aeb3ba;
            }
            button:nth-child(2) {
              padding: 5px 20px;
              color: #aeb3ba;
              font-size: 14px;
              background: transparent;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              border: 1px solid #aeb3ba;
            }
            button:nth-child(1).active,
            button:nth-child(2).active {
              color: #337ab7;
              border: 1px solid #108ee9;
            }
          }
          .compare-msgs {
            flex: 1;
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            button {
              padding: 5px 20px;
              color: #337ab7;
              font-size: 14px;
              background: #108ee9;
              border-radius: 5px;
              color: #fff;
              border: 1px solid #108ee9;
              outline: 0;
            }
          }
          .ant-input-affix-wrapper {
            flex: 1;
            margin-left: 1%;
            .ant-input {
              height: 32px;
            }
          }
        }
        .sk-fading-circle {
          z-index: 0;
          position: relative;
          top: 10px;
        }
      }
    }
    .consent-tabs-container.gdpr-vendor-tab-container {
      padding: 20px;
    }
    .gdpr-tab-container {
      .ant-tabs-tab:nth-child(4) {
        background: transparent;
        border: 0;
      }
    }
    .criteria-container {
      padding: 15px;
      margin-bottom: 10px;
      background: #fff;
      border-radius: 10px;
      display: flex;
      .criteria-title {
        flex: 0.1;
        color: #000;
        font-size: 15px;
      }
      .criteria-tags-container {
        flex: 0.9;
        .ant-tag {
          margin-bottom: 6px;
          color: #555758;
          .ant-tag-text {
            color: #555758;
          }
          .anticon-cross {
            color: rgb(93, 134, 167);
          }
        }
      }
      .remove-criteria {
        height: 20px;
        width: 20px;
        background: #b8c9e3;
        text-align: center;
        border-radius: 100%;
        color: #fff;
        line-height: 1.5;
      }
    }
    .user-selection-container {
      padding: 15px;
      margin-bottom: 10px;
      background: #fff;
      border-radius: 10px;
      .user-selection {
        display: flex;
        padding: 5px;
        .user-selection-title {
          flex: 0.1;
          color: #000;
          font-size: 12px;
        }
        .user-selection-tags {
          flex: 0.9;
          .ant-tag {
            margin-bottom: 6px;
            .ant-tag-text {
              color: #555758;
            }
            .anticon-cross {
              color: rgb(93, 134, 167);
            }
          }
        }
      }
    }

    .recharts-legend-wrapper {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      .legend-custom {
        margin-left: 1%;
        .customLegendIcon {
          border: 1px #d2caca;
          display: inline-block;
          width: 20px;
          height: 0;
          vertical-align: middle;
          margin-right: 1%;
        }
      }
    }
  }
