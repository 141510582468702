@primary-color: #4f28b3;
@primary-color-hover: #6565a9;

#avocado {
  .consent-performance-v2 {
    a {
      color: @primary-color;
    }
    span.anticon-info-circle, span.anticon-download {
      svg {
        color: @primary-color;
      }
    }
    .card:hover {
      box-shadow: none;
    }
    .no-report-message {
      box-shadow: none;
      border-radius: 10px;
      padding: 10%;
      text-align: center;
    }
    .adblock {
      box-shadow: none;
      border-radius: 10px;
      padding: 1% !important;
      margin: 0 !important;
    }
    .select-multi-container {
      display: flex;
      justify-content: space-between;
      font-family: "Open Sans";

      .multiselect-sites-container {
        flex: 0.3;

        .spsg-select{
          width: 250px;
        }

        .note,
        .multi-select-heading {
          font-size: 10px;
          letter-spacing: 0;
          color: #79858f;
          opacity: 0.88;
        }
        .multi-select-heading {
          font-size: 12px;
        }
        .spsg-select-multiple {
          width: 100%;
          min-width: 200px;
        }
      }
      .rightAligned {
        display: flex;
        flex: 0.65;

        .multiselect-rightaligned-container {
          flex: 1;
          margin-right: 15px;
          .multi-select-heading {
            font-size: 12px;
            letter-spacing: 0;
            color: #79858f;
            opacity: 0.88;
          }
          .multiselect-os-container,
          .multiselect-region-container,
          .multiselect-browser-container,
          .multiselect-devices-container {
            .ant-select-selection {
              border-radius: 0;
              border: 0;
              border-bottom: 1px solid #a4adb6;
            }
          }
        }
        .add-criteria {
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 32px;
            color: @primary-color;
            &:hover {
              color: @primary-color-hover;
            }
          }
        }
      }
      .ant-select-selection {
        background-color: #f3f8fc;
        border-bottom: 1px solid #a4adb6;
        .ant-select-selection__rendered {
          line-height: initial;
          margin-left: 10px;
        }
      }
      .ant-select-selection--multiple {
        padding-bottom: 0;
      }
      .ant-select-selection__placeholder {
        color: #555758;
        margin-top: 0;
      }
    }
    .consent-tabs-container {
      font-family: "Open Sans";
      .spsg-tabs {
        .ant-tabs-content-holder {
          padding: 0px;
        }
      
        .ant-tabs-nav {
          border-bottom: 1px solid #dae4eb;
          margin-bottom: 0;
          padding-top: 20px;
          .ant-tabs-extra-content {
            .filters {
              .date-range {
                .ant-radio-group.spsg-radio-group {
                  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
                    background-color: #fff;
                  }
                }
              }
              .ant-picker-large {
                padding: 1% 2%;
                width: 180px;
                .ant-picker-input > input {
                  font-size: 14px;
                }
              }
              //overriding is important for this particular case
              .ant-calendar-picker
                .ant-calendar-picker-input.ant-input.ant-input-lg {
                width: auto !important;
              }
              .quarterly-date-picker-select {
                width: 180px;
                .ant-select-selector {
                  height: 40px;
                  padding: 4px 8px;
                }
              }
            }
          }
        }
      }
      .consentblock {
        padding-bottom: 30px;
        margin-bottom: 30px;
        .property-tabs {
          display: flex;
          .toggle-line1 {
            border-bottom: 1px solid #108ee9;
          }
          .toggle-line1-active {
            border-bottom: 3px solid #108ee9;
          }
          .toggle-line2 {
            border-bottom: 1px solid #ff950c;
          }
          .toggle-line2-active {
            border-bottom: 3px solid #ff950c;
          }
          .toggle-line3 {
            border-bottom: 1px solid #ff0000;
          }
          .toggle-line3-active {
            border-bottom: 3px solid #ff0000;
          }
          .toggle-line4 {
            border-bottom: 1px solid #a60cff;
          }
          .toggle-line4-active {
            border-bottom: 3px solid #a60cff;
          }
          .toggle-line5 {
            border-bottom: 1px solid #3db725;
          }
          .toggle-line5-active {
            border-bottom: 3px solid #3db725;
          }
          .toggle-line6 {
            border-bottom: 1px solid #0cd6ff;
          }
          .toggle-line6-active {
            border-bottom: 3px solid #0cd6ff;
          }
          .toggle-line7 {
            border-bottom: 1px solid #d35400;
          }
          .toggle-line7-active {
            border-bottom: 3px solid #d35400;
          }
        }
        .property-params,
        .instructionalText {
          flex-grow: 0.3;
          text-align: center;
          padding: 20px 0;
          cursor: pointer;
        }
        .instructional-text-icon {
          flex-grow: 0.1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .metrices-toggle-wrapper {
          flex-grow: 0.1;
          text-align: center;
          padding: 1% 0;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: 1px solid #dfdfdf;
          border-width: thin;
          .metric-label {
            color: #333333;
            opacity: 0.85;
            font-size: 11px;
            letter-spacing: 0.16px;
            .metric-label-note {
              font-size: 9px;
            }
          }
          .metric-total {
            line-height: initial;
            .metric-per {
              color: #333;
              font-weight: 600;
              font-size: 16px;
            }
            .metric-abs {
              color: #3e3e3e;
              font-size: 10px;
            }
          }
        }
        .instructionalText {
          border-bottom: 1px solid #dae4eb;
        }
        .property-action {
          flex-grow: 2;
          text-align: right;
          padding: 20px 0;
          padding: 20px 20px;
          border-bottom: 1px solid #dae4eb;
          .consent-right-arrow {
            font-size: 9px;
            color: #337ab7;
          }
          a {
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .property-title-container {
          padding: 1% 20px;
          display: flex;
          .graph-desc-container {
            flex: 0.7;
            line-height: initial;
          }
          .icons-list {
            align-self: center;
            flex: 0.3;
            text-align: right;
            .anticon {
              border: 1px solid #dae4eb;
              padding: 6px 15px;
              color: #aeb3ba;
              font-size: 15px;
              cursor: pointer;
            }
            .anticon.active {
              border: 1px solid #108ee9;
              color: #555758;
            }
            .anticon-bar-chart {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
            .anticon-line-chart {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
        .table-title-wrapper {
          display: flex;
          padding: 20px;
          .table-title {
            font-size: 20px;
            color: #000;
            align-items: center;
            display: flex;
            flex: 1;
          }
        }
        .action-wrapper {
          display: flex;
          justify-content: space-between;
          padding: 0 20px;
          .compare-msgs {
            display: flex;
            align-items: center;
            flex: 1;
            width: 180px;
          }
        }
        .msg-action-wrapper {
          display: flex;
          flex: 1;
          justify-content: flex-end;
          align-items: center;
          .ant-input-affix-wrapper {
            height: 32px;
            flex: 0.6;
          }
          .view-cols-btn {
            width: 200px;
            margin-right: 2%;
            color: #4b4b4b;
            display: flex;
            align-items: center;
            .anticon-caret-down {
              margin-left: auto;
            }
          }
          a {
            color: @primary-color;
          }
        }
        .property-title {
          font-weight: 400;
          font-size: 20px;
          letter-spacing: 0.24px;
        }
        .property-desc {
          font-size: 12px;
          letter-spacing: 0.14px;
          color: #444444;
          opacity: 0.8;
          font-weight: 100;
        }
        .recharts-tooltip-wrapper {
          min-width: 30%;
          background: #fff;
          padding: 1%;
          line-height: 2;
          box-shadow: 0px 0px 8px #ddd;
          display: flex;
          .tooltip-custom {
            flex: 1;
            .tooltip-entity-container {
              display: flex;
              .tooltip-entities {
                width: 35%;
                font-weight: 300;
                letter-spacing: 0.12px;
                color: #000000;
                opacity: 0.8;
              }
              span {
                width: 65%;
                letter-spacing: 0.12px;
                color: #000000;
              }
              span.tooltip-custom-text-style {
                letter-spacing: 0.12px;
                color: @primary-color;
              }
              span.tooltip-custom-text-bold {
                font-weight: bold;
              }
            }
            .tooltip-entity-container-note {
              font-size: 10px;
              font-style: italic;
            }
            .tooltip-view-more {
              text-align: right;
            }
          }
        }
        .vendors-filter-container {
          display: flex;
          padding: 20px;
          .vendor-property-select-container {
            flex: 0.3;
            .ant-select-selection--single {
              height: 35px;
              .ant-select-selection__placeholder {
                margin-top: 0;
              }
              .ant-select-selection-selected-value {
                margin-top: 3%;
              }
              .ant-select-search--inline .ant-select-search__field {
                margin-top: 3%;
              }
            }
          }
          .vendor-geo-select-container {
            flex: 0.2;
            margin-left: 1%;
            .ant-select-selection--multiple {
              height: 35px;
              .ant-select-selection__placeholder {
                margin: 0;
              }
            }
          }
          .ant-select-selection {
            border-radius: 0;
            border: 0;
            border-bottom: 1px solid #a4adb6;
            background-color: #f3f8fc;
            .ant-select-selection__placeholder {
              color: #555758;
            }
            .ant-select-selection__rendered {
              line-height: initial;
              margin-left: 10px;
            }
          }
        }
        .table-title-container {
          display: flex;
          margin: 50px 20px 20px 20px;
          justify-content: space-between;
          >div {
            display: flex;
            .compare-msgs {
              button {
                height: 35px;
                font-size: 14px;
                margin-right: 12px;
                line-height: 14px;
              }
            }
          }
          .table-title {
            font-size: 20px;
            color: #000;
            flex: 0.6;
          }
          .table-title-convert { // number percentage
            flex: 1;
            button:nth-child(1) {
              padding: 5px 20px;
              color: #aeb3ba;
              font-size: 14px;
              background: transparent;
              border-right: 0;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              border: 1px solid #aeb3ba;
            }
            button:nth-child(2) {
              padding: 5px 20px;
              color: #aeb3ba;
              font-size: 14px;
              background: transparent;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              border: 1px solid #aeb3ba;
            }
            button:nth-child(1).active,
            button:nth-child(2).active {
              color: #337ab7;
              border: 1px solid #108ee9;
            }
          }
          .ant-input-affix-wrapper {
            flex: 1;
            margin-left: 1%;
            .ant-input {
              height: 32px;
            }
          }
        }
        .sk-fading-circle {
          z-index: 0;
          position: relative;
          top: 10px;
        }
 
      }
    }
    .consent-tabs-container.gdpr-vendor-tab-container {
      padding: 20px;
    }
    .criteria-container {
      padding: 15px;
      margin: 1% 0;
      background: #fff;
      border-radius: 10px;
      display: flex;
      .editable-criteria-title {
        flex: 0.2;
        color: #000;
        font-size: 15px;
        display: inline-flex;
        margin: 8px 0px;
        .ant-input {
          height: 32px;
        }
        .anticon.anticon-edit {
          margin-left: 1%;
          margin-top: 2px;
          color: @primary-color;
        }
        .anticon.anticon-check-square {
          font-size: 34px;
          color: @primary-color;
          border-radius: 5px;
          &:hover {
            color: @primary-color-hover;
          }
        }
        .anticon.anticon-close-square {
          font-size: 34px;
          color: @primary-color;
          border-radius: 5px;
          &:hover {
            color: @primary-color-hover;
          }
        }
      }
      .criteria-tags-container {
        flex: 0.9;
        .ant-tag {
          margin-bottom: 6px;
          color: #2d2d2d;
          background-color: #e9dfff;
          .ant-tag-text {
            color: #2d2d2d;
          }
          .anticon-cross,
          .anticon-close {
            color: rgb(93, 134, 167);
          }
        }
        .moreless {
          text-decoration: underline;
          font-weight: 600;
          padding: 0 5px;
          color: @primary-color;
          &:hover {
            color: @primary-color-hover;
            cursor: pointer;
          }
        }
      }
      .remove-criteria {
        margin-top: 8px;
        span.anticon-delete {
          width: 30px;
          height: 30px;
          svg {
            font-size: 18px;
            color: @primary-color;
            &:hover {
              color: @primary-color-hover;
            }
          }
        }
      }
    }

    .recharts-legend-wrapper {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      .legend-custom {
        margin-left: 1%;
        .customLegendIcon {
          border: 1px #d2caca;
          display: inline-block;
          width: 20px;
          height: 0;
          vertical-align: middle;
          margin-right: 1%;
        }
      }
    }
    .timeframe-errors {
      background: #ffd6d6;
      display: flex;
      padding: 15px 20px;
      > div.icon {
        margin-right: 10px;
        color: #ff0909;
        font-size: 25px;
      }
      span.timeframe {
        color: #ff0909;
        font-weight: bold;
      }
    }
  }


  .consnet-tableCSS {
    margin: 20px 20px;
    tbody > tr > td {
      padding: 1%;
      .consent-table-metrics {
        white-space: nowrap;
        .tbl-metric-per {
          font-weight: 700;
        }
      }
    }
    .ant-table {
      border-radius: 0;
      .right-align {
        text-align: right;
      }
      .left-align {
        text-align: left;
        &.rpt-trim-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
        }
      }
      .center-align {
        text-align: center;
      }
      .ant-table-title {
        padding: 16px 0;
        .msg-filtercols-wrapper {
          text-align: right;
        }
        .table-title-container {
          display: flex;
          margin: 20px 0px;
          align-items: center;
          .table-title {
            font-size: 20px;
            color: #000;
            flex: 1;
          }
          .table-title-convert { // number percentage
            flex: 1;
            button:nth-child(1) {
              padding: 5px 20px;
              color: #aeb3ba;
              font-size: 14px;
              background: transparent;
              border-right: 0;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              border: 1px solid #aeb3ba;
            }
            button:nth-child(2) {
              padding: 5px 20px;
              color: #aeb3ba;
              font-size: 14px;
              background: transparent;
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
              border: 1px solid #aeb3ba;
            }
            button:nth-child(1).active,
            button:nth-child(2).active {
              color: #337ab7;
              border: 1px solid #108ee9;
            }
          }
          .ant-input-affix-wrapper {
            flex: 1;
          }
          .vendors-table-search { //ConsentPVVendor
            flex: 1;
            display: flex;
            justify-content: flex-end;
            .view-cols-btn {
              width: 200px;
              margin-right: 2%;
              color: #4b4b4b;
              display: flex;
              align-items: center;
              .anticon-caret-down {
                margin-left: auto;
              }
            }
            .search-container {
              flex: 0.6;
              margin-right: 1%;
              .ant-input-affix-wrapper {
                padding: 0 11px;
              }
            }
          }
        }
      }
      .ant-table-footer {
        padding: 16px 0px;
        background: #fff;
        border-radius: 0;
        position: initial;
        button {
          padding: 5px 20px;
          color: #337ab7;
          font-size: 14px;
          background: transparent;
          border: 1px solid #108ee9;
          border-radius: 5px;
          float: right;
        }
      }
      .ant-table-placeholder {
        border: 1px solid #e8e8e8;
        border-top: 0;
      }
    }
    .ant-table-expanded-row > td.no-trim-td {
      white-space: nowrap;
    }
    .ant-table-expanded-row > td {
      padding: 0;
    }
  }
  .collapsibleTable {
    padding: 20px;

    .ant-collapse-header {
      background: #E9DFFF;
    }
    .ant-collapse-content {
      padding: 0;
    }
    .ant-collapse-item {
      margin-bottom: 20px;
    }
    .ant-collapse-borderless > .ant-collapse-item {
      border: 0;
    }
    .ant-collapse > .ant-collapse-item {
      border: 0;
    }
    .ant-collapse-content-box {
      padding: 0px 0px 8px 0px;
    }
  }

  .recharts-tooltip-wrapper:empty {
    display: none !important;
  }

}
.ant-popover-inner-content {
  font-size: 11px;
  line-height: 2em;
  .export-pdf-icon {
    font-size: 20px;
    color: red;
    cursor: pointer;
  }

}
.instructional-popover {
  width: 500px;
}
.export-popover {
  width: auto;
  text-align: center;
}
.compareMsgsModal,
.msgDesModal,
.recentChangeModal {
  width: 90% !important;
  .msg-info-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    .partition-det {
      flex: 1.5;
      font-size: 16px;
      .partition-name {
        margin-right: 1%;
      }
    }
    .view-cols-btn {
      width: 200px;
      margin-right: 2%;
      color: #4b4b4b;
      display: flex;
      align-items: center;
      .anticon-caret-down {
        margin-left: auto;
      }
    }
  }
  .ant-modal-close {
    color: #fff;
  }
  .ant-modal-header {
    background: #212f43;
    .ant-modal-title {
      color: #fff;
    }
    span.anticon-download {
      svg {
        color: #fff;
      }
    }
  }
  .modal-title {
    font-size: 20px;
    font-weight: bold;
    margin: 0px 20px;
    position: absolute;
  }
  .recharts-legend-item {
    float: right;
  }
  .recharts-legend-wrapper {
    .custom-legend {
      width: 80%;
      float: right;
      text-align: right;
      height: 50px;
      overflow: scroll;
    }
  }
  .recharts-tooltip-wrapper {
    min-width: 30%;
    .custom-msg-tooltip {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 1px 2px 6px #00000080;
      border-radius: 3px;
      padding: 2%;
    }
  }
  .ant-pagination-options {
    .ant-select-selection--single {
      width: auto;
    }
  }
  a {
    color:@primary-color
  }
  span.anticon-download {
    svg {
      color: @primary-color;
    }
  }
  .consnet-tableCSS {
    #avocado.consnet-tableCSS()
  }
}
.recentChangeModal .ant-table-title {
  display: flex;
  justify-content: flex-end;
}

.ant-checkbox-group.reports-table-filter {
  display: flex;
  flex-direction: column;
  .ant-checkbox-wrapper {
    margin-left: 8px;
    font-weight: 400;
  }
}
