@import '../../../../styleguide/styleguide.less';
.properties_selection-mixin{
  .properties-section {
    display: flex;
    width: 100%;
    height: 50vh;
    margin: 0px 0px;
    align-items: flex-start;
    .properties-checkgroup-section {
      border-right: 1px solid #d3d9dd;
      // border-radius: 5px 0px 0px 5px;
      width: 300px;
      height: 50vh;
      .ant-tabs {
        width: inherit;
        .ant-tabs-nav {
          margin: 0px;
          height: 48px;
          &::before {
            margin: 0px var(--ant-margin);
          }
          .ant-tabs-nav-list {
            .ant-tabs-tab {
              width: 120px;
              justify-content: center;
            }
          }
        }
        .ant-tabs-content-holder {
          padding: 8px var(--ant-padding);
          label.ant-checkbox-wrapper {
            position: relative;
            width: 95%;
            // span.ant-checkbox {
            //   position: absolute;
            //   right: 0;
            // }
            span:not(.ant-checkbox) {
              padding-left: 8px;
            }
          }
        }
      }
      .properties-checkgroup-options {
        overflow-y: scroll;
        overflow-x: clip;
        height: calc(50vh - 170px);
        // padding-left: 8px;
        .ant-tree {
          .ant-tree-node-content-wrapper {
            max-width: 230px;
            display: flex;
            .ant-tree-title {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
          span.ant-tree-switcher{
            display: none;
          }
          // .ant-tree-treenode{
          //   padding-left: 4px;
          // }
        }
        // .ant-tree-checkbox {
        //   position: absolute;
        //   right: 0;
        // }
      }

      .property-group-options {
        overflow-y: scroll;
        max-height: 350px;
        height: calc(50vh - 120px);
        div.property-group {
          display: flex;
          justify-content: left;
          margin: 0px 8px;
          align-items: center;
          .button-group{
            width: 45px;
            display: flex;
            justify-content: space-between;
            .property-group-btn{
              background: var(--ant-color-primary);
              border-radius: 3px;
              align-items: center;
              height: 16px;
              width: 16px;
              padding: 0px;
              color: white;
              line-height: 0;
              margin-right: var(--ant-margin-xs);
              .ant-btn-icon{
                font-size: 10px;
                span.anticon {
                  font-size: 10px;
                }
              }
              &:disabled{
                background-color: #fff;
                color: #000;
              }
            }
          }
        }
        .ant-typography {
          padding: 0 var(--ant-padding-xxs);
        }
      }
    }
    .selected-properties {
      padding: 0 var(--ant-padding) var(--ant-padding) var(--ant-padding);
      width: calc(1000% - 300px);
      height: inherit;
      div.property-groups-container {
        height: calc(100% - 48px);
        overflow-y: scroll;
        padding-right: var(--ant-padding);
        &.centered{
          position: relative;
          >div {
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
          }
          color: #D9D9D9;
          font: normal normal 600 16px/60px Open Sans;
        }
      }
      div.heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: var(--ant-padding) 0;
        height: 48px;
      }
      .property-group{
        padding: 16px 0;
        border-bottom: 1px solid var(--ant-color-border-secondary);
        .group-name {
          color: @primary-color;
          margin-bottom: 8px;
          svg {
            opacity: 0.7;
          }
        }
        .tags-container {
          display: flex;
          flex-wrap: wrap;
          row-gap: 12px;
        }
      }
    }
  }
}