#avocado{
  #messages-v2 {
    margin-top : 30px;
    padding-top: 40px;
    .quill-mention>span {
      background-color: lightgreen;
      display         : inline-block;
    }
    .nt-header {
      display       : flex;
      flex-direction: row;
      margin-bottom : 20px;


      >.nt-title {
        font-size  : 24px;
        font-weight: 900;
        line-height: 30px;
        color      : #000;
        flex       : 1;
        margin     : 0;
        align-items: center;
        display    : flex;

        .page-title {
          font-weight: 500;
          font-size  : 17px;
          color      : #5875a3;
        }

        .page-label {
          color      : #212F43;
          opacity    : 0.6;
          font-size  : 16px;
          font-weight: 500;
        }
      }

      >.nt-right {
        display        : flex;
        align-items    : center;
        justify-content: flex-end;

        .ant-select,
        .ant-input-search {
          margin-right: 20px;
        }

        button {
          margin-bottom: 0 !important;
        }

      }
    }
    .redirect-to-group-wrapper {
      margin-bottom: 1%;
      .redirect-text {
        cursor: pointer;
      }
    }
    .spsg-tabs .ant-tabs-content-holder {
      padding-top: 10px;
      background-color: #fff;
    }
  }
}

.checkbox-input-options-table {
  overflow: scroll;

  .row-container {
    display        : flex;
    justify-content: space-between;
    border         : 1px solid #d1d1d1;
    padding        : 4px;
    margin         : 5px;
    margin-right   : 0;
    position       : relative;
  }

  .rm-btn {
    position : absolute;
    top      : 0;
    right    : 5px;
    font-size: 12px;
    color    : #727272;
    cursor   : pointer;

    &.disabled {
      cursor : default;
      opacity: .3;
    }
  }

  .inputs-container {
    flex  : 3;
    height: 75px;
  }

  .label-value-input {
    display    : flex;
    align-items: center;

    label {
      margin-right: 5px;
      font-size   : 12px;
    }

    input {
      width        : 100%;
      height       : 30px;
      margin-top   : 3px;
      margin-bottom: 3px;
      font-size    : 12px;
    }
  }

  span {
    font-size: 12px;
  }

  .checkbox-container {
    flex        : 1;
    height      : 75px;
    padding-top : 23px;
    padding-left: 5px;
  }

  .switch-container {
    flex           : 2;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;

    .switch-item {
      display        : flex;
      justify-content: space-between;
      margin         : 5px;
      align-items    : inherit;

      div {
        font-size   : 11px;
        margin-right: 5px;
      }

      div.large {
        font-size  : 14px;
        font-weight: 500;
      }
    }
  }
}

.checkbox-input-options-table.boolean-style {
  h4 {
    font-size  : 11px;
    font-weight: 600;
    display    : flex;

    >span {
      margin: 0 !important;
      color : red !important;
      top   : 0 !important;
    }
  }

  >div {
    >div {
      width: 48%;
    }
  }
}

.select-group-type-holder {
  margin-bottom: 10px;
}

.missing-views-list {
  .not-selected {
    cursor: pointer;
    color : #03a9f4;
  }

  .selected {
    font-weight    : 500;
    text-decoration: underline;
    color          : #2196f3;
    cursor         : default;
  }
}

.template-confirm {
  .template-buttons {
    margin-top: 20px;
    text-align: center;
  }

  .ant-confirm-btns>button:last-child {
    display: none;
  }
}

#builder-preview {
  position    : absolute;
  width       : calc(100% + 40px);
  height      : 100%;
  padding     : 0;
  margin      : 0;
  border      : 0;
  outline     : 0;
  left        : 50%;
  transform   : translateX(-50%);
  transition  : width 0.45s ease;
  border-left : 20px solid #000;
  border-right: 20px solid #000;
}

#iframe-holder {
  position: absolute;
  width   : 100%;
  height  : 100%;
  padding : 0;
  margin  : 0;
  border  : 0;
  outline : 0;

  &.mobile {
    #builder-preview {
      width: 420px;
    }
  }
}

.no-messages-found {
  padding   : 20px;
  text-align: center;
}

.add-component-modal {
  .ant-modal-body {
    background: #f6f8fa;
  }

  .components-grid {
    display   : flex;
    flex-wrap : wrap;
    margin-top: 5px;

    .components-search input {
      height       : 40px;
      font-size    : 14px;
      margin-bottom: 0 !important;
      padding-left : 15px !important;
      width        : 100%;
    }

    .components-search {
      margin-bottom: 20px;
      margin-left  : 10px;
      margin-right : 10px;
      width:100%;
    }

    .required-hr {
      width      : 100%;
      font-size  : 16;
      font-weight: 500;

      div {
        margin       : 10px;
        border-bottom: 2px #909090 solid;
      }
    }

    >.components-holder {
      display   : flex;
      flex-wrap : wrap;
      margin-top: 5px;
      height    : 55vh;
      overflow  : scroll;
      width     : 100%;

      >.component-item {
        width        : 25%;
        margin-bottom: 20px;
        cursor       : pointer;

        >.frame {
          margin       : 0 10px;
          background   : #FFF;
          border-radius: 5px;
          border       : 1px solid #d9d9d9;

          .ci-icon {
            height     : 100px;
            position   : relative;
            font-size  : 60px;
            text-align : center;
            line-height: 120px;
          }

          .ci-title {
            font-size     : 14px;
            text-align    : center;
            font-weight   : 500;
            padding-bottom: 20px;
          }

        }

        >.frame.added {
          opacity: .3;
        }

        &:hover {
          >.frame {
            border-color: #411f90;
          }

          >.frame.added {
            border-color: #d9d9d9;
            cursor      : not-allowed;
          }
        }
      }
    }

    .components-holder.missing-components-holder {
      height         : initial;
      min-height     : 25vh;
      max-height     : 50vh;
      align-items    : center;
      justify-content: center;

      >.component-item.missing-component-item {
        margin: 10px 0;

        &:hover {
          >.frame {
            border-color: #d9d9d9;
            cursor      : default;
          }
        }
      }
    }

    .missing-components-holder.extra-rows {
      justify-content: 'initial';

    }
  }
}

.missing-container {
  text-align: center;

  .missing-languages {
    margin-top: 24px;
    width     : auto;
    display   : inline-block;

    .missing-language,
    .missing-header {
      display       : flex;
      flex-direction: row;
      align-items   : center;

      .missing-language {
        width      : 180px;
        white-space: nowrap;
        text-align : left;
      }

      .missing-name {
        width       : 300px;
        white-space : nowrap;
        margin-right: 16px;
        text-align  : left;
      }

      .missing-settings {
        width       : 225px;
        white-space : nowrap;
        margin-right: 16px;
        text-align  : left;
        cursor      : pointer;
      }
    }

    .missing-header {
      >div {
        font-weight: bold;
      }
    }
  }
}

.missing-required-modal {
  .missing-frame {
    display    : flex;
    background : gray;
    align-items: center;
    padding    : 20px;
    height     : 70px;

    >.ci-icon {
      height      : 100px;
      position    : relative;
      font-size   : 85px;
      text-align  : center;
      line-height : 120px;
      margin-right: 20px;
    }

  }
}

.save-template-modal {
  .ant-modal-body>div {
    padding: 20px;
  }

  .ant-select {
    width     : 100%;
    margin-top: 15px;
  }

  .ant-select-selection--single {
    width: auto !important;
  }
}

.templates-modal {
  .ant-modal-close-x {
    color: #000;
  }

  .ant-modal-body {
    background: #f6f8fa;
  }

  .templates-box {
    display   : flex;
    flex-wrap : wrap;
    margin-top: 5px;

    .templates-options {
      display       : flex;
      flex-direction: row;
      width         : 100%;

      .templates-search input {
        //height       : 40px;
        font-size    : 14px;
        margin-bottom: 0 !important;
        padding-left : 15px !important;
      }

      .templates-search {
        margin-bottom: 20px;
        margin-left  : 10px;
        margin-right : 20px;
        flex         : 1;
      }

      .template-types {
        margin-right: 10px;
      }

      .ant-radio-group-large .ant-radio-button-wrapper {
        height     : 40px;
        line-height: 38px;
        font-size  : 14px;
      }
    }

    >.template-option {
      width        : 50%;
      margin-bottom: 20px;
      cursor       : pointer;

      >.frame {
        padding: 0 10px;

        .delete-btn {
          position: absolute;
          right   : 15px;
          z-index : 999;
          top     : 5px;
        }

        .preview {
          background   : #595959;
          height       : 270px;
          overflow     : hidden;
          position     : relative;
          border-radius: 5px;

          >.cover {
            background: transparent;
            position  : absolute;
            width     : 100%;
            height    : 100%;
            z-index   : 2;
          }

          .typeContainer {
            background: #000;
            opacity   : 50%;
            color     : white;
            text-align: center;
          }

          iframe {
            max-width : 100%;
            max-height: 100%;
            width     : 100%;
            height    : 100%;
            outline   : none;
            border    : 0;
          }
        }

        .name {
          font-size  : 16px;
          text-align : center;
          font-weight: 500;
          padding    : 10px 0;
        }
      }

      &:hover {
        .preview .cover {
          background: rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
}

.message-builder {
  overflow: visible;

  .ant-modal {
    max-width: 100vw;
    width    : 100% !important;
    height   : 100%;
    top      : 0;
    padding  : 0;
    margin   : 0 !important;

    .ant-modal-header {
      padding   : 0;
      z-index   : 999999;
      position  : absolute;
      width     : 100%;
      background: #fff;
    }

    .ant-modal-content {
      border-radius: 0;
      width        : 100%;
      height       : 100%;
      background   : #F5F6F7;
      z-index      : 9;
      padding      : 0;

      .ant-modal-body {
        padding : 0;
        position: absolute;
        width   : 100%;
        top     : 0;
        z-index : 9;
        height  : calc(100vh);

        .bulletin {
          margin-top: 68px;
        }

        .builder-title {
          height       : 68px;
          line-height  : 68px;
          position     : absolute;
          top          : 0;
          width        : 100%;
          background   : #fff;
          border-bottom: 1px solid #ddd;

          >span {
            display: inline !important;
          }

          .message-id{
            font-size: 16px;
            color : #03a9f4;
          }
          .meesage-title-verticle-line{
            font-size: 32px;
            font-weight: 700;
          }
          >svg {
            position : absolute;
            left     : 30px;
            top      : 50%;
            transform: translateY(-50%);
            font-size: 20px;
            cursor   : pointer;

            &:hover {
              color: #333;
            }
          }

          button {
            top         : 50%;
            transform   : translateY(-50%);
            height      : 36px;
            float       : right;
            margin-right: 20px;
            line-height: 16px;

            svg {
              margin-left: -6px !important;
              margin-right: 0px;
            }
          }

          button.ant-btn-circle {
            top         : 50%;
            transform   : translateY(-50%);
            height      : 36px;
            width       : 36px;
            font-size   : 16px;
            float       : right;
            margin-right: 20px;

            svg {
              margin-left: 0px;
            }
          }

          h3 {
            position     : absolute;
            left         : 30px;
            top          : 50%;
            transform    : translateY(-50%);
            font-weight  : 600;
            margin-top   : 0;
            margin-bottom: 0;

            .ant-tag {
              top: -4px !important;
            }
          }
          button.spsg-btn {
            border-radius: 5px;
          }
        }
      }
      .ant-modal-footer {
        margin-top: 0;
      }
    }
  }

  .bulletin>.ant-layout-header {
    background : #334151;
    color      : #FFF;
    padding    : 0 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size  : 24px;
    font-weight: 700;
    position   : relative;
  }

  .bulletin>.ant-layout-header button {
    position: absolute;
    right   : 20px;
    top     : 17px;
  }

  .bulletin>.ant-layout-header img {
    margin-right: 15px;
  }

  .add-new-component {
    position: absolute;
    z-index : 2147483646;
    bottom  : 30px;
    left    : 30px;
  }

  .add-new-component i {
    margin-right: 10px;
  }

  .ant-layout {
    background: #FFF;
  }

  .bulletin .domain-bg {
    width          : 100%;
    height         : 100%;
    background-size: 100%;
  }

  .on-dev {
    outline : 1px dashed #aaa !important;
    z-index : 2147483640;
    position: relative;
  }

  #on-hover {
    outline : 2px dashed #4CAF50 !important;
    cursor  : pointer;
    z-index : 2147483647;
    position: relative;
  }

  .on-active {
    outline : 2px dashed #108ee9 !important;
    cursor  : pointer;
    z-index : 2147483647;
    position: relative;
  }

  #on-hover.on-active {
    outline: 2px dashed #108ee9 !important;
  }

  #on-hover .add-div {
    position                  : absolute;
    right                     : 10px;
    top                       : 0;
    font-size                 : 14px;
    font-weight               : 500;
    color                     : #FFF;
    background                : #108ee9;
    height                    : 20px;
    line-height               : 20px;
    padding                   : 0 5px;
    z-index                   : 2147483647;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius : 4px;

    &:hover {
      opacity: 0.85;
    }
  }

  #on-hover .trash-div {
    position                  : absolute;
    right                     : 10px;
    top                       : 0;
    font-size                 : 14px;
    font-weight               : 500;
    color                     : #FFF;
    background                : #EF5350;
    height                    : 20px;
    line-height               : 20px;
    padding                   : 0 5px;
    z-index                   : 2147483647;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius : 4px;

    &:hover {
      opacity: 0.85;
    }
  }

  .sidebar {
    background : #FFF;
    max-height : 100%;
    position   : relative;
    border-left: 1px solid #ddd;

    .ql-snow .ql-tooltip {
      z-index : 999;
      position: fixed;
      left    : inherit !important;
      top     : 200px !important;
    }

    .hidden-fields-container {
      padding: 20px;

      .empty-hidden-fields-container {
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;
        height         : 300px;

        >div:first-child {
          font-size    : 16px;
          font-weight  : 600;
          margin-bottom: 5px;
        }
      }

      .header-container {
        display      : flex;
        margin-bottom: 5px;

        div {
          width      : 155px;
          font-weight: 600;
        }
      }

      .field-container {
        display        : flex;
        justify-content: space-between;
        align-items    : baseline;

        .rm-btn {
          cursor: pointer;
        }

        input {
          width: 150px;
        }
      }
    }

    .sidebar-tabs {
      display       : flex;
      flex-direction: row;

      .global-settings {
        width       : 50% !important;
        border-right: 1px solid #ddd;
      }

      >div {
        display        : flex;
        justify-content: center;
        align-items    : center;
        flex           : 1;
        border-bottom  : 1px solid #ddd;
        padding        : 10px 0;
        color          : #000;
        font-weight    : 400;
        background     : #f5f6f7;
        cursor         : pointer;

        .comp-count {
          background   : red;
          width        : 16px;
          height       : 16px;
          line-height  : 16px;
          text-align   : center;
          border-radius: 8px;
          color        : #fff;
          font-size    : 12px;
          position     : absolute;
          margin-top   : -6px;
          margin-left  : 12px;
          font-weight  : 400;
        }

        &:hover {
          background: #fff;
        }

        &:first-child {
          flex : none;
          width: 60px;
        }

        &:not(:first-child):not(:last-child) {
          border-right: 1px solid #ddd;
          border-left : 1px solid #ddd;
        }

        &.tab-active {
          border-bottom: 1px solid transparent;
          background   : #fff;
        }
      }
    }

    .options-color-picker,
    .options-copy-font-size-and-opacity,
    .options-z-index {
      display      : inline-flex;
      position     : relative;
      border-radius: 4px;
      height       : 30px;
      padding      : 0px;
      color        : rgba(0, 0, 0, 0.65);
      font-size    : 12px;
      width        : 100%;
    }

    .options-color-picker,
    .options-z-index {
      input[type="text"] {
        height          : 30px;
        padding         : 0 10px;
        width           : inherit;
        border-left     : 2px solid transparent;
        border-radius   : 4px;
        background-color: transparent;
        border          : 1px solid #d9d9d9;

        &:hover {
          border: 1px solid #49a9ee;
        }

        &:focus {
          border    : 1px solid #49a9ee;
          box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
        }
      }

      &.options-error {
        border: 0;

        input[type="text"] {
          border: 1px solid red !important;
        }
      }
    }

    .options-color-picker {

      input[type="color"] {
        position: absolute;
        top     : 1px;
        right   : 1px;
        cursor  : pointer;
        opacity : 0;
        display : block;
        height  : 28px;
        width   : 34px;
        z-index : 100;
      }

      span {
        position        : absolute;
        top             : 6px;
        right           : 8px;
        padding         : 0 0 2px 0;
        font-size       : 16px;
        color           : #999999;
        text-align      : center;
        background-color: transparent;
        width           : 18px;
        height          : 18px;
        border-radius   : 3px;
        border          : 1px solid #d9d9d9;
      }
    }

    .option-details {
      .four-options {
        margin: 0;
      }
    }
  }

  .sidebar .ant-layout-header {
    padding      : 0 30px;
    background   : #4CA9D1;
    font-weight  : 700;
    font-size    : 16px;
    overflow     : hidden;
    color        : #fff;
    border-bottom: 0px solid #e2e4e6;
  }

  .sidebar .ant-layout-header i {
    margin-right: 10px;
  }

  .sidebar .ant-layout-sider-trigger {
    background: #b0b2b4;
  }

  .sidebar .cancel-sidebar {
    float     : right;
    margin-top: 20px;
  }

  .sidebar .cancel-sidebar:hover {
    color           : #ccc;
    background-color: #ccc;
    border-color    : #ccc;
  }

  .sidebar .ant-tabs .ant-tabs-bar {
    margin-bottom: 0;
  }

  .sidebar .ant-tabs .ant-tabs-nav-container {
    background   : #4CA9D1;
    text-align   : right;
    padding-right: 8px;
  }

  .sidebar .ant-tabs .ant-tabs-ink-bar {
    display: none !important;
  }

  .sidebar .ant-tabs .ant-tabs-tab {
    padding-bottom: 0;
    margin-right  : 0;
  }


  .sidebar .ant-tabs .ant-tabs-tab {
    padding-left : 8px;
    padding-right: 8px;
  }

  .sidebar .ant-tabs .ant-tabs-tab>span {
    display                : block;
    color                  : #FFF;
    padding                : 10px 12px 10px 12px;
    border-top-left-radius : 5px;
    border-top-right-radius: 5px;
    font-weight            : 500;
  }

  .sidebar .ant-tabs .ant-tabs-tab-active>span {
    background: #FFF;
    display   : block;
    color     : #000;
  }

  .sidebar .add-link {
    background: none;
    color     : inherit;
    border    : none;
    padding   : 0;
    font      : inherit;
    cursor    : pointer;
    outline   : inherit;
    color     : #fff;
    position  : absolute;
    z-index   : 1;
    right     : 130px;
    top       : 18px;
    font-size : 16px;
  }

  .sidebar .add-link:hover {
    color: rgba(255, 255, 255, 0.5);
  }

  .ant-modal-wrap,
  .ant-modal-mask {
    z-index: 2147483640;
  }

  .component-modal .ant-modal-body {
    padding: 0;
  }

  .component-modal .ant-modal-content {
    border-radius: 5px;
  }

  .component-modal .ant-modal-body .ant-collapse>.ant-collapse-item:last-child {
    border-bottom: 0;
  }

  .component-modal .ant-modal-header {
    background: #4CA9D1;
  }

  .component-modal .ant-modal-header .ant-modal-title {
    color: #fff;
  }

  .component-modal .ant-modal-close-x {
    color: #fff;
  }

  .component-modal .ant-modal-close-x:hover {
    color: rgba(255, 255, 255, 0.2);
  }

  .sidebar-collapse {
    border-radius: 0;
    border       : 0;
    background   : #FFF;
  }

  .sidebar-collapse>.ant-collapse-item>.ant-collapse-header {
    padding    : 12px 0 12px 20px;
    font-weight: 600;
    font-size  : 14px;
  }

  .sidebar-collapse>.ant-collapse-item>.ant-collapse-header>span>svg {
    margin-right: 8px;
  }

  .sidebar-collapse>.ant-collapse-item:last-child,
  .sidebar-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
    border-radius: 0;
  }

  .sidebar-collapse .ant-collapse-item .ant-collapse-header .anticon {
    left : initial;
    right: 20px;
  }


  #crumbs {
    z-index   : 1;
    color     : #000;
    width     : ~"calc(100% - 360px)";
    left      : 0;
    padding   : 8px 20px;
    position  : fixed;
    z-index   : 2147483647;
    background: rgba(0, 0, 0, 1);
    top       : 68px;
  }

  #crumbs>.ant-breadcrumb ol {
    justify-content: center;
  }

  #crumbs .ant-breadcrumb .ant-breadcrumb-separator {
    color: rgba(255, 255, 255, 0.3);
  }

  #crumbs>.ant-breadcrumb>ol>li>span.ant-breadcrumb-link {
    color      : rgba(255, 255, 255, 0.75);
    cursor     : pointer;
    font-weight: 400;
    font-size  : 13px;
  }

  #crumbs>.ant-breadcrumb>ol>li>span.ant-breadcrumb-link svg {
    margin-right: 8px;
    position    : relative;
    top         : 0px;
  }

  #crumbs>.ant-breadcrumb>ol>li>span:hover.ant-breadcrumb-link {
    text-decoration: underline;
  }

  #crumbs .ant-breadcrumb>ol>li:last-child>span.ant-breadcrumb-link {
    font-weight    : 600;
    color          : #fff;
    cursor         : auto;
    text-decoration: none;
  }

  .settings-form {
    list-style: none outside none;
    margin    : 0;
    padding   : 0 0;
    margin-top: 15px;

    >li {
      float        : left;
      width        : 100%;
      padding      : 0 0 15px 0;
      margin-bottom: 15px;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: 0;
        margin-bottom: 0;
      }

      h4 {
        text-transform: capitalize;

        span {
          margin-left: 8px;
          font-weight: 400;
          color      : #bbb;
          float      : right;
          font-style : italic;
          font-size  : 12px;
          position   : relative;
          top        : 3px;
        }
      }

      .input-language-container {
        position: relative;
        .screen {
          position        : absolute;
          width           : 100%;
          height          : 100%;
          background-color: #bfbfbf45;
          z-index         : 999;
        }
      }
    }
  }

  .settings-collapse {
    background   : #fff;
    border-radius: 0;
    border-left  : 0;
    border-right : 0;
    margin-top   : -1px;
    z-index      : 99999;

    .quill-container {
      position: relative;

      .native-ott-warning {
        font-size: 9px;
        font-weight: bold;
      }

      .screen {
        position        : absolute;
        width           : 100%;
        height          : 100%;
        background-color: #bfbfbf45;
        z-index         : 999;
      }

      .ql-error{
        border: 1px solid red;
      }
      .ql-toolbar.ql-snow{
        button.ql-vendor-count{
          height: 25px;
          width: 25px !important;
          background: #E9DFFF;
          border-radius: 50%;
          padding: 0px;
        }
      }
    }

    .ant-collapse-item:last-child>.ant-collapse-content,
    .ant-collapse>.ant-collapse-item:last-child,
    .ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
      border-radius: 0;
    }

    .ant-collapse-content {
      background: #f9fafb;
      border-top: 1px solid #ddd;
      overflow: hidden;
    }

    .ant-collapse-header {
      text-transform: capitalize;
      font-size     : 14px;
      padding       : 12px 0 12px 40px;
      height        : auto;
      line-height   : 22px;

      .arrow {
        font-size  : 14px;
        line-height: 46px;
      }
    }
  }

  .language-settings{
    padding: 10px;
    background:#f5f6f7;
  }

  .top-form {
    display: table;
    content: '';
    clear  : both;
    width  : 100%;
  }

  .set-switch {
    display       : flex;
    flex-direction: row;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 2;
    }

    >div {
      flex           : 1;
      display        : flex;
      justify-content: flex-end;
    }
  }

  .set-location {
    display       : flex;
    flex-direction: row;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
    }

    >div {
      display        : flex;
      flex           : 2;
      position       : relative;
      justify-content: flex-end;
    }
  }

  .set-inputnumber {
    display       : flex;
    flex-direction: row;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 2;
    }

    input {
      font-size: 12px;
    }

    >div {
      flex: 1;
    }
  }

  .set-input {
    display       : flex;
    flex-direction: row;
    align-items   : center;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
    }

    .spsg-input{
      width: 75%
    }

    >input {
      flex         : 2;
      margin-bottom: 0 !important;
    }
  }

  .cat-select-checkbox {
    margin-top: 10px;
  }

  .cat-type-select {
    margin-bottom: 10px;
  }

  .set-languages {
    display       : flex;
    flex-direction: column;
    align-items   : flex-start;

    h4 {
      margin-bottom: 10px;
    }

    input {
      margin-bottom: 0 !important;
    }

    .ant-select-selection {
      width: auto !important;
    }

    .ant-select {
      margin-bottom: 0 !important;
      width        : 50px;
      height       : 28px;
    }
  }

  .text-language {
    display       : flex;
    flex-direction: row;
    align-items   : center;
    margin-top    : 10px;
    padding-right : 10px;

    h4 {
      margin       : 0;
      font-size    : 12px;
      font-weight  : 600;
      padding-right: 10px;
    }

    .ant-select-selection {
      width: auto !important;
    }

    .ant-select {
      flex         : 1;
      margin-bottom: 0 !important;
    }
  }

  .four-options {
    margin: 0 -5px;

    h4 {
      display     : flex;
      align-items: baseline;
      padding-left: 5px;
      font-size   : 12px;
      font-weight : 600;
    }

    >.option-inputs {
      display       : flex;
      flex-direction: row;

      input {
        margin-bottom: 0 !important;
      }

      >div {
        flex      : 1;
        margin    : 0 5px;
        font-size : 12px;
        text-align: center;

        >input {
          font-size : 12px;
          text-align: center;
        }

        >h5 {
          font-size  : 10px !important;
          text-align : center;
          margin-top : 4px;
          font-weight: 500;
        }
      }

      .ant-select-selection--single {
        width: auto !important;
      }
    }

    .collapsible-panel{
      .collapsible-panel-header{
        display: flex;
        align-items: center;
        padding: 5px;
        background: #EFF2F6;
        h3{
          flex:1
        }
      }
      .collapsible-panel-content{
         background: #EFF2F6;
         padding: 8px;
         padding-top: 5px;
          .dropdown-title{
            font: normal normal normal 12px/15px Open sans;
            margin-bottom: 2px;
          }
          .ant-upload.ant-upload-drag{
            background: #F5F5F5 0% 0% no-repeat padding-box;
            border: 1px solid #CECECE;
            border-radius: 3px;
            width: 159px;
            margin-left: 24px;
          }
          .ant-upload.ant-upload-drag .ant-upload-btn{
            padding: 0px;
          }
          .upload-container{
            display: flex;
            align-items: center;
          }
          .csv-selection-upload{
            padding-left: 5px;
            .bullet-container{
              display: flex;
              padding-left: 5px;
              padding-right: 5px;
              align-items: center;
              color: black;
              font-weight: 600;
              font-size: 12px;
              margin-top: 5px;
              h4{
                margin-left: 5px;
              }
            }
          }
          
          button{
            display: block;
            width: 159px;
            height: 32px;
            background: #F5F5F5 0% 0% no-repeat padding-box;
            font: normal normal 600 12px/15px Open sans;
            padding:5px;
            &:disabled{
              background: #F2F2F2 0% 0% no-repeat padding-box;
            }
          }
          .download-csv-text{
            color:#512DA8;
            font-weight:600;
            cursor:pointer;
          }
          .spsg-select-multiple{
            width: 100% !important;
            margin-top: 5px;
          }
      }
    }

    

    .bulk-option{
      background: aliceblue;
      padding: 10px;
      .ant-upload.ant-upload-drag{
        background: none;
        border:0px;
      }
      .ant-upload.ant-upload-drag .ant-upload-btn{
        padding: 0px;
      }
      h4{
        margin-top: 20px;
      }
      button{
        display: block;
      }
      .download-csv-text{
        color:#512DA8;
        font-weight:600;
      }
      .spsg-select-multiple{
        width: 100% !important;
      }
    }

    h3 {
      font-size   : 12px;
      font-weight : 600;
      padding-left: 5px;
      margin-top  : 10px;
    }

    .padding-flex {
      display       : inline-flex;
      width         : 50%;
      flex-direction: row;
      align-items   : center;
      padding       : 0 8px;

      >.padding-label {
        width    : 40px;
        font-size: 12px;
      }

      >.ant-slider {
        flex  : 1;
        margin: 5px 6px;
      }
    }

    .padding-flex.radius {
      >.padding-label {
        width    : 25px;
        font-size: 14px;
      }
    }

    .border-flex {
      display       : inline-flex;
      width         : 50%;
      flex-direction: row;
      align-items   : center;
      padding       : 0 8px;

      >.border-label {
        width: 40px;
      }

      >.border-color {
        flex: 1;

        .cpswatch {
          position: relative;
          width   : 100px;
          float   : right;
        }
      }

      >.ant-slider {
        flex  : 1;
        margin: 5px 6px;
      }
    }
  }

  .set-font {
    display       : flex;
    flex-direction: row;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
    }

    >input {
      flex: 2;
    }
  }

  .set-flexjustify {
    display       : flex;
    flex-direction: row;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
    }

    >div {
      display        : flex;
      flex           : 2;
      position       : relative;
      justify-content: flex-end;
      align-items    : baseline;
    }
  }

  .set-flexalign {
    display       : flex;
    flex-direction: row;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
    }

    >div {
      display        : flex;
      flex           : 2;
      position       : relative;
      justify-content: flex-end;
    }
  }

  .select-common-dropdown {
    display    : flex;
    align-items: baseline;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 5px;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
    }

    .ant-select-selection--single {
      width: 200px !important;
    }
  }

  .set-direction {
    display       : flex;
    flex-direction: row;
    text-transform: capitalize;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
    }

    >div {
      display        : flex;
      flex           : 2;
      position       : relative;
      justify-content: flex-end;
    }
  }

  .option-input-table {
    max-height: 350px;
    overflow  : scroll;
  }

  .set-option-type {
    display        : flex;
    justify-content: space-between;
    align-items    : baseline;
    padding-bottom : 15px;
    margin-bottom  : 15px;
    border-bottom  : 1px solid #ddd;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
    }
  }

  .option-input-table {
    display        : flex;
    justify-content: space-between;

    .option-row {
      display    : flex;
      align-items: baseline;


      >div {
        margin-left: 5px;
        cursor     : pointer;
      }

      >div.remove-disabled {
        opacity: 0.2;
        cursor : default;
      }
    }
  }

  .option-input-column-holder {
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
    width          : 48%;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
      width          : 48%;
      margin-bottom  : 5px;
    }
  }

  .add-input {
    display      : flex;
    align-content: baseline;
    cursor       : pointer;
    font-size    : 12px;
    font-weight  : 600;

    div:first-of-type {
      margin-right    : 3px;
      background-color: white;
      color           : green;
    }
  }

  .cpcolor {
    height       : 28px;
    border-radius: 2px;
    display      : block;
    position     : relative;
  }

  .cphex {
    position       : absolute;
    display        : flex;
    width          : 100%;
    height         : 100%;
    justify-content: center;
    align-items    : center;
    top            : 0;
    left           : 0;
    text-shadow    : 0px 0px #FFFFFF;
  }

  .cpswatch {
    padding      : 0px;
    background   : #fff;
    border-radius: 4px;
    border       : 1px solid #d9d9d9;
    display      : block;
    cursor       : pointer;
    width        : 100%;
  }

  .cppopover {
    position: absolute;
    z-index : 2;
  }

  .cpcover {
    position: fixed;
    top     : 0px;
    right   : 0px;
    bottom  : 0px;
    left    : 0px;
  }

  .cppopover .sketch-picker {
    position  : fixed !important;
    right     : 20px;
    margin-top: -280px;
    box-shadow: none !important;
    border    : 1px solid #ddd;
    z-index   : 2147483647;

    >div:last-child {
      display: none !important;
    }
  }

  .set-color {
    display       : flex;
    flex-direction: row;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 2;
    }

    >div {
      flex    : 1;
      position: relative;
    }
  }

  .set-align {
    display       : flex;
    flex-direction: row;

    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
    }

    >div {
      display        : flex;
      flex           : 2;
      position       : relative;
      justify-content: flex-end;
    }
  }

  .set-type {
    >h4 {
      font-size      : 12px;
      font-weight    : 600;
      margin         : 0;
      display        : flex;
      justify-content: flex-start;
      align-items    : center;
      flex           : 1;
    }

    >div {
      text-align: center;
      margin-top: 15px;
    }

    >.option-inputs {
      display       : flex;
      flex-direction: row;
      margin-top    : 20px;

      >div {
        flex      : 1;
        margin    : 0 5px;
        font-size : 12px;
        text-align: center;

        &:first-child {
          flex: 0.4;
        }

        >input {
          font-size : 12px;
          text-align: center;
        }

        >h5 {
          font-size  : 10px !important;
          text-align : center;
          margin-top : 4px;
          font-weight: 500;
        }
      }

      .ant-select-selection--single {
        width: auto !important;
      }
    }
  }

  #folder {
    display: table;
    content: '';
    clear  : both;
    width  : 100%;
  }

  #folder .heads,
  .rows>div {
    display       : flex;
    flex-direction: row;
  }

  #folder .heads>div,
  #folder .rows>div>div {
    padding: 10px 15px;
  }


  #folder .heads {
    border-bottom: 1px solid #ddd;
    color        : #000;
  }

  #folder .heads {
    background : #fff;
    border-left: 1px solid #ddd;
  }

  #folder .heads-name {
    flex: 1;
  }

  #folder .heads-info {
    width      : 160px;
    border-left: 1px solid #ddd;
  }

  #folder .heads-actions {
    width: 100px;
  }


  #folder .child-name {
    flex       : 1;
    cursor     : pointer;
    color      : #000;
    font-weight: 500;
  }

  #folder .child-name>span {
    font-style : italic;
    color      : #aaa;
    margin-left: 10px;
    font-weight: 300;
    font-size  : 13px;
  }

  #folder .child-name i {
    margin-right: 10px;
  }

  #folder .child-info {
    font-style : italic;
    cursor     : pointer;
    width      : 160px;
    border-left: 1px transparent;
  }

  #folder .child-actions {
    text-align: right;
    width     : 100px;
  }

  #folder .child-actions i {
    font-size: 16px;
  }

  #folder .child-actions .fa-trash-alt {
    color : #e74c3c;
    cursor: pointer;
  }

  #folder .child-actions .fa-trash-alt:hover {
    color: #c72c1c;
  }

  #folder .child-actions .fa-cog {
    color       : #333;
    cursor      : pointer;
    margin-right: 12px;
  }

  #folder .child-actions .fa-cog:hover {
    color: #555;
  }

  #folder .child-actions .fa-clone {
    color       : #27ae60;
    cursor      : pointer;
    margin-right: 12px;
  }

  #folder .child-actions .fa-clone:hover {
    color: #078e40;
  }

  #folder .placeholder {
    background: #242628;
    padding   : 10px 15px;
    display   : block;
  }

  .placeholder:before {
    content: "Move here";
    color  : #fff;
  }

  #folder .row-child.dragging * {
    pointer-events: none;
  }


  #folder .rows>.row-child:nth-child(even) {
    background: #f4f5f5;
  }

  #folder .rows>.row-child {
    border-bottom: 1px solid #f4f5f5;
  }

  #folder .rows>.row-child:hover {
    background   : #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
  }

  #folder .no-children {
    width          : 100%;
    justify-content: center;
    font-style     : italic;
    padding        : 25px 10px;
    background     : #fff;
  }

  #folder .no-children:hover {
    background: #fff;
  }

  #folder #temp-row {
    display      : block;
    height       : 10px;
    background   : #FFF;
    border-bottom: 0;
  }

  .sidebar .add-component-button {
    text-align: center;
    margin-top: 20px;
  }


  #folder .child-move {
    text-align: right;
    width     : 30px;
    padding   : 10px 10px 10px 15px !important;
    position  : relative;
  }

  #folder .child-move svg {
    color    : #aaa;
    cursor   : pointer;
    font-size: 12px;
    position : absolute;
  }

  #folder .child-move>svg:first-child {
    top       : 50%;
    transform : translateY(-50%);
    margin-top: -7px;
  }

  #folder .child-move>svg:last-child {
    top       : 50%;
    transform : translateY(-50%);
    margin-top: 7px;
  }

  #folder .child-move svg:hover {
    color: rgba(24, 144, 255, 1.0);
  }

  #folder .child-move svg.disabled {
    color         : rgba(0, 0, 0, 0.0);
    pointer-events: none !important;
  }

  #folder #drag-id {
    background: blue !important;
  }

  .add-component-list {
    padding: 0 8px;
  }

  .add-component-list .ant-card-head {
    padding    : 0 12px;
    font-size  : 14px;
    line-height: 14px;
    min-height : 0px;
    font-weight: 600;
    transition : all 0.2s ease;
  }

  .add-component-list .ant-card-body {
    padding  : 12px;
    font-size: 12px;
  }

  .add-component-list .ant-card {
    cursor: pointer;
  }

  .add-component-list .ant-card:hover .ant-card-head {
    color     : #fff;
    background: #81BF97;
  }

  .add-component-list .ant-card-head-title .ant-btn {
    position    : absolute;
    right       : 12px;
    top         : 11px;
    color       : #fff;
    border-color: #fff;
  }

  .add-component-list .ant-card-head-title .ant-btn:hover {
    position    : absolute;
    right       : 12px;
    top         : 11px;
    color       : #fff;
    border-color: #fff;
  }
}


.notice-preview {
  position  : relative;
  background: #FFF;

  .custom-css {
    position: absolute;
    bottom  : 0;
    width   : 100%;

    .ace_gutter {
      background: rgba(30, 30, 30, 0.5);
    }

    .ace-twilight {
      background-color: rgba(0, 0, 0, 0.5);
    }

  }

  #custom-css-dragger {
    background-color: #000;
    height          : 10px;
    width           : 100%;
    cursor          : ns-resize;
  }

  .toolbar {
    border-radius: 5px;
    position     : absolute;
    top          : 15px;
    right        : 15px;
    background   : #333333;
    min-width    : 180px;
    z-index: 2;

    h4 {
      font-size    : 12px;
      color        : #fff;
      padding      : 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      margin       : 0;
      line-height  : 12px;

      span {
        float      : right;
        font-style : italic;
        color      : rgba(255, 255, 255, 0.5);
        font-size  : 10px;
        margin-left: 10px;
      }
    }

    .toolbar-options {
      padding   : 10px;
      text-align: left;

      >button {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .toolbar.toolbar-bottom,
  .template-preview.preview-bottom {
    top   : auto;
    bottom: 15px;
  }

  .navigation {
    left : 15px;
    right: unset;
    width: 270px;

    h4 {
      border-bottom: none;
    }

    >div {
      display        : flex;
      justify-content: space-between;
      border-bottom  : 1px solid rgba(255, 255, 255, 0.2);
      font-family: Open Sans;

      .view-navigation-select{
        .ant-radio-inner::after{
          width: 16px !important;
          height: 16px !important;
          top:50% !important;
          left: 50% !important;
        }
        span{
          color: white;
          font-size: 12px;
        }
      }


      .toggle {
        display        : flex;
        flex-direction : column;
        align-items    : center;
        justify-content: center;
        width          : 30px;
        height         : 30px;
        transition     : all 200ms;
      }

      .toggle.flip {
        transform: rotateX(-180deg);
      }
    }

    span {
      font-size: 12px;
    }
  }

  label {
    display    : block;
    height     : 30px;
    line-height: 30px;
    color      : white;
  }
}


#empty-notice {
  z-index                : 2147483646;
  width                  : 100%;
  position               : absolute;
  top                    : 50%;
  left                   : 50%;
  transform              : translate(-50%, -50%);
  font-family            : Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
  -webkit-font-smoothing : antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background             : #fff;
  min-height             : 0;
  max-height             : 560px;
  max-width              : 520px;
  padding                : 40px;
  border-radius          : 10px;
  text-align             : center;
  font-size              : 14px;
  cursor                 : pointer;

  .empty-or {
    margin: 15px 0;
    color : #999;
  }

  button {
    border                     : none;
    text-align                 : center;
    text-decoration            : none;
    display                    : inline-block;
    -webkit-transition-duration: 0.3s;
    transition-duration        : 0.3s;
    cursor                     : pointer;
    background-color           : #108ee9;
    padding                    : 4px 15px;
    border-radius              : 4px;
    color                      : #fff;
    line-height                : 30px;
    -webkit-font-smoothing     : antialiased;
    -moz-osx-font-smoothing    : grayscale;
    font-weight                : 600;
  }

  button:hover {
    background-color: #49a9ee;
  }
}

.notice p {
  font-size: inherit;
}

.notice-table {
  margin: 15px 0 50px 0;

  .nt-header {
    display       : flex;
    flex-direction: row;
    margin-bottom : 50px;

    >h1 {
      font-size  : 30px;
      font-weight: 900;
      line-height: 30px;
      color      : #000;
      flex       : 1;
      margin     : 0;
      align-items: center;
      display    : flex;
    }

    >div {
      display        : flex;
      align-items    : center;
      justify-content: flex-end;

      .ant-select,
      .ant-input-search {
        margin-right: 20px;
      }
    }
  }

  .nt-card {
    padding: 10px 40px;

    >.nt-row {
      border-bottom : 1px solid #ddd;
      display       : flex;
      flex-direction: row;
      padding       : 20px 0;

      .ant-checkbox-wrapper {
        padding-right: 20px;
        display      : flex;
        align-items  : center;
      }

      .nt-preview {
        width       : 160px;
        min-width   : 160px;
        height      : 120px;
        overflow    : hidden;
        position    : relative;
        margin-right: 20px;
        cursor      : pointer;

        &:hover {
          opacity: 0.8
        }

        .message-scaled {
          width              : 160px;
          height             : 120px;
          overflow           : hidden;
          border             : 0;
          background-color   : #595959;
          box-shadow         : 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          border-radius      : 5px;
          -webkit-user-select: none;
          -moz-user-select   : none;
          -ms-user-select    : none;
          user-select        : none;
          pointer-events     : none;
          cursor             : pointer;

          iframe {
            max-width : 100%;
            max-height: 100%;
            border    : 0;
            outline   : none;
          }
        }
      }

      .nt-title {
        flex           : 1;
        display        : flex;
        flex-direction : column;
        align-items    : flex-start;
        justify-content: center;

        h4 {
          font-size   : 14px;
          font-weight : 600;
          line-height : 16px;
          color       : #000;
          margin      : 0 0 7px 0;
          text-align  : left;
          padding     : 20px;
          padding-left: 0;
          cursor      : pointer;
        }

        span {
          border-radius: 9px;
          background   : #f5f6f7;
          color        : #53606E;
          font-weight  : 500;
          padding      : 2px 8px;
          font-size    : 12px;
        }
      }

      .nt-stat {
        display          : flex;
        flex             : 1;
        flex-direction   : column;
        align-items      : flex-start;
        justify-content  : center;
        //padding        : 0 60px 0 20px;

        h4 {
          font-size  : 14px;
          font-weight: 600;
          line-height: 16px;
          color      : #000;
          margin     : 0 0 7px 0;
          display    : block;
        }

        span {
          color      : #53606E;
          font-weight: 500;
          font-size  : 12px;
          display    : block;
        }

        >div {
          border-radius: 9px;
          background   : #f5f6f7;
          color        : #53606E;
          font-weight  : 500;
          padding      : 2px 8px;
          font-size    : 12px;
        }
      }

      .nt-actions {
        display        : flex;
        align-items    : center;
        justify-content: flex-end;
        padding-left   : 60px;

        button {
          margin-right: 20px;
        }

        svg {
          font-size: 16px;
          color    : #000;
          cursor   : pointer;

          &:hover {
            color: #555;
          }

          width: auto;
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .ant-input-affix-wrapper.ant-input-search.template-search {
    width : 272px;
    height: 32px;
    border: 1px solid #EEEEEE;
    margin: 10px;
  }

  .template-table-container {
    margin-top: 10px;

    .ant-table-thead>tr>th {
      background: #F3F8FC;
      height    : 32px;
    }

    th.ant-table-cell {
      color: #000 !important;
    }

    .template-display {
      width        : 155px;
      height       : 125px;
      background   : #F3F3F3;
      border-radius: 7px;
      border       : 1px solid #EEEEEE;
    }

  }
}

.notice-table-template {
  margin: 15px 0 50px 0;

  .top-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .nt-header {
    display       : flex;
    flex-direction: row;
    margin-bottom : 50px;

    >h1 {
      font-size  : 30px;
      font-weight: 900;
      line-height: 30px;
      color      : #000;
      flex       : 1;
      margin     : 0;
      align-items: center;
      display    : flex;
    }

    >div {
      display        : flex;
      align-items    : center;
      justify-content: flex-end;

      .ant-select,
      .ant-input-search {
        margin-right: 20px;
      }
    }
  }

  .nt-card {

    >.nt-row {
      border-bottom : 1px solid #ddd;
      display       : flex;
      flex-direction: row;
      padding       : 20px 0;

      .ant-checkbox-wrapper {
        padding-right: 20px;
        display      : flex;
        align-items  : center;
      }

      .nt-preview {
        width       : 160px;
        min-width   : 160px;
        height      : 120px;
        overflow    : hidden;
        position    : relative;
        margin-right: 20px;
        cursor      : pointer;

        &:hover {
          opacity: 0.8
        }

        .message-scaled {
          width              : 160px;
          height             : 120px;
          overflow           : hidden;
          border             : 0;
          background-color   : #595959;
          box-shadow         : 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          border-radius      : 5px;
          -webkit-user-select: none;
          -moz-user-select   : none;
          -ms-user-select    : none;
          user-select        : none;
          pointer-events     : none;
          cursor             : pointer;

          iframe {
            max-width : 100%;
            max-height: 100%;
            border    : 0;
            outline   : none;
          }
        }
      }

      .nt-title {
        flex           : 1;
        display        : flex;
        flex-direction : column;
        align-items    : flex-start;
        justify-content: center;

        h4 {
          font-size   : 14px;
          font-weight : 600;
          line-height : 16px;
          color       : #000;
          margin      : 0 0 7px 0;
          text-align  : left;
          padding     : 20px;
          padding-left: 0;
          cursor      : pointer;
        }

        span {
          border-radius: 9px;
          background   : #f5f6f7;
          color        : #53606E;
          font-weight  : 500;
          padding      : 2px 8px;
          font-size    : 12px;
        }
      }

      .nt-stat {
        display          : flex;
        flex             : 1;
        flex-direction   : column;
        align-items      : flex-start;
        justify-content  : center;
        //padding        : 0 60px 0 20px;

        h4 {
          font-size  : 14px;
          font-weight: 600;
          line-height: 16px;
          color      : #000;
          margin     : 0 0 7px 0;
          display    : block;
        }

        span {
          color      : #53606E;
          font-weight: 500;
          font-size  : 12px;
          display    : block;
        }

        >div {
          border-radius: 9px;
          background   : #f5f6f7;
          color        : #53606E;
          font-weight  : 500;
          padding      : 2px 8px;
          font-size    : 12px;
        }
      }

      .nt-actions {
        display        : flex;
        align-items    : center;
        justify-content: flex-end;
        padding-left   : 60px;

        button {
          margin-right: 20px;
        }

        svg {
          font-size: 16px;
          color    : #000;
          cursor   : pointer;

          &:hover {
            color: #555;
          }

          width: auto;
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .ant-input-affix-wrapper.ant-input-search.template-search {
    width : 272px;
    height: 32px;
    border: 1px solid #EEEEEE;
    margin: 10px;
  }

  .template-table-container {
    margin-top: 10px;

    .ant-table-row {
      cursor: pointer;
    }

    .ant-table-thead>tr>th {
      background    : #F3F8FC;
      height        : 32px;
      text-transform: initial !important;
      font-size     : 0.929em !important;
    }

    th.ant-table-cell {
      color: #000 !important;
    }

    .template-display {
      width        : 155px;
      height       : 125px;
      background   : #F3F3F3;
      border-radius: 7px;
      border       : 1px solid #EEEEEE;
    }

    .temp-record-style {
      color: #000000A6;
    }

    .status-container {
      display: inline-grid;
    }

    .ant-table-pagination.ant-pagination {
      margin: 16px;
    }
  }
}

.notice-table-template {
  margin: 15px 0 50px 0;

  .nt-header {
    display       : flex;
    flex-direction: row;
    margin-bottom : 50px;

    >h1 {
      font-size  : 30px;
      font-weight: 900;
      line-height: 30px;
      color      : #000;
      flex       : 1;
      margin     : 0;
      align-items: center;
      display    : flex;
    }

    >div {
      display        : flex;
      align-items    : center;
      justify-content: flex-end;

      .ant-select,
      .ant-input-search {
        margin-right: 20px;
      }
    }
  }

  .nt-card {

    >.nt-row {
      border-bottom : 1px solid #ddd;
      display       : flex;
      flex-direction: row;
      padding       : 20px 0;

      .ant-checkbox-wrapper {
        padding-right: 20px;
        display      : flex;
        align-items  : center;
      }

      .nt-preview {
        width       : 160px;
        min-width   : 160px;
        height      : 120px;
        overflow    : hidden;
        position    : relative;
        margin-right: 20px;
        cursor      : pointer;

        &:hover {
          opacity: 0.8
        }

        .message-scaled {
          width              : 160px;
          height             : 120px;
          overflow           : hidden;
          border             : 0;
          background-color   : #595959;
          box-shadow         : 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          border-radius      : 5px;
          -webkit-user-select: none;
          -moz-user-select   : none;
          -ms-user-select    : none;
          user-select        : none;
          pointer-events     : none;
          cursor             : pointer;

          iframe {
            max-width : 100%;
            max-height: 100%;
            border    : 0;
            outline   : none;
          }
        }
      }

      .nt-title {
        flex           : 1;
        display        : flex;
        flex-direction : column;
        align-items    : flex-start;
        justify-content: center;

        h4 {
          font-size   : 14px;
          font-weight : 600;
          line-height : 16px;
          color       : #000;
          margin      : 0 0 7px 0;
          text-align  : left;
          padding     : 20px;
          padding-left: 0;
          cursor      : pointer;
        }

        span {
          border-radius: 9px;
          background   : #f5f6f7;
          color        : #53606E;
          font-weight  : 500;
          padding      : 2px 8px;
          font-size    : 12px;
        }
      }

      .nt-stat {
        display          : flex;
        flex             : 1;
        flex-direction   : column;
        align-items      : flex-start;
        justify-content  : center;
        //padding        : 0 60px 0 20px;

        h4 {
          font-size  : 14px;
          font-weight: 600;
          line-height: 16px;
          color      : #000;
          margin     : 0 0 7px 0;
          display    : block;
        }

        span {
          color      : #53606E;
          font-weight: 500;
          font-size  : 12px;
          display    : block;
        }

        >div {
          border-radius: 9px;
          background   : #f5f6f7;
          color        : #53606E;
          font-weight  : 500;
          padding      : 2px 8px;
          font-size    : 12px;
        }
      }

      .nt-actions {
        display        : flex;
        align-items    : center;
        justify-content: flex-end;
        padding-left   : 60px;

        button {
          margin-right: 20px;
        }

        svg {
          font-size: 16px;
          color    : #000;
          cursor   : pointer;

          &:hover {
            color: #555;
          }

          width: auto;
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.url-modal-container {
  .url-container {
    display        : flex;
    justify-content: space-between;
    align-items    : center;

    div {
      border         : 1px solid #dadada;
      padding        : 10px;
      font-size      : 10px;
      font-weight    : 500;
      border-radius  : 2px;
      display        : flex;
      justify-content: center;
      align-items    : center;
      background     : aliceblue;
      width          : 95%;
    }
  }
}

.ant-modal-wrap.message-builder {
  .ant-layout-has-sider {
    padding: 0;
  }

  .ant-layout.bulletin {
    padding: 0;
  }
}

.icon-style {
  float : right;
  margin: 20px;
  cursor: pointer;
}

.filter-popup {
  display: flex;
  flex-direction: column;

  .titleContainer {
    display: flex;
    flex-direction: column;
    flex   : 1;

    .title1 {
      flex     : 1;
      font-size: 12px;
      cursor   : pointer;
    }

    .title2 {
      font-size: 12px;
      color    : #1370FB;
      cursor   : pointer;
    }
  }

  .btnContainer {
    display        : flex;
    flex           : 1;
    justify-content: flex-end;
    margin-top: 10px;

    .aplyBtn {
      background-color: #7f92bd;
      border          : 1px solid #7f92bd;
      color           : white;
      border          : #EEEEEE;
      margin-left     : 5px;
    }
  }

  .ant-checkbox-group {
    display: grid;
  }

  .ant-checkbox-group-item {
    font-size: 12px;
    color    : #000000A6;
    padding  : 5px;
  }
}

.filter-popup-new{
  .popover-content-container{
    display: flex;
    flex-direction: column;
    padding: 5px;
  .spsg-checkbox {
    &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked::after {
      border-color: #411F90;
    }
    &.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #411F90 ;
      border-color: #411F90;
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner::after{
      background-color: #411F90;
    }
    .ant-checkbox.ant-checkbox-checked.ant-checkbox-indeterminate .ant-checkbox-inner{
      background-color: transparent;
    }
  }
  .ant-checkbox-group {
    display: grid;
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-checked::after {
      border-color: #411F90;
    }
    .ant-checkbox-checked .ant-checkbox-inner{
      background-color: #411f90;
      border-color: #411f90;
    }
  }
}
.btnContainer{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    .ant-btn{
      font-family: Oswald;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 12px;
      text-align: center;
      padding: 9px 16px;
      height: 35px;
      box-shadow: none;
      background: #FFFFFF;
      border: 1px solid #D7DDE9;
      border-radius: 5px;
      &.btn-primary {
        background: #411F90;
        border: 1px solid #411F90;
        color: #fff;
        margin-left: 5px;
        &:hover {
          color: #fff;
          background: #6565A9;
          border: 1px solid #6565A9;
        }
        &:focus {
          color: #fff;
          background: #6565A9;
          border: 1px solid #6565A9;
        }
        &[disabled] {
          opacity: 0.7;
        }
      }
      &.btn-secondary {
        background: #fff;
        border: 1px solid #411F90;
        color: #411F90;
        &:hover {
          color: #6565A9;
          background: #fff;
          border: 1px solid #6565A9;
        }
        &:focus {
          color: #6565A9;
          background: #fff;
          border: 1px solid #6565A9;
        }
        &[disabled] {
          opacity: 0.7;
        }
      }
    }
}
}
.spsg-infotip.save-template {
  .ant-popover-inner > .ant-popover-inner-content {
    max-width: none;
    div:last-child {
      justify-content: center !important;
    }
  }
}

.spsg-infotip.tcf-version-update-infotip .ant-popover-inner{ 
  >.ant-popover-inner-content{
    background: #FFECEA;
    color: #ff2626;
    padding: 5px 10px;
    border-radius: 5px;
  }
}
.redirect-system-templates {
  cursor     : pointer;
  color      : #1370fb;
  font-weight: 500;

  .avo-dialogue-icons {
    color  : #1370fb !important;
    opacity: 1 !important;
    padding: 0;
    margin : 0 !important;
  }
}

.redirect-system-templates:hover {
  text-decoration: underline;
  font-style     : italic;
}

.system-templates-error {
  background-color: #fff;
}

.template-preview {
  display        : inline-flex;
  justify-content: center;
  padding-top    : 1%;
  position: absolute;
  z-index: 1;
  margin-left: 10px;

  .preview-action-wrapper {
    border-radius  : 6px;
    padding        : 8px;
    display        : flex;
    align-items    : center;
    background     : #333333;
    color          : #fff;
    position       : relative;
    display        : flex;
    justify-content: center;

    .anticon-folder-view {
      font-size   : 18px;
      margin-right: 2px;
    }

    .ant-select {
      width        : 200px;
      margin-bottom: 0 !important;
      margin-left  : 5px;
    }
  }
}

.template-preview.preview-middle {
  left: 0;
  right: 0;
  margin: 0 auto;
}

.template-type-selection {
  display       : flex;
  flex          : 1;
  flex-direction: row;

  .ant-btn-ghost:first-child {
    margin-right: 5px;
  }
}
.ant-modal-wrap.spsg-modal.translation-modal{
  font-size: 14px;
  font-style: 'Open sans';
  .ant-modal-content{
    width: 750px !important;
  }
}

.ant-modal-wrap.spsg-modal.translations-status{
  font-size: 14px;
  font-style: 'Open sans';
  .ant-modal{
    width: fit-content !important;
  }
  .ant-modal-content{
   // width: 750px !important;
    .translation-container{
      display: flex;
      flex:1;
      flex-direction: column;
      .translation-row{
        display: flex;
        flex:1;
        flex-direction: row;
        justify-content: space-between;
        margin:5px;
        .translation-count{
          margin:5px;
          flex:1;
        }
        title{
          font: normal normal normal 14px/21px Open Sans;
          color: #272727;
        }
        .info-icon{
          color: #6CD800;
          margin-right: 2px;
        }
        .warning-icon{
          color: #FFA200;
          margin-right: 2px;
        }
        .error-icon{
          color: #FF0000;
          margin-right: 2px;
        }
        .translation-text{
          font: normal normal normal 12px/16px Open Sans;
          letter-spacing: 0px;
          color: #7B7B7B;
          flex:1;
        }
      }
    }
  }
  .error-info{
    margin-top: 20px;
    .error-highlight{
      border: 1px solid red;
      border-radius: 5px;
    }
  }
  .detail-table{
    margin-top: 20px;
  }
}

.spsg-infotip.vc-infotip {
  .ant-popover-inner{
    >.ant-popover-inner-content{
      max-width: none;
    }
  }
}
