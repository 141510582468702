  @steel-blue: #5875a3;
  @dark-grey-blue: #354052;
  @light-steel-blue: #708eb6;
  @font-headers: myriad-pro, MyriadPro, Helvetica, Arial;

  .sort-filter-wrapper {
    display: flex;
    flex-direction: row;
    margin: 24px 0;
  }


  .container {
    width: 100%;


    .messaging {
      margin-top: -55;
    }

    .navbar-header {
      display: none;
    }

    .campaigns-container,
    .partitionsets-container,
    .scenarios-container,
    .messages-container {
      padding-top: 105px;
    }

    .campaigns-container.open-activate {
      margin-top: 100px;

      #avocado .messaging-page-header {
        margin-top: 140px;
      }
    }

    .open-import {
      margin-top: 95px;

      #avocado .messaging-page-header {
        margin-top: 135px;
      }
    }

    .open-create-window {
      margin-top: 60px;

      #avocado .messaging-page-header {
        margin-top: 100px;
      }
    }

    #avocado .messaging-page-header {
      position: absolute;
      margin-top: 40px;
      top: 135px;
      left: 5px;
      z-index: 1;
      .page-label{
       color: #212F43;
       opacity: 0.6;
       font-size: 16px;
      }
    }
 
    .redirect-to-group-wrapper{
      position: absolute;
      margin-top: 40px;
      top: 100px;
      left: 5px;
      z-index: 1;
      .redirect-text {
        cursor: pointer;
      }
    }

    .sort-filter-wrapper {
      position: absolute;
      top: 135px;
      right: 170px;
      width: 175px;

      button {
        width: 50%;
      }
    }

    h1 {
      color: @steel-blue;
      padding-left: 15px;
    }

    h3 {
      margin-top: 0;
      font-family: @font-headers;
      font-weight: 300;
      color: @dark-grey-blue;
      font-size: 22px;
      margin-left: 5px;
    }
  }

  .messages-container .hovertip,
  .info-tooltip {
    font-size: 14px;
    color: @light-steel-blue;
  }

  .avo-dialogue-icons-scenario {
    color: @steel-blue;
    opacity: 0.7;
    background: inherit;
    border: none;
    position: relative;
    top: 3px;

    &:hover {
      opacity: 1;
    }
  }

  .avo-dialogue-icons {
    color: @steel-blue;
    opacity: 0.7;
    background: inherit;
    margin: 0px 10px;
    border: none;
    position: relative;
    top: 3px;

    &:hover {
      opacity: 1;
    }
  }

  .partitionset.card {
    .partition-campaign-type {
      font-size: 18px;
      color: #333;
      font-weight: 500;
    }
    .panel-body {
      thead {
        .long-col {
          width: 300px;
        }
        .short-col {
          width: 100px;
        }
      }
    }
  }

  .scenario.panel.card {
    tbody {
      tr {
        td:first-child {
          text-align: center;
        }
      }
    }
  }

  .campaigns-container,
  .partitionsets-container,
  .scenarios-container,
  .messages-container {
    padding-top: 55px !important;
  }
