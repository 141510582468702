  @font-main: proxima-nova, ProximaNova, Helvetica, Arial;

  .subnav {
    padding-left:0;
    border-right: none;
    position: fixed;
    top: 96px;
    z-index: 101;
    left: 20px;

    .list-inline li {
      font-size: 14px;
      margin: 0 30px 0 5px;

      a {
        color: #212F43;
        padding: 13px 0;
        font-family: @font-main;
        font-weight: 300;

        &.active {
          color: #0294FF;
          border-bottom: 3px solid #0294FF;
          text-decoration: none;
        }

        &:hover {
          text-decoration: none;
          color: #0294FF;
        }
      }

      a.disabled, a[disabled] {
        color: #BDC1C6;
      }
    }
  }
