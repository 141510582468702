#free-scan-container {
  background: #f7f7f7;
  padding: 0 !important;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow-y: scroll;
  #free-scan-form-container {
    width: 428px;
    margin-top: 40px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #0000001f;
    border: 0.5px solid #d4d4d4;
    border-radius: 20px;
    opacity: 1;
    padding: 40px 65px 40px 65px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    .star {
      color: #e2574c;
    }
    .success {
      padding: 40px 54px 40px 54px;
      height: 344px;
    }
    .success-flex {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    > form {
      .ant-form-item-has-error
        :not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        border-color: #ff2626 !important;
        &:focus {
          box-shadow: none !important;
        }
      }
      .ant-form-item-explain-error {
        text-align: left;
        font: normal normal normal 10px/24px Open Sans;
        letter-spacing: 0px;
        color: #ff2626;
        opacity: 1;
      }
    }
    .free-scan-info {
      text-align: left;
      font: normal normal 600 12px/24px "Open Sans";
      letter-spacing: 0px;
      opacity: 1;
      color: #272727;
      padding: 0;
      &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
      .ant-checkbox:hover .ant-checkbox-inner,
      .ant-checkbox-input:focus + .ant-checkbox-inner,
      .ant-checkbox-checked::after {
        border-color: #411f90;
      }
      &.ant-checkbox-wrapper-checked:hover .ant-checkbox-inner,
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #411f90 !important;
        border-color: #411f90 !important;
      }
      &.scan-label {
        font-size: 13px;
      }
      &.right-align {
        text-align: right;
        font-size: 10px;
        font-weight: normal;
      }
      &.scan-checkbox {
        font-weight: normal;
        display: flex;
        align-items: flex-start;
      }
      a {
        color: #411f90;
      }
    }
    .free-scan-input {
      input {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0px;
        color: #272727;
        opacity: 1;
        height: 35px;

        &::placeholder {
          text-align: left;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.14px;
          color: #cacaca;
          opacity: 1;
        }
        &:focus,
        &:hover {
          border-color: #411f90;
          box-shadow: none;
        }
      }
    }
    .free-scan-btn {
      height: 34px;
      width: 100%;
      font-size: 15px;
      line-height: 14px;
      &.spsg-btn.btn-primary {
        &:hover {
          background-color: #411f90;
          opacity: 0.85;
        }
        &[disabled] {
        opacity: 0.5 !important;
        }
      }
    }

    .free-scan-select {
      .ant-select {
        .ant-select-selector {
          .ant-select-selection-item {
            line-height: 32px;
          }
          height: 35px;
          text-align: left;
          font: normal normal normal 14px/24px "Open Sans";
          letter-spacing: 0px;
          color: #272727;
          opacity: 1;
        }
        &:hover .ant-select-selector,
        &:focus .ant-select-selector {
          border: 1px solid #411f90 !important;
          box-shadow: none !important;
        }
        .ant-select-open {
          border: 1px solid #411f90 !important;
          box-shadow: none !important;
        }
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border: 1px solid #411f90 !important;
      }
    }
  }
  .spl-logo {
    margin-bottom: 30px;
    text-align: center;
    img {
      width: 181px;
    }
  }

  .success-alert {
    margin-top: 40px;
    padding: 0px 5px 0px 5px;
    background: #e2f4e4 0% 0% no-repeat padding-box;
    border-radius: 25px;
    opacity: 1;
    text-align: center;
    line-height: 35px;
    width: 197px;
    height: 35px;
    svg {
      color: white;
      border-radius: 100%;
      background: #43ba54 0% 0% no-repeat padding-box;
      width: 18px;
      height: 18px;
      margin-top: 0px;
      padding: 4px;
      transform: translateY(2px);
    }
    .success-text {
      font: normal normal normal 13px/35px Open Sans;
      letter-spacing: 0.13px;
      color: #272727;
      padding-left: 6px;
    }
  }
  .email-text {
    margin-top: 15px;
    font: normal normal 600 15px/24px Open Sans;
    letter-spacing: 0px;
    color: #272727;
    text-align: center;
  }
}
.ant-select-dropdown.dropdownLocations {
  width: 270px;
  font: normal normal normal 14px/40px Open Sans;
  letter-spacing: 0px;
  color: #272727;
  .free-scan-input {
    > input {
      text-align: left;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0px;
      color: #272727;
      opacity: 1;
      height: 35px;
      width: 75%;
      margin: 19px;
      margin-right: 10px;
      &::placeholder {
        text-align: left;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.14px;
        color: #cacaca;
        opacity: 1;
      }
      &:focus,
      &:hover {
        border-color: #411f90;
        box-shadow: none;
      }
    }
  }
}
.disable-background{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  width: 100%;
}
