.darkness {
  position: absolute;
  width: 99999px;
  height: 99999px;
  background-color: black;
  opacity: 0.5;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 99999;
}

.warning-window-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 999999;

  .warning-window {
    position: absolute;
    margin: 0 auto;
    width: 600px;
    padding: 80px 50px;
    background-color: white;
    border: 1px solid grey;
    border-radius: 5px;
    left: ~"calc(50% - 300px)";
    top: ~"calc(50% - 200px)";

    p {
      color: grey;
      font-size: 17px;
      text-align: center;
    }

    p:first-child {
      font-size: 25px;
    }

    .buttons-container {
      margin: 0 auto;
      width: 260px;
      margin-top: 48px;

      button:first-child {
        margin-right: 10px;
      }
    }
  }
}
