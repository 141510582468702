@import (css) url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

.spsg-radio {
  &.ant-radio-wrapper {
    color: #000;
  }

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
  }

  .ant-radio-inner {
    outline: 0 !important;
    width: 18px;
    height: 18px;
  }
  
  &.ant-radio-wrapper:hover .ant-radio-inner, 
  .ant-radio:hover .ant-radio-inner, 
  .ant-radio-input:focus + .ant-radio-inner,
  .ant-radio-checked::after {
    border-color: #411F90 !important;
  }
  &.ant-radio-wrapper-checked:hover .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner {
    border-color:#411F90!important;
  }

  .ant-radio-inner::after {
    background-color:#411F90;
  }

}

.spsg-radio-group {
  .ant-radio-button-wrapper {
    height: 40px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 38px;
    color: #aaaaaa;
    &:hover {
      color: #411F90;
    }
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #411F90;
    border-color: #411F90;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background-color: #411F90;
  }
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-checked::after {
    border-color: #411F90 !important;
  }
  &.ant-radio-wrapper-checked:hover .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner {
    border-color:#411F90;
    background-color: white;
  }

  .ant-radio-inner::after {
    background-color:#411F90;
  }
}
