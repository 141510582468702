.website-vendor-scan-modal {
  .ant-modal {
    width: 70% !important;
    .ant-modal-header {
      background: #411f90;
      font-family: 'Oswald';
      height: 50px;
      .ant-modal-title {
        font-size: 16px;
        letter-spacing: 0.2px;
        color: #ffffff;
        font-weight: 100;
      }
    }
    .ant-modal-close {
      color: #fff;
    }
    .ant-modal-body {
      padding: 3%;
      .no-vendor-categorization-error {
        font-size: 10px;
        display: initial;
        align-items: center;
        .anticon-info-circle{
          color: #899ab4;
          font-size: 12px;
          margin-left: 5px;
        }
      }
      .vendor-cat-wrapper {
        .back-to-scan {
          border: 0;
          color: #411F90;
          padding: 0;
          font-size: 14px;
        }
        .ant-divider {
          margin: 8px 0;
        }
        p {
          color: #00000080;
          font-size: 12px;
        }
      }
      .ant-table {
        margin: 5px 0;
        table {
          border-collapse: collapse;
          padding: 0;
          border: 1px solid #eeeeee;
          th {
            text-transform: capitalize;
            font-size: 12px;
            letter-spacing: 0px;
            color: #fff;
            font-weight: 100;
            background: #6565A9 0% 0% no-repeat padding-box;
          }
          td {
            padding: 1%;
            color: #4b4b4b;
            .lb-tag {
              padding: 1%;
              width: 130px;
              text-align: center;
            }
            .li-tag {
              display: flex;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              flex: 0.88;
              align-items: center;
              justify-content: center;
              padding: 2%;
            }
            .lb-consent {
               background: #e7f0fd;
               color: #1e74f7;
            }
            .lb-legitimate {
               background: #fff4e0;
               color: #fd9034;
            }
            .lb-not-applicable {
              background: #f2f2f2;
              color: #000;   
            }
          }
          tr.empty-row-error {
            background: #ffecec;
          }
          tr.cookie-new {
            border-left: 2px solid #60c92c;
          }
          tr.cookie-modified {
            border-left: 2px solid #ff9148;
          }
          .ant-select.ant-select-single {
            margin: 0;
            width: 100%;
            .ant-select-selector {
                padding: 0 3%;
                .ant-select-selection-item {
                  padding: 0;
                }
            }
            .ant-select-selection--single {
              width: auto;
              .ant-select-selection-selected-value {
                padding-right: 1%;
              }
            }
          }
          .categorization-legal-basis {
            .ant-select-selector {
              padding: 2% !important;
              .ant-select-selection-item {
                display: flex;
              }
              .ant-select-selection-placeholder {
                align-items: center;
                display: flex;
               }
            }
          }
          td {
            .btn-add-row {
              width: 100%;
              border: 0;
              color: #899ab4;
            }
            .avo-dialogue-icons.avo-sp-delete{
              margin: 0;
            }
            .ant-input {
              height: 32px;
              margin-bottom: 0;
            }
            .ant-input.disabled-input {
              background: #fcfcfc 0% 0% no-repeat padding-box;
              border: 1px solid #eeeeee;
              cursor: not-allowed !important;
            }
            .duration-wrapper {
              display: flex;
              .ant-select {
                flex: 0.5;
              }
              .ant-input {
                flex: 0.5;
                margin-left: 2%;
              }
            }
          }
        }
      }
      .descr {
        font-size: 12px;
        color: #00000099;
      }
      .ant-select.ant-select-multiple {
		    width: 100%;
        padding: 10px;
        background: #fbfbfb 0% 0% no-repeat padding-box;
        border: 1px solid #e1e1e1;
        max-height: 100px;
        overflow: hidden;
        overflow-y: scroll;
        .ant-select-selection__choice {
          border: 0;
          font-size: 13px;
          letter-spacing: 0.13px;
          color: #1370fb;
          background: #e0edfe 0% 0% no-repeat padding-box;
        }
        .ant-select-selection__choice__remove {
          color: #1370fb;
        }  
        .ant-select-selector {
          border: 0;
          background-color: transparent;
          font-size: 12px;
          box-shadow: none;
          .ant-select-selection-item {
            background: #fff;
            color: #411F90;
            .ant-select-selection-item-remove {
               color: #411F90;
            }
          }
        }
      }
      .auto-add-rescan-container {
        display: flex;
        align-items: center;
        background: #e9dfff 0% 0% no-repeat padding-box;
        padding: 7px 10px;
        margin-bottom: 10px;
        justify-content: space-between;
        button {
          border: 1px solid #411f90;
          color: #411f90;
          background: #f2f8fc;
        }
        .anticon {
           color: #00000099;
        }
      }
      .no-vendor-found {
        background: #e9dfff;
        padding: 1%;
      }
      .vendor-cat-warning {
        color: #00000099;
        font-size: 12px;
      }
      .ant-tabs {
        .ant-tabs-extra-content {
          .ant-btn {
            border: 1px solid #1370FB;
            color: #1370FB;
          }
        }
        .ant-tabs-nav-wrap {
          height: auto;
          .ant-tabs-nav-list {
            padding-top: 0;
            .ant-tabs-tab {
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 1px solid #d9d9d9;
              color: #4b4b4b;
              font-size: 14px;
              padding: 10px;
              line-height: 20px;
              margin-right: 0;
              .cookie-modified-count {
                background: #ff9148 0% 0% no-repeat padding-box;
                border-radius: 100%;
                height: 20px;
                width: 19px;
                text-align: center;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                font-size: 12px;
              }
            }
            .ant-tabs-tab-active {
              background: #7f92bd 0% 0% no-repeat padding-box;
              border: 1px solid #d9d9d9;
              color: #fff;
              .ant-tabs-tab-btn{
               color: #fff;
              }
            }
          }
        }
        .ant-tabs-content {
          max-height: 400px;
          overflow-x: scroll;
          .ant-tabs-tabpane {
            .legend-wrapper {
              display: flex;
              color: #000000a6;
              align-items: center;
              font-size: 12px;
              margin-bottom: 2%;
              .anticon {
                height: 12px;
                width: 12px;
                margin-right: 1%;
              }
              .anticon.new {
                background: #52c41a 0% 0% no-repeat padding-box;
                color: #52c41a;
              }
              .anticon.old {
                background: #ff9148 0% 0% no-repeat padding-box;
                color: #ff9148;
                margin-left: 1%;
              }
            }
            .gAtpErr {
              font-size: 12px;
              padding: 5px;
              margin-bottom: 2%;
              color: #4B4B4B;
              background: #FFF1F0 0% 0% no-repeat padding-box;
              border: 1px solid #FFD2CF;
            }
            .collapse-name-all,
            .collapse-name {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .ant-checkbox-wrapper {
                color: #000;
                font-size: 13px;
              }
              .iab-warning {
                font-size: 10px;
                .anticon {
                  color: #00000099;
                }
              }
            }
            .collapse-name-all { 
                border-bottom: 1px solid #f9f9f9;
                margin-bottom: 1%;
                padding-bottom: 2%;
                .ant-checkbox-wrapper {
                  color: #000000A6;
                }
                .google-atp-def {
                  color: #000000;
                }
			}
            .ant-collapse-borderless {
              background-color: transparent;
            }
            .ant-collapse-header {
              padding-left: 0;
            }
            .ant-collapse-content {
              padding: 0;
              .ant-collapse-content-box {
                padding-top: 0px;
                font-size: 13px;
                letter-spacing: 0.18px;
                color: #000000a6;
              }
            }
          }
        }
      }
    }
    .ant-modal-footer {
      .ant-btn-primary {
        background: #411f90 0% 0% no-repeat padding-box;
        border: 1px solid #411f90;
        color: #fff;
      }
      .rescan {
        float: left;
        border: 1px solid #411f90;
        color: #411f90;
      }
    }
  }
}
.cookie-duration {
  li {
    text-transform: capitalize;
  }
}

.scan-site-selection {
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #411f90;
  }
}