.vendor-scan {

  .ant-modal-content {
    .ant-modal-body {
      padding: 0 0;

      .ant-select {
        width: 100%;
      }
    }

    .ant-modal-footer {
      .ant-btn:nth-child(2) {
        background-color: #411f90;
        color: #fff;
        font-family: Oswald;
      }
    }

    i.anticon.anticon-info-circle {
      color: gray;
    }

    padding-top: 35px;
    max-width: 480px;

    .modal-top{
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 15px;
    }

    .auto-add-rescan-container {
      display: flex;
      justify-content: space-between;

      p.rescan {
        text-decoration: underline;
        color: #1370FB;
        padding-top: 15px;
        cursor: pointer;
      }
    }

    p {
      color: #30425C;
      font-size: 12px;
      line-height: 16px;
    }

    p.title {
        font-size: 18px;
        font-weight: bold;
        line-height: 16px;
    }

    .list-of-vendors-container {
      i {
        cursor: pointer;
      }

      .ant-checkbox-wrapper {
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
      }

      .scrolable-vendors {
        max-height: 400px;
        overflow: scroll;

        .top {
          display: flex;
          justify-content: space-between;
        }
      }

      .select-all {

        .ant-checkbox-wrapper {
          font-size: 10px;
          line-height: 10px;
          font-weight: normal;
        }

        .selected-num {
          display: flex;

          p {
          	font-size: 12px;
            font-weight: 600;
          }

          i {
            color: #7AD91A;
            margin-top: 1px;
            margin-right: 5px;
            font-size: 14px;
          }
        }

        li {
          border-top: 1px solid #E5E9EE;
          background-color: #F7F9FC;
          padding: 10px 30px;
          display: flex;
          justify-content: space-between;

          &:first-child {
            padding-bottom: 0;
            font-size: 10px;
            line-height: 10px;
          }
        }
      }

      li {
        padding: 15px 30px;
        border-bottom: 1px solid #E5E9EE;
        font-size: 12px;
        line-height: 12px;

        &.detail-open {
          background-color: #F7F9FC;
        }
      }
    }

    .scanned-vendor-detail-container {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        white-space: nowrap;
      }

      p {
        padding-right: 10px;
        margin-bottom: 0;
      }

      > div {
        margin-top: 10px;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
