    .sdk-layout {
        margin-top: 60px;
        background: white;

        .sdk-list {
            list-style-type: disc;
            padding-left: 25px;
        }
 
        .sdks-list h3{
            font-size: 25px;
            font-weight: 800;
            font-family: 'Proxima-Nova';
        }
          
        .main-sdk {
            width: 100%;
            margin: 0 auto;
        }

        .help-paragraph {
            width: 100%;
            padding: 50px 389px;
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 17px;
        }  
        .help-paragraph-title { 
            text-align: center;
            font-size: 20px;
            font-weight: 800;
            line-height: 24px;
            font-family: "Proxima-Nova";
        }
        .help-paragraph-desc {
            font-size: 13px;
            line-height: 18px;
            margin-top: 10px;
            margin-bottom: 20px;
            text-align: center;
        }
    }
